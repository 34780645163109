import React from "react";
import { Quizzes } from "./Quizzes";
import { QuizBrowseContextProvider } from "./QuizBrowseContext";

export const QuizBrowseContainer = () => {
  return (
    <QuizBrowseContextProvider>
      <Quizzes />
    </QuizBrowseContextProvider>
  );
};
