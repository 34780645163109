import React, { Suspense } from "react";
import classNames from "classnames";
import { NavLink, Route, Switch } from "react-router-dom";

import { Account } from "./account/Account";
import { Notifications } from "./notifications/Notifications";
import { EditProfile } from "./edit-profile/EditProfile";
import { Affiliations } from "./affiliations/Affiliations";
import { Stripe } from "./stripe/Stripe";

import { routes } from "routes";
import { LoadingSpinner } from "../../components/loading-spinner/LoadingSpinner";
import {Helmet} from "react-helmet";

const tabs = [
  {
    route: routes.myProfileEditProfile,
    name: "Edit profile",
  },
  {
    route: routes.myProfile,
    name: "Account",
  },
  {
    route: routes.myProfileAffiliations,
    name: "Affiliations",
  },
  {
    route: routes.myProfileNotifications,
    name: "Notifications",
  },
  /*{
    route: routes.myProfileStripe,
    name: "Stripe Setup",
  },*/
];

export const MyProfile = () => {
  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | My Profile</title>
      </Helmet>
      <div className="">
        <div className="flex w-full flex-wrap">
          {tabs.map(({ route, name }) => (
            <NavLink
              key={route}
              to={route}
              activeClassName="border-b-4 border-indigo-600 text-black"
              exact
              className={classNames(
                "md:w-auto flex items-center justify-center h-full text-sm relative font-medium mr-6 py-3 md:py-4 text-gray-500 text-base"
              )}
            >
              {name}
            </NavLink>
          ))}
        </div>
      </div>

      <div className="bg-white border border-gray-200 rounded p-4 md:p-8 mt-4">
        <Switch>
          <Route path={routes.myProfile} exact>
            <Suspense
              fallback={
                <div className="flex justify-center">
                  <LoadingSpinner className="w-6 text-purple-600" />
                </div>
              }
            >
              <Account />
            </Suspense>
          </Route>

          <Route path={routes.myProfileEditProfile}>
            <EditProfile />
          </Route>

          <Route path={routes.myProfileNotifications}>
            <Notifications />
          </Route>

          <Route path={routes.myProfileAffiliations}>
            <Affiliations />
          </Route>

          {/*<Route path={routes.myProfileStripe}>
            <Stripe />
          </Route>*/}
        </Switch>
      </div>
    </div>
  );
};
