import React from "react";
import { ClockIcon } from "@heroicons/react/outline";
import { FlagIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { useQuery } from "react-query";
import get from "lodash/get";

import { Button } from "components/button/Button";
import { getUserRequest } from "../../../api/user/user";
import { routes } from "../../../routes";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";

const Badge = ({ text }: { text: string }) => {
  return (
    <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-1 text-sm font-medium text-yellow-800 m-1">
      <div className="w-5 mr-1">
        <ClockIcon className="w-5 h-5 text-yellow-500" />
      </div>
      <div>{text}</div>
    </span>
  );
};

export const Gamification = () => {
  const { data, isLoading } = useQuery("user", () => getUserRequest(), {
    suspense: false,
  });

  const profile: { [key: string]: boolean } = {
    name: !!get(data, "data.name"),
    affiliation: !!get(data, "data.affiliation"),
    avatar: !!get(data, "data.avatar"),
    social: !!get(data, "data.social") || !!get(data, "data.website"),
    location: !!get(data, "data.location"),
    bio: !!get(data, "data.bio"),
    area_of_interest: get(data, "data.area_of_interest", []).length > 0,
    tags: get(data, "data.tags", []).length > 0,
    looking_for: get(data, "data.looking_for", []).length > 0,
  };

  const profileCompletionNum = Object.keys(profile).reduce(
    (acc, item) => (profile[item] ? 1 : 0) + acc,
    0
  );

  return (
    <div className="rounded-lg p-5 bg-violet-50 shadow-lg ring-1 ring-black ring-opacity-5">
      <div className="text-xl font-medium flex items-center justify-center">
        {profileCompletionNum === 9 && <FlagIcon className='w-5 mr-2 text-green-500' />}
        Profile completion {((profileCompletionNum / 9) * 100).toFixed(0)}%
      </div>

      {isLoading && !data ? (
        <div className="flex items-center justify-between min-h-full">
          <IconLoading />
        </div>
      ) : (
        <div>
          <div className="mt-3">
            <div className="flex w-full bg-white rounded-full h-3 dark:bg-gray-700">
              <div
                className="flex items-center justify-center bg-green-500 h-3 rounded-full text-white text-sm"
                style={{
                  width: `${(profileCompletionNum / 9) * 100}%`,
                }}
              />
            </div>
          </div>

          {profileCompletionNum !== 9 && <div className="flex justify-center flex-wrap my-5">
            {!profile.name && <Badge text="Name" />}
            {!profile.affiliation && <Badge text="Affiliation" />}
            {!profile.avatar && <Badge text="Profile Picture" />}
            {!profile.social && (
              <Badge text="At least one of the following: Website, Google Scholar, LinkedIn, Twitter, Instagram, Facebook" />
            )}
            {!profile.location && <Badge text="Location" />}
            {!profile.bio && <Badge text="Bio" />}
            {!profile.area_of_interest && <Badge text="Areas of Interest" />}
            {!profile.tags && <Badge text="User Tags" />}
            {!profile.looking_for && (
              <Badge text="What are you looking for with Let’s Get Proof?" />
            )}
          </div>}

          {profileCompletionNum !== 9 && (
            <NavLink to={routes.myProfileEditProfile}>
              <Button variant="primary" className="w-full">
                Complete Your Profile
              </Button>
            </NavLink>
          )}
        </div>
      )}
    </div>
  );
};
