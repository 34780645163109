import { PropsWithoutRef } from "react";

export interface FieldErrorProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["p"]>, "className"> {}

export const FieldError = ({ children, ...props }: FieldErrorProps) => (
  <p {...props} className="mt-1 text-sm text-red-600">
    {children}
  </p>
);
