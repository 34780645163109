import { forwardRef, PropsWithoutRef } from "react";
import { FieldProps, SelectOption } from "types/field";
import { FieldGroup } from "../FieldGroup";

export type SelectFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["select"]>,
  "className" | "children"
> &
  FieldProps & {
    options?: SelectOption[];
  };

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({ options, children, required, optional, ...props }, ref) => (
    <FieldGroup required={required} optional={optional} {...props}>
      {(computedProps) => (
        <select ref={ref} {...computedProps} {...props}>
          {options
            ? options.map((option, index) => (
                <option
                  key={index}
                  disabled={option.disabled}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))
            : children}
        </select>
      )}
    </FieldGroup>
  )
);
