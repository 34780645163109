import React, { useCallback, useState } from "react";
import { useMutation, useQuery } from "react-query";
import classNames from "classnames";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import * as z from "zod";
import { CheckIcon } from "@heroicons/react/solid";

import { Button } from "components/button/Button";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";
import { TextField } from "components/text-field/TextField";
import { PlayerField } from "components/player-field/PlayerField";
import { ImageUploadField } from "components/image-upload-field/ImageUploadField";
import { TextareaField } from "components/textarea-field/TextareaField";
import { RichEditorField } from "components/rich-editor-field/RichEditorField";
import { getProjectsTagsRequest } from "api/project/project";

import {
  WorkingGroupData,
  createWorkingGroup,
  patchWorkingGroup,
  getWorkingGroupGoals,
  getWorkingGroupRolesOfPartner,
  uploadImageWorkingGroup,
  wgSetSupplementaryFileRequest,
  wgUpdateSupplementaryFileRequest,
  wgSupplementaryDeleteRequest,
} from "api/workingGroups";

import { setFormError } from "utils/setError";
import { validURL } from "utils/url";
import { WorkingGroupPreview } from "../working-group-preview/WorkingGroupPreview";
import { useModal } from "hooks/use-modal";
import { Modal } from "components/modal/Modal";

import { useContextService } from "hooks/use-context-service";
import { pipe } from "fp-ts/function";
import { useStringRequired } from "../../../hooks/use-validation-input";
import { getUserRequest } from "../../../api/user/user";
import { showNotification as showNotificationService } from "../../../services/notification";
import { FileUploadField } from "../../../components/file-upload-field/FileUploadField";
import { TrashIcon } from "@heroicons/react/outline";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";

const useFormSchema = ({
  step,
  workingGroupsGoals,
  workingGroupsRoles,
}: {
  step: number;
  workingGroupsGoals?: { data?: { id: number; slug: string }[] };
  workingGroupsRoles?: { data?: { id: number; slug: string }[] };
}) =>
  z
    .object({
      title: pipe(z.string(), useStringRequired()),
      tags: z.number().array().min(1, "This field is required"),
      summary: pipe(z.string(), useStringRequired()).refine(
        (val) => val !== "<p><br></p>" || !val,
        {
          message: "This field is required",
        }
      ),

      image: pipe(z.string(), useStringRequired()),
      embedded_link: z.nullable(z.string()).optional(),
      additional_info: z.nullable(z.string()).optional(),

      goals_of_working_group: z
        .string()
        .array()
        .optional()
        .refine((val) => (step === 3 ? val && val.length !== 0 : true), {
          message: "This field is required",
        }),
      other_goals_of_wg: z.string().optional(),
      industry_partner_likely: z
        .nullable(z.string())
        .optional()
        .refine((val) => (step === 3 ? Boolean(val) : true), {
          message: "This field is required",
        }),
      role_of_industry_partner: z.number().array().optional(),
      other_role_of_industry_partner: z.string().optional(),
      member_obligations: z
        .string()
        .optional().refine(
        (val) => step === 3 ? val !== "<p><br></p>" || !val : true,
        {
          message: "This field is required",
        }
      ),
      supplementary_materials:
        step === 2
          ? z
              .object({
                item_id: z.string().optional().nullable(),
                name: z.string().optional().nullable(),
                url: z.string().optional().nullable(),
                label: z.string().optional().nullable(),
                file_type: z.string().optional().nullable(),
                file_url: z.string().optional().nullable(),
              })
              .array()
          : z.any(),
    })
    .refine(
      (data) =>
        !(
          data.industry_partner_likely === "yes" &&
          step === 3 &&
          (!data.role_of_industry_partner ||
            data?.role_of_industry_partner?.length === 0)
        ),
      {
        message: "This field is required",
        path: ["role_of_industry_partner"],
      }
    )
    // .refine(
    //   (data) =>
    //     !(
    //       data.goals_of_working_group &&
    //       data.goals_of_working_group.includes(
    //         get(
    //           find(
    //             workingGroupsGoals?.data || [],
    //             ({ slug }) => slug === "other"
    //           ),
    //           "id",
    //           0
    //         )
    //       ) &&
    //       !data.other_goals_of_wg &&
    //       step === 3
    //     ),
    //   {
    //     message: "This field is required",
    //     path: ["other_goals_of_wg"],
    //   }
    // )
    .refine(
      (data) =>
        !(
          data.role_of_industry_partner &&
          data.role_of_industry_partner.includes(
            get(
              find(
                workingGroupsRoles?.data || [],
                ({ slug }) => slug === "other"
              ),
              "id",
              0
            )
          ) &&
          !data.other_role_of_industry_partner &&
          step === 3
        ),
      {
        message: "This field is required",
        path: ["other_role_of_industry_partner"],
      }
    );

export const SupplementaryItem = ({
  group_id,
  index,
  id,
  initialFileType,
  item_id,
  removeSupplementary,
  hasFile,
  type,
}: {
  group_id: string;
  index: number;
  id: string;
  item_id: string;
  initialFileType: string;
  removeSupplementary: (index: number) => void;
  hasFile: boolean;
  type?: string;
}) => {
  const showNotification = useContextService(showNotificationService);

  const [loaded, setLoaded] = useState(0);
  const [isLinkSuccess, setIsLinkSuccess] = useState(false);
  const [smType, setSMType] = useState("document");
  const [error, setError] = useState<
    { type: string; message: string } | undefined
  >();

  const {
    getValues,
    formState,
    register,
    control,
    setError: setFormError,
    setValue,
  } = useFormContext();

  const wgSupplementaryDeleteQuery = useMutation(wgSupplementaryDeleteRequest);

  const uploadFileSupplementaryQuery = useMutation(
    wgSetSupplementaryFileRequest
  );
  const wgUpdateSupplementaryFileRequestQuery = useMutation(
    wgUpdateSupplementaryFileRequest
  );

  const uploadFileSupplementaryCallback = useCallback(
    async (uploadFile) => {
      setError(undefined);

      if (uploadFile) {
        const file = await fetch(uploadFile);

        const formData = new FormData();
        formData.append("file", await file.blob(), "group");
        formData.append(
          "file_name",
          getValues(`supplementary_materials.${index}.name`)
        );

        try {
          const result = await uploadFileSupplementaryQuery.mutateAsync({
            group_id,
            data: formData,
            setLoaded,
          });

          setValue(
            `supplementary_materials.${index}.item_id`,
            `${result.data.data.id}`
          );
        } catch (e) {
          showNotification({
            title: "Error",
            message: get(
              e,
              "response.data.messages.file[0]",
              "Something went wrong"
            ),
            variant: "ERROR",
          });

          setError({
            type: "error",
            message: get(e, "response.data.messages.file[0]", "Error"),
          });
        }
      }
    },
    [
      setValue,
      setError,
      group_id,
      uploadFileSupplementaryQuery,
      showNotification,
      getValues,
      index,
    ]
  );

  const saveLinkSupplementaryCallback = useCallback(async () => {
    setError(undefined);

    if (!getValues(`supplementary_materials.${index}.label`)) {
      setFormError(`supplementary_materials.${index}.label`, {
        type: "custom",
        message: "Required",
      });
    }

    if (!getValues(`supplementary_materials.${index}.url`)) {
      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: "Required",
      });
    }

    if (!validURL(getValues(`supplementary_materials.${index}.url`))) {
      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: "Not a valid url",
      });

      return;
    }

    if (
      !(
        getValues(`supplementary_materials.${index}.label`) &&
        getValues(`supplementary_materials.${index}.url`)
      )
    ) {
      return;
    }

    const formData = new FormData();
    formData.append(
      "label",
      getValues(`supplementary_materials.${index}.label`)
    );
    formData.append("url", getValues(`supplementary_materials.${index}.url`));
    formData.append("type", "link");

    try {
      const result = await (item_id
        ? wgUpdateSupplementaryFileRequestQuery
        : uploadFileSupplementaryQuery
      ).mutateAsync({
        id: item_id,
        group_id,
        data: formData,
        setLoaded,
      });

      setValue(
        `supplementary_materials.${index}.item_id`,
        `${result.data.data.id}`
      );

      setIsLinkSuccess(true);

      setTimeout(() => {
        setIsLinkSuccess(false);
      }, 1000);
    } catch (e) {
      showNotification({
        title: "Error",
        message: get(
          e,
          "response.data.messages.file[0]",
          "Something went wrong"
        ),
        variant: "ERROR",
      });

      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: JSON.stringify(get(e, "response.data.messages", "Error")),
      });
    }
  }, [
    item_id,
    setFormError,
    setValue,
    setError,
    group_id,
    wgUpdateSupplementaryFileRequestQuery,
    uploadFileSupplementaryQuery,
    showNotification,
    getValues,
    index,
  ]);

  return (
    <>
      <input
        type="hidden"
        {...register(`supplementary_materials.${index}.item_id`)}
      />

      <div className="flex-1 hidden">
        <TextField
          label="File name"
          placeholder="File name"
          required
          disabled={uploadFileSupplementaryQuery.isSuccess || hasFile}
          error={get(
            formState.errors,
            `supplementary_materials[${index}].name`
          )}
          {...register(`supplementary_materials.${index}.name`)}
        />
      </div>

      {!type &&
        !(uploadFileSupplementaryQuery.isSuccess || hasFile) &&
        !uploadFileSupplementaryQuery.isLoading && (
          <div className="pb-4">
            <div className="text-xs text-gray-600">Type</div>
            <label htmlFor="field-document" className="mr-3">
              <input
                type="radio"
                name="type"
                value="document"
                checked={smType === "document"}
                id="field-document"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSMType("document");
                  }
                }}
              />
              <span className="ml-1 text-sm font-medium text-gray-700">
                Document
              </span>
            </label>
            <label htmlFor="field-link">
              <input
                type="radio"
                name="type"
                value="link"
                checked={smType === "link"}
                id="field-link"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSMType("link");
                  }
                }}
              />
              <span className="ml-1 text-sm font-medium text-gray-700">
                Link
              </span>
            </label>
          </div>
        )}

      {type === "link" || smType === "link" ? (
        <div className="w-full py-4" style={{ maxWidth: "420px" }}>
          <div className="flex mb-4">
            <div className="mr-4" style={{ width: "348px " }}>
              <div className="" style={{ width: "332px " }}>
                <TextField
                  label="Description"
                  placeholder="Description"
                  error={get(
                    formState.errors,
                    `supplementary_materials[${index}].label`
                  )}
                  {...register(`supplementary_materials.${index}.label`)}
                />
              </div>
            </div>

            <div className="w-16 pt-1 mt-6 ml-2">
              {!uploadFileSupplementaryQuery.isLoading && (
                <button
                  className="text-red-600 flex items-center mt-0.5"
                  type="button"
                  onClick={async () => {
                    if (item_id) {
                      await wgSupplementaryDeleteQuery.mutateAsync({
                        id: item_id,
                        group_id,
                      });
                    }
                    removeSupplementary(index);
                  }}
                >
                  <TrashIcon className="w-5" />
                  {wgSupplementaryDeleteQuery.isLoading && (
                    <IconLoading className="w-4 h-4 ml-2" />
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="mr-4 mb-4">
            <TextField
              label="URL"
              placeholder="URL"
              error={get(
                formState.errors,
                `supplementary_materials[${index}].url`
              )}
              {...register(`supplementary_materials.${index}.url`)}
            />
          </div>

          <div className="flex">
            <Button
              size="exSmall"
              type="button"
              disabled={
                uploadFileSupplementaryQuery.isLoading ||
                wgUpdateSupplementaryFileRequestQuery.isLoading
              }
              loading={
                uploadFileSupplementaryQuery.isLoading ||
                wgUpdateSupplementaryFileRequestQuery.isLoading
              }
              spinner
              onClick={() => {
                saveLinkSupplementaryCallback();
              }}
            >
              Save
            </Button>

            {isLinkSuccess && (
              <div className="rounded bg-green-100 text-green-700 text-xs flex items-center px-2 ml-2">
                Success
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full mr-4" style={{ maxWidth: "420px" }}>
          <div className="w-full mr-4" style={{ width: "420px" }}>
            <div className="flex">
              <div className="flex-1">
                <FileUploadField
                  label="File"
                  name={`supplementary_materials.${index}.url`}
                  fileNameField={`supplementary_materials.${index}.name`}
                  fileNameValue={getValues(
                    `supplementary_materials.${index}.name`
                  )}
                  error={error}
                  isLoading={uploadFileSupplementaryQuery.isLoading}
                  disabled={uploadFileSupplementaryQuery.isSuccess || hasFile}
                  control={control}
                  setValue={setValue}
                  initialFileType={initialFileType}
                  onChangeProp={(val) => {
                    uploadFileSupplementaryCallback(val);
                  }}
                  loaded={loaded}
                />
              </div>
              <div className="w-16 pt-1 mt-6 ml-2">
                {!uploadFileSupplementaryQuery.isLoading && (
                  <button
                    className="text-red-600 flex items-center mt-0.5"
                    type="button"
                    onClick={async () => {
                      if (item_id) {
                        await wgSupplementaryDeleteQuery.mutateAsync({
                          id: item_id,
                          group_id,
                        });
                      }
                      removeSupplementary(index);
                    }}
                  >
                    <TrashIcon className="w-5" />
                    {wgSupplementaryDeleteQuery.isLoading && (
                      <IconLoading className="w-4 h-4 ml-2" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const WorkingGroupForm = ({
  id,
  initialValues,
  supplementary_items,
}: {
  id?: string;
  initialValues?: WorkingGroupData;
  supplementary_items?: {
    id?: string;
    name: string;
    label?: string;
    type?: string;
    url: string;
    file_url?: string;
  }[];
}) => {
  const [step, setStep] = useState(1);
  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  const { data: workingGroupsGoals } = useQuery(
    `getWGGoals`,
    useContextService(getWorkingGroupGoals),
    {
      suspense: false,
    }
  );

  const { data: workingGroupsRoles } = useQuery(
    `getWGRoles`,
    useContextService(getWorkingGroupRolesOfPartner),
    {
      suspense: false,
    }
  );

  const { data: meData } = useQuery("user", () => getUserRequest(), {
    suspense: false,
    onError: (e) => {
      console.log(e);
    },
  });

  const createWorkingGroupQuery = useMutation(
    useContextService(createWorkingGroup),
    {
      onSuccess: (response) => {
        setCreatedId(response?.data?.data.id!);
        // setFormSuccessMessage("Success");
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const formMethods = useForm<WorkingGroupData>({
    mode: "onChange",
    resolver: zodResolver(
      useFormSchema({ step, workingGroupsGoals, workingGroupsRoles })
    ),
    defaultValues: initialValues
      ? {
          title: initialValues.title || "",
          tags: initialValues.tags || "",
          summary: initialValues.summary || "",

          goals_of_working_group: initialValues.goals_of_working_group || [],
          other_goals_of_wg: initialValues.other_goals_of_wg || "",
          industry_partner_likely: initialValues.industry_partner_likely,
          role_of_industry_partner:
            initialValues.role_of_industry_partner || [],
          other_role_of_industry_partner:
            initialValues.other_role_of_industry_partner || "",
          member_obligations:
            initialValues.member_obligations ||
            "<ul><li>Contribute to Scientific Discussions</li><li>Post at least one comment per month</li><li>Have fun!</li></ul>",

          image: initialValues.image || "",
          embedded_link: initialValues.embedded_link || "",
          additional_info: initialValues.additional_info || "",
          supplementary_materials:
            supplementary_items && supplementary_items.length > 0
              ? supplementary_items.map((item) => ({
                  item_id: `${item.id}`,
                  name: `${item.name}`,
                  label: `${item.label}`,
                  file_type: `${item.type}`,
                  url: `${item.url || item.file_url}`,
                }))
              : [],
        }
      : {
          member_obligations:
            "<ul><li>Contribute to Scientific Discussions</li><li>Post at least one comment per month</li><li>Have fun!</li></ul>",
        },
  });

  const {
    register,
    control,
    handleSubmit,
    formState,
    setValue,
    setError,
    watch,
  } = formMethods;

  const {
    fields: fieldsSupplementary,
    append: appendSupplementary,
    remove: removeSupplementary,
  } = useFieldArray({
    control,
    name: "supplementary_materials",
  });

  const { modalVisible, openModal, closeModal } = useModal();

  const [createdId, setCreatedId] = useState<string | undefined>();

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const formValues = watch();

  const patchWorkingGroupService = useContextService(patchWorkingGroup);
  const uploadImageWorkingGroupService = useContextService(
    uploadImageWorkingGroup
  );
  const uploadImageQuery = useMutation((data: any) =>
    uploadImageWorkingGroupService((id || createdId)!, data)
  );

  const patchWorkingGroupQuery = useMutation(
    (data: WorkingGroupData) =>
      patchWorkingGroupService((id || createdId)!, data),
    {
      onSuccess: () => {
        // setFormSuccessMessage("Success");
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const nextStep = useCallback(() => {
    window.scrollTo(0, 0);
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);
    setStep(step + 1);
  }, [step]);

  const onSubmit = async (data: WorkingGroupData) => {
    if (step === 1) {
      try {
        await (id
          ? patchWorkingGroupQuery.mutateAsync
          : createWorkingGroupQuery.mutateAsync)({
          title: data.title,
          summary: data.summary,
          tags: data.tags,
          embedded_link: data.embedded_link,
          additional_info: data.additional_info,
        });

        if (data.image && !data.image.includes("s3.amazonaws.com")) {
          const image = await fetch(data.image);

          const formData = new FormData();
          formData.append("file", await image.blob(), "working_group");

          await uploadImageQuery.mutateAsync(formData);
        }

        nextStep();
      } catch (e) {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      }
    }

    if (step === 2) {
      return;
    }

    if (step === 3) {
      try {
        await patchWorkingGroupQuery.mutateAsync({
          goals_of_working_group: data.goals_of_working_group,
          other_goals_of_wg: data.other_goals_of_wg,
          industry_partner_likely: data.industry_partner_likely,
          role_of_industry_partner: data.role_of_industry_partner,
          other_role_of_industry_partner: data.other_role_of_industry_partner,
          member_obligations: data.member_obligations,
        });
        nextStep();
      } catch (e) {
        console.log(e);
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      }
    }
  };

  return (
    <>
      <Modal visible={modalVisible} onClose={closeModal} className="sm:w-11/12">
        <WorkingGroupPreview workingGroup={formValues} created_by={meData} />
      </Modal>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col flex-wrap justify-between md:flex-row">
            <h2 className="mb-3 text-3xl font-bold">
              <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800">
                Working group
              </span>{" "}
              {formValues.title}
            </h2>

            <div className="flex items-center mb-3">
              {/*<Button variant="secondary" type="button" onClick={openModal}>
                Preview
              </Button>*/}
              {step <= 3 && (
                <Button
                  type="button"
                  className="ml-4"
                  loading={
                    createWorkingGroupQuery.isLoading ||
                    patchWorkingGroupQuery.isLoading ||
                    uploadImageQuery.isLoading
                  }
                  spinner
                  onClick={() => {
                    if (step === 2) {
                      window.scrollTo(0, 0);
                      setFormSuccessMessage(undefined);
                      setFormErrorMessage(undefined);
                      setTimeout(() => {
                        setStep(step + 1);
                      }, 0);
                    }

                    handleSubmit(onSubmit)();
                  }}
                >
                  {step === 3 ? "Submit" : step === 2 ? "Next" : "Save & next"}
                </Button>
              )}
            </div>
          </div>

          <nav aria-label="Progress" className="mt-5 mb-10">
            <ol className="bg-white border border-gray-300 divide-y divide-gray-300 rounded-md md:flex md:divide-y-0">
              {[
                { name: "General", id: "01" },
                { name: "Supplementary Materials ", id: "02" },
                { name: "Specifics", id: "03" },
              ].map((stepItem, stepIdx) => (
                <li key={stepItem.name} className="relative md:flex-1 md:flex">
                  {stepIdx + 1 < step ? (
                    <span className="flex items-center w-full group">
                      <span className="flex items-center px-6 py-3 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-green-500 rounded-full">
                          <CheckIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-4 text-sm font-medium text-green-500">
                          {stepItem.name}
                        </span>
                      </span>
                    </span>
                  ) : stepIdx + 1 === step ? (
                    <span
                      className="flex items-center px-6 py-3 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-green-500 rounded-full">
                        <span className="text-green-500">{stepItem.id}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-gray-900">
                        {stepItem.name}
                      </span>
                    </span>
                  ) : (
                    <span className="flex items-center group">
                      <span className="flex items-center px-6 py-3 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full">
                          <span className="text-gray-500">{stepItem.id}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500">
                          {stepItem.name}
                        </span>
                      </span>
                    </span>
                  )}

                  {stepIdx !== 2 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div
                        className="absolute top-0 right-0 hidden w-5 h-full md:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-full h-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>

          <div className={classNames({ hidden: step !== 1 })}>
            <div className="mb-5">
              <TextField
                label="Title"
                placeholder="Title"
                error={formState.errors?.title}
                {...register("title", { required: true })}
              />
            </div>

            <div className="mb-5">
              <MultiSelectField
                label="Category"
                error={formState.errors?.tags}
                name="tags"
                control={control}
                defaultFirst
                options={map(get(projectTags, "data"), ({ id, title }) => ({
                  value: id,
                  label: title,
                }))}
              />
            </div>

            <div className="mb-5">
              <RichEditorField
                label="Summary"
                placeholder="Summary"
                name="summary"
                error={formState.errors?.summary}
                control={control}
              />
            </div>

            <div className="block">
              <div className="md:w-1/2">
                <div>
                  <RichEditorField
                    label="Additional info"
                    placeholder="Additional info"
                    name="additional_info"
                    error={formState.errors?.additional_info}
                    control={control}
                  />
                </div>
                <div className="mt-5">
                  <ImageUploadField
                    label="Associated image"
                    name="image"
                    error={formState.errors?.image}
                    control={control}
                    setValue={setValue}
                  />
                </div>
                <div className="mt-5">
                  <PlayerField
                    label="Associated video"
                    optional
                    name="embedded_link"
                    error={formState.errors?.embedded_link}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classNames({ hidden: step !== 3 })}>
            <div className="block">
              <div className="md:w-1/2">
                <div className="mb-5">
                  <MultiSelectField
                    isCreatable
                    label="Goal of working group"
                    error={formState.errors?.goals_of_working_group}
                    name="goals_of_working_group"
                    control={control}
                    defaultFirst
                    options={[
                      {
                        label: "Research Discussion",
                        value: "Research Discussion",
                      },
                      {
                        label: "Create a Registry",
                        value: "Create a Registry",
                      },
                      {
                        label: "Case Discussion",
                        value: "Case Discussion",
                      },
                      {
                        label: "Literature Discussion",
                        value: "Literature Discussion",
                      },
                      {
                        label: "Lab Group",
                        value: "Lab Group",
                      },
                    ]}
                  />
                </div>
                {formValues.goals_of_working_group &&
                  formValues.goals_of_working_group.includes(
                    get(
                      find(
                        workingGroupsGoals?.data || [],
                        ({ slug }) => slug === "other"
                      ),
                      "id",
                      0
                    )
                  ) && (
                    <div className="mb-5">
                      <TextareaField
                        label="Other"
                        placeholder="Other"
                        error={formState.errors?.other_goals_of_wg}
                        {...register("other_goals_of_wg", { required: true })}
                      />
                    </div>
                  )}

                <div className="mb-5">
                  <div className="block mb-1 text-sm font-medium text-gray-700">
                    Is there likely to be an industry partner?
                  </div>

                  <label htmlFor="field-yes" className="mr-3">
                    <input
                      {...register("industry_partner_likely")}
                      type="radio"
                      name="industry_partner_likely"
                      value="yes"
                      id="field-yes"
                    />
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      Yes
                    </span>
                  </label>
                  <label htmlFor="field-no">
                    <input
                      {...register("industry_partner_likely")}
                      type="radio"
                      name="industry_partner_likely"
                      value="no"
                      id="field-no"
                    />
                    <span className="ml-1 text-sm font-medium text-gray-700">
                      No
                    </span>
                  </label>

                  {formState.errors?.industry_partner_likely && (
                    <div className="mt-1 text-sm text-red-600">
                      {formState.errors?.industry_partner_likely.message}
                    </div>
                  )}
                </div>

                {formValues.industry_partner_likely === "yes" && (
                  <div className="mb-5">
                    <MultiSelectField
                      label="What is the role of the industry partner?"
                      error={formState.errors?.role_of_industry_partner}
                      name="role_of_industry_partner"
                      control={control}
                      defaultFirst
                      options={(workingGroupsRoles?.data || []).map(
                        ({ id, title }) => ({
                          value: id,
                          label: title,
                        })
                      )}
                    />
                  </div>
                )}

                {formValues.role_of_industry_partner &&
                  formValues.role_of_industry_partner.includes(
                    get(
                      find(
                        workingGroupsRoles?.data || [],
                        ({ slug }) => slug === "other"
                      ),
                      "id",
                      0
                    )
                  ) && (
                    <div className="mb-5">
                      <TextareaField
                        label="Other"
                        placeholder="Other"
                        error={formState.errors?.other_role_of_industry_partner}
                        {...register("other_role_of_industry_partner", {
                          required: true,
                        })}
                      />
                    </div>
                  )}

                <div className="mb-5">
                  <RichEditorField
                    label="Member obligations"
                    placeholder="Member obligations"
                    name="member_obligations"
                    error={formState.errors?.member_obligations}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classNames({ hidden: step !== 2 })}>
            <div className="mb-8">
              <h4 className="text-base font-medium">Supplementary Materials</h4>

              <p className="mb-4 text-gray-700">
                Have something else you want to share (study protocols, images,
                figures, tables, etc.)? Upload it here!
              </p>

              <div className="w-full max-w-xl px-4 py-2 mt-2 mb-4 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                This information will be made public. <br /> Please ensure you
                have the appropriate permissions to upload these files.
              </div>

              <ul className="max-w-sm mb-8">
                {fieldsSupplementary.map((item, index) => (
                  <li
                    key={item.id}
                    className={classNames("my-2 py-2", {
                      "border-t border-gray-200": !!index,
                    })}
                  >
                    <SupplementaryItem
                      group_id={(id || createdId)!}
                      id={item.id}
                      type={item.file_type}
                      hasFile={Boolean(
                        get(
                          formValues,
                          `supplementary_materials.${index}.url`,
                          ""
                        ).includes("s3.amazonaws.com")
                      )}
                      initialFileType={get(
                        formValues,
                        `supplementary_materials.${index}.file_type`
                      )}
                      item_id={get(
                        formValues,
                        `supplementary_materials.${index}.item_id`
                      )}
                      index={index}
                      removeSupplementary={removeSupplementary}
                    />
                  </li>
                ))}
              </ul>
              <Button
                size="exSmall"
                type="button"
                style={{ width: "348px" }}
                onClick={() =>
                  appendSupplementary({
                    item_id: "",
                    name: "",
                    url: "",
                    label: "",
                  })
                }
              >
                Add item
              </Button>
            </div>
          </div>

          {step === 4 && (
            <div className="px-4 py-12 mx-auto text-center max-w-7xl sm:px-6 lg:py-16 lg:px-8">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Success!</span>
                <span className="block">
                  Your working group has been sent for review
                </span>
              </h2>
              <div className="flex justify-center mt-8">
                <div className="inline-flex rounded-md">
                  <Button
                    variant="secondary"
                    size="large"
                    type="button"
                    onClick={openModal}
                  >
                    Preview
                  </Button>
                </div>
              </div>
            </div>
          )}

          {formErrorMessage && (
            <div className="w-full px-4 py-3 mt-5 mb-1 text-sm text-red-800 bg-red-100 rounded">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full px-4 py-3 mt-5 mb-1 text-sm text-green-800 bg-green-100 rounded">
              {formSuccessMessage}
            </div>
          )}

          {step <= 3 && (
            <div className="flex items-center">
              {step > 1 && (
                <Button
                  type="button"
                  className="mt-4 mr-2"
                  variant="secondary"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                type="button"
                className="mt-4"
                loading={
                  createWorkingGroupQuery.isLoading ||
                  patchWorkingGroupQuery.isLoading ||
                  uploadImageQuery.isLoading
                }
                spinner
                onClick={() => {
                  if (step === 2) {
                    window.scrollTo(0, 0);
                    setFormSuccessMessage(undefined);
                    setFormErrorMessage(undefined);
                    setTimeout(() => {
                      setStep(step + 1);
                    }, 0);
                  }

                  handleSubmit(onSubmit)();
                }}
              >
                {step === 3 ? "Submit" : step === 2 ? "Next" : "Save & next"}
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </>
  );
};
