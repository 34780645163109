import { Dispatch, SetStateAction } from "react";
import { get, forEach } from "lodash";
import { UseFormSetError } from "react-hook-form/dist/types/form";

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const setFormError = (
  response: any,
  setFormError: Dispatch<SetStateAction<string | undefined>>,
  setError: UseFormSetError<any>
) => {
  if (get(response, "message")) {
    setFormError(JSON.stringify(get(response, "message")));
    return;
  }
  if (get(response, "messages.non_field_errors")) {
    setFormError(JSON.stringify(get(response, "messages.non_field_errors")));
  }
  if (get(response, "messages.non_field")) {
    setFormError(get(response, "messages.non_field", []).join(","));
  }
  if (get(response, "detail")) {
    setFormError(get(response, "detail"));
  }
  if (get(response, "messages.detail")) {
    setFormError(get(response, "messages.detail"));
  }
  if (get(response, "messages.file")) {
    setFormError(get(response, "messages.file", []).join(","));
  }

  forEach(
    forEach(Object.keys(get(response, "messages", {})), (field) => {
      setError(field, {
        type: "error",
        message: Array.isArray(get(response, `messages.${field}`))
          ? get(response, `messages.${field}`, []).join(", ")
          : JSON.stringify(get(response, `messages.${field}`, "")),
      });
    })
  );
};
