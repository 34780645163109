import React from "react";
import { useContextService } from "../../hooks/use-context-service";
import { getStaticPage } from "../../api/static-pages";
import { useQuery } from "react-query";
import get from "lodash/get";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import classNames from "classnames";
import { Helmet } from "react-helmet";

const GET_INVOLVED = "GET_INVOLVED";

export const GetInvolved = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [GET_INVOLVED],
    () => getStaticPageService({ slug: "get-involved" }),
    {
      suspense: false,
    }
  );

  return (
    <div className="pb-12 mx-auto">
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.title}</title>
          </Helmet>

          {data?.data?.before_title && (
            <h3
              className={classNames("my-2 text-xl font-bold", {
                "text-center": data?.data?.title_align === "CENTER",
                "text-right": data?.data?.title_align === "RIGHT",
              })}
            >
              {data?.data?.before_title}
            </h3>
          )}
          <h2
            className={classNames("my-2 text-3xl font-bold", {
              "text-center": data?.data?.title_align === "CENTER",
              "text-right": data?.data?.title_align === "RIGHT",
            })}
          >
            {data?.data?.title}
          </h2>

          {data?.data && (
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{ __html: data?.data?.body }}
            />
          )}
        </div>
      )}
    </div>
  );
};
