import withQuery from "with-query";
import get from "lodash/get";
import omit from "lodash/omit";
import pickBy from "lodash/pickBy";
import trimEnd from "lodash/trimEnd";

/**
 * Takes an url and params and returns combined url
 * @param {String} url
 * @param {Object} params
 */
export const withParams = (url: string, params = {}) => {
  let rest = { ...params };

  const urlWithParams = `${url}`
    .replace(/:([\w\d.]+)(\?)?/gim, (match, p1, p2) => {
      const param = get(rest, p1, p2 && "");

      rest = omit(rest, p1);

      return param;
    })
    .replace("//", "/");

  return withQuery(
    trimEnd(urlWithParams, "/"),
    pickBy(rest, (p) => p !== null && p !== undefined && p !== "")
  );
};

export const clearQueryParams = () => {
  const newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", newurl);
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};
