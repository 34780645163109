import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";

export const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-gray-900">
      <Helmet>
        <title>Let’s Get Proof | Terms and Conditions</title>
      </Helmet>

      <h1 className="text-3xl font-bold mb-2">Terms and Conditions</h1>
      <div className="text-gray-600 text-sm mb-8">
        Last updated March 2nd, 2022.
      </div>

      <div>
        <p className="p4">Table of Contents</p>
        <ol className="list-decimal ml-8">
          <li className="li4">Introduction</li>
          <li className="li4">Agreement of Terms</li>
          <li className="li4">Change or Updates of Terms</li>
          <li className="li6">Jurisdiction of Use</li>
          <li className="li6">User Registration and Representations</li>
          <li className="li4">Prohibited Activities and User Contributions</li>
          <li className="li4">
            Project Proposal and Working Group Submissions
          </li>
          <li className="li4">
            PrecisionFunding
            <span className="s1">
              <sup>TM</sup>
            </span>
          </li>
          <li className="li4">Fees and Payments</li>
          <li className="li4">Guidelines for Commenting and Reviewing</li>
          <li className="li4">Third Party Websites and Content</li>
          <li className="li4">User Contribution License</li>
          <li className="li4">Let’s Get Proof’s Intellectual Property</li>
          <li className="li4">Social Media</li>
          <li className="li4">Submissions</li>
          <li className="li4">Site Management</li>
          <li className="li4">Privacy Policy</li>
          <li className="li4">Copyright infringements</li>
          <li className="li4">Term and Termination</li>
          <li className="li4">Modifications and Interruptions</li>
          <li className="li4">Governing Law</li>
          <li className="li4">Disclaimer</li>
          <li className="li4">Limitations of Liability</li>
          <li className="li4">Indemnification</li>
          <li className="li4">
            User Data<span className="Apple-converted-space">&nbsp;</span>
          </li>
          <li className="li4">
            Electronic Communications, Transactions, and Signatures
            <span className="Apple-converted-space">&nbsp;</span>
          </li>
          <li className="li4">
            California Users and Residents
            <span className="Apple-converted-space">&nbsp;</span>
          </li>
          <li className="li4">Miscellaneous</li>
        </ol>
        <p className="p7">&nbsp;</p>
        <p className="p5">&nbsp;</p>
        <ol className="ol1">
          <li className="li3">
            <strong className="font-semibold">Introduction</strong>
          </li>
        </ol>
        <p className="p6">
          Let’s Get Proof
          <span className="s1">
            <sup>TM</sup>
          </span>{" "}
          is a global platform that connects researchers, patients, and donors
          looking to fund meaningful work. By bringing together the elements of
          Dynamic Review
          <span className="s1">
            <sup>TM</sup>
          </span>{" "}
          and PrecisionFunding
          <span className="s1">
            <sup>TM</sup>
          </span>
          , our vision is to make medical research more efficient, inclusive,
          and impactful. <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          The following explains our Terms of Use (the “Terms”), please review
          them carefully. Your understanding of these conditions is important to
          us and, while some items need to be expressed in legal terms, we have
          done our best to provide them to you in a language we can all
          understand. If you have any questions, please do not hesitate to reach
          out to us at contact@letsgetproof.com.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Agreement of Terms</strong>
          </li>
        </ol>
        <p className="pb-4">
          These Terms constitute a legally binding agreement made between you,
          whether personally or on behalf of an entity (“you”)
          and&nbsp;Let’s Get Proof Inc.&nbsp;("Company", “we”, “us”, or “our”),
          concerning your access to and use of
          the&nbsp;http://www.letsgetproof.com&nbsp;website as well as any other
          media form, media channel, mobile website or mobile application
          related, linked, or otherwise connected thereto (collectively, the
          “Site”). You agree that by accessing the Site, you have read,
          understood, and agree to be bound by all of these
          Terms,&nbsp;including the&nbsp;Privacy Policy, the Cookie Policy, as
          well as any other rules as outlined on the Site, including but not
          limited to our Researcher Guide section, which are incorporated into these
          Terms.<span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or&nbsp;register for the
          Site. If deemed necessary, we may request proof of age.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Changes or Updates of Terms
            </strong>
          </li>
        </ol>
        <p className="p6">
          The Terms, policies, and guidelines on the Site are subject to change
          at any time and will become effective immediately upon being posted to
          the Site. Revised Terms, policies, and guidelines will never be
          applied retroactively. Continued use of the Site following such
          changes signals your agreement to the new Terms. If you do not agree
          to all of the modifications and changes to the Site, you must cease
          use of the Site. It is your responsibility to check the Site for any
          such modifications or changes to these Terms.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <ol className="ol1">
          <li className="li10">
            <strong className="font-semibold">
              Jurisdiction of Use
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="p6">
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.&nbsp;
        </p>
        <p className="p6">
          The Site is not tailored to comply with industry-specific regulations
          (Health Insurance Portability and Accountability Act (HIPAA), Federal
          Information Security Management Act (FISMA), etc.), so if your
          interactions would be subject to such laws, you may not use this Site.
          You may not use the Site in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </p>
        <ol className="ol1">
          <li className="li10">
            <strong className="font-semibold">
              User Registration and Representations
            </strong>
          </li>
        </ol>
        <p className="p6">
          You may access certain pages of the Site without registering for an
          account. These include but are not limited to browsing Project
          Proposals, Working Groups, and the Lecture Series. Registration is
          however required for certain functions, including but not limited to
          Project Proposal or Working Group submission, Commenting and
          associated functionality, Project Proposal Rating, PrecisionFunding
          <span className="s1">
            <sup>TM</sup>
          </span>{" "}
          (see Section 8 below), as well as viewing the Lecture Series videos.
          Users looking to create a Working Group or Project Proposal,
          regardless of whether for the purposes of the latter are for
          PrecisionFunding
          <span className="s1">
            <sup>TM</sup>
          </span>{" "}
          or not, must have a university/institution-affiliated email address
          and should be able to otherwise provide proof of said affiliation by
          e.g., providing a link to their profile on said
          university’s/institution’s website. The registration process requires
          you to select a username and password. The information provided must
          be accurate, complete, current and shall be updated as necessary. Do
          not impersonate anyone or choose a username that is offensive or may
          violate anyone’s rights. We reserve the right to refuse registration
          or to remove, reclaim, or change a username you select if we
          determine, in our sole discretion, that such username is
          inappropriate, obscene, or otherwise objectionable. Do not create a
          username on behalf of another individual or organization without prior
          authorization to do so. Failure to adhere to these rules constitutes a
          breach of the Terms and may result in Let’s Get Proof cancelling your
          account.<span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="p6">
          You agree to keep your password confidential and will be responsible
          for all use of your account and password. You will immediately notify
          Let’s Get Proof of any unauthorized use of your account or related
          security breach. You agree that you will not access the Site through
          automated or non-human means, whether through a bot, script or
          otherwise, that you will not use the Site for any illegal or
          unauthorized purpose, and that your use of the Site will not violate
          any applicable law or regulation.
        </p>
        <ol className="ol1">
          <li className="li10">
            <strong className="font-semibold">
              Prohibited Activities and User Contributions
            </strong>
          </li>
        </ol>
        <p className="p6">
          Let’s Get Proof strives to create a culture of inclusion and support, as
          well as facilitate collaboration. While we want to encourage free
          expression of opinions and ideas within the community, certain
          behaviors will not be tolerated. In general, you may not access or use
          the Site for any purpose other than that for which we make the Site
          available. The Site may not be used in connection with any commercial
          endeavors except those that are specifically endorsed or approved by
          us.&nbsp;&nbsp;
        </p>
        <p className="p6">
          <strong className="font-semibold">
            <em>6.I. Prohibited Activities</em>
          </strong>
        </p>
        <p className="p6">
          As a user of the Site, you agree not to:
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <ul className="ul1">
          <li className="li6">
            Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li className="li6">
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li className="li6">
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any content or enforce limitations on the use
            of the Site and/or the content contained therein.
          </li>
          <li className="li6">
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li className="li6">
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li className="li6">
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li className="li6">
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
          <li className="li6">
            Engage in unauthorized framing of or linking to the Site.
          </li>
          <li className="li6">
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li className="li6">
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li className="li6">
            Delete the copyright or other proprietary rights notice from any
            content.
          </li>
          <li className="li6">
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li className="li6">
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li className="li6">
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li className="li6">
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li className="li6">
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li className="li6">
            Copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li className="li6">
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Site.
          </li>
          <li className="li6">
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li className="li6">
            Use a buying agent or purchasing agent to make purchases on the
            Site.
          </li>
          <li className="li6">
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li className="li6">
            Use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the content for any revenue-generating endeavor
            or commercial enterprise.
          </li>
          <li className="li6">
            Use the Site to advertise or offer to sell goods and services.
          </li>
          <li className="li6">Sell or otherwise transfer your profile.</li>
        </ul>
        <p className="p6">
          <strong className="font-semibold">
            <em>6.II. User Contributions</em>
          </strong>
          <em>
            <span className="Apple-converted-space">&nbsp;</span>
          </em>
        </p>
        <p className="p6">
          The Site may invite you to chat, contribute to, or participate in
          blogs, message boards, online forums, and other functionality, and
          provides you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be treated as non-confidential and non-proprietary. When
          you create or make available any Contributions, you thereby represent
          and warrant that:
        </p>
        <ul className="ul1">
          <li className="li6">
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li className="li6">
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms.
          </li>
          <li className="li6">
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms.
          </li>
          <li className="li6">
            Your Contributions are not false, inaccurate, or misleading.
          </li>
          <li className="li6">
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li className="li6">
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li className="li6">
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li className="li6">
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </li>
          <li className="li6">
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li className="li6">
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li className="li6">
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </li>
          <li className="li6">
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li className="li6">
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms, or any applicable law
            or regulation.
          </li>
          <li className="li6">
            You understand that Let’s Get Proof has the right, but not the
            obligation, to delete, edit, modify, reformat, excerpt, or translate
            any materials, content or information submitted by you and that all
            information publicly posted or privately transmitted through the
            Site is the sole responsibility of the person from which such
            content originated.
          </li>
          <li className="li6">
            You understand that Let’s Get Proof will not be liable for any errors
            or omissions in any content and that Let’s Get Proof cannot guarantee
            the identity of any other users with whom you may interact in the
            course of using the Site.
          </li>
        </ul>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Project Proposal and Working Group Submissions
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          The following outlines what you are agreeing to by submitting a
          Project Proposal or initiating a Working Group on Let’s Get Proof.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          <strong className="font-semibold">
            <em>
              7.I. All Project Proposal and Working Group Submissions
              <span className="Apple-converted-space">&nbsp;</span>
            </em>
          </strong>
        </p>
        <p className="pb-4">
          When you create a Project Proposal or initiate a Working Group, you
          agree that you have a university/institutional affiliation and are
          able to demonstrate proof of such affiliation via a
          university/institution-associated email address and a link to your
          profile on the university’s/institution’s website. All Project
          Proposals or Working Groups submitted by Project Owners or Working
          Group initiators that do not fulfill both these requirements will
          neither be approved nor published by Let’s Get Proof/the Site.
          Let’s Get Proof reserves the right, but has no obligation, to remove a
          Project or Working Group listing from public listings for any reason.
        </p>
        <p className="pb-4">
          <strong className="font-semibold">
            <em>7.II. Project Proposals Seeking PrecisionFunding</em>
          </strong>
          <span className="s1">
            <strong className="font-semibold">
              <em>
                <sup>TM</sup>
              </em>
            </strong>
          </span>
        </p>
        <p className="pb-4">
          Let’s Get Proof is, in part, a fundraising platform for medical research
          and allows Project Owners to submit Project Proposals with the
          intention of raising funds (PrecisionFundingTM, see Section 8) from
          other users (“Donors”, “Supporters”, “Backers”). To receive funds,
          Project Owners must be willing and able to register a
          university/institutional bank account with our third-party payment
          processor (see Section 8). Project Owners must specify a total budget
          necessary to achieve the research outlined in the Project Proposal. By
          submitting a Project Proposal that is seeking PrecisionFundingTM, you
          (the Project Owner) are inviting Donors to enter into a contract with
          you. It is solely the Donor’s choice to pledge to a Project. Donors
          understand that pledging to a Project does not give them any rights in
          or to that Project, including without limitation any ownership,
          control, or distribution rights. The Project Owner is free to obtain
          additional funding for the Project, enter into contracts for the
          Project, allocate rights in or to the Project, and otherwise direct
          the Project under his/her sole discretion. Upon pledging to a Project,
          a Donor signals that he/she has accepted the terms of and entered into
          that contract. This is a direct legal agreement between the Project
          Owner and the Donor; Let’s Get Proof remains wholly outside of this
          contract and its associated obligations, conditions, and terms. The
          minimum terms of such contracts are as follows:
        </p>
        <ul className="ul1">
          <li className="li8">
            When a Project has been successfully funded (i.e., has reached its
            funding goal) and has been approved by the Content Expert, the
            Project Owner is obligated to carry out the Project as outlined in
            the Project Proposal and according to any associated timelines to
            the best of his/her ability. Following completion of the Project,
            the terms of the contract between Project Owner and Donor are deemed
            fulfilled. <span className="Apple-converted-space">&nbsp;</span>
          </li>
          <li className="li8">
            Project Owners are expected to maintain a high level of effort and
            dedication in executing the research outlined in their Project
            Proposals. Further, Project Owners are expected to maintain open,
            honest, and frequent communication with their Donors.
          </li>
          <li className="li8">
            If Project Owners are unable to fulfill their contractual
            obligations with the Donors for any reason, they must demonstrate
            reasonable effort of finding alternative means of completing their
            Projects or to bringing them to the best possible conclusion for
            their Donors. To this end, it is expected that Project Owners: I)
            regularly communicate with their Donors and describe what work has
            been done, how the funds were used, and what is hindering them from
            completing the Project as planned/outlined in the Project Proposal;
            II) have used the funds appropriately and made every effort to bring
            the Project to completion; III) will work diligently to fulfill the
            Project goals to the best of their ability and within a reasonable
            timeframe; IV) are not misrepresentative in their communication with
            their Donors; V) may offer refunds to their Donors.
          </li>
          <li className="li8">
            Project Owners are solely responsible for fulfilling the terms and
            conditions of their contract with their Donors and may be subject to
            legal action by their Donors if unable to satisfy them.
            <span className="Apple-converted-space">&nbsp;</span>
          </li>
          <li className="li8">
            Finally, donors are expected to acknowledge and understand that the
            nature of medical research can be unpredictable and that there may
            be unexpected changes or delays that may prevent Project Owners from
            completing the Project as promised.
            <span className="Apple-converted-space">&nbsp;</span>
          </li>
        </ul>
        <p className="p11">&nbsp;</p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">PrecisionFunding</strong>
            <span className="s1">
              <strong className="font-semibold">
                <sup>TM</sup>
              </strong>
            </span>
            <strong className="font-semibold">
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          The following outlines the details of the PrecisionFunding™ of
          Projects on Let’s Get Proof™. When pledging to a project, you (the Donor)
          are agreeing to the following terms.
        </p>
        <p className="pb-4">
          All funds are collected through a third-party payment processor, which
          we may change at any time at our sole discretion (the
          “Processor”).&nbsp;User credit card information is never stored on
          Let’s Get Proof’s servers. Let’s Get Proof is not responsible for the
          performance or fairness of the Processor. Prior to disbursement of
          funds, Let’s Get Proof will subtract our fees (“Platform Fees”, see
          Section 9), as well as those of the Processor (“Processor Fees”).
          Because of occasional failures of some credit cards (e.g., due to card
          expiration and failure to update the information by the Donor),
          Let’s Get Proof makes no guarantee surrounding the full receipt of the
          targeted amount (minus the Platform and Processor Fees, as well as any
          applicable taxes).
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          Donors will provide their payment information when they pledge but
          will not be charged at that time. Donors are only charged if the
          Project has reached its funding goal. Following successful achievement
          of the funding goal and prior to disbursement of the funds, the
          Project is subject to final approval of by a Content Expert appointed
          by Let’s Get Proof. If approved, the amount pledged will be the amount
          collected. If the Project does not reach its funding goal and achieve
          final approval by the Content Expert, Donors will not be charged.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          The Processor may pre-authorize or reserve a charge on your credit
          card or alternative payment method for any amount up to the total
          pledge amount at any time between the date of the pledge and the
          collection of funds.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          Donors can change or cancel their pledges at any time before a project
          has reached its funding goal. If the Project has successfully achieved
          its funding goal, Donors wishing to change or cancel their pledges
          must do so by directly contacting the Project Owner.{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          Let’s Get Proof does not offer refunds. In certain situations,
          Let’s Get Proof may, but is under no obligation to, seek the refund of
          Project funding if the Project Owner misrepresents the Project or
          misuses the funds. We will not hold funds on Project Owners’ behalf.
          Let’s Get Proof does not oversee the performance or punctuality of
          Projects. We are not liable or responsible for any loss or damage
          incurred as a result of Donors’ interactions with Project Owners or
          vice versa. Let’s Get Proof is under no obligation to become involved in
          disputes between Project Owners and Donors, or between site members
          and any third party. Without limiting the generality of the foregoing,
          in the event of a dispute, you hereby release Let’s Get Proof, its
          officers, directors, employees, agents and successors in rights from
          any and all claims, damages and demands of every kind, known or
          unknown, suspected or unsuspected, disclosed or undisclosed, arising
          out of or in any way related to such disputes and our service. The
          responsibility of executing the research according to the Project
          Proposal lies entirely with the Project Owner. However, Let’s Get Proof
          reserves the right, but has no obligation, to cancel or suspend a
          Project and refund all payments at any time and for any reason.
        </p>
        <p className="pb-4">
          Project Owners may refund pledges if they so choose, at any time for
          any reason. Upon doing so, Project Owners relieve themselves of their
          contractual obligations with the Donors in question and the contract
          becomes void. <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          Please note that there may be delays in the disbursement of funds to
          the Project Owners (i.e., between the end of a Project’s successful
          fundraising campaign and a Project Owner’s access to the funds).{" "}
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Fees and Payments</strong>
          </li>
        </ol>
        <p className="pb-4">
          Creating an account on Let’s Get Proof is free for individuals. For
          certain organizations or industry partners, we offer a
          recurring/subscription package model that allow access to 20 employees
          of said organization/industry partner.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          If a Project Owner creates a Project that reaches its funding goal and
          fulfills all other requirements as outlined in Section 8, Let’s Get Proof
          will collect a 9% Platform Fee, in addition to any Processor Fees.
          These Processor Fees may vary depending on the location of the Project
          Owner (generally 3-5%).
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          In situations where a fee for service is employed, you will have the
          opportunity to review and accept the terms associated with said fees,
          which may be subject to change. If Let’s Get Proof changes its fee
          structure, said changes will come into effect only after being clearly
          posted on the Site. Further, Let’s Get Proof may temporarily change the
          Platform Fees for promotional events, new services, etc. These will
          come into effect with notice of such temporary events is posted on the
          Site.<span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          You are responsible for paying all fees and applicable taxes
          associated with your use of the Site. If a Project or listing is
          removed from the Site for violation of these Terms, all fees paid will
          be non-refundable, unless we determine, in our sole and absolute
          discretion, that a refund is appropriate.
        </p>
        <p className="pb-4">
          Let’s Get Proof makes no representations regarding the deductibility of
          any contribution for tax purposes. Please consult your tax advisor for
          more information.
        </p>
        <p className="pb-4">
          Our Processor services are provided by Stripe and are subject to
          the&nbsp;
          <a
            href="https://stripe.com/connect/account-terms"
            target="_blank"
            rel="noreferrer"
            className="text-indigo-700"
          >
            <span className="s3">Stripe Connected Account Agreement</span>
          </a>
          &nbsp;and/or the&nbsp;
          <a
            href="https://stripe.com/connect-account/legal/recipient"
            target="_blank"
            rel="noreferrer"
            className="text-indigo-700"
          >
            <span className="s3">Stripe Recipient Agreement</span>
          </a>
          , which includes the&nbsp;
          <a
            href="https://stripe.com/legal"
            target="_blank"
            rel="noreferrer"
            className="text-indigo-700"
          >
            <span className="s4">Stripe Services Agreement&nbsp;</span>
          </a>
          (collectively, the “Stripe Services Agreement”). Your use of
          Let’s Get Proof signals both your agreement to these Terms as well as
          those of the Stripe Services Agreement. The Stripe Services Agreement
          may be modified by Stripe at any time. As a condition of
          Let’s Get Proof’s partnership with Stripe, you agree to provide current,
          complete, and accurate purchase and account information for all
          relevant services on the Site to Stripe. You further agree to promptly
          update account and payment information, including email address,
          payment method, and payment card expiration date, so that Stripe can
          complete your transactions and contact you as needed.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Guidelines for Commenting and Reviewing
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          We provide you areas on the Site to leave comments, reviews, or
          ratings. When posting a comment or review, you must comply with the
          following criteria: (1) you should have firsthand experience with the
          person/entity being commented on or reviewed (e.g., have read the
          proposal in detail and evaluated the research team); (2) your comments
          or reviews should not contain offensive profanity, or abusive, racist,
          offensive, or hate language; (3) your comments or reviews should not
          contain discriminatory references based on religion, race, gender,
          national origin, age, marital status, sexual orientation, or
          disability; (4) your comments or reviews should not contain references
          to illegal activity; (5) you should not be affiliated with competitors
          if posting negative comments or reviews; (6) you should not make any
          conclusions as to the legality of conduct; (7) you may not post any
          false or misleading statements; and (8) you may not organize a
          campaign encouraging others to post comments or reviews, whether
          positive or negative.&nbsp;
        </p>
        <p className="pb-4">
          We may accept, reject, or remove comments or reviews in our sole
          discretion. We have absolutely no obligation to screen comments or
          reviews or to delete comments or reviews, even if anyone considers the
          comments or reviews objectionable or inaccurate. Comments or reviews
          are not endorsed by us, and do not necessarily represent our opinions
          or the views of any of our affiliates or partners. We do not assume
          liability for any comment or review or for any claims, liabilities, or
          losses resulting from any comment or review. By posting a comment or
          review, you&nbsp;hereby grant to us a perpetual, non-exclusive,
          worldwide, royalty-free, fully-paid, assignable, and sublicensable
          right and license to reproduce, modify, translate, transmit by any
          means, display, perform, and/or distribute all content relating to
          comments or reviews.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Third Party Websites and Content
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third-Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content"). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness
          by us, and we are not responsible for any Third-Party Websites
          accessed through the Site or any Third-Party Content posted on,
          available through, or installed from the Site, including the content,
          accuracy, offensiveness, opinions, reliability, privacy practices, or
          other policies of or contained in the Third-Party Websites or the
          Third-Party Content. Inclusion of, linking to, or permitting the use
          or installation of any Third-Party Websites or any Third-Party Content
          does not imply approval or endorsement thereof by us. If you decide to
          leave the Site and access the Third-Party Websites, you do so at your
          own risk, and you should be aware these Terms no longer govern. You
          should review the applicable terms and policies, including privacy and
          data gathering practices, of any website to which you navigate from
          the Site or relating to any applications you use or install from the
          Site. Any purchases you make through Third-Party Websites will be
          through other websites and from other companies, and we take no
          responsibility whatsoever in relation to such purchases which are
          exclusively between you and the applicable third party. You agree and
          acknowledge that we do not endorse the products or services offered on
          Third-Party Websites and you shall hold us harmless from any harm
          caused by your purchase of such products or services. Additionally,
          you shall hold us harmless from any losses sustained by you or harm
          caused to you relating to or resulting in any way from any Third-Party
          Content or any contact with Third-Party Websites.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">User Contribution License</strong>
          </li>
        </ol>
        <p className="pb-4">
          By posting your Contributions to any part of the Site&nbsp;or making
          Contributions accessible to the Site by linking your account from the
          Site to any of your social networking accounts, you automatically
          grant, and you represent and warrant that you have the right to grant,
          to us an unrestricted, unlimited, irrevocable, perpetual,
          non-exclusive, transferable, royalty-free, fully-paid, worldwide
          right, and license to host, use, copy, reproduce, disclose, sell,
          resell, publish, broadcast, retitle, archive, store, cache, publicly
          perform, publicly display, reformat, translate, transmit, excerpt (in
          whole or in part), and distribute such Contributions (including,
          without limitation, your image and voice) for any purpose, commercial,
          advertising, or otherwise, and to prepare derivative works of, or
          incorporate into other works, such Contributions, and grant and
          authorize sublicenses of the foregoing. The use and distribution may
          occur in any media formats and through any media channels.&nbsp;
        </p>
        <p className="pb-4">
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          franchise name, and organization name as applicable, and any of the
          trademarks, service marks, trade names, logos, and personal and
          commercial images you provide. You waive all moral rights in your
          Contributions. If you are not the author of your Contributions, you
          represent and warrant that the author has waived all moral rights in
          your Contributions. You warrant that moral rights have not otherwise
          been asserted in your Contributions.&nbsp;
        </p>
        <p className="pb-4">
          We do not assert any ownership over your Contributions. Ownership of
          intellectual property rights or other proprietary rights associated
          with your Contributions is not transferred to us by these Terms.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          You represent and warrant that your Contributions do not infringe
          copyright or other intellectual property rights of third parties. In
          the event that your Contributions infringe third party intellectual
          property rights, you expressly agree to indemnify us from liability
          according to Section 24.
          <span className="Apple-converted-space">&nbsp;</span>
        </p>
        <p className="pb-4">
          We are not liable for any statements or representations in your
          Contributions provided by you in any area on the Site. You are solely
          responsible for your Contributions to the Site and you expressly agree
          to exonerate us from any and all responsibility and to refrain from
          any legal action against us regarding your Contributions. &nbsp;&nbsp;
        </p>
        <p className="pb-4">
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to re-categorize
          any Contributions to place them in more appropriate locations on the
          Site; and (3) to pre-screen or delete any Contributions at any time
          and for any reason, without notice. We have no obligation to monitor
          your Contributions. &nbsp;
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              {" "}
              Let’s Get Proof’s Intellectual Property
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          Unless otherwise indicated, the Site is our proprietary property and
          all source code, databases, functionality, software, website designs,
          audio, video, text, photographs, and graphics on the Site
          (collectively, the “Content”) and the trademarks, service marks, and
          logos contained therein (the “Marks”) are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property and unfair competition laws, and
          international conventions. The Content and the Marks are provided on
          the Site “AS IS” for your information and personal use only. The Marks
          include, but are not limited to, Let’s Get Proof
          <span className="s1">
            <sup>TM</sup>
          </span>
          , the Let’s Get Proof Logo, PrecisionFunding
          <span className="s1">
            <sup>TM</sup>
          </span>{" "}
          and Dynamic Review
          <span className="s1">
            <sup>TM</sup>
          </span>
          . Except as expressly provided in these Terms, no part of the Site and
          no Content or Marks may be copied, reproduced, aggregated,
          republished, uploaded, posted, publicly displayed, encoded,
          translated, transmitted, distributed, sold, licensed, or otherwise
          infringed or exploited for any commercial purpose whatsoever, without
          our express prior written permission.
        </p>
        <ol className="ol1">
          <li className="li12">
            <strong className="font-semibold">Social Media</strong>
          </li>
        </ol>
        <p className="pb-4">
          As part of the functionality of the Site, you may link your account
          with online accounts you have with third-party service providers (each
          such account, a “Third-Party Account”) by either: (1) providing your
          Third-Party Account login information through the Site; or (2)
          allowing us to access your Third-Party Account, as is permitted under
          the applicable terms and conditions that govern your use of each
          Third-Party Account. You represent and warrant that you are entitled
          to disclose your Third-Party Account login information to us and/or
          grant us access to your Third-Party Account, without breach by you of
          any of the terms and conditions that govern your use of the applicable
          Third-Party Account, and without obligating us to pay any fees or
          making us subject to any usage limitations imposed by the third-party
          service provider of the Third-Party Account. By granting us access to
          any Third-Party Accounts, you understand that (1) we may access, make
          available, and store (if applicable) any content that you have
          provided to and stored in your Third-Party Account (the “Social
          Network Content”) so that it is available on and through the Site via
          your account, including without limitation any friend lists and (2) we
          may submit to and receive from your Third-Party Account additional
          information to the extent you are notified when you link your account
          with the Third-Party Account. Depending on the Third-Party Accounts
          you choose and subject to the privacy settings that you have set in
          such Third-Party Accounts, personally identifiable information that
          you post to your Third-Party Accounts may be available on and through
          your account on the Site. Please note that if a Third-Party Account or
          associated service becomes unavailable or our access to such
          Third-Party Account is terminated by the third-party service provider,
          then Social Network Content may no longer be available on and through
          the Site. You will have the ability to disable the connection between
          your account on the Site and your Third-Party Accounts at any time.
          PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
          PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY
          BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We make
          no effort to review any Social Network Content for any purpose,
          including but not limited to, for accuracy, legality, or
          non-infringement, and we are not responsible for any Social Network
          Content. You acknowledge and agree that we may access your email
          address book associated with a Third-Party Account and your contacts
          list stored on your mobile device or tablet computer solely for
          purposes of identifying and informing you of those contacts who have
          also registered to use the Site. You can deactivate the connection
          between the Site and your Third-Party Account by contacting us using
          the contact information below or through your account settings (if
          applicable). We will attempt to delete any information stored on our
          servers that was obtained through such Third-Party Account, except the
          username and profile picture that become associated with your account.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Submissions</strong>
          </li>
        </ol>
        <p className="pb-4">
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Site Management</strong>
          </li>
        </ol>
        <p className="pb-4">
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms; (2) take appropriate legal action
          against anyone who, in our sole discretion, violates the law or these
          Terms, including without limitation, reporting such user to law
          enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Privacy Policy</strong>
          </li>
        </ol>
        <p className="pb-4">
          We care about data privacy and security. By using the Site, you agree
          to be bound by our Privacy Policy posted on the Site, which is
          incorporated into these Terms. Please be advised the Site is hosted
          in&nbsp;Canada. If you access the Site from any other region of the
          world with laws or other requirements governing personal data
          collection, use, or disclosure that differ from applicable laws
          in&nbsp;Canada, then through your continued use of the Site, you are
          transferring your data to&nbsp;Canada, and you agree to have your data
          transferred to and processed in&nbsp;Canada.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Copyright Infringements</strong>
          </li>
        </ol>
        <p className="pb-4">
          We respect the intellectual property rights of others. If you believe
          that any material available on or through the Site infringes upon any
          copyright you own or control, please immediately notify us using the
          contact information provided below (a “Notification”). A copy of your
          Notification will be sent to the person who posted or stored the
          material addressed in the Notification. Please be advised that
          pursuant to applicable law you may be held liable for damages if you
          make material misrepresentations in a Notification. Thus, if you are
          not sure that material located on or linked to by the Site infringes
          your copyright, you should consider first obtaining legal advice.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Term and Termination</strong>
          </li>
        </ol>
        <p className="pb-4">
          These Terms shall remain in full force and effect while you use the
          Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS, WE RESERVE
          THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
          DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING CERTAIN IP
          ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
          WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
          COVENANT CONTAINED IN THESE TERMS OR OF ANY APPLICABLE LAW OR
          REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
          DELETE&nbsp;YOUR&nbsp;ACCOUNT AND&nbsp;ANY CONTENT OR INFORMATION THAT
          YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE
          DISCRETION.&nbsp;If we terminate or suspend your account for any
          reason, you are prohibited from registering and creating a new account
          under your name, a fake or borrowed name, or the name of any third
          party, even if you may be acting on behalf of the third party. In
          addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Modifications and Interruptions
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of the Site. &nbsp;
        </p>
        <p className="pb-4">
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms will be construed
          to obligate us to maintain and support the Site or to supply any
          corrections, updates, or releases in connection therewith.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Governing Law<span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          These Terms shall be governed by and interpreted according to the laws
          of&nbsp;the Province of Alberta and the laws of Canada applicable in
          the Province of Alberta.&nbsp;Let’s Get Proof Inc.&nbsp;and yourself
          irrevocably agree that the courts of&nbsp;the Province of Alberta and
          the Federal Court of Canada, as the case may be, and any courts of
          appeal from these courts, shall have exclusive jurisdiction to resolve
          any dispute which may arise in connection with these Terms.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Disclaimer</strong>
          </li>
        </ol>
        <p className="pb-4">
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Limitations of Liability</strong>
          </li>
        </ol>
        <p className="pb-4">
          IN&nbsp;NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
          CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
          INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
          ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES.&nbsp;NOTWITHSTANDING&nbsp;ANYTHING TO THE
          CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
          WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES
          BE LIMITED TO&nbsp;THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
          US&nbsp;DURING THE&nbsp;SIX (6)&nbsp;MONTH PERIOD PRIOR TO ANY CAUSE
          OF ACTION&nbsp;ARISING.&nbsp;CERTAIN US STATE LAWS AND INTERNATIONAL
          LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
          OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
          ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
          YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Indemnification
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable legal fees and expenses, made
          by any third party due to or arising out of:&nbsp;(1) your
          Contributions;&nbsp;(2) use of the Site; (3) breach of these Terms;
          (4) any breach of your representations and warranties set forth in
          these Terms; (5) your violation of the rights of a third party,
          including but not limited to intellectual property rights; or (6) any
          overt harmful act toward any other user of the Site with whom you
          connected via the Site. Notwithstanding the foregoing, we reserve the
          right, at your expense, to assume the exclusive defense and control of
          any matter for which you are required to indemnify us, and you agree
          to cooperate, at your expense, with our defense of such claims. We
          will use reasonable efforts to notify you of any such claim, action,
          or proceeding which is subject to this indemnification upon becoming
          aware of it.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">User Data</strong>
          </li>
        </ol>
        <p className="pb-4">
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site. You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              Electronic Communications, Transactions, and Signatures
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          Visiting&nbsp;the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">
              California Users and Residents
              <span className="Apple-converted-space">&nbsp;</span>
            </strong>
          </li>
        </ol>
        <p className="pb-4">
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
        <ol className="ol1">
          <li className="li9">
            <strong className="font-semibold">Miscellaneous</strong>
          </li>
        </ol>
        <p className="pb-4">
          These Terms and any policies or operating rules posted by us on the
          Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms shall not operate as a waiver of
          such right or provision. These Terms operate to the fullest extent
          permissible by law. We may assign any or all of our rights and
          obligations to others at any time. We shall not be responsible or
          liable for any loss, damage, delay, or failure to act caused by any
          cause beyond our reasonable control. If any provision or part of a
          provision of these Terms is determined to be unlawful, void, or
          unenforceable, that provision or part of the provision is deemed
          severable from these Terms and does not affect the validity and
          enforceability of any remaining provisions. There is no joint venture,
          partnership, employment or agency relationship created between you and
          us as a result of these Terms or use of the Site. You agree that these
          Terms will not be construed against us by virtue of having drafted
          them. You hereby waive any and all defenses you may have based on the
          electronic form of these Terms and the lack of signing by the parties
          hereto to execute these Terms.
        </p>
        <p className="p9">
          <strong className="font-semibold">
            Questions and Contact Information
          </strong>
        </p>

        <div className="pb-2">
          If you have any questions or complaints regarding a project, pledge,
          or content, contact us using our{" "}
          <NavLink to="/contact-us" className="text-indigo-700">
            contact form
          </NavLink>{" "}
          or by email at{" "}
          <a href="mailto:contact@letsgetproof.com" className="text-indigo-700">
            contact@letsgetproof.com
          </a>
        </div>
      </div>
    </div>
  );
};
