import React, { FC, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as IconLogo } from "../../assets/icons/logo.svg";
import { confirmAffiliation } from "api/account";
import { Badge } from "components/badge/Badge";
import { LoadingSpinner } from "components/loading-spinner/LoadingSpinner";
import { useContextService } from "hooks/use-context-service";

const Wrapper: FC = ({ children }) => (
  <div className="flex flex-col items-center justify-center w-full min-h-screen">
    <IconLogo className="w-40 mb-4" />
    {children}
  </div>
);

export const ConfirmAffiliationEmail = () => {
  const history = useHistory();

  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location?.search), [
    location,
  ]);

  const confirmAffiliationMutation = useMutation(
    useContextService(confirmAffiliation),
    {
      onSuccess: (response) => {
        setTimeout(() => {
          history.push("/my-profile/affiliations");
        }, 2000);
      },
    }
  );

  const confirmAffiliationMutationRef = useRef(confirmAffiliationMutation);

  useEffect(() => {
    confirmAffiliationMutationRef.current.mutate({
      token: params.get("token")!,
    });
  }, [params]);

  const { t } = useTranslation();

  if (confirmAffiliationMutation.isLoading) {
    return (
      <Wrapper>
        <LoadingSpinner className="w-6 text-purple-600" />
      </Wrapper>
    );
  }

  if (confirmAffiliationMutation.isSuccess) {
    return (
      <Wrapper>
        <Badge variant="success">
          {t("Your email has been successfully verified.")}
        </Badge>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Badge variant="danger">
        {t("Invalid or expired confirmation code.")}
      </Badge>
    </Wrapper>
  );
};
