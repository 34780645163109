import React from "react";
import { NavLink } from "react-router-dom";
import map from "lodash/map";
import get from "lodash/get";
import { UserGroupIcon, ChatIcon } from "@heroicons/react/solid";

// import { joinRequestWorkingGroup } from "api/workingGroups";

// import { Button } from "components/button/Button";
import { WorkingGroupData } from "api/workingGroups";
import { withParams } from "utils/url";

import { routes } from "routes";
// import { useContextService } from "../../../../hooks/use-context-service";
// import { useMutation } from "react-query";
// import { showNotification as showNotificationService } from "../../../../services/notification";

// const JOIN_WORKING_GROUP_REQUEST = "JOIN_WORKING_GROUP_REQUEST";

const WorkingGroupItem = ({
  title,
  // is_member,
  // has_join_request,
  // slug,
  comments,
  members,
  summary,
  created_by,
  image,
}: WorkingGroupData) => {
  // const joinRequestWorkingGroupService = useContextService(
  //   joinRequestWorkingGroup
  // );

  // const showNotification = useContextService(showNotificationService);

  // const joinRequestWorkingGroupMutation = useMutation(
  //   [JOIN_WORKING_GROUP_REQUEST],
  //   () => joinRequestWorkingGroupService(slug!),
  //   {
  //     onSuccess: () => {
  //       // setIsJoinRequested(true);
  //       showNotification({
  //         title: "Success",
  //         message: "Join request has been sent",
  //       });
  //     },
  //     onError: () => {
  //       showNotification({
  //         title: "Error",
  //         message: "Something went wrong",
  //       });
  //     },
  //   }
  // );

  // const [isJoinRequested, setIsJoinRequested] = useState(has_join_request);

  return (
    <div className="flex flex-col border border-gray-200 bg-white rounded h-full">
      <div
        className="relative w-full h-56 bg-gray-200 bg-center bg-contain bg-no-repeat rounded-t"
        style={{ backgroundImage: `url(${image})` }}
      />
      <div
        className="p-4 md:p-8 break-words"
        style={{ wordBreak: "break-word" }}
      >
        <h2 className="font-nunito font-bold text-xl lg:text-2xl text-blue-550 mb-1">{title}</h2>
        {summary && (
          <div className="text-gray-700 text-sm">
            <div
              className="wysiwyg-editor wg-summary"
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          </div>
        )}
      </div>

      <div className="flex items-center mt-auto px-4 pb-4 md:px-8 md:pb-8">
        <div
          className="flex justify-center items-center rounded-full bg-gray-400 w-8 h-8 text-white font-medium bg-center bg-cover"
          style={{ backgroundImage: `url(${created_by?.avatar})` }}
        >
          {!created_by?.avatar ? get(created_by, "name[0]") : ""}
        </div>
        <div className="ml-2">{created_by?.name}</div>

        <div className="ml-auto flex text-sm text-gray-600 font-medium">
          <div className="flex items-center">
            <UserGroupIcon className="-mt-0.5 mr-1 w-4" /> {members}
          </div>
          <div className="flex items-center text-gray-700 ml-4">
            <ChatIcon className="mr-1 w-4" /> {comments}
          </div>
        </div>
      </div>

      {/*{!is_member && (
        <div className="px-4 pb-4 md:px-8 md:pb-8">
          <Button
            size="small"
            loading={joinRequestWorkingGroupMutation.isLoading}
            spinner
            disabled={
              isJoinRequested || joinRequestWorkingGroupMutation.isLoading
            }
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await joinRequestWorkingGroupMutation.mutate();
              setIsJoinRequested(true);
            }}
          >
            {isJoinRequested ? "Join request has been sent" : "Private Member join"}
          </Button>
        </div>
      )}*/}
    </div>
  );
};

export const List = ({ listData }: { listData: WorkingGroupData[] }) => {
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {map(listData, (data) => (
          <NavLink
            to={withParams(routes.workingGroupSingle, { id: data.slug })}
            key={data.slug}
          >
            <WorkingGroupItem key={data.id} {...data} />
          </NavLink>
        ))}
      </div>
    </div>
  );
};
