import React, { useState, useMemo, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";
import { CheckIcon, ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";
import ReCAPTCHA from "react-google-recaptcha";

import { TextField } from "components/text-field/TextField";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";
import { SelectField } from "components/select-field/SelectField";
import { Button } from "components/button/Button";
import { PhoneInputField } from "components/phone-input-field/PhoneInputField";
import { ConfirmSmsForm } from "./ConfirmSmsForm";

import { registerRequest } from "api/auth/auth";
import { setFormError } from "utils/setError";
import { routes } from "routes";
import queryString from "query-string";
import { getProjectsTagsRequest } from "api/project/project";
import map from "lodash/map";
import { TextareaField } from "../../../components/textarea-field/TextareaField";
import { SSO } from "../sso/SSO";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export const Register = () => {
  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required("Required")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email"
        ),
      name: yup.string().required("Required"),
      password: yup
        .string()
        .required("Required")
        .min(
          8,
          "This password is too short. It must contain at least 8 characters."
        ),
      affiliation: yup.string(),
      university_profile_page_url: yup.string(),
      university_affiliate_email: yup
        .string()
        .matches(
          /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email"
        ),
      role: yup.array(yup.string()),
      looking_for: yup.array(yup.string()),
      gender: yup.string(),
      phone_number: yup.string(),
      location: yup.string(),
      race: yup.array(yup.string()),
      ethnicity: yup.string(),
      age_group: yup.string(),
      career_stage: yup.string(),
      pronouns: yup.string(),
      find_about_by: yup.string(),
      find_about_by_other_info: yup.string(),
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    setError,
    watch,
    reset,
    control,
    setValue,
  } = useForm<{
    name: string;
    email: string;
    password: string;
    role: string;
    phone_number: string;
    affiliation?: string;
    university_profile_page_url?: string;
    university_affiliate_email?: string;
    looking_for: string[];
    gender: string;
    race: string[];
    location: string;
    bio: string;
    social: string;
    area_of_interest: string[];
    tags: string[];
    age_group: string;
    ethnicity: string;
    career_stage: string;
    pronouns?: string;
    find_about_by?: string;
    find_about_by_other_info?: string;
  }>({
    mode: "onChange",
    defaultValues: {
      gender: "prefer_not_to_disclose",
      race: ["prefer_not_to_disclose"],
      age_group: "prefer_not_to_disclose",
      ethnicity: "prefer_not_to_disclose",
      career_stage: "prefer_not_to_disclose",
    },
    resolver: yupResolver(schema),
  });

  const {
    find_about_by,
    role,
    looking_for,
    affiliation,
    university_affiliate_email,
    university_profile_page_url,
    location,
  } = watch();

  const [canConfirmAffiliate, setCanConfirmAffiliate] = useState<boolean>(
    false
  );
  const [lookingForProjectCreate, setLookingForProjectCreate] = useState<
    boolean | null
  >(null);
  const [willingToTellMore, setWillingToTellMore] = useState<boolean | null>(
    null
  );
  const [showGender, setShowGender] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isGenderStep, setIsGenderStep] = useState<boolean>(false);
  const [isRegistred, setIsRegistred] = useState<boolean>(false);
  const [isSorryMessage, setIsSorryMessage] = useState<boolean>(false);
  const [formError, setFormErrorMessage] = useState<string | undefined>();
  const [showRegister, setShowRegister] = useState(false);

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { sms_confirm_token } = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  const registerQuery = useMutation(registerRequest, {
    onSuccess: (response) => {
      // if (process.env.REACT_APP_GTAG) {
      //   ReactGA.gtag("config", process.env.REACT_APP_GTAG);
      //   ReactGA.gtag("event", "conversion", {
      //     send_to: "AW-10977792147/qTvOCM7cjbgYEJOhz_Io",
      //     value: 2.0,
      //     currency: "CAD",
      //   });
      // }

      // ReactGA.event({
      //   category: 'Conversion',
      //   value: 15,
      //   action: 'Completion of sign up'
      // });

      if (response.data.data?.sms_confirm_token) {
        const newUrl =
          window.location.protocol +
          "//" +
          window.location.host +
          window.location.pathname +
          "?" +
          `sms_confirm_token=${response.data.data.sms_confirm_token}`;
        window.history.pushState({ path: newUrl }, "", newUrl);
      }

      setIsRegistred(true);
    },
    onError: (e) => {
      setFormErrorMessage("Something went wrong");
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  useEffect(() => {
    if (looking_for && looking_for.includes("looking_for_funding")) {
      if (
        canConfirmAffiliate &&
        affiliation &&
        university_affiliate_email &&
        university_profile_page_url &&
        !formState.errors?.university_affiliate_email
      ) {
        setIsGenderStep(true);
      } else {
        setIsGenderStep(false);
      }
    }
  }, [
    canConfirmAffiliate,
    formState.errors?.university_affiliate_email,
    looking_for,
    affiliation,
    university_affiliate_email,
    university_profile_page_url,
  ]);

  useEffect(() => {
    setIsGenderStep(false);
    if (looking_for && !looking_for.includes("looking_for_funding")) {
      setIsGenderStep(true);
    }
  }, [looking_for]);

  useEffect(() => {
    if (role && role.length > 0 && !role.includes("healthcare_professional")) {
      setIsGenderStep(true);
    }
  }, [role]);

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const onSubmit = (data: any) => {

    registerQuery.mutate({
      captcha: recaptchaValue,
      email: data.email,
      password: data.password,
      name: data.name,
      role: data.role,
      phone_number: data.phone_number,
      looking_for: data.looking_for,
      gender: data.gender,
      race: data.race,
      ethnicity: data.ethnicity,
      age_group: data.age_group,
      career_stage: data.career_stage,
      affiliation: data.affiliation,
      university_affiliate_email: data.university_affiliate_email,
      university_profile_page_url: data.university_profile_page_url,
      pronouns: data.pronouns || null,
      find_about_by: data.find_about_by || null,
      find_about_by_other_info: data.find_about_by_other_info,
      location: data.location,
      bio: data.bio,
      social: data.social,
      area_of_interest: data.area_of_interest,
      tags: data.tags,
      is_project_creator: !!lookingForProjectCreate,
    });
  };

  return (
    <div className="flex flex-col items-center justify-center h-full max-w-xl mx-auto">
      <Helmet>
        <title>Let’s Get Proof | Sign up</title>
      </Helmet>

      <div className="flex flex-col items-center w-full max-w-screen-lg">
        <div className="flex items-center text-sm justify-center pb-3">
          Already part of the team?
          <NavLink
            to={routes.login}
            className="inline-flex items-center ml-2 font-medium transition-colors text-violet-500 hover:text-violet-800"
          >
            Sign in
          </NavLink>
        </div>
        <h1 className="mb-8 text-xl font-medium text-center lg:text-3xl">
          Welcome to Let’s Get Proof!
        </h1>

        {currentStep === 1 && (
          <div className="mb-8 flex justify-center">
            <SSO />
          </div>
        )}

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center w-full max-w-screen-lg"
        >
          <div
            className={classNames("w-full px-4 sm:p-0", [
              currentStep === 1 ? "block" : "hidden",
            ])}
          >
            <div className="w-full p-5 bg-white rounded sm:p-8">
              {!isSorryMessage && (
                <div>
                  {/*<div className="w-full mt-5 mb-5">
                  <MultiSelectField
                    label="What are you looking for with Let’s Get Proof?"
                    error={get(formState, "errors.looking_for[0]")}
                    name="looking_for"
                    control={control}
                    options={[
                      {
                        value: "want_to_fund_research",
                        label: "I am looking to fund research",
                      },
                      {
                        value: "looking_for_funding",
                        label: "I am looking for funding",
                      },
                      {
                        value: "interested_in_collaboration",
                        label:
                          "I am interested in advancing the understanding of a medical problem/issue through collaboration",
                      },
                      {
                        value: "looking_for_help",
                        label: "I am looking for help/guidance/mentorship",
                      },
                      {
                        value: "want_to_help",
                        label:
                          "I would like to help researchers with study designs",
                      },
                      {
                        value: "want_participate",
                        label:
                          "I would like to participate in contributing patients with rare diseases to collaborative studies",
                      },
                    ]}
                  />
                </div>

                {looking_for && (
                  <div className="w-full">
                    <MultiSelectField
                      label="Role (choose all that apply) optional"
                      error={formState.errors?.role}
                      name="role"
                      control={control}
                      defaultFirst
                      options={[
                        {
                          value: "healthcare_professional",
                          label:
                            "I am a healthcare professional looking  to advance medical discovery",
                        },
                        {
                          value: "donor_looking_to_support",
                          label:
                            "I am a donor looking to support the latest & greatest medical research",
                        },
                        {
                          value: "want_to_see_what_the_latest",
                          label:
                            "I  want to see what the latest & greatest medical research is",
                        },
                        {
                          value: "data_scientist_looking_to_advance",
                          label:
                            "I am a statistician/data scientist looking to advance medical discovery",
                        },
                        {
                          value: "non_medical_scientist_looking_to_advance",
                          label:
                            "I am a non-medical scientist looking to advance medical discovery",
                        },
                        {
                          value: "non_scientist_looking_to_advance",
                          label:
                            "I am a non-scientist looking to advance medical discovery",
                        },
                      ]}
                    />
                  </div>
                )}*/}

                  <div className="">
                    <div className="text-base text-gray-700 text-center">
                      Are you willing to tell us more information about
                      yourself?
                    </div>
                    <div className="flex items-center justify-center mt-3">
                      <Button
                        type="button"
                        className="mr-2"
                        variant={willingToTellMore ? "primary" : "secondary"}
                        onClick={() => {
                          setWillingToTellMore(true);
                        }}
                      >
                        {willingToTellMore && (
                          <CheckIcon className="mr-2 text-white w-5" />
                        )}
                        Yes
                      </Button>
                      <Button
                        type="button"
                        className="ml-2"
                        variant={
                          willingToTellMore === false ? "redPale" : "secondary"
                        }
                        onClick={() => {
                          setWillingToTellMore(false);
                          // setShowRegister(true);
                        }}
                      >
                        Maybe later
                      </Button>
                    </div>
                  </div>

                  {/*<div className="mt-10">
                    <div className="text-base text-gray-700 text-center">
                      Are you looking to create a project?
                    </div>
                    <div className="flex items-center justify-center mt-3">
                      <Button
                        type="button"
                        className="mr-2"
                        variant={
                          lookingForProjectCreate ? "primary" : "secondary"
                        }
                        onClick={() => {
                          setLookingForProjectCreate(true);
                        }}
                      >
                        {lookingForProjectCreate && (
                          <CheckIcon className="mr-2 text-white w-5" />
                        )}
                        Yes
                      </Button>
                      <Button
                        type="button"
                        className="ml-2"
                        variant={
                          lookingForProjectCreate === false
                            ? "redPale"
                            : "secondary"
                        }
                        onClick={() => {
                          setLookingForProjectCreate(false);
                          // setShowRegister(true);
                        }}
                      >
                        No
                      </Button>
                    </div>
                  </div>*/}

                {/*{lookingForProjectCreate && (
                  <div className="mt-10">
                    <div className="text-base text-gray-700 text-center">
                      I can confirm I am affiliated with a university and have a
                      university-associated email address*
                    </div>
                    <div className="flex items-center justify-center mt-3">
                      <Button
                        type="button"
                        className="mr-2"
                        variant={canConfirmAffiliate ? "primary" : "secondary"}
                        onClick={() => {
                          setCanConfirmAffiliate(true);
                        }}
                      >
                        {canConfirmAffiliate && (
                          <CheckIcon className="mr-2 text-white w-5" />
                        )}
                        Yes
                      </Button>
                      <Button
                        type="button"
                        className="ml-2"
                        variant="secondary"
                        onClick={() => {
                          setCanConfirmAffiliate(false);
                          setIsSorryMessage(true);
                        }}
                      >
                        No
                      </Button>
                    </div>

                    <div className="mt-5">
                      <TextField
                        label="Affiliation"
                        placeholder="Affiliation"
                        error={formState.errors?.affiliation}
                        {...register("affiliation")}
                      />
                    </div>

                    <div className="mt-5">
                      <TextField
                        label="University affiliate email"
                        placeholder="Affiliation"
                        error={formState.errors?.university_affiliate_email}
                        {...register("university_affiliate_email")}
                      />
                    </div>

                    <div className="mt-5">
                      <TextField
                        label="University profile page url"
                        placeholder="University profile page url"
                        error={formState.errors?.university_profile_page_url}
                        {...register("university_profile_page_url")}
                      />
                    </div>

                    <div className="text-xs text-gray-600 pt-4">
                      *Most medical research requires university or institution
                      infrastructure and resources to be carried out. For this
                      reason and to ensure integrity of the projects posted on
                      Let’s Get Proof, we require proof of affiliation
                    </div>
                  </div>
                )}*/}

                  {role && role.length > 0 && isGenderStep && (
                    <div className="mt-6">
                      <button
                        type="button"
                        onClick={() => {
                          setShowGender(!showGender);
                        }}
                        className="flex items-center border-dashed border-b border-violet-600 text-violet-600 mb-2 font-medium"
                      >
                        {showGender && <ArrowUpIcon className="w-4 mr-2" />}
                        {!showGender && <ArrowDownIcon className="w-4 mr-2" />}
                        Tell Us More About Yourself
                      </button>
                      <div
                        className={classNames([
                          showGender ? "block" : "hidden",
                        ])}
                      >
                        <div className="w-full mt-4 px-4 py-2 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                          <h3 className="font-semibold text-lg text-gray-900">
                            Why are we asking this?
                          </h3>
                          <div className="text-sm">
                            A major goal of Let’s Get Proof is to promote
                            equity, diversity, and inclusion. Collecting this
                            information will give a sense of how we are doing
                            and guide us on how we can improve.
                          </div>
                        </div>

                        <div className="w-full mt-5">
                          <SelectField
                            label="Gender (optional)"
                            placeholder="Select"
                            options={[
                              {
                                value: "",
                                label: "Select",
                              },
                              {
                                value: "male",
                                label: "Male",
                              },
                              {
                                value: "female",
                                label: "Female",
                              },
                              {
                                value: "non-binary",
                                label: "Non-binary",
                              },
                              {
                                value: "prefer_not_to_disclose",
                                label: "Prefer not to disclose",
                              },
                            ]}
                            error={formState.errors?.gender}
                            {...register("gender", { required: true })}
                          />
                        </div>

                      {/*<div className="w-full mt-5">
                        <MultiSelectField
                          label="Race (optional)"
                          name="race"
                          control={control}
                          defaultFirst
                          options={[
                            {
                              value: "american_indian_or_alaska_native",
                              label: "American Indian or Alaska Native",
                            },
                            {
                              value: "asian",
                              label: "Asian",
                            },
                            {
                              value: "black_or_african_american",
                              label: "Black or African American",
                            },
                            {
                              value: "native_hawalan_or_other_pacific_islander",
                              label:
                                "Native Hawaiian or Other Pacific Islander",
                            },
                            {
                              value: "white",
                              label: "White",
                            },
                            {
                              value: "prefer_not_to_disclose",
                              label: "Prefer not to disclose",
                            },
                          ]}
                          onChange={(val) => {
                            if (
                              val.length &&
                              val[val.length - 1].value ===
                                "prefer_not_to_disclose"
                            ) {
                              setValue("race", ["prefer_not_to_disclose"]);
                            } else if (
                              val.length &&
                              val.some(
                                (item) =>
                                  item.value === "prefer_not_to_disclose"
                              )
                            ) {
                              setValue(
                                "race",
                                val
                                  .filter(
                                    (item) =>
                                      item.value !== "prefer_not_to_disclose"
                                  )
                                  .map((item) => item.value)
                              );
                            }
                          }}
                        />
                      </div>*/}

                      {/*<div className="w-full mt-5">
                        <SelectField
                          label="Ethnicity (optional)"
                          placeholder="Select"
                          options={[
                            {
                              value: "",
                              label: "Select",
                            },
                            {
                              value: "hispanic_or_latino",
                              label: "Hispanic or Latino",
                            },
                            {
                              value: "not_hispanic_or_latino",
                              label: "Not Hispanic or Latino",
                            },
                            {
                              value: "prefer_not_to_disclose",
                              label: "Prefer not to disclose",
                            },
                          ]}
                          error={formState.errors?.ethnicity}
                          {...register("ethnicity", { required: true })}
                        />
                      </div>*/}

                        <div className="w-full mt-5">
                          <SelectField
                            label="Career stage (optional)"
                            placeholder="Select"
                            options={[
                              {
                                value: "",
                                label: "Select",
                              },
                              {
                                value: "early_career_researcher",
                                label: "Early career researcher",
                              },
                              {
                                value: "junior_staff",
                                label: "Junior staff",
                              },
                              {
                                value: "senior_staff",
                                label: "Senior staff",
                              },
                              {
                                value: "prefer_not_to_disclose",
                                label: "Prefer not to disclose",
                              },
                            ]}
                            error={formState.errors?.career_stage}
                            {...register("career_stage", { required: true })}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="w-full flex justify-between mt-8 border-t border-gray-100 pt-8">
                    <Button
                      type="button"
                      variant="text"
                      className="mr-5"
                      onClick={() => {
                        setCurrentStep(3);
                      }}
                    >
                      Skip for later
                    </Button>
                    <Button
                      type="button"
                      className="ml-5"
                      // disabled={
                      //   !(looking_for && looking_for.length > 0) ||
                      //   (!canConfirmAffiliate &&
                      //     looking_for &&
                      //     looking_for.includes("looking_for_funding"))
                      // }
                      disabled={[
                        // lookingForProjectCreate,
                        willingToTellMore,
                      ].includes(null)}
                      onClick={() => {
                        if (
                          [lookingForProjectCreate, willingToTellMore].includes(
                            true
                          )
                        ) {
                          setCurrentStep(2);
                        } else {
                          setCurrentStep(3);
                        }
                        // setShowRegister(true);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames("w-full px-4 sm:p-0", [
              currentStep === 2 ? "block" : "hidden",
            ])}
          >
            <div className="w-full p-5 bg-white rounded sm:p-8">
              {!isSorryMessage && (
                <div>
                  {lookingForProjectCreate && (
                    <div className="">
                      <div className="text-base text-gray-700 text-center">
                        I can confirm I am affiliated with a university and have
                        a university-associated email address*
                      </div>
                      <div className="flex items-center justify-center mt-3">
                        <Button
                          type="button"
                          className="mr-2"
                          variant={
                            canConfirmAffiliate ? "primary" : "secondary"
                          }
                          onClick={() => {
                            setCanConfirmAffiliate(true);
                          }}
                        >
                          {canConfirmAffiliate && (
                            <CheckIcon className="mr-2 text-white w-5" />
                          )}
                          Yes
                        </Button>
                        <Button
                          type="button"
                          className="ml-2"
                          variant="secondary"
                          onClick={() => {
                            setCanConfirmAffiliate(false);
                            setIsSorryMessage(true);
                          }}
                        >
                          No
                        </Button>
                      </div>

                      <div className="mt-5">
                        <TextField
                          label="Affiliation"
                          placeholder="Affiliation"
                          error={formState.errors?.affiliation}
                          {...register("affiliation")}
                        />
                      </div>

                      <div className="mt-5">
                        <TextField
                          label="University affiliate email"
                          placeholder="Affiliation"
                          error={formState.errors?.university_affiliate_email}
                          {...register("university_affiliate_email")}
                        />
                      </div>

                      <div className="mt-5">
                        <TextField
                          label="University profile page url"
                          placeholder="University profile page url"
                          error={formState.errors?.university_profile_page_url}
                          {...register("university_profile_page_url")}
                        />
                      </div>

                      <div className="text-xs text-gray-600 pt-4">
                        *Most medical research requires university or
                        institution infrastructure and resources to be carried
                        out. For this reason and to ensure integrity of the
                        projects posted on Let’s Get Proof, we require proof of
                        affiliation
                      </div>
                    </div>
                  )}

                  {willingToTellMore && (
                    <div className="mt-10">
                      <div className="w-full mt-5">
                        <SelectField
                          label="Preferred pronouns"
                          placeholder="Select"
                          options={[
                            {
                              value: "",
                              label: "Select",
                            },
                            {
                              value: "SHE",
                              label: "She/her",
                            },
                            {
                              value: "HE",
                              label: "He/him",
                            },
                            {
                              value: "THEY",
                              label: "They/them",
                            },
                            {
                              value: "NOT_DISCLOSE",
                              label: "Prefer not to disclose",
                            },
                          ]}
                          error={formState.errors?.pronouns}
                          {...register("pronouns", { required: true })}
                        />
                      </div>

                      <div className="mt-5">
                        <TextareaField
                          label="Bio"
                          placeholder="Bio"
                          error={formState.errors?.bio}
                          {...register("bio")}
                        />
                      </div>

                      <div className="mt-5">
                        <TextareaField
                          label="Social"
                          placeholder="Social"
                          error={formState.errors?.social}
                          {...register("social")}
                        />
                      </div>

                    <div className="mt-5">
                      <TextField
                        label="Country"
                        placeholder="Country"
                        {...register("location")}
                      />
                    </div>

                      <div className="w-full mt-5">
                        <SelectField
                          label="How did you find out about us?"
                          placeholder="Select"
                          options={[
                            {
                              value: "",
                              label: "Select",
                            },
                            {
                              value: "SEARCH_ENGINE",
                              label: "Search Engine (Google, Yahoo, etc.)",
                            },
                            {
                              value: "RECOMMENDED_BY_FRIEND",
                              label: "Recommended by friend or colleague",
                            },
                            {
                              value: "SOCIAL_MEDIA",
                              label: "Social media",
                            },
                            {
                              value: "BLOG_OR_PUBLICATION",
                              label: "Blog or publication",
                            },
                            {
                              value: "OTHER",
                              label: "Other",
                            },
                          ]}
                          error={formState.errors?.find_about_by}
                          {...register("find_about_by", { required: true })}
                        />
                      </div>

                      {find_about_by === "OTHER" && (
                        <div className="w-full mt-5">
                          <TextareaField
                            label="Other"
                            placeholder="Other"
                            error={formState.errors?.find_about_by_other_info}
                            {...register("find_about_by_other_info")}
                          />
                        </div>
                      )}

                      <div className="w-full mt-5">
                        <MultiSelectField
                          label="Area(s) of interest"
                          error={get(formState, "errors.area_of_interest[0]")}
                          name="area_of_interest"
                          control={control}
                          options={map(
                            get(projectTags, "data"),
                            ({ id, title }) => ({
                              value: id,
                              label: title,
                            })
                          )}
                        />
                      </div>

                      <div className="w-full mt-5">
                        <MultiSelectField
                          label="Profile tags"
                          error={get(formState, "errors.tags[0]")}
                          name="tags"
                          placeholder="Select your top two"
                          control={control}
                          options={[
                            {
                              value: "MEDICAL_DOCTOR",
                              label: "Medical doctor (MD)",
                            },
                            {
                              value: "CLINICAL_RESEARCHER",
                              label: "Clinical researcher (MD/PhD)",
                            },
                            {
                              value: "ALLIED_HEALTHCARE_PROFESSIONAL",
                              label: "Allied healthcare professional (AHP)",
                            },
                            {
                              value: "CAREGIVER",
                              label: "Caregiver",
                            },
                            {
                              value: "SURVIVOR",
                              label: "Survivor",
                            },
                            {
                              value: "OTHER",
                              label: "Other",
                            },
                          ]}
                          max={2}
                        />
                      </div>
                    </div>
                  )}

                  <div className="w-full flex justify-between mt-8 border-t border-gray-100 pt-8">
                    <Button
                      type="button"
                      variant="text"
                      className="mr-5"
                      onClick={() => {
                        setCurrentStep(3);
                      }}
                    >
                      Skip for later
                    </Button>
                    <Button
                      type="button"
                      variant="secondary"
                      className="ml-auto"
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      type="button"
                      className="ml-5"
                      disabled={!!formState.errors?.university_affiliate_email}
                      onClick={() => {
                        if (university_affiliate_email) {
                          setValue("email", university_affiliate_email);
                        }
                        setCurrentStep(3);
                      }}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}

              {isSorryMessage && (
                <div>
                  <div className="w-full px-4 py-2 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                    We’re sorry, we can only distribute funds to
                    university-affiliated projects at this time. But please feel
                    free to choose another way to interact with us.
                  </div>

                  <Button
                    type="button"
                    className="w-full mt-4"
                    variant="secondary"
                    spinner
                    onClick={() => {
                      reset();
                      setIsSorryMessage(false);
                      setIsGenderStep(false);
                      setLookingForProjectCreate(null);
                      setCurrentStep(1);
                    }}
                  >
                    Back
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div
            className={classNames("w-96 px-4 sm:p-0", [
              currentStep === 3 ? "block" : "hidden",
            ])}
          >
            <div className="w-full p-5 bg-white rounded sm:p-8">
              <div className="flex flex-col items-center">
                <h2 className="mb-3 text-xl font-bold">
                  Please sign up to continue
                </h2>

                {isRegistred ? (
                  <div className="w-full px-4 py-2 text-sm text-center text-green-700 bg-green-100 rounded">
                    You have successfully registered.
                    <br />
                    Please check your email (including your spam folder).
                  </div>
                ) : (
                  <div className="w-full">
                    <div className="w-full mb-5">
                      <TextField
                        label="Name"
                        placeholder="John Doe"
                        error={formState.errors?.name}
                        {...register("name", { required: true })}
                      />
                    </div>
                    <div className="w-full mb-5">
                      <TextField
                        label="Email"
                        placeholder="john.doe@gmail.com"
                        error={formState.errors?.email}
                        {...register("email", { required: true })}
                      />
                    </div>
                    <div className="w-full mb-5">
                      <Controller
                        name="phone_number"
                        control={control}
                        render={({
                          field: { onChange, value, name, ref },
                          fieldState: { error },
                        }) => (
                          <PhoneInputField
                            optional
                            onChange={onChange}
                            value={value}
                            error={error}
                            label="Phone number"
                          />
                        )}
                      />
                    </div>
                    <div className="w-full mb-5">
                      <TextField
                        label="Password"
                        type="password"
                        placeholder="Password"
                        error={formState.errors?.password}
                        {...register("password", { required: true })}
                      />
                    </div>

                  {formError && (
                    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded text-center">
                      {formError}
                    </div>
                  )}

                  <div className="mb-4">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={(token) => {
                        setRecaptchaValue(token);
                      }}
                      sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                    />
                  </div>

                    <Button
                      type="submit"
                      className="w-full"
                      loading={registerQuery.isLoading}
                      spinner
                      disabled={!recaptchaValue}
                    >
                      Create account
                    </Button>

                    <Button
                      type="button"
                      variant="secondary"
                      className="w-full mt-2"
                      onClick={() => {
                        setCurrentStep(1);
                      }}
                    >
                      Back
                    </Button>

                    <div className="mt-5 text-sm text-center text-gray-700">
                      By signing up, you agree to our Privacy Policy, Cookie
                      Policy and Terms of Use
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {isRegistred && sms_confirm_token && (
        <div className="w-96 p-8">
          <ConfirmSmsForm />
        </div>
      )}
    </div>
  );
};
