import classNames from "classnames";
import {Fragment, useEffect, useRef, useState} from "react";
import get from "lodash/get";
import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as IconBell } from "assets/icons/bell.svg";
import { useUserQuery } from "queries/use-user-query";
import { NotificationList } from "./NotificationList";

export const Notifications = ({
  theme = "light",
}: {
  theme?: "dark" | "light";
}) => {
  const { data: userResponse } = useUserQuery();
  const [notificationCount, setNotificationCount] = useState(get(userResponse, "data.unread_notification_count", 0));
  const ws = useRef<null | WebSocket>(null);

  useEffect(() => {
    ws.current = new WebSocket(`${process.env.REACT_APP_WS_URI}/ws/notifications?token=${window.localStorage.getItem("token")}`);

    const wsCurrent = ws.current;

    ws.current.onmessage = e => {
      // const message = JSON.parse(e.data);
      setNotificationCount(notificationCount + 1)
    };

    return () => {
      wsCurrent.close();
    };
  }, [notificationCount, setNotificationCount]);

  return (
    <Popover className="relative" onClick={() => {
      setNotificationCount(0);
    }}>
      {({ open }) => (
        <>
          <Popover.Button
            className={`p-2 rounded ${
              open
                ? "text-gray-900 bg-gray-100"
                : classNames("text-opacity-90", {
                    "text-gray-700": theme === "light",
                    "text-white": theme === "dark",
                  })
            }`}
          >
            <IconBell className="w-4" />
            {notificationCount > 0 && (
              <span className="flex items-center justify-center absolute top-1 right-1 inline-block w-5 h-4 text-xs text-white font-medium transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
                {notificationCount}
              </span>
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-80 max-w-sm px-4 mt-3 transform -translate-x-2/3 left-1/2 sm:px-0 lg:max-w-3xl">
              <NotificationList />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
