import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
import { DashboardContent } from "./DashboardContent";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";

export const Dashboard = () => {
  return (
    <Suspense
      fallback={
        <div className="flex justify-center my-8">
          <IconLoading />
        </div>
      }
    >
      <Helmet>
        <title>Let’s Get Proof | Dashboard</title>
      </Helmet>
      <DashboardContent />
    </Suspense>
  );
};
