import React, { useContext, useMemo, useState } from "react";
import { format } from "date-fns";
import truncateHtml from "truncate-html";
import get from "lodash/get";
import classNames from "classnames";
import {
  PencilIcon,
  ThumbDownIcon,
  ThumbUpIcon,
  TrashIcon,
  ChatIcon,
} from "@heroicons/react/solid";
import {
  ThumbDownIcon as ThumbDownOutlineIcon,
  ThumbUpIcon as ThumbUpOutlineIcon,
} from "@heroicons/react/outline";

import { Button } from "components/button/Button";
import {
  Discussion,
  putTopicVote,
  deleteTopicDiscussionComment,
} from "api/discussions";
import { TopicsDiscussionsContext } from "./TopicsDiscussionsContext";
import { Discussions } from "../Discussions";
import { TopicsCommentForm } from "./TopicsCommentForm";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { withParams } from "utils/url";
import { routes } from "routes";
import { NavLink } from "react-router-dom";
import { useMutation } from "react-query";
import { useContextService } from "hooks/use-context-service";
import { AppContext } from "../../../context";
import ReactGA from "react-ga4";

const Comment = ({
  id,
  group_id,
  created_at,
  updated_at,
  deleted_at,
  created_by,
  text,
  replyName,
  user_reaction,
  votes,
  is_owner,
  is_public,
  is_member,
  type,
  num_replies,
}: Pick<
  Discussion,
  | "id"
  | "created_at"
  | "updated_at"
  | "deleted_at"
  | "created_by"
  | "text"
  | "user_reaction"
  | "num_replies"
  | "votes"
  | "is_owner"
  | "is_public"
> & {
  group_id: string;
  replyName?: string;
  type?: string;
  is_member?: boolean;
}) => {
  const [userReaction, setUserReaction] = useState<string | undefined>(
    user_reaction
  );
  const [vote, setVote] = useState(0);
  const [editValue, setEditValue] = useState("");
  const [edit_is_public, setEditIsPublic] = useState<
    boolean | null | undefined
  >(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [show, setShow] = useState(false);
  const [showAllReplay, setShowAllReplay] = useState(false);

  const discussionVoteMutation = useMutation(useContextService(putTopicVote));
  const deleteTopicDiscussionCommentMutation = useMutation(
    useContextService(deleteTopicDiscussionComment)
  );
  const { authToken } = useContext(AppContext);

  const voteResult = useMemo(
    () => (votes?.up || 0) - (votes?.down || 0) + vote,
    [votes, vote]
  );

  const voteUp = useMemo(
    () =>
      (votes?.up || 0) +
      (userReaction === "up" && vote
        ? 1
        : !userReaction && vote < 0
        ? -1
        : userReaction === "down" && vote < 0 && user_reaction
        ? -1
        : 0),
    [votes, vote, userReaction, user_reaction]
  );
  const voteDown = useMemo(
    () =>
      (votes?.down || 0) +
      (userReaction === "down" && vote
        ? 1
        : !userReaction && vote > 0
        ? -1
        : userReaction === "up" && vote > 0 && user_reaction
        ? -1
        : 0),
    [votes, vote, userReaction, user_reaction]
  );

  return (
    <>
      <div
        className={classNames("flex items-start relative z-10 px-1 py-3", {
          "bg-yellow-100": show,
        })}
      >
        <NavLink
          to={withParams(routes.researcherProfile, {
            id: created_by?.id,
          })}
          className="flex justify-center items-center rounded-full bg-gray-300 w-10 h-10 text-white font-medium bg-center bg-cover"
          style={{ backgroundImage: `url(${created_by?.avatar})` }}
        >
          {!created_by?.avatar ? get(created_by, "name[0]") : ""}
        </NavLink>
        <div className="flex-1 ml-3">
          <div className="flex items-center text-xs text-gray-500">
            {created_at === "now"
              ? "Now"
              : format(new Date(created_at), "MMM dd YYY, HH:mm")}

            {is_owner &&
              !deleteTopicDiscussionCommentMutation.isSuccess &&
              !deleted_at && (
                <button
                  type="button"
                  className="flex items-center text-violet-500 text-xs ml-3 pl-1 hover:text-violet-700"
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <PencilIcon className="mr-1 w-3" />
                  Edit
                </button>
              )}
            {is_owner && !isConfirmDelete && !deleted_at && (
              <button
                type="button"
                className="flex items-center text-red-500 text-xs ml-2 pl-1 hover:text-red-600"
                onClick={() => {
                  setIsConfirmDelete(true);
                }}
              >
                <TrashIcon className="mr-1 w-3" />
                Delete
              </button>
            )}

            {is_owner &&
              isConfirmDelete &&
              !deleteTopicDiscussionCommentMutation.isSuccess && (
                <div className="inline-flex items-center ml-4">
                  Confirm{" "}
                  <Button
                    size="exSmall"
                    variant="danger"
                    loading={deleteTopicDiscussionCommentMutation.isLoading}
                    disabled={deleteTopicDiscussionCommentMutation.isLoading}
                    className="ml-2"
                    onClick={() => {
                      deleteTopicDiscussionCommentMutation.mutateAsync({
                        id: group_id,
                        comment_id: id,
                        topicType:
                          type === "blog"
                            ? "blog/api"
                            : type === "project"
                            ? "projects"
                            : "groups",
                      });
                    }}
                  >
                    Delete discussion
                  </Button>
                </div>
              )}
          </div>
          {deleteTopicDiscussionCommentMutation.isSuccess ? (
            <div className="bg-red-100 text-red-500 p-4 my-2 rounded-lg py-3 relative z-10">
              Successfully deleted{" "}
            </div>
          ) : (
            <div>
              <div className="flex justify-between items-center">
                <NavLink
                  to={withParams(routes.researcherProfile, {
                    id: created_by?.id,
                  })}
                  className="text-lg font-bold text-blue-550 hover:text-violet-700 pb-1"
                >
                  {created_by?.name}
                </NavLink>
                <div className="flex items-center">
                  {!is_owner && authToken ? (
                    <button
                      disabled={is_owner || discussionVoteMutation.isLoading}
                      className={classNames(
                        {
                          "text-red-500": userReaction === "down",
                          "text-gray-300":
                            (userReaction && userReaction !== "down") ||
                            is_owner,
                        },
                        "p-1 text-gray-500 disabled:cursor-default"
                      )}
                      type="button"
                      onClick={async () => {
                        try {
                          await discussionVoteMutation.mutateAsync({
                            id,
                            reaction: "down",
                          });

                          if (!userReaction) {
                            setVote(vote - 1);
                            setUserReaction("down");
                            // ReactGA.event({
                            //   category: "User",
                            //   action: "Dislike",
                            // });
                          } else if (userReaction === "down") {
                            setVote(vote + 1);
                            setUserReaction(undefined);
                          } else if (userReaction === "up") {
                            setVote(vote - 2);
                            setUserReaction("down");
                            // ReactGA.event({
                            //   category: "User",
                            //   action: "Dislike",
                            // });
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      {userReaction === "down" ? (
                        <ThumbDownIcon className="w-4 mt-1" />
                      ) : (
                        <ThumbDownOutlineIcon className="w-4 mt-1" />
                      )}
                    </button>
                  ) : (
                    <div className="w-6" />
                  )}
                  <div
                    className={classNames(
                      "flex items-center justify-center w-10 h-6 rounded-full text-xs font-semibold",
                      {
                        "bg-green-100 text-green-500": voteResult > 0,
                        "bg-red-100 text-red-500": voteResult < 0,
                        "bg-gray-200 text-gray-500": voteResult === 0,
                      }
                    )}
                  >
                    {voteDown ? "-" : ""}
                    {voteDown || 0} / {voteUp || 0}
                  </div>
                  {!is_owner && authToken ? (
                    <button
                      disabled={is_owner || discussionVoteMutation.isLoading}
                      className={classNames(
                        {
                          "text-green-500": userReaction === "up",
                          "text-gray-300":
                            (userReaction && userReaction !== "up") || is_owner,
                        },
                        "p-1 text-gray-500 disabled:cursor-default"
                      )}
                      type="button"
                      onClick={async () => {
                        try {
                          await discussionVoteMutation.mutateAsync({
                            id,
                            reaction: "up",
                          });

                          if (!userReaction) {
                            setVote(vote + 1);
                            setUserReaction("up");
                            // ReactGA.event({
                            //   category: "User",
                            //   action: "Like",
                            // });
                          } else if (userReaction === "down") {
                            setVote(vote + 2);
                            setUserReaction("up");
                            // ReactGA.event({
                            //   category: "User",
                            //   action: "Like",
                            // });
                          } else if (userReaction === "up") {
                            setVote(vote - 1);
                            setUserReaction(undefined);
                          }
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      {userReaction === "up" ? (
                        <ThumbUpIcon className="w-4" />
                      ) : (
                        <ThumbUpOutlineIcon className="w-4" />
                      )}
                    </button>
                  ) : (
                    <div className="w-6" />
                  )}
                </div>
              </div>
              {!isEdit && (
                <div className="text-sm text-gray-700 font-medium">
                  {replyName && (
                    <span className="bg-gray-200 rounded-xl px-2 py-1 mr-1 text-xs text-gray-700 font-semibold text-sm">
                      @{replyName}
                    </span>
                  )}{" "}
                  <div className="mt-1 mb-3">
                    {(
                      edit_is_public === undefined ? is_public : edit_is_public
                    ) ? (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-200 text-green-900 mr-2">
                        Public
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-100 text-indigo-800 mr-2">
                        Private
                      </span>
                    )}

                    {updated_at &&
                      created_at !== "now" &&
                      format(new Date(created_at), "MMM dd YYY, HH:mm:ss") !==
                        format(
                          new Date(updated_at),
                          "MMM dd YYY, HH:mm:ss"
                        ) && (
                        <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                          Edited at{" "}
                          {format(new Date(updated_at), "MMM dd, YYY")}
                        </span>
                      )}

                    {deleted_at && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                        Deleted at{" "}
                        {format(new Date(deleted_at), "MMM dd YYY, HH:mm")}
                      </span>
                    )}

                    {(editValue || edit_is_public !== undefined) && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                        Edited on {format(new Date(), "MMM dd, YYY")}
                      </span>
                    )}
                  </div>
                  <div>
                    <div
                      className={classNames(
                        "wysiwyg-editor text-lg font-medium",
                        {
                          // "comment-clamp": !showAllReplay,
                        }
                      )}
                      dangerouslySetInnerHTML={{
                        __html: truncateHtml(
                          (editValue || text).replace(/<p><br><\/p>/g, ""),
                          showAllReplay ? 99999 : 600
                        ),
                      }}
                    />

                    {truncateHtml(editValue || text, 600)
                      .replace(/(<([^>]+)>)/gi, "")
                      .endsWith("...") && (
                      <Button
                        size="exSmall"
                        variant="white"
                        className="my-1"
                        onClick={() => {
                          setShowAllReplay(!showAllReplay);
                        }}
                      >
                        {showAllReplay ? "Show less" : "Show more"}
                      </Button>
                    )}
                  </div>
                </div>
              )}

              {isEdit && (
                <div className="mt-2">
                  {" "}
                  <TopicsCommentForm
                    group_id={group_id}
                    type={type}
                    comment_id={id}
                    onSubmit={(comment: Discussion) => {
                      setEditValue(comment.text);
                      setEditIsPublic(comment.is_public);
                      setIsEdit(false);
                    }}
                    onEditCancel={() => {
                      setIsEdit(false);
                    }}
                    defaultValues={{
                      text: editValue || text,
                      is_public:
                        edit_is_public === undefined
                          ? is_public
                          : edit_is_public,
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames("mt-1 pl-5 ml-6 border-l-2", {
          "border-gray-200": show,
          "border-transparent": !show,
        })}
      >
        <button
          className="flex items-center mt-1 ml-3 text-violet-550 text-lg font-semibold"
          onClick={() => {
            setShow(!show);
          }}
        >
          <span className="underline">{show ? "hide" : "show"} replies</span>{" "}
          <ChatIcon className="w-5 ml-4 mr-0.5" /> ({num_replies})
        </button>

        {show && (
          <div className="pl-3 pr-1">
            <Discussions type={type} id={id} canWrite={is_member} />
          </div>
        )}
      </div>
    </>
  );
};

export const TopicsDiscussionsList = ({
  id,
  is_member,
  type,
  can_create_discussion,
  can_set_private,
}: {
  id: string;
  is_member: boolean;
  type?: string;
  can_create_discussion?: boolean;
  can_set_private?: boolean;
}) => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
  } = useContext(TopicsDiscussionsContext);

  const { authToken } = useContext(AppContext);
  const [commentsAdded, setCommentsAdded] = useState<Discussion[]>([]);
  const [isAddTopic, setIsAddTopic] = useState(false);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          {!authToken && (
            <div className="mb-6 text-lg font-medium text-gray-600">
              Please{" "}
              <NavLink to="/register" className="font-medium text-violet-600">
                create an account
              </NavLink>{" "}
              or{" "}
              <NavLink to="/login" className="font-medium text-violet-600">
                log in
              </NavLink>{" "}
              to join the discussion!
            </div>
          )}

          {authToken && is_member && can_create_discussion && (
            <div className="mb-4">
              {isAddTopic ? (
                <div className="mt-8">
                  <TopicsCommentForm
                    group_id={id}
                    type={type}
                    can_set_private={can_set_private}
                    onSubmit={(newComment: Discussion) => {
                      setCommentsAdded([
                        { ...newComment, created_at: "now" },
                        ...commentsAdded,
                      ]);

                      setIsAddTopic(false);
                    }}
                  />
                </div>
              ) : (
                <Button
                  size="exSmall"
                  onClick={() => {
                    setIsAddTopic(true);
                  }}
                >
                  Add topic
                </Button>
              )}
            </div>
          )}

          {[...commentsAdded, ...listData].map(
            (
              {
                id: comment_id,
                text,
                is_public,
                user_reaction,
                created_at,
                updated_at,
                deleted_at,
                created_by,
                replies,
                votes,
                is_owner,
                num_replies,
              },
              i
            ) => (
              <div
                key={comment_id}
                className={classNames(
                  "py-4 pl-4 pr-2 border-gray-200 border-t",
                  [i % 2 ? "bg-white" : "bg-violet-100"]
                )}
              >
                <Comment
                  group_id={id}
                  id={comment_id}
                  text={text}
                  is_public={is_public}
                  created_at={created_at}
                  updated_at={updated_at}
                  deleted_at={deleted_at}
                  created_by={created_by}
                  user_reaction={user_reaction}
                  is_owner={is_owner}
                  votes={votes}
                  is_member={is_member}
                  type={type}
                  num_replies={num_replies}
                />
              </div>
            )
          )}

          {listData.length === 0 && commentsAdded.length === 0 && (
            <div className="text-gray-500 pt-4">No topics yet.</div>
          )}
        </div>
      )}

      {hasNextPage && (
        <div className="flex justify-center mt-8">
          <Button
            loading={isFetchingNextPage}
            spinner
            size="small"
            disabled={!hasNextPage}
            onClick={handleFetchNextPage}
            className="w-full"
          >
            Load more topics
          </Button>
        </div>
      )}
    </div>
  );
};
