import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import { useContextService } from "../../hooks/use-context-service";
import { getStaticPage } from "../../api/static-pages";
import { useQuery } from "react-query";
import get from "lodash/get";
import { checkNode, setNodeId, extractContent } from 'domains/researcher-guide/ResearcherGuide';
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import {html2json, json2html, Node} from "html2json";
import map from "lodash/map";

const FAQ_REQ = "FAQ_REQ";

export const FAQ = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [FAQ_REQ],
    () => getStaticPageService({ slug: "faqs" }),
    {
      suspense: false,
    }
  );

  const [content, setContent] = useState<string>();
  const [headings, setHeadings] = useState<
    {
      name: string;
      id: string;
      child: { name: string; id: string }[];
    }[]
    >();

  useEffect(() => {
    if (data && data.data) {
      const nodes = html2json(data?.data?.body);
      const headings: {
        name: string;
        id: string;
        child: { name: string; id: string }[];
      }[] = [];
      let id = 1;

      if (Array.isArray(nodes.child)) {
        setNodeId(nodes.child, (node: Node) => {
          node.attr = { ...node.attr, id: `id-${id}` };
          id++;
        });

        checkNode(nodes.child, (node: Node) => {
          if (node.tag === "h2") {
            headings.push({
              name: extractContent(json2html(node)),
              // @ts-ignore
              id: node.attr.id!,
              child: [],
            });
          }
          if (node.tag === "h3" && headings[headings.length - 1]) {
            headings[headings.length - 1].child.push({
              name: extractContent(json2html(node)),
              // @ts-ignore
              id: node.attr.id!,
            });
          }
        });
      }

      setHeadings(headings);
      setContent(json2html(nodes));
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-gray-900">
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.title}</title>
          </Helmet>

          <h2 className="my-2 text-3xl font-bold">{data?.data?.title}</h2>

          {headings && headings.length > 0 && (
            <ul className="pb-4 text-violet-600">
              {map(headings, ({ name, id, child }) => (
                <li key={id} className='mb-3'>
                  <a href={`#${id}`}>{name}</a>

                  {child.length > 0 && (
                    <ul className="pt-1 ml-5 text-violet-600">
                      {map(child, ({ name, id }) => (
                        <li key={id}>
                          <a href={`#${id}`}>{name}</a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}

          {content && (
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
      )}
    </div>
  );
};
