import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useInfiniteQuery } from "react-query";

import { getFeed, Feed } from "api/feed";
import { useContextService } from "../../hooks/use-context-service";
// import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDebouncedValue } from "../../hooks/use-debounced-value";

export const LIST_QUERY_KEY = "FEED/LIST";

export const initialState = {
  dispatch: (_action: Actions) => {},
  listData: [] as Feed[],
  debouncedSearchQuery: "",
  isLoading: false,
  isFetching: false,
  hasNextPage: false as boolean | undefined,
  isFetchingNextPage: false as boolean | undefined,
  columnSorting: {},
  handleFetchNextPage: () => {},
};

export type Actions = {
  type: "SET_COLUMN_SORTING";
  columnSorting: {};
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case "SET_COLUMN_SORTING":
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const FeedContext = createContext<typeof initialState>(
  initialState
);

export interface FeedListFields {
  page: number;
  searchQuery: string;
  event_type: string;
  object_type: string;
}

export const FeedContextProvider: FC = ({ children }) => {
  const methods = useForm<FeedListFields>({
    defaultValues: {
      page: 1,
      searchQuery: "",
    },
  });

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { setValue } = methods;
  const [searchQuery, page, event_type, object_type] = methods.watch([
    "searchQuery",
    "page",
    "event_type",
    "object_type",
  ]);

  const debouncedSearchQuery = useDebouncedValue<string>(searchQuery, 750);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === "development") {
      console.info("Dispatch Action", {
        ...action,
        context: LIST_QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  const getFeedService = useContextService(getFeed);

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    LIST_QUERY_KEY,
    (params) => {
      return getFeedService({
        searchQuery: debouncedSearchQuery,
        event_type,
        object_type,
        page,
        perPage: 10,
      });
    },
    {
      suspense: false,
      retry: false,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination.currentPage! <
        lastPage?.data?.pagination.total!,
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (page && page > 1) {
      fetchNextPage();
    }
    // if (page === 1) {
    //   remove();
    //   refetch();
    // }
  }, [page, fetchNextPage, remove]); // eslint-disable-line

  useEffect(() => {
    setValue("page", 1);
    setTimeout(() => {
      remove();
      refetch();
    }, 0);
  }, [debouncedSearchQuery, event_type, object_type, setValue, refetch, remove]);

  const listData = useMemo(() => {
    return (
      data?.pages
        .map((feedItem) =>
          (feedItem?.data?.data || []).map((item) => ({
            ...item,
          }))
        )
        .flat() || []
    );
  }, [data]);

  const handleFetchNextPage = useCallback(() => {
    setValue("page", page + 1);
  }, [setValue, page]);

  return (
    <FormProvider {...methods}>
      <FeedContext.Provider
        value={{
          ...state,
          dispatch,
          listData,
          isFetching,
          isLoading,
          hasNextPage,
          isFetchingNextPage,
          handleFetchNextPage,
        }}
      >
        {children}
      </FeedContext.Provider>
    </FormProvider>
  );
};
