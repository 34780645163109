import React, { useEffect } from "react";

// import ImageAndrewDemchuk from "assets/images/Andrew-Demchuk.jpg";
// import ImageSoffienAjmi from "assets/images/SoffienAjmi.jpg";
// import ImageJoachimFladt from "assets/images/JoachimFladt.jpg";
// import ImageAmeerHassan from "assets/images/Ameer-Hassan.jpg";
// import ImageDashiellYoungSaver from "assets/images/Dashiell-Young-Saver.jpg";
// import ImageMohammedAlmekhlafi from "assets/images/Mohammed-Almekhlafi.jpg";
// import ImageBrianBuck from "assets/images/Brian-Buck.jpg";
// import ImageSantanuChakraborty from "assets/images/Santanu-Chakraborty.jpg";
// import ImageDarDowlatshashi from "assets/images/Dar-Dowlatshashi.jpg";
// import ImageAshutoshJadhav from "assets/images/Ashutosh-Jadhav.jpg";
// import ImageBabakJahromi from "assets/images/Babak-Jahromi.jpg";
// import ImageMichaelKelly from "assets/images/Michael-Kelly.jpg";
// import ImageMarkusMohlenbruch from "assets/images/MarkusMohlenbruch.jpg";
// import ImageRyanMcTaggart from "assets/images/RyanMcTaggart.jpg";
// import ImageBoNorrving from "assets/images/BoNorrving.jpg";
// import ImageJeyarajPandian from "assets/images/JeyarajPandian.jpg";
// import ImageJeremyRempel from "assets/images/JeremyRempel.jpg";
// import ImageJoannaSchaafsma from "assets/images/JoannaSchaafsma.jpg";
// import ImageNishitaSingh from "assets/images/NishitaSingh.jpg";
// import ImagePadmaSrivastava from "assets/images/PadmaSrivastava.jpg";
// import ImageSylajaPN from "assets/images/SylajaPN.jpg";
// import ImageSteffenTiedt from "assets/images/SteffenTiedt.jpg";
// import ImageDavidVolders from "assets/images/DavidVolders.jpg";
// import ImageShinichiYoshimura from "assets/images/ShinichiYoshimura.jpg";
// import ImageJustinFraser from "assets/images/JustinFraser.jpg";
// import ImageYuZhou from "assets/images/YuZhou.jpg";
// import ImageTudorJovin from "assets/images/TudorJovin.jpg";
// import ImageOssamaMansour from "assets/images/OssamaMansour.jpg";
// import ImageAlexanderPoppe from "assets/images/AlexanderPoppe.jpg";
// import ImageEricSmith from "assets/images/EricSmith.jpg";
// import ImageGarnetteSutherland from "assets/images/GarnetteSutherland.jpg";
import { useContextService } from "../../hooks/use-context-service";
import { getStaticPage } from "../../api/static-pages";
import { useQuery } from "react-query";
import get from "lodash/get";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import classNames from "classnames";
import {Helmet} from "react-helmet";

const SCIENTIFIC_REVIEW_COMMITTEE = "SCIENTIFIC_REVIEW_COMMITTEE";

export const ScientificReviewCommittee = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [SCIENTIFIC_REVIEW_COMMITTEE],
    () => getStaticPageService({ slug: "scientific-review-committee" }),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return error ? (
    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
      {get(error, "response.data.messages.detail", "Something went wrong")}
    </div>
  ) : isFetching ? (
    <div className="flex justify-center mt-24">
      <IconLoading />
    </div>
  ) : (
    <div className="pb-12 mx-auto">
      <Helmet>
        <title>Let’s Get Proof | {data?.data?.title}</title>
      </Helmet>

      {data?.data?.before_title && (
        <h3
          className={classNames("my-2 text-xl font-bold", {
            "text-center": data?.data?.title_align === "CENTER",
            "text-right": data?.data?.title_align === "RIGHT",
          })}
        >
          {data?.data?.before_title}
        </h3>
      )}
      <h2
        className={classNames("my-2 text-3xl font-bold", {
          "text-center": data?.data?.title_align === "CENTER",
          "text-right": data?.data?.title_align === "RIGHT",
        })}
      >
        {data?.data?.title}
      </h2>

      {data?.data && (
        <div
          className="wysiwyg-editor"
          dangerouslySetInnerHTML={{ __html: data?.data?.body }}
        />
      )}

      {/*<h2 className="mt-2 mb-6 text-3xl font-bold">
        Scientific Review Committee
      </h2>

      <div className="text-gray-700 pb-8">
        <div className="pb-4">
          Our Scientific Review Committee comprises a group of key opinion
          leaders in stroke medicine from all around the globe. Collectively,
          they offer a wide range of perspectives and experiences and are there
          to provide researchers on Let’s Get Proof with valuable feedback on their
          research ideas and proposals. With our Scientific Review Committee,
          Let’s Get Proof offers a truly unique opportunity to discuss with and
          gain input from such a varied and knowledgeable group of individuals!
        </div>
      </div>

      <div className="flex flex-col items-center text-center mb-16">
        <div className="w-32 sm:w-56">
          <img
            src={ImageAndrewDemchuk}
            alt="Andrew Demchuk"
            className="w-full rounded-lg"
          />
        </div>

        <div className="flex-1 text-gray-700 ml-4 lg:ml-6 mt-4">
          <div className="text-lg font-semibold text-gray-900 pb-2">
            Dr. Andrew M. Demchuk (Chair)
          </div>
          <div className="pb-1">Director, Calgary Stroke Program</div>
          <div className="pb-1">
            Professor, Clinical Neurosciences, Radiology
          </div>
          <div className="pb-1">
            Deputy Head, Dept of Clinical Neurosciences
          </div>
          <div className="pb-1">University of Calgary</div>
          <div className="pb-1">
            Chair, Board of Directors, Canadian Stroke Consortium
          </div>
          <div className="pb-1">
            Co-Lead, Leadership Council, Canadian Partnership for Stroke
            Recovery
          </div>
          <div className="">Canada</div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 gap-16">
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageDashiellYoungSaver}
              alt="Dashiell Young Saver"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dashiell Young-Saver (Statistics Lead)
            </div>
            <div className="text-sm pb-1">
              Founder and Director, Skew the Script
            </div>
            <div className="text-sm pb-1">USA</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageAmeerHassan}
              alt="Ameer Hassan"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Ameer E. Hassan
            </div>
            <div className="text-sm pb-1">Head of Neuroscience Department</div>
            <div className="text-sm pb-1">
              Director Endovascular Surgical Neuroradiology
            </div>
            <div className="text-sm pb-1">
              Director Clinical Neuroscience Research
            </div>
            <div className="text-sm pb-1">Valley Baptist Medical Center</div>
            <div className="text-sm pb-1">
              Professor of Neurology, University of Texas Rio Grande Valley
            </div>
            <div className="text-sm pb-1">President, SVIN</div>
            <div className="text-sm">USA</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageSoffienAjmi}
              alt="Soffien Ajmi"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Soffien Ajmi
            </div>
            <div className="text-sm pb-1">
              Consultant Neurologist, PhD Candidate
            </div>
            <div className="text-sm pb-1">
              Department of Neurology, Stavanger University Hospital,
            </div>
            <div className="text-sm pb-1">
              Faculty of Health Sciences, University of Stavanger
            </div>
            <div className="text-sm pb-1">Norway</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageMohammedAlmekhlafi}
              alt="Mohammed Almekhlafi"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Mohammed Almekhlafi
            </div>
            <div className="text-sm pb-1">
              Associate Professor, Clinical Neurosciences, Radiology, Community
              Health Sciences
            </div>
            <div className="text-sm pb-1">Calgary Stroke Program</div>
            <div className="text-sm pb-1">University of Calgary</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageBrianBuck}
              alt="Brian Buck"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Brian Buck
            </div>
            <div className="text-sm pb-1">Stroke Neurologist</div>
            <div className="text-sm pb-1">Associate Professor, Neurology</div>
            <div className="text-sm pb-1">University of Alberta</div>
            <div className="text-sm pb-1">Canada</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageSantanuChakraborty}
              alt="Santanu Chakraborty"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Santanu Chakraborty
            </div>
            <div className="text-sm pb-1">Professor, Radiology</div>
            <div className="text-sm pb-1">University of Ottawa</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageDarDowlatshashi}
              alt="Dar Dowlatshashi"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Dar Dowlatshahi
            </div>
            <div className="text-sm pb-1">Professor, University of Ottawa</div>
            <div className="text-sm pb-1">
              Senior Scientist, Ottawa Hospital Research Institute
            </div>
            <div className="text-sm pb-1">
              Stroke Neurologist, Ottawa Hospital
            </div>
            <div className="text-sm">Canada</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageJoachimFladt}
              alt="Joachim Fladt"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Joachim Fladt
            </div>
            <div className="text-sm pb-1">
              Neurologist and Visiting Researcher
            </div>
            <div className="text-sm pb-1">
              Department of Clinical Neurosciences
            </div>
            <div className="text-sm pb-1">Calgary Stroke Program</div>
            <div className="text-sm pb-1">University of Calgary</div>
            <div className="text-sm pb-1">Canada</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageJustinFraser}
              alt="Justin Fraser"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Justin F. Fraser
            </div>
            <div className="text-sm pb-1">
              Associate Professor and Vice-Chair,
            </div>
            <div className="text-sm pb-1">
              Department of Neurological Surgery
            </div>
            <div className="text-sm pb-1">University of Kentucky</div>
            <div className="text-sm">USA</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageAshutoshJadhav}
              alt="Ashutosh Jadhav"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Ashutosh P. Jadhav
            </div>
            <div className="text-sm pb-1">
              Vascular and Interventional Neurologist{" "}
            </div>
            <div className="text-sm pb-1">Barrow Neurological Institute</div>
            <div className="text-sm">USA</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageBabakJahromi}
              alt="Babak Jahromi"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Babak S. Jahromi
            </div>
            <div className="text-sm pb-1">
              Vice Chair, Regional Neurosurgery
            </div>
            <div className="text-sm pb-1">
              Professor and Chief, Cerebrovascular/Neurointerventional Surgery
            </div>
            <div className="text-sm pb-1">Northwestern Medicine</div>
            <div className="text-sm">USA</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageTudorJovin}
              alt="Tudor Jovin"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Tudor Jovin
            </div>
            <div className="text-sm pb-1">
              Director, Cooper Neurological Institute
            </div>
            <div className="text-sm pb-1">Chair, Department of Neurology</div>
            <div className="text-sm pb-1">Cooper University Health Care</div>
            <div className="text-sm pb-1">
              Professor, Neurology and Neurosurgery
            </div>
            <div className="text-sm pb-1">
              Cooper Medical School of Rowan University
            </div>
            <div className="text-sm">USA</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageMichaelKelly}
              alt="Michael Kelly"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Michael Kelly
            </div>
            <div className="text-sm pb-1">
              Professor and Head of Neurosurgery
            </div>
            <div className="text-sm pb-1">
              Saskatchewan Clinical Stroke Research Chair
            </div>
            <div className="text-sm pb-1">Royal University Hospital</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 h-28 sm:h-56 sm:w-56 rounded-lg bg-gray-200">
            <img
              src={ImageOssamaMansour}
              alt="Ossama Mansour"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Ossama Mansour{" "}
            </div>
            <div className="text-sm pb-1">
              Professor, Clinical and Interventional Neurology, Neuroradiology
            </div>
            <div className="text-sm pb-1">
              Director of Stroke and Neurointervention Unit
            </div>
            <div className="text-sm pb-1">
              Alexandria University, School of Medicine
            </div>
            <div className="text-sm pb-1">President, MENA-SINO</div>
            <div className="text-sm pb-1">Co-chair, MT2020 (MENA region)</div>
            <div className="text-sm pb-1">Board of Directors, SVIN</div>
            <div className="text-sm">Egypt</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageRyanMcTaggart}
              alt="Ryan McTaggart"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Ryan A. McTaggart
            </div>
            <div className="text-sm pb-1">
              Associate Professor, Diagnostic Imaging, Neurology, Neurosurgery
            </div>
            <div className="text-sm pb-1">
              Warren Alpert Medical School, Brown University
            </div>
            <div className="text-sm pb-1">
              Director, Interventional Radiology
            </div>
            <div className="text-sm pb-1">Rhode Island Hospital</div>
            <div className="text-sm">USA</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageMarkusMohlenbruch}
              alt="Markus Mohlenbruch"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Markus A. Möhlenbruch
            </div>
            <div className="text-sm pb-1">
              Head of Interventional Neuroradiology
            </div>
            <div className="text-sm pb-1">
              Professor, Department of Neuroradiology
            </div>
            <div className="text-sm pb-1">Heidelberg University Hospital</div>
            <div className="text-sm">Germany</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 h-28 sm:h-56 sm:w-56 rounded-lg bg-gray-200" />

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Raul Nogueira
            </div>
            <div className="text-sm pb-1">
              Professor, Neurology, Neurosurgery
            </div>
            <div className="text-sm pb-1">Director, UPMC Stroke Institute</div>
            <div className="text-sm pb-1">University of Pittsburgh</div>
            <div className="text-sm">USA</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageBoNorrving}
              alt="Bo Norrving"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Bo Norrving
            </div>
            <div className="text-sm pb-1">Senior Professor, Neurology</div>
            <div className="text-sm pb-1">Lund University</div>
            <div className="text-sm">Sweden</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageJeyarajPandian}
              alt="Jeyaraj Pandian"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Jeyaraj Durai Pandian
            </div>
            <div className="text-sm pb-1">
              Principal (Dean) and Professor of Neurology
            </div>
            <div className="text-sm pb-1">Christian Medical College</div>
            <div className="text-sm pb-1">
              Vice-President, World Stroke Organisation
            </div>
            <div className="text-sm pb-1">
              President, Indian Stroke Association
            </div>
            <div className="text-sm">India</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageAlexanderPoppe}
              alt="Alexander Poppe"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Alexander Poppe
            </div>
            <div className="text-sm pb-1">Clinical Associate Professor</div>
            <div className="text-sm pb-1">
              Department of Neurosciences, Université de Montréal
            </div>
            <div className="text-sm pb-1">
              Centre Hospitalier de l'Université de Montréal (CHUM)
            </div>
            <div className="text-sm">Canada</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageJeremyRempel}
              alt="Jeremy Rempel"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Jeremy L. Rempel
            </div>
            <div className="text-sm pb-1">
              Interventional and Diagnostic Neuroradiologist
            </div>
            <div className="text-sm pb-1">
              Assistant Clinical Professor, Radiology, Neurosurgery
            </div>
            <div className="text-sm pb-1">University of Alberta</div>
            <div className="text-sm pb-1">Partner - MIC Medical Imaging</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageJoannaSchaafsma}
              alt="Joanna Schaafsma"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Joanna Schaafsma
            </div>
            <div className="text-sm pb-1">
              Assistant Professor, University of Toronto
            </div>
            <div className="text-sm pb-1">
              Vascular Neurologist, University Health Network, Toronto
            </div>
            <div className="text-sm">Canada</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageNishitaSingh}
              alt="Nishita Singh"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Nishita Singh
            </div>
            <div className="text-sm pb-1">
              Stroke Fellow, Calgary Stroke Program
            </div>
            <div className="text-sm pb-1">
              Fellow representative, Canada Stroke Consortium
            </div>
            <div className="text-sm pb-1">University of Calgary</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>
        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 h-28 sm:h-56 sm:w-56 rounded-lg bg-gray-200">
            <img
              src={ImageEricSmith}
              alt="Eric Smith"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Eric Smith
            </div>
            <div className="text-sm pb-1">
              Professor, Clinical Neurosciences
            </div>
            <div className="text-sm pb-1">
              Medical Director of the Cognitive Neurosciences Clinic
            </div>
            <div className="text-sm pb-1">Calgary Stroke Program</div>
            <div className="text-sm pb-1">University of Calgary</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImagePadmaSrivastava}
              alt="Padma Srivastava"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Padma Srivastava
            </div>
            <div className="text-sm pb-1">
              Professor and Head, Department of Neurology
            </div>
            <div className="text-sm pb-1">Chief, Neurosciences Center</div>
            <div className="text-sm pb-1">AIIMS, New Delhi</div>
            <div className="text-sm pb-1">
              Past President, Indian Stroke Association
            </div>
            <div className="text-sm">India</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageGarnetteSutherland}
              alt="Garnette Sutherland"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Garnette Sutherland
            </div>
            <div className="text-sm pb-1">Professor of Neurosurgery</div>
            <div className="text-sm pb-1">University of Calgary</div>
            <div className="text-sm pb-1">Canada</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageSylajaPN}
              alt="Sylaja PN"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. P.N. Sylaja
            </div>
            <div className="text-sm pb-1">Professor and Head of Neurology,</div>
            <div className="text-sm pb-1">
              Head, Comprehensive Stroke Care Program,
            </div>
            <div className="text-sm pb-1">
              Sree Chitra Tirunal Institute for Medical Sciences and Technology
            </div>
            <div className="text-sm">India</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageSteffenTiedt}
              alt="Steffen Tiedt"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Steffen Tiedt
            </div>
            <div className="text-sm pb-1">
              Institute for Stroke and Dementia Research
            </div>
            <div className="text-sm pb-1">LMU Munich</div>
            <div className="text-sm">Germany</div>
          </div>
        </div>
        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageDavidVolders}
              alt="David Volders"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. David Volders
            </div>
            <div className="text-sm pb-1">
              Assistant Professor, Interventional and Diagnostic Neuroradiology
            </div>
            <div className="text-sm pb-1">Dalhousie University Halifax</div>
            <div className="text-sm">Canada</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 h-28 sm:h-56 sm:w-56 rounded-lg bg-gray-200" />

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Albert Yoo{" "}
            </div>
            <div className="text-sm pb-1">Medical Director</div>
            <div className="text-sm pb-1">
              Director of Research and Neuroendovascular Fellowship
            </div>
            <div className="text-sm pb-1">Texas Stroke Institute</div>
            <div className="text-sm">USA</div>
          </div>
        </div>

        <div className="flex">
          <div className="w-28 sm:w-56">
            <img
              src={ImageShinichiYoshimura}
              alt="Shinichi Yoshimura"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
            <div className="font-semibold text-gray-900 pb-2">
              Dr. Shinichi Yoshimura
            </div>
            <div className="text-sm pb-1">
              Professor and Chairman, Neurosurgery
            </div>
            <div className="text-sm pb-1">Hyogo Medical University</div>
            <div className="text-sm">Japan</div>
          </div>
        </div>

        <div className="flex lg:flex-row-reverse lg:pt-24">
          <div className="w-28 sm:w-56">
            <img
              src={ImageYuZhou}
              alt="Yu Zhou"
              className="w-full rounded-lg"
            />
          </div>

          <div className="flex-1 text-gray-700 ml-4 lg:mx-6 lg:text-right">
            <div className="font-semibold text-gray-900 pb-2">Dr. Yu Zhou</div>
            <div className="text-sm pb-1">
              Associate Professor, Neurovascular Center
            </div>
            <div className="text-sm pb-1">
              Changhai Hospital, Naval Medical University
            </div>
            <div className="text-sm">China</div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};
