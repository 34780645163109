import React, { forwardRef } from "react";
import map from "lodash/map";
import find from "lodash/find";
import get from "lodash/get";
import { Controller, FieldError } from "react-hook-form";
import { MultiSelect } from "react-multi-select-component";
import { XIcon } from "@heroicons/react/outline";

import { FieldGroup } from "../FieldGroup";

export interface OptionType {
  value: string | number;
  label: string;
}

export interface CustomSelectProps {
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (v: { value: string }[]) => void;
  optional?: boolean;
  options: OptionType[];
  control: any;
  rules?: any;
  error?: FieldError;
  defaultFirst?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
  max?: number;
}

export const MultiSelectField = forwardRef(
  (
    {
      control,
      name,
      disabled,
      options,
      rules,
      required,
      optional,
      isCreatable,
      onChange: onChangeProp,
      placeholder,
      max,
      ...props
    }: CustomSelectProps,
    ref
  ) => {
    return (
      <FieldGroup
        required={required}
        optional={optional}
        name={name}
        {...props}
      >
        {() => (
          <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <MultiSelect
                isCreatable={isCreatable}
                valueRenderer={(selected, _options) => {
                  return selected.length
                    ? selected.map(({ label, value }) => (
                        <span
                          key={value}
                          className="inline-flex items-center my-0.5 mx-1 bg-violet-100 text-violet-700 text-sm rounded px-2 py-0.5"
                        >
                          {label || value}

                          {isCreatable && (
                            <span
                              className="ml-2 text-violet-700"
                              onClick={() => {
                                onChange(
                                  selected
                                    .filter((item) => item.value !== value)
                                    .map((o: OptionType) => o.value)
                                );
                                onChangeProp &&
                                  onChangeProp(
                                    selected.filter(
                                      (item) => item.value !== value
                                    )
                                  );
                              }}
                            >
                              <XIcon className="w-3" />
                            </span>
                          )}
                        </span>
                      ))
                    : placeholder || "Select items";
                }}
                labelledBy="Select"
                onChange={(value: any) => {
                  onChange(map(value, (o: OptionType) => o.value));
                  onChangeProp && onChangeProp(value);
                }}
                value={map(value || [], (value) => ({
                  label: get(
                    find(options, (o) => o.value === value),
                    "label",
                    ""
                  ),
                  value,
                }))}
                disabled={disabled}
                options={
                  (max ? (value || []).length >= max : false)
                    ? options.map((item) => ({
                        ...item,
                        disabled: !value.includes(item.value),
                      }))
                    : options
                }
                hasSelectAll={false}
                disableSearch={!isCreatable}
              />
            )}
          />
        )}
      </FieldGroup>
    );
  }
);
