import React from "react";

import { TopicsDiscussionsList } from "./TopicsDiscussionsList";
import { TopicsDiscussionsContextProvider } from "./TopicsDiscussionsContext";

export const TopicsDiscussions = ({
  id,
  is_member,
  type,
  can_create_discussion = true,
  can_set_private = true,
}: {
  id: string;
  is_member: boolean;
  type?: string;
  can_create_discussion?: boolean;
  can_set_private?: boolean;
}) => {
  return (
    <TopicsDiscussionsContextProvider id={id} type={type}>
      <TopicsDiscussionsList
        id={id}
        is_member={is_member}
        can_set_private={can_set_private}
        type={type}
        can_create_discussion={can_create_discussion}
      />
    </TopicsDiscussionsContextProvider>
  );
};
