import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useContextService } from "hooks/use-context-service";
import get from "lodash/get";
import findIndex from "lodash/findIndex";

import { getQuiz } from "api/quiz";
import { QuizForm } from "./QuizForm";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { routes } from "../../../routes";
import classNames from "classnames";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { Button } from "../../../components/button/Button";

export const GET_QUIZ = "GET_QUIZ";

interface ParamTypes {
  id: string;
}

export const QuizSingle = () => {
  const { id } = useParams<ParamTypes>();

  const queryClient = useQueryClient();
  const getQuizService = useContextService(getQuiz);

  const { data, isFetching, error } = useQuery(
    [GET_QUIZ, id],
    () => getQuizService({ id }),
    {
      suspense: false,
      cacheTime: 0,
      keepPreviousData: false,
    }
  );

  const [step, setStep] = useState<number>();

  useEffect(() => {
    if (data && !step && step !== 0) {
      setStep(
        findIndex(
          data?.data?.data.questions,
          ({ id }) => id === data?.data?.data.current_question_id
        )
      );
    }
  }, [data, step]);

  const [formResultMessage, setFormResultMessage] = useState<
    | {
        message: string;
        type: string;
        retry: boolean;
        certificate_url?: string;
      }
    | undefined
  >();

  // const step = useMemo(
  //   () =>
  //     findIndex(
  //       data?.data?.data.questions,
  //       ({ id }) => id === data?.data?.data.current_question_id
  //     ),
  //   [data?.data?.data]
  // );

  return (
    <div>
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(
            error,
            "response.data.messages.detail",
            get(error, "response.data.messages.non_field")
          )}
        </div>
      ) : !data || isFetching ? (
        <div className="flex justify-center w-full mt-24">
          <IconLoading />
        </div>
      ) : (
        <div className="w-full pr-4">
          <Helmet>
            <title>Let’s Get Proof | Quiz | {data?.data?.data?.name}</title>
          </Helmet>

          <nav className="flex mb-8" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex items-center">
                  <NavLink
                    to={routes.quiz}
                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Quizzes
                  </NavLink>

                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                </div>
              </li>
            </ol>
          </nav>

          <div className="my-2">
            <h2 className="mt-2 mb-2 text-3xl font-bold">
              {data?.data?.data?.name}
            </h2>
          </div>

          {(step || step === 0) && (
            <div>
              <div className="mb-1 text-base font-medium text-gray-700">
                Question {step + 1}/{data?.data?.data.questions.length}
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 dark:bg-gray-700 max-w-md">
                <div
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{
                    width: `${
                      ((step + 1) / data?.data?.data.questions.length!) * 100
                    }%`,
                  }}
                />
              </div>
            </div>
          )}

          {formResultMessage && (
            <div>
              <div
                className={classNames("rounded-md p-4", [
                  formResultMessage.type === "success"
                    ? "bg-green-50"
                    : "bg-red-50",
                ])}
              >
                <div className="flex">
                  <div className="flex-shrink-0">
                    {formResultMessage.type === "success" ? (
                      <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                      />
                    ) : (
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                  <div className="ml-3">
                    <h3 className="font-medium text-green-800">
                      {formResultMessage.type === "success"
                        ? "Quiz passed"
                        : "Quiz not passed"}
                    </h3>
                    <div
                      className={classNames("mt-2 text-sm", [
                        formResultMessage.type === "success"
                          ? "text-green-700"
                          : "text-red-700",
                      ])}
                    >
                      <p>{formResultMessage?.message}</p>
                    </div>
                    {formResultMessage.type === "success" && (
                      <div className="mt-4">
                        <div className="-mx-2 -my-1.5 flex">
                          <NavLink to={routes.quiz}>
                            <Button variant="green">Back to Quiz List</Button>
                          </NavLink>
                        </div>
                      </div>
                    )}
                    {formResultMessage.type === "error" && (
                      <div className="mt-4">
                        <p className="mb-6 text-red-800">
                          Would you like to improve your answers for next
                          time? Please join the ASPECTS in Stroke Working
                          Group and connect with other members that can help
                          you out:{" "}
                          <a href="https://www.letsgetproof.com/working-groups/aspects-in-stroke-nuances-and-challenges-3" className='underline'>
                            https://www.letsgetproof.com/working-groups/aspects-in-stroke-nuances-and-challenges-3
                          </a>
                        </p>

                        <div className="-mx-2 -my-1.5 flex">

                          <NavLink to={routes.quiz}>
                            <Button variant="redPale">Back to Quiz List</Button>
                          </NavLink>
                          {formResultMessage.retry && (
                            <Button
                              variant="redPale"
                              className="ml-4"
                              onClick={() => {
                                window.location.reload();
                              }}
                            >
                              Retry
                            </Button>
                          )}
                        </div>
                      </div>
                    )}

                    {formResultMessage.certificate_url && (
                      <div className="mt-4 -mx-2">
                        <a
                          href={formResultMessage.certificate_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button variant="primary">
                            Download certificate
                          </Button>
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {data?.data?.data &&
            get(data?.data?.data, `questions[${step}].id`) &&
            (step || step === 0) &&
            !formResultMessage && (
              <QuizForm
                setFormResultMessage={setFormResultMessage}
                data={data?.data?.data!.questions[step]!}
                quizId={data?.data?.data!.id!}
                step={step}
                onSubmit={() => {
                  queryClient.invalidateQueries(GET_QUIZ);
                  setStep(step + 1);
                }}
                onBack={() => {
                  queryClient.invalidateQueries(GET_QUIZ);
                  setTimeout(() => {
                    setStep(step - 1);
                  }, 0);
                }}
              />
            )}

          {/*{(!data?.data?.data?.delayed_until &&
            data?.data?.data?.remaining_attempts! > 0) ||
          (data?.data?.data?.delayed_until &&
            isAfter(new Date(), new Date(data?.data?.data?.delayed_until))) ||
          (data?.data?.data?.delayed_until &&
            isSameDay(
              new Date(data?.data?.data?.delayed_until),
              new Date()
            )) ? (
            <QuizForm data={data?.data?.data!} onSubmit={() => {}} />
          ) : (
            <div className='rounded-md p-4 bg-red-50 text-red-800'>
              <h3 className="font-medium text-red-800">Quiz Unavailable</h3>
              {data?.data?.data?.delayed_until && (
                <p>
                  Delayed until:{" "}
                  {format(new Date(data?.data?.data?.delayed_until), "MMM dd YYY")}
                </p>
              )}
            </div>
          )}*/}
        </div>
      )}
    </div>
  );
};
