import React from "react";
import { useQuery } from "react-query";
import { format } from "date-fns";
import get from "lodash/get";
import { HomeIcon } from "@heroicons/react/outline";

import { useContextService } from "hooks/use-context-service";
import { getWGUpdatesList, getWorkingGroup } from "api/workingGroups";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { withParams } from "utils/url";
import { routes } from "routes";
import { NavLink, useParams } from "react-router-dom";
import classNames from "classnames";
import { Button } from "../../../components/button/Button";
import {Helmet} from "react-helmet";

interface ParamTypes {
  group_id: string;
}

export const GroupUpdatesList = () => {
  const { group_id } = useParams<ParamTypes>();

  const getWGUpdatesListQuery = useContextService(getWGUpdatesList);

  const { data, isLoading, isError } = useQuery(
    `GET_WG_UPDATES_${group_id}`,
    () => getWGUpdatesListQuery({ group_id: group_id! }),
    {
      suspense: false,
    }
  );

  const getWorkingGroupQuery = useContextService(getWorkingGroup);

  const { data: workingGroup } = useQuery(
    ["workingGroup", group_id],
    () => getWorkingGroupQuery({ id: group_id }),
    {
      enabled: !!group_id,
      suspense: false,
    }
  );

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : isError ? (
    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
      Error
    </div>
  ) : (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Working Group Updates</title>
      </Helmet>

      <h2 className="text-3xl font-bold mb-4">
        <span className="inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-purple-100 text-purple-800">
          Working Group updates
        </span>{" "}
        {get(workingGroup, "data.data.title")}
      </h2>

      <nav className="flex mb-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div className="flex items-center">
              <NavLink
                to={routes.dashboard}
                className="mr-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <HomeIcon className="w-5" />
              </NavLink>

              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <NavLink
                to={routes.workingGroupsMy}
                className="mr-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Working groups
              </NavLink>

              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>

          <li>
            <div className="flex items-center">
              <NavLink
                to={withParams(routes.groupUpdatesList, { group_id })}
                className="mr-2 text-sm font-medium text-gray-900"
              >
                Working Groups updates
              </NavLink>
            </div>
          </li>
        </ol>
      </nav>

      {get(workingGroup, "data.data.approved_status") === "approved" && (
        <div>
          <div className="mb-4">
            <NavLink to={withParams(routes.groupUpdatesAdd, { group_id, slug: get(workingGroup, "data.data.slug") })}>
              <Button type="button" size="small" variant="primary">
                Add an update
              </Button>
            </NavLink>
          </div>

          <div>
            {data?.data?.data && data?.data?.data?.length === 0 && (
              <div className="my-8 text-gray-600 text-center">No updates</div>
            )}
            {data?.data?.data?.map(
              ({ id, title, created_at, approved_status }, i) => (
                <div
                  className={classNames(
                    "px-4 py-5 grid sm:grid-cols-4 sm:gap-4 sm:px-6",
                    [i % 2 !== 0 ? "bg-gray-50" : "bg-white"]
                  )}
                  key={i}
                >
                  <div className="text-sm font-medium text-gray-900">
                    {title}
                  </div>
                  <div className="text-sm text-gray-500">
                    {format(new Date(created_at!), "MMM dd YYY, HH:mm")}
                  </div>
                  <div className="text-sm text-gray-500">
                    <div
                      className={classNames(
                        "inline-flex py-1 px-3 rounded-full capitalize",
                        [
                          approved_status === "approved"
                            ? "bg-green-600 text-white"
                            : "bg-gray-200 text-gray-500",
                        ]
                      )}
                    >
                      {approved_status?.replace(/_/g, " ")}
                    </div>
                  </div>

                  <div>
                    <NavLink
                      to={withParams(routes.groupUpdatesEdit, { group_id, slug: get(workingGroup, "data.data.slug"), id })}
                    >
                      <Button type="button" size="exSmall" variant="secondary">
                        Edit
                      </Button>
                    </NavLink>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}

      {get(workingGroup, "data.data.approved_status") !== "approved" && (
        <div className="text-sm text-gray-700 rounded-lg bg-yellow-50 border-yellow-400 border p-4 mb-4">
          Working group status: {get(workingGroup, "data.data.approved_status")}
        </div>
      )}
    </div>
  );
};
