import React, { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { Controller, FieldError } from "react-hook-form";

import { ReactComponent as IconImage } from "assets/icons/image.svg";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { FieldGroup } from "../FieldGroup";
import { ImageCropper } from "../image-cropper/ImageCropper";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";
import { useModal } from "../../hooks/use-modal";
import { useContextService } from "../../hooks/use-context-service";
import { showNotification as showNotificationService } from "../../services/notification";

export interface CustomUploadProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  view?: "default" | "small-rounded";
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
  setValue: Function;
  aspect?: number;
  onChangeProp?: (val: string) => void;
}

export const ImageUploadField = forwardRef(
  (
    {
      control,
      name,
      aspect,
      disabled,
      rules,
      required,
      optional,
      setValue,
      isLoading,
      view = "default",
      onChangeProp,
      ...props
    }: CustomUploadProps,
    ref
  ) => {
    const showNotification = useContextService(showNotificationService);
    const { modalVisible, openModal, closeModal } = useModal();
    const [image, setImage] = useState<string>("");

    const onDrop = useCallback(
      (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length) {
          const reader = new FileReader();
          reader.readAsDataURL(acceptedFiles[0]);
          reader.onload = function () {
            setImage(`${reader.result}`);
            openModal();
          };
        }

        if (rejectedFiles.length) {
          rejectedFiles.forEach(
            ({ errors }: { errors: { message: string }[] }) => {
              errors.forEach(({ message }: { message: string }) => {
                showNotification({
                  title: "Error",
                  message,
                  variant: "ERROR",
                });
              });
            }
          );
        }
      },
      [showNotification, openModal, setImage]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      disabled,
      accept: "image/jpeg, image/png, image/jpg",
      maxFiles: 1,
    });

    return (
      <>
        <FieldGroup
          required={required}
          optional={optional}
          name={name}
          hasShadow={false}
          {...props}
        >
          {(computedProps) => (
            <Controller
              rules={rules}
              name={name}
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    type="text"
                    {...computedProps}
                    className="hidden"
                  />

                  <Modal
                    visible={modalVisible}
                    onClose={closeModal}
                    className="sm:w-11/12"
                  >
                    <ImageCropper
                      onCrop={(imageBase64) => {
                        setValue(name, imageBase64);
                        onChangeProp && onChangeProp(imageBase64);
                        closeModal();
                      }}
                      onClose={() => {
                        closeModal();
                      }}
                      aspect={aspect || 1.705}
                      value={image}
                    />
                  </Modal>

                  <div
                    className={classNames(
                      "flex justify-center relative bg-white border-gray-300 cursor-pointer bg-center",
                      {
                        "rounded out border-2 border-dashed bg-cover bg-no-repeat":
                          view === "default",
                      },
                      {
                        "rounded-full bg-gray-200 out border-1 w-24 h-24 bg-cover":
                          view === "small-rounded",
                      },
                      [value && !isLoading ? "items-end" : "items-center"]
                    )}
                    style={{
                      backgroundImage: `url(${value})`,
                      width: view === "small-rounded" ? "96px" : "360px",
                      height: view === "small-rounded" ? "96px" : "216px",
                    }}
                    {...getRootProps()}
                  >
                    {isLoading && (
                      <div className="bg-violet-100 p-4 rounded-lg">
                        <IconLoading className="w-8" />
                      </div>
                    )}

                    {!isDragActive && !isLoading && !disabled && (
                      <Button
                        type="button"
                        size={view === "small-rounded" ? "exSmall" : "medium"}
                        className={classNames("", {
                          "relative -mb-1": view === "small-rounded",
                        })}
                      >
                        {value ? "Replace" : "Upload"}
                        {view === "default" ? " image" : ""}
                      </Button>
                    )}
                    <div>
                      <input {...getInputProps()} />
                      {isDragActive && (
                        <div
                          className={classNames(
                            "absolute top-0 left-0 z-10 flex w-full h-full p-3 bg-white bg-opacity-80",
                            {
                              "rounded-full": view === "small-rounded",
                            }
                          )}
                        >
                          <div
                            className={classNames(
                              "flex flex-col items-center justify-center w-full h-full border-2 border-gray-300 border-dashed rounded",
                              {
                                "rounded-full": view === "small-rounded",
                              }
                            )}
                            style={{ backgroundColor: "rgba(0,0,0,.05)" }}
                          >
                            <IconImage />
                            {view === "default" && (
                              <div className="text-2xl font-semibold text-gray-700">
                                Drop your image here
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            />
          )}
        </FieldGroup>
      </>
    );
  }
);
