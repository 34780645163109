import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import get from "lodash/get";

import { WorkingGroupForm } from "../working-group-form/WorkingGroupForm";
import { getWorkingGroup, supplementaryItemsGet } from "api/workingGroups";
import { useContextService } from "hooks/use-context-service";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import {Helmet} from "react-helmet";

interface ParamTypes {
  id: string;
}

const SUPPLEMENTARY_ITEMS_GET = "SUPPLEMENTARY_ITEMS_GET";

export const WorkingGroupEdit = () => {
  const { id } = useParams<ParamTypes>();
  const getWorkingGroupQuery = useContextService(getWorkingGroup);
  const supplementaryItemsGetQuery = useContextService(supplementaryItemsGet);

  const { data, isFetching, error } = useQuery(
    ["workingGroup", id],
    () => getWorkingGroupQuery({ id }),
    {
      enabled: !!id,
      suspense: false,
    }
  );

  const {
    data: supplementaryItemsData,
    isFetching: supplementaryItemsIsFetching,
  } = useQuery(
    [SUPPLEMENTARY_ITEMS_GET, id],
    () => supplementaryItemsGetQuery({ group_id: id }),
    {
      suspense: false,
    }
  );

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Edit Working Group</title>
      </Helmet>

      {id &&
      ((isFetching && !data) ||
        (supplementaryItemsIsFetching && !supplementaryItemsData)) ? (
        <div className="flex items-center justify-between min-h-full">
          <IconLoading />
        </div>
      ) : error ? (
        <div className="w-full py-3 px-4 rounded mb-1 bg-red-100 text-sm text-red-800 mt-5">
          {get(error, "response.data.messages.detail")}
        </div>
      ) : (
        <WorkingGroupForm
          id={id}
          initialValues={get(data, "data.data")}
          supplementary_items={supplementaryItemsData?.data!}
        />
      )}
    </div>
  );
};
