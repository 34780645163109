import React from "react";
import { WorkingGroupForm } from "../working-group-form/WorkingGroupForm";
import { Helmet } from "react-helmet";

export const WorkingGroupCreate = () => {
  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Create Working Group</title>
      </Helmet>
      <WorkingGroupForm />
    </div>
  );
};
