import React, { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { Controller, FieldError } from "react-hook-form";
import { UploadIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { ReactComponent as IconPdf } from "assets/icons/pdf.svg";
import { ReactComponent as IconCsv } from "assets/icons/csv.svg";
import { ReactComponent as IconText } from "assets/icons/text.svg";
import { ReactComponent as IconPpt } from "assets/icons/ppt.svg";
import { ReactComponent as IconZip } from "assets/icons/zip.svg";
import { ReactComponent as IconDoc } from "assets/icons/doc.svg";
import { ReactComponent as IconXls } from "assets/icons/xls.svg";
import { FieldGroup } from "../FieldGroup";
import { useContextService } from "../../hooks/use-context-service";
import { showNotification as showNotificationService } from "../../services/notification";

export interface CustomUploadProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  view?: "default" | "small-rounded";
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
  setValue: Function;
  aspect?: number;
  loaded?: number;
  initialFileType?: string;
  fileNameField?: string;
  fileNameValue?: string;
  currentValue?: {file: string; filename: string}[];
  onChangeProp?: (val: { file: string; filename: string }[]) => void;
}

export const fileIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case "pdf":
      return <IconPdf className="mr-2 w-6" />;
    case "csv":
      return <IconCsv className="mr-2 w-6" />;
    case "powerpoint":
      return <IconPpt className="mr-2 w-6" />;
    case "zip":
      return <IconZip className="mr-2 w-6" />;
    case "excel":
      return <IconXls className="mr-2 w-6" />;
    case "doc":
      return <IconDoc className="mr-2 w-6" />;
    default:
      return <IconText className="mr-2 w-6" />;
  }
};

const fileToDataURL = (file: File) => {
  var reader = new FileReader();
  return new Promise(function (resolve, reject) {
    reader.onload = function (event) {
      resolve({ file: event?.target?.result, filename: file.name });
    };
    reader.readAsDataURL(file);
  });
};

const readAsDataURL = (files: File[]) => {
  var filesArray = Array.prototype.slice.call(files);
  // @ts-ignore
  return Promise.all<{ file: string; filename: string }[]>(
    // @ts-ignore
    filesArray.map(fileToDataURL)
  );
};

export const FilesUploadField = forwardRef(
  (
    {
      control,
      name,
      aspect,
      disabled,
      rules,
      required,
      optional,
      setValue,
      isLoading,
      view = "default",
      onChangeProp,
      initialFileType,
      fileNameValue,
      fileNameField,
      loaded,
      currentValue= [],
      ...props
    }: CustomUploadProps,
    ref
  ) => {
    const showNotification = useContextService(showNotificationService);
    const [files, setFiles] = useState<{ file: string; filename: string }[]>(
      []
    );

    const onDrop = useCallback(
      async (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length) {
          const filesList: {
            file: string;
            filename: string;
          }[] = await readAsDataURL(acceptedFiles);

          const newFileValues = [...currentValue, ...filesList];

          setFiles(newFileValues);

          setValue(name, newFileValues);
          onChangeProp && onChangeProp(newFileValues);
        }

        if (rejectedFiles.length) {
          rejectedFiles.forEach(
            ({ errors }: { errors: { message: string }[] }) => {
              errors.forEach(({ message }: { message: string }) => {
                showNotification({
                  title: "Error",
                  message,
                  variant: "ERROR",
                });
              });
            }
          );
        }
      },
      [currentValue, showNotification, setValue, onChangeProp, name]
    );

    const onRemove = useCallback(
      async (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);

        setFiles(newFiles);
        setValue(name, newFiles);
        onChangeProp && onChangeProp(newFiles);
      },
      [files, setValue, onChangeProp, name]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      disabled,
      accept: "image/jpeg, image/png, image/gif, application/pdf",
      maxFiles: 10,
    });

    return (
      <>
        <FieldGroup
          required={required}
          optional={optional}
          name={name}
          hasShadow={false}
          {...props}
        >
          {(computedProps) => (
            <Controller
              rules={rules}
              name={name}
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || []}
                    type="text"
                    {...computedProps}
                    className="hidden"
                  />

                  {!(
                    value &&
                    value.includes("s3.amazonaws.com") &&
                    disabled
                  ) && (
                    <div className={classNames("relative")} {...getRootProps()}>
                      <button
                        type="button"
                        className={classNames(
                          "bg-white border-gray-300 cursor-pointer flex relative items-center overflow-hidden form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0",
                          {
                            "hover:text-blue-600 hover:bg-white hover:border-blue-600 hover:outline-none focus:text-blue-600 focus:bg-white focus:border-blue-600 focus:outline-none":
                              !disabled && !props.error,
                            "border-red-300 bg-red-100": props.error,
                          }
                        )}
                      >
                        <span className="flex items-center relative z-10">
                          {isLoading ? (
                            <div className="ml-0 mr-2">
                              <IconLoading className="w-5 h-5" />
                            </div>
                          ) : (
                            <div className="ml-0 mr-2">
                              <UploadIcon className="w-5 h-5" />
                            </div>
                          )}
                          {/*{fileName ? fileName : "Choose file"}*/}
                          {currentValue && currentValue.length > 0 ? 'Add files' : 'Choose file or multiple files'}
                        </span>
                        <span
                          className={classNames(
                            "absolute h-full left-0 bottom-0 z-0",
                            {
                              "bg-blue-100": !props.error,
                              "bg-red-100": props.error,
                            }
                          )}
                          style={{ width: `${loaded}%` }}
                        />
                      </button>
                      <div className="text-xs pt-1 text-gray-500">
                        Supported file types: .jpg, .png, .gif, .pdf
                      </div>
                      <div>
                        <input {...getInputProps()} />
                      </div>
                    </div>
                  )}

                  {value && (
                    <div className="flex flex-wrap mt-3">
                      {value?.map(
                        (
                          file: { file: string; filename: string },
                          i: number
                        ) => (
                          <div className="relative mr-1" key={i}>
                            {file.file.includes("data:image") && (
                              <img src={file.file} width={100} alt="" />
                            )}
                            {file.file.includes("data:application/pdf") && (
                              <div className="w-24 h-24 flex flex-col items-center justify-center bg-white rounded">
                                <IconPdf className="w-6" />
                                <div className="pt-2 h-6 px-1 text-xs text-gray-600 w-full overflow-hidden text-center">
                                  {file.filename}
                                </div>
                              </div>
                            )}

                            <button
                              type="button"
                              className="absolute top-1 right-1 rounded-full bg-white flex items-center justify-center w-6 h-6 text-red-600"
                              onClick={() => {
                                onRemove(i);
                              }}
                            >
                              <TrashIcon className="w-4" />
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  )}

                  {/*{(["image/jpeg", "image/png", "image"].includes(fileType) ||
                    (!fileType &&
                      initialFileType &&
                      ["image/jpeg", "image/png", "image"].includes(
                        initialFileType
                      ))) &&
                    value &&
                    !props.error && (
                      <div className="mt-3">
                        <img src={value} width={360} alt="" />
                      </div>
                    )}*/}
                </>
              )}
            />
          )}
        </FieldGroup>
      </>
    );
  }
);
