import { FooterHome } from "components/footer/FooterHome";
import { TopBar } from "components/topbar/TopBar";
import { PropsWithoutRef } from "react";

export interface LayoutProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["div"]>, "className"> {}

export const LayoutProject = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col min-h-screen bg-white">
      <TopBar />
      <div className="flex flex-1 w-full max-w-screen-xl mx-auto">
        <div className="flex-1 p-4 pt-4 md:p-8 max-w-full w-full">
          {children}
        </div>
      </div>

      <div className="mt-8">
        <FooterHome />
      </div>
    </div>
  );
};
