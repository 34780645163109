import { Context } from "types";
import { withParams } from "utils/url";

type createdBy = {
  id: number;
  email: string;
  name: string;
  role: string;
  title?: string;
  avatar?: string;
};

export type Discussion = {
  id: string;
  text: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  is_reply: boolean;
  is_public?: boolean | null;
  user_reaction: string;
  num_replies: number;
  is_owner: boolean;
  images: { id: string; image: string; image_thumbnail: string; type: string; filename: string; }[];
  votes: { up: number; down: number };
  quotes?: {
    id: string;
    text: string;
    created_by: createdBy;
  };
  replies: {
    id: string;
    text: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
    created_by: createdBy;
    user_reaction: string;
    is_owner: boolean;
    images: { id: string; image: string; image_thumbnail: string; type: string; filename: string; }[];
    votes: { up: number; down: number };
    quotes?: {
      id: string;
      text: string;
      created_by: createdBy;
    };
  }[];
  created_by: createdBy;
};

export const getTopics = ({ apiClient }: Context) => ({
  id,
  page,
  perPage,
  topicType = 'groups',
}: {
  id: string;
  page: number;
  perPage: number;
  topicType?: string;
}) =>
  apiClient<{
    data: Discussion[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams("/:topicType/:id/discussions", { id, page, perPage, topicType }),
    method: "GET",
  });

export const getDiscussionComments = ({ apiClient }: Context) => ({
  id,
  page,
  perPage,
}: {
  id: string;
  page: number;
  perPage: number;
}) =>
  apiClient<{
    data: Discussion[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams("/discussion/:id/comments", {
      id,
      page,
      perPage,
    }),
    method: "GET",
  });

export const postDiscussionComment = ({ apiClient }: Context) => ({
  id,
  data,
}: {
  id: string;
  data: FormData;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/discussion/:id/comments", { id }),
    method: "POST",
    data,
    headers: {
      "content-type": "multipart/form-data",
    },
  });

export const putDiscussionComment = ({ apiClient }: Context) => ({
  id,
  data,
  comment_id,
}: {
  id: string;
  data: FormData;
  comment_id: string;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/discussion/:id/comments/:comment_id", { id, comment_id }),
    method: "PUT",
    data,
  });

export const deleteDiscussionComment = ({ apiClient }: Context) => ({
  id,
  comment_id,
}: {
  id: string;
  comment_id: string;
}) =>
  apiClient({
    url: withParams("/discussion/:id/comments/:comment_id", { id, comment_id }),
    method: "DELETE",
  });

export const deleteCommentImage = ({ apiClient }: Context) => ({
  id,
  discussion_id,
}: {
  id: string;
  discussion_id: string;
}) =>
  apiClient({
    url: withParams("/discussion/:discussion_id/comments/image/:id", {
      id,
      discussion_id,
    }),
    method: "DELETE",
  });

export const postTopicDiscussionComment = ({ apiClient }: Context) => ({
  id,
  text,
  parent,
  is_public,
  topicType = 'groups',
}: {
  id: string;
  text: string;
  parent?: string;
  topicType?: string;
  is_public?: boolean | null;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/:topicType/:id/discussions", { id, topicType }),
    method: "POST",
    data: { text, parent, is_public: !!is_public },
  });

export const putTopicDiscussionComment = ({ apiClient }: Context) => ({
  id,
  text,
  comment_id,
  is_public,
  topicType = 'groups',
}: {
  id: string;
  text: string;
  comment_id: string;
  is_public?: boolean | null;
  topicType?: string;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/:topicType/:id/discussions/:comment_id", { id, comment_id, topicType }),
    method: "PUT",
    data: { text, is_public: !!is_public },
  });

export const deleteTopicDiscussionComment = ({ apiClient }: Context) => ({
  id,
  comment_id,
  topicType = 'groups',
}: {
  id: string;
  comment_id: string;
  topicType?: string;
}) =>
  apiClient({
    url: withParams("/:topicType/:id/discussions/:comment_id", { id, comment_id, topicType }),
    method: "DELETE",
  });

export const putDiscussionVote = ({ apiClient }: Context) => ({
  id,
  reaction,
}: {
  id: string;
  reaction: string;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/comments/vote/:id", { id }),
    method: "PUT",
    data: { reaction },
  });

export const putTopicVote = ({ apiClient }: Context) => ({
  id,
  reaction,
}: {
  id: string;
  reaction: string;
}) =>
  apiClient<{
    data: Discussion;
  }>({
    url: withParams("/discussion/vote/:id", { id }),
    method: "PUT",
    data: { reaction },
  });
