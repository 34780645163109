import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export const CookiePolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-gray-900">
      <Helmet>
        <title>Let’s Get Proof | Let’s Get Proof Cookies Policy</title>
      </Helmet>

      <h1 className="text-3xl font-bold mb-2">Let’s Get Proof Cookies Policy</h1>
      <div className="text-gray-600 text-sm mb-8">
        Last updated: March 2nd, 2022
      </div>

      <div className="pb-4">
        This Cookies Policy explains what Cookies are and how We use them. You
        should read this policy so You can understand what type of cookies We
        use, or the information We collect using Cookies and how that
        information is used.
      </div>
      <div className="pb-4">
        Cookies do not typically contain any information that personally
        identifies a user, but personal information that we store about You may
        be linked to the information stored in and obtained from Cookies. For
        further information on how We use, store and keep your personal data
        secure, see our Privacy Policy.
      </div>

      <div className="pb-4">
        We do not store sensitive personal information, such as mailing
        addresses, account passwords, etc. in the Cookies We use.
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Interpretation and Definitions
      </h2>

      <h3 className="text-lg font-semibold mt-4 mb-2">Interpretation</h3>
      <div className="pb-4">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">Definitions</h3>
      <div className="pb-4">For the purposes of this Cookies Policy:</div>

      <ul className="list-disc ml-8 pb-4">
        <li>
          Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Cookies Policy) refers to Let’s Get Proof Inc., Calgary, Alberta,
          Canada.
        </li>
        <li>
          Cookies means small files that are placed on Your computer, mobile
          device or any other device by a website, containing details of your
          browsing history on that website among its many uses.
        </li>
        <li>
          Website refers to Let’s Get Proof, accessible from www.letsgetproof.com
        </li>
        <li>
          You means the individual accessing or using the Website, or a company,
          or any legal entity on behalf of which such individual is accessing or
          using the Website, as applicable.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        The use of the Cookies
      </h2>
      <h3 className="text-lg font-semibold mt-4 mb-2">
        Type of Cookies We Use
      </h3>

      <div className="pb-4">
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close your web browser.
      </div>

      <div className="pb-4">
        We use both session and persistent Cookies for the purposes set out
        below:
      </div>

      <div className="font-semibold pb-2">Necessary / Essential Cookies</div>
      <div className="pb-2">Type: Session Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-4">
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </div>

      <div className="font-semibold pb-2">
        Cookies Policy / Notice Acceptance Cookies
      </div>
      <div className="pb-2">Type: Persistent Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-4">
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </div>

      <div className="font-semibold pb-2">Functionality Cookies</div>
      <div className="pb-2">Type: Persistent Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-2">
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Your Choices Regarding Cookies
      </h3>
      <div className="pb-2">
        If You prefer to avoid the use of Cookies on the Website, first You must
        disable the use of Cookies in your browser and then delete the Cookies
        saved in your browser associated with this website. You may use this
        option for preventing the use of Cookies at any time.
      </div>
      <div className="pb-2">
        If You do not accept Our Cookies, You may experience some inconvenience
        in your use of the Website and some features may not function properly.
      </div>
      <div className="pb-2">
        If You'd like to delete Cookies or instruct your web browser to delete
        or refuse Cookies, please visit the help pages of your web browser.
      </div>
      <div className="pb-2">
        For the Chrome web browser, please visit this page from Google:
        <a
          href="https://support.google.com/accounts/answer/32050"
          className="text-indigo-700"
          target="_blank"
          rel="noreferrer"
        >
          https://support.google.com/accounts/answer/32050
        </a>
      </div>
      <div className="pb-2">
        For the Internet Explorer web browser, please visit this page from
        Microsoft:{" "}
        <a
          href="http://support.microsoft.com/kb/278835"
          className="text-indigo-700"
          target="_blank"
          rel="noreferrer"
        >
          http://support.microsoft.com/kb/278835
        </a>
      </div>
      <div className="pb-2">
        For the Firefox web browser, please visit this page from Mozilla:
        <a
          href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
          className="text-indigo-700"
          target="_blank"
          rel="noreferrer"
        >
          https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored
        </a>
      </div>
      <div className="pb-2">
        For the Safari web browser, please visit this page from Apple:
        <a
          href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          className="text-indigo-700"
          target="_blank"
          rel="noreferrer"
        >
          https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac
        </a>
      </div>
      <div className="pb-2">
        For any other web browser, please visit your web browser's official web
        pages.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        More Information about Cookies
      </h3>
      <div className="pb-2">
        You can learn more about cookies here: All About Cookies by TermsFeed.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">Contact Us</h3>

      <div className="pb-2">
        If you have any questions about this Cookies Policy, You can contact us:
        <br />
        By email:{" "}
        <a href="mailto:contact@letsgetproof.com" className="text-indigo-700">
          contact@letsgetproof.com
        </a>
      </div>
    </div>
  );
};
