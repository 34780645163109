import React from "react";
import { useQuery } from "react-query";
import get from "lodash/get";

import { getMyWorkingGroupsAppliedList } from "api/workingGroups";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useContextService } from "../../../hooks/use-context-service";
import { ChatIcon, UserGroupIcon } from "@heroicons/react/solid";
import { withParams } from "../../../utils/url";
import { routes } from "../../../routes";
import { NavLink } from "react-router-dom";
import { Button } from "../../../components/button/Button";
import {Helmet} from "react-helmet";

const WORKING_GROUP_MY_APPLIED = "WORKING_GROUP_MY_APPLIED";

export const WorkingGroupMyApplied = () => {
  const getMyWorkingGroupsAppliedListService = useContextService(
    getMyWorkingGroupsAppliedList
  );

  const { data, isLoading } = useQuery(
    WORKING_GROUP_MY_APPLIED,
    () => getMyWorkingGroupsAppliedListService({}),
    {
      suspense: false,
    }
  );

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <div className="">
      <Helmet>
        <title>Let’s Get Proof | Working Groups |  Applied</title>
      </Helmet>

      {data?.data?.data?.map(
        ({ image, title, slug, created_by, members, comments }) => (
          <NavLink
            to={withParams(routes.workingGroupSingle, { id: slug })}
            className="flex items-center pb-8 mb-8 border-b border-gray-200"
            key={slug}
          >
            {image ? <img src={image} alt={title} className='w-32' /> : <div className='bg-gray-200 w-32 h-20' /> }
            <div className="ml-4">
              <div className="font-semibold flex">{title}</div>

              <div className="flex items-center pt-3">
                <div
                  className="flex justify-center items-center rounded-full bg-gray-400 w-8 h-8 text-white font-medium bg-center bg-cover"
                  style={{ backgroundImage: `url(${created_by?.avatar})` }}
                >
                  {!created_by?.avatar ? get(created_by, "name[0]") : ""}
                </div>
                <div className="ml-2">{created_by?.name}</div>

                <div className="flex text-sm text-gray-600 font-medium ml-8">
                  <div className="flex items-center">
                    <UserGroupIcon className="-mt-0.5 mr-1 w-4" /> {members}
                  </div>
                  <div className="flex items-center text-gray-700 ml-4">
                    <ChatIcon className="mr-1 w-4" /> {comments}
                  </div>
                </div>
              </div>
            </div>
          </NavLink>
        )
      )}

      {data?.data?.data?.length === 0 && (
        <div className="my-8 text-gray-600">
          <div className="mb-2">No data</div>
          <NavLink to={routes.workingGroups}>
            <Button>Browse working groups</Button>
          </NavLink>
        </div>
      )}
    </div>
  );
};
