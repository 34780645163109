import React, { useState } from "react";
import { Controller, FieldErrors } from "react-hook-form";
import get from "lodash/get";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';

import { SelectOption } from "../../types/field";

export interface CustomProps {
  name: string;
  label?: string;
  control: any;
  disabled?: boolean;
  error?: FieldErrors;
  rules?: any;
  options: SelectOption[];
}

export function SortableItem(props: any) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className='px-4 py-2 mb-2 bg-white font-medium border-dashed border border-gray-300 rounded w-80'>
      {props.label}
    </div>
  );
}

export const SortingField = React.forwardRef(
  ({ control, name, rules, options, disabled }: CustomProps, ref) => {
    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      })
    );

    return (
      <Controller
        rules={rules}
        name={name}
        control={control}
        render={({ field: { onChange, value, name } }) => {
          const handleDragEnd = (event: any) => {
            const { active, over } = event;

            if (active.id !== over.id) {
              const oldIndex = value.indexOf(active.id);
              const newIndex = value.indexOf(over.id);
              onChange(arrayMove(value, oldIndex, newIndex));
            }
          };

          return (
            <div className="flex relative">
              <div className='py-2'>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                >
                  <SortableContext
                    items={value}
                    strategy={verticalListSortingStrategy}
                    disabled={disabled}
                  >
                    {value.map((id: number) => (
                      <SortableItem
                        key={id}
                        id={id}
                        label={get(
                          options.find((item) => item.value === id),
                          "label"
                        )}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </div>
            </div>
          );
        }}
      />
    );
  }
);
