import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import get from "lodash/get";
import map from "lodash/map";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { useParams } from "react-router-dom";

import { PaginationSection } from "./PaginationSection";
import { SingleArticle } from "./SingleArticle";
import { Button } from "components/button/Button";
import { useContextService } from "../../../hooks/use-context-service";
import { Menu } from "../menu/Menu";
import { getStaticSectionPage } from "../../../api/static-pages";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";
import Imagelogoaspects212fsd from "assets/images/logoaspects212fsd.png";

const AIS_HOMEPAGE = "AIS_HOMEPAGE";

interface ParamTypes {
  path: string;
}

export const AisHomepage = () => {
  const { path } = useParams<ParamTypes>();
  const [isIframeScrolling, setIsIframeScrolling] = useState(false);
  const [isIframeClicked, setIsIframeClicked] = useState(false);

  const getStaticPageService = useContextService(getStaticSectionPage);
  const { data, isFetching, error } = useQuery(
    [AIS_HOMEPAGE, path],
    () => getStaticPageService({ slug: `aspect-in-stroke/${path}` }),
    {
      suspense: false,
    }
  );

  const sliderSettings = {
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    adaptiveHeight: true,
    focusOnSelect: true,
    beforeChange: (prevSlide: number, slide: number) => {},
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | {get(data, "data.data.meta_title", "")}</title>
        <meta name="description" content={get(data, "data.data.meta_description", "")} />
      </Helmet>

      <div className="w-full my-8">
        <img src={Imagelogoaspects212fsd} className="w-full" alt="" />
      </div>

      <div className="my-8 relative z-40">
        <Menu />
      </div>

      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div className="pb-12 mx-auto">
          {data?.data?.data?.before_title && (
            <h3
              className={classNames("my-2 text-xl font-bold", {
                "text-center": data?.data?.data?.title_align === "CENTER",
                "text-right": data?.data?.data?.title_align === "RIGHT",
              })}
            >
              {data?.data?.data?.before_title}
            </h3>
          )}

          {data?.data?.data &&
            map(data?.data?.data?.body, (item, index) => (
              <div key={index}>
                {item.type === "carousel" ? (
                  <div className="hero-slider page-slider pb-32">
                    <div className="w-full max-w-screen-xl mx-auto relative z-10 mb-8">
                      <Slider {...sliderSettings}>
                        {map(item.slides, (slide, i) => (
                          <div key={i} className="px-12 mb-4">
                            <h3
                              className="wysiwyg-editor text-center mb-4"
                              dangerouslySetInnerHTML={{
                                __html: slide.description,
                              }}
                            />

                            {slide.image && (
                              <div className="mb-4 text-center">
                                <img
                                  src={slide.image}
                                  alt=""
                                  className="mx-auto"
                                />
                              </div>
                            )}

                            {slide.iframe && (
                              <div
                                className="mb-4 flex justify-center iframe-overscroll"
                                onMouseEnter={() => {
                                  setIsIframeScrolling(true);
                                }}
                                onMouseLeave={() => {
                                  setIsIframeScrolling(false);
                                  setIsIframeClicked(false);
                                }}
                                onMouseDown={() => {
                                  setIsIframeClicked(true);
                                }}
                              >
                                <iframe
                                  width="100%"
                                  height="480"
                                  src={slide.iframe}
                                />
                              </div>
                            )}

                            {slide.button_link && (
                              <div className="mb-4 text-center">
                                <a
                                  href={slide.button_link}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button>{slide.button_label}</Button>
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                ) : item.type === "multiple_articles_pages_pagination" ? (
                  <div>
                    <PaginationSection
                      articles={item.articles!}
                      articles_in_line={data?.data?.data?.articles_in_line!}
                      first_alone={data?.data?.data?.first_alone!}
                      setIsIframeScrolling={setIsIframeScrolling}
                      setIsIframeClicked={setIsIframeClicked}
                    />
                  </div>
                ) : item.type === "multiple_articles_pages" ? (
                  <div>
                    <SingleArticle
                      articles={item.articles!}
                      setIsIframeScrolling={setIsIframeScrolling}
                      setIsIframeClicked={setIsIframeClicked}
                    />
                  </div>
                ) : item.type === "iframe" ? (
                  <div
                    className="mb-4 flex justify-center iframe-overscroll"
                    onMouseEnter={() => {
                      setIsIframeScrolling(true);
                    }}
                    onMouseLeave={() => {
                      setIsIframeScrolling(false);
                      setIsIframeClicked(false);
                    }}
                    onMouseDown={() => {
                      setIsIframeClicked(true);
                    }}
                  >
                    <iframe
                      width="100%"
                      height="480"
                      src={item.value}
                    />
                  </div>
                ) : (
                  <div
                    className="mb-8"
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  />
                )}
              </div>
            ))}

        </div>
      )}

      <div
        className={classNames(
          "iframe-helper bg-blue-100 text-blue-700 font-medium",
          { open: isIframeScrolling }
        )}
      >
        {isIframeClicked ? (
          <span>
            Up, Down keys scroll change slices; Left, Right change sequences
          </span>
        ) : (
          <span>
            Scrolling in iframe, Scroll outside to scroll page.
            <br />
            Click inside to use controls: up, down keys scroll change slices;
            left, right change sequences.
          </span>
        )}
      </div>
    </div>
  );
};
