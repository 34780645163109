import { Context } from "types";

export const contactUs = ({ apiClient }: Context) => (data: {
  name: string;
  email: string;
  text: string;
  phone_number?: string;
}) =>
  apiClient<{ access: string }>({
    url: "/contact-us",
    method: "POST",
    data,
  });
