import { Context } from "../types";
import { withParams } from "../utils/url";

export enum EventType {
  BLOG_CREATED = "BLOG_CREATED",
  PROJECT_CREATED = "PROJECT_CREATED",
  WORKING_GROUP_CREATED = "WORKING_GROUP_CREATED",
  BLOG_COMMENT_CREATED = "BLOG_COMMENT_CREATED",
  PROJECT_COMMENT_CREATED = "PROJECT_COMMENT_CREATED",
  WORKING_GROUP_COMMENT_CREATED = "WORKING_GROUP_COMMENT_CREATED",
  BLOG_DISCUSSION_CREATED = "BLOG_DISCUSSION_CREATED",
  PROJECT_DISCUSSION_CREATED = "PROJECT_DISCUSSION_CREATED",
  WORKING_GROUP_DISCUSSION_CREATED = "WORKING_GROUP_DISCUSSION_CREATED",
  PROJECT_UPDATE_CREATED = "PROJECT_UPDATE_CREATED",
  WORKING_GROUP_UPDATE_CREATED = "WORKING_GROUP_UPDATE_CREATED",
  PROJECT_FUNDING_COMPLETED = "PROJECT_FUNDING_COMPLETED",
  PROJECT_REACHED_MILESTONE = "PROJECT_REACHED_MILESTONE",
}

export enum ObjectType {
  PROJECT = "PROJECT",
  COMMENT = "COMMENT",
  DISCUSSION = "DISCUSSION",
  WORKING_GROUP = "WORKING_GROUP",
  BLOG = "BLOG",
  PROJECT_POST = "PROJECT_POST",
  WORKING_GROUP_POST = "WORKING_GROUP_POST",
}

export type Feed = {
  id: number;
  title: string | null;
  description: string | null;
  created_at: string;
  object_type: ObjectType;
  event_type: EventType;
  content: {
    id: number;
    discussion_id?: number;
    text: string;
    created_by: {
      id: number;
      email: string;
      name: string;
      first_name: string;
      occupation: string[];
      title: string | null;
      avatar: string;
      avatar_thumbnail: string;
      affiliation: string;
      profile_is_private: boolean;
    };
    is_owner: boolean | null;
    user_reaction: any | null;
    votes: any;
    created_at: string;
    updated_at: string;
    num_replies: number;
    is_public: boolean;
  };
  related_content: any | null;
  related_object_type: string | null;
};

export const getFeed = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 1,
  searchQuery,
  event_type,
  object_type,
}: {
  page?: number;
  perPage?: number;
  searchQuery: string
  event_type: string;
  object_type: string;
}) =>
  apiClient<{
    data: Feed[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/feed`, {
      page,
      perPage,
      ordering: "-created_at",
      search: searchQuery,
      event_type,
      object_type,
    }),
    method: "GET",
  });
