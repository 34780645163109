import React from "react";
import { useQuery } from "react-query";

import { getProjectUpdatesSingle } from "api/project";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useParams } from "react-router-dom";
import { useContextService } from "../../../hooks/use-context-service";
import { ProjectUpdatesForm } from "./ProjectUpdatesForm";

interface ParamTypes {
  project_id: string;
  id: string;
}

export const ProjectUpdatesContainer = () => {
  const { project_id, id } = useParams<ParamTypes>();
  const getProjectUpdatesSingleQuery = useContextService(
    getProjectUpdatesSingle
  );

  const { data, isLoading, isError } = useQuery(
    `PROJECT_UPDATES_SINGLE_${project_id}_${id}`,
    () => getProjectUpdatesSingleQuery({ project_id, id }),
    {
      suspense: false,
      enabled: !!(project_id && id),
    }
  );

  return isLoading ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : isError ? (
    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
      Error
    </div>
  ) : (
    <div className="flex justify-between flex-col-reverse sm:flex-row">
      <ProjectUpdatesForm
        project_id={project_id}
        id={id}
        defaultValues={{
          title: data?.data?.data?.title,
          text: data?.data?.data?.text,
          video_url: data?.data?.data?.video_url,
          image: data?.data?.data?.image,
        }}
      />
    </div>
  );
};
