import React from "react";
import get from "lodash/get";
import { NavLink } from "react-router-dom";

import { WorkingGroupMember } from "api/workingGroups";
import { withParams } from "utils/url";
import { routes } from "routes";

const UserContent: React.FC<{ user_id?: string }> = ({ user_id, children }) => {
  return user_id ? (
    <NavLink
      to={withParams(routes.researcherProfile, {
        id: user_id,
      })}
    >
      {children}
    </NavLink>
  ) : (
    <div>{children}</div>
  );
};
export const UserItem = ({
  name,
  role,
  avatar,
  email,
  user_id,
  affiliation,
}: Partial<
  Pick<
    WorkingGroupMember,
    "name" | "role" | "avatar" | "email" | "user_id" | "affiliation"
  >
>) => {
  return (
    <div className="flex items-center">
      {user_id ? (
        <NavLink
          to={withParams(routes.researcherProfile, {
            id: user_id,
          })}
          className="flex items-center justify-center w-12 h-12 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full"
          style={{
            backgroundImage: `url(${avatar})`,
          }}
        >
          {!avatar ? get(name, "[0]") : ""}
        </NavLink>
      ) : (
        <div
          className="flex items-center justify-center w-12 h-12 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full"
          style={{
            backgroundImage: `url(${avatar})`,
          }}
        >
          {!avatar ? get(name, "[0]") : ""}
        </div>
      )}
      <div className="ml-3 text-sm font-medium">
        <UserContent user_id={user_id}>
          <div>
            {role && <div className="text-xs text-gray-500">{role}</div>}
            <div className="flex-wrap mt-3 text-center md:mt-0 md:flex md:items-center">
              {name && (
                <div className="text-sm font-medium text-gray-900">{name}</div>
              )}

              {name && (
                <svg
                  className="hidden w-4 h-4 text-indigo-600 md:block"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M11 0h3L9 20H6l5-20z"></path>
                </svg>
              )}

              <div className="text-sm font-medium text-gray-500">{email}</div>
            </div>
            <div>{affiliation}</div>
          </div>
        </UserContent>
      </div>
    </div>
  );
};
