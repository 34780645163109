import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get } from "lodash";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { pipe } from "fp-ts/function";

import { AuthCodeInput } from "components/auth-code-input/AuthCodeInput";
import { Button } from "components/button/Button";

import { createOTP } from "api/auth/auth";
import { getOtpRequest } from "api/user/user";
import { LoadingSpinner } from "components/loading-spinner/LoadingSpinner";
import { MfaInfo } from "components/mfa-info/MfaInfo";
import { USER_QUERY_KEY } from "queries/use-user-query";
import { useStringRequired } from "hooks/use-validation-input";

const useFormSchema = () =>
  z.object({
    totpToken: pipe(z.string(), useStringRequired()),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const SetupOtpForm = ({ onSubmit }: { onSubmit: () => void }) => {
  const { handleSubmit, setError, reset, control } = useForm<FormSchema>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),
  });

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const queryClient = useQueryClient();

  const createOTPQuery = useMutation(createOTP, {
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries(USER_QUERY_KEY);
      setFormSuccessMessage("Otp has been successfully set.");
      setTimeout(() => {
        onSubmit();
      }, 1500);
    },
    onError: (e) => {
      setError("totpToken", { type: "error", message: "Invalid token" });
    },
  });

  const { data, isFetching } = useQuery("otp-totp", () => getOtpRequest(), {
    suspense: false,
  });

  const onSubmitForm = (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);
    createOTPQuery.mutate({ token: data.totpToken });
  };

  return (
    <div className="mx-auto" style={{ maxWidth: "325px" }}>
      <form onSubmit={handleSubmit(onSubmitForm)} className="">
        <h2 className="text-xl font-medium text-black mb-2 text-center">
          Set up Two Factor Authentication
        </h2>

        <MfaInfo />

        {isFetching ? (
          <div className="flex justify-center items-center">
            <LoadingSpinner className="w-6 text-purple-600" />
          </div>
        ) : (
          <div>
            <div className="flex justify-center items-center font-medium text-lg text-center mt-8 mb-4 text-gray-700">
              1. Scan this QR code with an Authenticator app of your choice
            </div>
            <div className="mx-auto w-40 h-40 border border-gray-200 rounded p-0.5">
              <img
                src={"data:image/png;base64," + get(data, "data.qrcode")}
                alt=""
                className="w-full h-full"
              />
            </div>
            <div className="font-medium text-lg text-center mt-12 text-gray-700">
              2. Enter your code
            </div>
            <div className="mb-5">
              <Controller<FormSchema>
                name="totpToken"
                control={control}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                }) => (
                  <AuthCodeInput
                    onChange={onChange}
                    value={value}
                    error={error}
                    label="Code"
                  />
                )}
              />
            </div>

            {formErrorMessage && (
              <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
                {formErrorMessage}
              </div>
            )}

            {formSuccessMessage && (
              <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
                {formSuccessMessage}
              </div>
            )}

            <Button
              type="submit"
              className="w-full"
              loading={createOTPQuery.isLoading}
              spinner
            >
              Setup TOTP
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};
