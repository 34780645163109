import classNames from "classnames";
import { forwardRef, PropsWithoutRef } from "react";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";

type Size = "exSmall" | "small" | "medium" | "large";

type Variant =
  | "primary"
  | "secondary"
  | "white"
  | "violet550"
  | "danger"
  | "redPale"
  | "red"
  | "green"
  | "transparentWhite"
  | "text";

export interface ButtonProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className"> {
  size?: Size;
  rounded?: string;
  variant?: Variant;
  loading?: boolean;
  spinner?: boolean;
  className?: string;
}

const sizeClassNames: Record<Size, string> = {
  exSmall: "px-2 py-1 text-xs font-normal leading-4",
  small: "px-3 py-2 text-sm leading-4",
  medium: "px-5 py-2 text-sm",
  large: "px-6 py-2 text-base",
};

const variantClassNames: Record<Variant, string> = {
  primary:
    "border-transparent text-white bg-violet-550 hover:bg-violet-560 focus:ring-red-300",
  green:
    "border-transparent text-white bg-green-600 hover:bg-green-700 focus:ring-green-500",
  violet550:
    "border-transparent text-white bg-violet-550 hover:bg-violet-560 focus:ring-red-300",
  red:
    "border-transparent text-white bg-red-500 hover:bg-red-550 focus:ring-red-300",
  danger:
    "border-transparent text-white bg-red-600 hover:bg-red-700 focus:ring-red-500",
  redPale:
    "border-transparent text-red-700 bg-red-100 hover:bg-red-200 focus:ring-red-500",
  secondary:
    "border-transparent text-violet-700 bg-violet-100 hover:bg-violet-200 focus:ring-violet-500",
  white:
    "border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-violet-500",
  transparentWhite:
    "border-white text-white bg-transparent hover:bg-white/20 focus:ring-white transition duration-150 ease-in",
  text:
    "text-violet-600 shadow-none border-transparent bg-white hover:text-violet-700 focus:ring-violet-500",
};

export const getButtonClassNames = ({
  size = "medium",
  variant = "primary",
  loading,
  rounded = "rounded",
  className,
}:
  | (Pick<ButtonProps, "size" | "variant" | "rounded"> &
      Pick<ButtonProps, "loading"> &
      Pick<ButtonProps, "className">)
  | undefined = {}) =>
  classNames(
    "inline-flex items-center border font-medium justify-center shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-80 transition-colors",
    className,
    {
      "cursor-wait": loading,
      "disabled:cursor-not-allowed": !loading,
    },
    rounded,
    sizeClassNames[size],
    variantClassNames[variant]
  );

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size,
      className,
      variant,
      rounded,
      loading,
      spinner: withSpinner,
      ...props
    },
    ref
  ) => (
    <button
      ref={ref}
      {...props}
      className={getButtonClassNames({
        rounded,
        className,
        size,
        variant,
        loading,
      })}
    >
      {children}
      {loading && withSpinner && (
        <span className="inline-block ml-2">
          <LoadingSpinner className="w-3 h-3" />
        </span>
      )}
    </button>
  )
);
