import { Card } from "components/card/Card";
import { LoginForm } from "./components/LoginForm";
import { Helmet } from "react-helmet";
import React from "react";

export const LoginContainer = () => (
  <div className="flex items-center h-full max-w-sm mx-auto">
    <Helmet>
      <title>Let’s Get Proof | Login</title>
    </Helmet>
    <Card>
      <LoginForm />
    </Card>
  </div>
);
