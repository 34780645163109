import React from "react";
import { Blogs } from "./Blogs";
import { BlogBrowseContextProvider } from "./BlogBrowseContext";

export const BlogBrowseContainer = () => {
  return (
    <BlogBrowseContextProvider>
      <Blogs />
    </BlogBrowseContextProvider>
  );
};
