import React from "react";
import classNames from "classnames";
import map from "lodash/map";
import get from "lodash/get";
import { LockClosedIcon } from "@heroicons/react/outline";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useQuery } from "react-query";
import { useContextService } from "../../hooks/use-context-service";
import { getUserAccountById } from "../../api/account";
import { NavLink, useParams } from "react-router-dom";
import { withParams } from "../../utils/url";
import { routes } from "../../routes";
import {Helmet} from "react-helmet";

interface ParamTypes {
  id: string;
}

export const ResearcherProfile = () => {
  const { id } = useParams<ParamTypes>();

  const getUserAccountByIdQuery = useContextService(getUserAccountById);

  const { data: user, isFetching } = useQuery(
    "user-id",
    () => getUserAccountByIdQuery({ id }),
    {
      suspense: false,
      enabled: !!id,
    }
  );

  return !user || isFetching ? (
    <div className="flex justify-center mt-24">
      <IconLoading />
    </div>
  ) : (
    <div className="flex flex-wrap pb-8">
      <Helmet>
        <title>Let’s Get Proof | User Profile</title>
      </Helmet>

      <div
        className="relative w-full bg-gray-200 bg-contain bg-center bg-no-repeat"
        style={{
          maxHeight: "420px",
          height: "calc(100vw / 2.5)",
          backgroundImage: `url(${user?.data?.data?.profile_background})`,
        }}
      >
        <div className="absolute left-8 flex items-center -bottom-8 md:-bottom-12">
          <div
            className="flex items-center justify-center bg-cover bg-center text-4xl font-bold text-white bg-violet-600 rounded-full w-24 h-24 md:w-32 md:h-32"
            style={{ backgroundImage: `url(${user?.data?.data?.avatar})` }}
          >
            {!user?.data?.data?.avatar && (
              <div>{get(user?.data?.data, "name[0]")}</div>
            )}
          </div>

          <div className="ml-4">
            <div className="px-3 rounded text-white bg-violet-600 text-xl font-semibold">
              {user?.data?.data?.name} {user?.data?.data?.title}
            </div>

            {user?.data?.data?.affiliation && (
              <span className="px-2 mt-1 rounded text-white bg-violet-600 text-base font-medium">
                {user?.data?.data?.affiliation}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mt-16 w-full">
        {user?.data?.data?.bio && (
          <>
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
              Researcher Bio
            </div>
            <div className="text-gray-700">
              <div
                className="wysiwyg-editor"
                dangerouslySetInnerHTML={{ __html: user?.data?.data?.bio }}
              />
            </div>
          </>
        )}

        {user?.data?.data?.profile_is_private && (
          <div className="flex flex-col items-center justify-center my-8">
            <LockClosedIcon className="mb-2 w-8" />
            This account is private
          </div>
        )}

        {!user?.data?.data?.profile_is_private && (
          <div>
            <div className="mt-4">
              {get(user, "data.data.title", "") && (
                <div className="pb-2">
                  <span className="font-semibold">Title:</span>{" "}
                  {get(user, "data.data.title", "")}
                </div>
              )}
              {get(user, "data.data.location", "") && (
                <div className="pb-2">
                  <span className="font-semibold">Location:</span>{" "}
                  {get(user, "data.data.location", "")}
                </div>
              )}
              {get(user, "data.data.website", "") && (
                <div className="pb-2">
                  <span className="font-semibold">Website:</span>{" "}
                  <a href={get(user, "data.data.website", "")} target="_blank" rel="noreferrer" className='text-violet-700 underline'>
                    {get(user, "data.data.website", "")}
                  </a>
                </div>
              )}
              {get(user, "data.data.social", "") && (
                <div className="pb-2">
                  <span className="font-semibold">Social:</span>{" "}
                  {get(user, "data.data.social", "").split('\n').map((item: string, i: number) => <div key={i}>{item}</div>)}
                </div>
              )}
            </div>

            <div className="lg:grid lg:grid-cols-3 lg:gap-6 mt-8">
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-8">
                <div className="text-xl mb-2 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                  Created projects (
                  {get(user, "data.data.created_projects", []).length})
                </div>
                <div>
                  {map(
                    user?.data?.data?.created_projects,
                    ({ slug, title }, i) => (
                      <div
                        className={classNames("pt-4", {
                          "py-4 border-b border-gray-200":
                            i + 1 <
                            get(user, "data.data.created_projects", []).length,
                        })}
                        key={slug}
                      >
                        <NavLink
                          to={withParams(routes.project, { id: slug })}
                          className="text-lg font-medium text-indigo-600 hover:text-indigo-800"
                        >
                          {title}
                        </NavLink>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-8">
                <div className="text-xl mb-2 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                  Backed projects (
                  {get(user, "data.data.backed_projects", []).length})
                </div>
                <div>
                  {map(
                    user?.data?.data?.backed_projects,
                    ({ slug, title }, i) => (
                      <div
                        className={classNames("pt-4", {
                          "py-4 border-b border-gray-200":
                            i + 1 <
                            get(user, "data.data.backed_projects", []).length,
                        })}
                        key={slug}
                      >
                        <NavLink
                          to={withParams(routes.project, { id: slug })}
                          className="text-lg font-medium text-indigo-600 hover:text-indigo-800"
                        >
                          {title}
                        </NavLink>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-8">
                <div className="text-xl mb-2 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                  Working groups (
                  {get(user, "data.data.created_working_groups", []).length})
                </div>
                <div>
                  {map(
                    user?.data?.data?.created_working_groups,
                    ({ slug, title }, i) => (
                      <div
                        className={classNames("pt-4", {
                          "py-4 border-b border-gray-200":
                            i + 1 <
                            get(user, "data.data.backed_projects", []).length,
                        })}
                        key={slug}
                      >
                        <NavLink
                          to={withParams(routes.workingGroupSingle, { id: slug })}
                          className="text-lg font-medium text-indigo-600 hover:text-indigo-800"
                        >
                          {title}
                        </NavLink>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
