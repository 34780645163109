import React from "react";
import { useQuery } from "react-query";

import { useContextService } from "../../hooks/use-context-service";
import { getLectureSeries } from "../../api/lecture-series";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import { withParams } from "../../utils/url";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";
import {Helmet} from "react-helmet";

export const LectureSeries = () => {
  const getLectureSeriesQuery = useContextService(getLectureSeries);

  const { data, isLoading } = useQuery(
    `GET_LECTURE_SERIES`,
    getLectureSeriesQuery,
    {
      suspense: false,
    }
  );

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Lecture Series</title>
      </Helmet>

      <h2 className="text-3xl font-bold mb-4">Hear from the experts</h2>
      <p className="mb-8 text-gray-700 max-w-4xl">
        Let’s Get Proof has brought together specially curated content from experts
        around the world on a wide variety of "hot topics" - suitable for both
        experienced researchers and those looking to learn more about medicine{" "}
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {data?.data?.data?.map(
          ({ id, title, video_file_url, image, author, tags, summary }) => (
            <div
              className="flex flex-col border border-gray-200 bg-white rounded p-4 md:p-8"
              key={id}
            >
              <div className="mb-2">
                <video controls className="w-full" poster={image}>
                  <source src={video_file_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>

              <NavLink to={withParams(routes.lectureSeriesSingle, { id })}>
                <h2 className="text-lg font-bold my-2">{title}</h2>

                <div className="flex justify-between">
                  <div>
                    <span className="text-gray-700 text-sm">
                      Name of Speaker
                    </span>
                    <br />
                    <span className="font-medium">{author}</span>
                  </div>
                </div>

                {summary && (
                  <div className="mt-3">
                    <span className="text-gray-700 text-sm">Summary</span>
                    <br />
                    {summary}
                  </div>
                )}

                <div className="mt-4">
                  {tags.map(({ title }, i) => (
                    <span
                      key={i}
                      className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mr-1 mb-1"
                    >
                      {title}
                    </span>
                  ))}
                </div>
              </NavLink>
            </div>
          )
        )}
      </div>
    </div>
  );
};
