import { Context } from "types";
import { withParams } from "utils/url";
import { apiUrl, axiosInstance, getHeaders } from "./index";
import { WorkingGroupMember } from "./workingGroups";

import { Project } from "./types";

export type ProjectUpdates = {
  id?: string;
  title?: string;
  text?: string;
  created_at?: string;
  image?: string;
  video_url?: string;
  approved_status?: string;
};

export const validateCreateProjectPermission = ({ apiClient }: Context) => () =>
  apiClient<{
    statuses: {
      affiliation_status: { status: string; message: "string" };
      role_status: { status: string; message: "string" };
      stripe_account: { status: string; message: "string" };
    };
  }>({
    url: withParams("/me/projects/create/validate"),
    method: "GET",
  });

export const getInvoiceSettings = ({ apiClient }: Context) => () =>
  apiClient<{
    data: {
      admin_fee_percent: number;
      stripe_flat_fee: number;
      stripe_percent_fee: number;
      tax_percent: number;
    };
  }>({
    url: withParams("/invoice-settings"),
    method: "GET",
  });

export const deleteProject = ({ apiClient }: Context) => ({
  id,
}: {
  id: number;
}) =>
  apiClient({
    url: withParams("/me/projects/:id", { id }),
    method: "DELETE",
  });

export const addFavoriteProject = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient({
    url: withParams("/projects/:id/add-favorite", { id }),
    method: "PUT",
  });

export const deleteFavoriteProject = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient({
    url: withParams("/projects/:id/delete-favorite", { id }),
    method: "DELETE",
  });

export const sendToApprovalProject = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient({
    url: withParams("/projects/:id/send-to-approval", { id }),
    method: "POST",
    data: {},
  });

export const donateProject = ({ apiClient }: Context) => ({
  id,
  amount,
  kbf_location,
}: {
  id: number;
  amount: string;
  kbf_location?: string;
}) =>
  apiClient<{
    data: {
      intent_secret: string;
      stripe_public_key: string;
      redirect_link?: string;
    };
  }>({
    url: withParams("/projects/:id/donate", { id }),
    method: "POST",
    data: { amount, kbf_location },
  });

export const donateProjectCallback = ({ apiClient }: Context) => ({
  payment_intent,
  payment_intent_client_secret,
}: {
  payment_intent: string;
  payment_intent_client_secret: string;
}) =>
  apiClient({
    url: withParams("/me/pledges/clientside-callback"),
    method: "POST",
    data: { payment_intent, payment_intent_client_secret },
  });

export const budgetItemsGet = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string;
}) =>
  apiClient<{
    external_budget_items: { id?: string; name: string; amount: string }[];
    budget_items: { id?: string; name: string; amount: string }[];
  }>({
    url: withParams("/me/projects/:project_id/budget-item/get", {
      project_id,
      perPage: 100,
    }),
    method: "GET",
  });

export const supplementaryItemsGet = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string;
}) =>
  apiClient<{ id?: string; name: string; type: string; url: string }[]>({
    url: withParams("/me/projects/:project_id/supplementary-materials", {
      project_id,
      perPage: 100,
    }),
    method: "GET",
  });

export const budgetItemsProjectGet = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string | number;
}) =>
  apiClient<{
    external_budget_items: { id?: string; name: string; amount: string }[];
    budget_items: { id?: string; name: string; amount: string }[];
  }>({
    url: withParams("/projects/:project_id/budget-item", {
      project_id,
      perPage: 100,
    }),
    method: "GET",
  });

export const supplementaryProjectItemsGet = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string | number;
}) =>
  apiClient<{
    data: { id?: string; name: string; type: string; url: string }[];
  }>({
    url: withParams("/projects/:project_id/supplementary-materials", {
      project_id,
      perPage: 100,
    }),
    method: "GET",
  });

export const budgetItemsUpdate = ({ apiClient }: Context) => ({
  project_id,
  data,
}: {
  project_id: string;
  data: {
    external_budget_items: { id?: string; name: string; amount: string }[];
    budget_items: { id?: string; name: string; amount: string }[];
  };
}) =>
  apiClient({
    url: withParams("/me/projects/:project_id/budget-item", { project_id }),
    method: "POST",
    data: data,
  });

export const getProjectUpdatesPublicList = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string;
}) =>
  apiClient<{
    data: ProjectUpdates[];
  }>({
    url: withParams("/projects/:project_id/posts", {
      project_id,
      perPage: 99,
    }),
    method: "GET",
  });

export const getProjectUpdatesList = ({ apiClient }: Context) => ({
  project_id,
}: {
  project_id: string;
}) =>
  apiClient<{
    data: ProjectUpdates[];
  }>({
    url: withParams("/me/projects/:project_id/posts", {
      project_id,
      perPage: 99,
    }),
    method: "GET",
  });

export const getProjectUpdatesSingle = ({ apiClient }: Context) => ({
  project_id,
  id,
}: {
  project_id: string;
  id: string;
}) =>
  apiClient<{ data: ProjectUpdates }>({
    url: withParams("/me/projects/:project_id/posts/:id", { project_id, id }),
    method: "GET",
  });

export const postProjectUpdate = ({ apiClient }: Context) => ({
  project_id,
  data,
}: {
  project_id: string;
  data: ProjectUpdates;
}) =>
  apiClient({
    url: withParams("/me/projects/:project_id/posts", { project_id }),
    method: "POST",
    data,
  });

export const patchProjectUpdate = ({ apiClient }: Context) => ({
  project_id,
  id,
  data,
}: {
  project_id: string;
  id: string;
  data: ProjectUpdates;
}) =>
  apiClient({
    url: withParams("/me/projects/:project_id/posts/:id", { project_id, id }),
    method: "PATCH",
    data,
  });

export const projectUpdateSetImageRequest = ({
  id,
  project_id,
  data,
}: {
  id: string;
  project_id: string;
  data: any;
}) =>
  axiosInstance.post(
    `${apiUrl()}${withParams("/me/projects/:project_id/posts/:id/set-image", {
      id,
      project_id,
    })}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
    }
  );

export const getProjectMembers = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/projects/${slug}/members`),
    method: "GET",
  });

export const getJoinRequestsProject = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/projects/${slug}/join-requests`),
    method: "GET",
  });

export const joinRequestApproveProject = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/join-requests/:member_id/accept`, {
      slug,
      member_id,
    }),
    method: "POST",
    data: {},
  });

export const joinRequestDeclineProject = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/join-requests/:member_id/decline`, {
      slug,
      member_id,
    }),
    method: "POST",
    data: {},
  });

export const getInvitedMembersProject = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/projects/${slug}/invited-members`),
    method: "GET",
  });

export const deleteInvitedMemberProject = ({ apiClient }: Context) => ({
  slug,
  id,
}: {
  slug: string;
  id: string;
}) =>
  apiClient({
    url: withParams("/projects/:slug/invited-members/:id", { slug, id }),
    method: "DELETE",
  });

export const inviteMemberProject = ({ apiClient }: Context) => (
  slug: string,
  data: { email: string; message: string }
) =>
  apiClient<{ data: { email: string; message: string } }>({
    url: withParams(`/projects/:slug/invite-member`, { slug }),
    method: "POST",
    data,
  });

export const confirmMemberProject = ({ apiClient }: Context) => (
  slug: string,
  invite_hash: string
) =>
  apiClient<{ data: { email: string; message: string } }>({
    url: withParams(`/projects/:slug/invited-members/:invite_hash`, {
      slug,
      invite_hash,
    }),
    method: "POST",
    data: {},
  });

export const leaveMemberProject = ({ apiClient }: Context) => (slug: string) =>
  apiClient({
    url: withParams(`/projects/:slug/members/leave`, { slug }),
    method: "POST",
    data: {},
  });

export const joinRequestCancelProject = ({ apiClient }: Context) => (
  slug: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/join-requests`, { slug }),
    method: "DELETE",
  });

export const joinRequestProject = ({ apiClient }: Context) => (
  slug: string,
  message: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/join-requests`, { slug }),
    method: "POST",
    data: { message },
  });

export const removeMemberProject = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/members/:member_id`, {
      slug,
      member_id,
    }),
    method: "DELETE",
  });

export const changeMemberRoleProject = ({ apiClient }: Context) => (
  slug: string,
  member_id: string,
  role: string
) =>
  apiClient({
    url: withParams(`/projects/:slug/members/:member_id`, {
      slug,
      member_id,
    }),
    method: "PUT",
    data: {
      role,
    },
  });

export const getMyProjectsJoinedList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 100,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
}) =>
  apiClient<{
    data: Project[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/me/projects/joined`, {
      page,
      perPage,
    }),
    method: "GET",
  });

export const getMyProjectsAppliedList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 100,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
}) =>
  apiClient<{
    data: Project[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/me/projects/applied`, {
      page,
      perPage,
    }),
    method: "GET",
  });
