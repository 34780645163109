export const routes = {
  login: "/login",
  forgotPassword: "/forgot-password",
  forgotPasswordConfirm: "/forgot-password/confirm",
  setNewPasswordConfirm: "/set-password",
  register: "/register",
  registerLite: "/register-lite",
  confirmUser: "/confirm-user",
  confirmAffiliation: "/confirm-university-email",
  dashboard: "/dashboard",
  myProfile: "/my-profile",
  myDonations: "/my-pledges",
  projectUpdatesList: "/project-updates/:project_id",
  projectUpdatesEdit: "/project-updates/:project_id/edit/:id",
  projectUpdatesAdd: "/project-updates/:project_id/add",
  groupUpdatesList: "/group-updates/:group_id",
  groupUpdatesEdit: "/group-updates/:group_id/:slug/edit/:id",
  groupUpdatesAdd: "/group-updates/:group_id/:slug/add",
  myProfileNotifications: "/my-profile/notifications",
  myProfileAffiliations: "/my-profile/affiliations",
  myProfileStripe: "/my-profile/stripe",
  myProfileEditProfile: "/my-profile/edit-profile",
  myProjects: "/my-projects",
  researcherProfile: "/profile/:id",
  projects: "/projects",
  fundedProjects: "/funded-projects",
  project: "/project/:id",
  submitProject: "/submit-project",
  submitProjectSingle: "/submit-project/:id",
  contactUs: "/contact-us",
  about: "/about",
  ourModel: "/our-model",
  pressAndMedia: "/press-and-media",
  getInvolved: "/get-involved",
  meetTheTeam: "/meet-the-team",
  ourRules: "/our-rules",
  workingGroups: "/working-groups",
  blogList: "/blog",
  blogSingle: "/blog/:id",
  quiz: "/certificates",
  quizSingle: "/certificates/:id",
  workingGroupsMy: "/working-groups/my",
  workingGroupsMyJoined: "/working-groups/my/joined",
  workingGroupsMyApplied: "/working-groups/my/applied",
  projectsMyJoined: "/my-projects/joined",
  projectsMyApplied: "/my-projects/applied",
  workingGroupsList: "/working-groups/list",
  workingGroupCreate: "/working-groups/create",
  workingGroupEdit: "/working-groups/edit/:id",
  workingGroupSingle: "/working-groups/:id",
  lectureSeries: "/lecture-series",
  lectureSeriesSingle: "/lecture-series/:id",
  wgLearnMore: "/working-groups/learn-more",
};
