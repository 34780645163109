import React, { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { pipe } from "fp-ts/lib/function";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import {
  Discussion,
  postDiscussionComment,
  putDiscussionComment,
  deleteCommentImage,
} from "api/discussions";
import { Button } from "components/button/Button";
import { FilesUploadField } from "components/files-upload-field/FilesUploadField";
import { RichEditorField } from "components/rich-editor-field/RichEditorField";
import { useContextService } from "hooks/use-context-service";
import { useStringRequired } from "hooks/use-validation-input";
import { TrashIcon } from "@heroicons/react/solid";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";
import get from "lodash/get";
import { setFormError } from "../../utils/setError";
import { ReactComponent as IconPdf } from "../../assets/icons/pdf.svg";
import ReactGA from "react-ga4";

const useFormSchema = () =>
  z.object({
    text: pipe(z.string(), useStringRequired()).refine(
      (val) => val !== "<p><br></p>" || !val,
      {
        message: "This field is required",
      }
    ),
    files: z
      .object({
        file: z.string(),
        filename: z.string(),
      })
      .array()
      .optional(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const CommentForm = ({
  discussion_id,
  comment_id,
  reply_id,
  onSubmit,
  onReplyCancel,
  onEditCancel,
  defaultValues,
  images,
  deletedImageId,
  setDeletedImageId,
  type,
}: {
  discussion_id: string;
  comment_id?: string;
  reply_id?: string;
  defaultValues?: { text: string };
  onSubmit: (comment: Discussion) => void;
  onReplyCancel?: () => void;
  onEditCancel?: () => void;
  images?: {
    id: string;
    image: string;
    image_thumbnail: string;
    type: string;
    filename: string;
  }[];
  deletedImageId?: string[];
  setDeletedImageId?: (imagesId: string[]) => void;
  type?: string;
}) => {
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm<FormSchema>({
    defaultValues,
    resolver: zodResolver(useFormSchema()),
  });

  const { files } = watch();

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const discussionCommentMutation = useMutation(
    useContextService(postDiscussionComment),
    {
      onSuccess: (response) => {
        onSubmit(response?.data?.data!);
        reset();
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
        return new Promise(() => {});
      },
    }
  );
  const discussionEditCommentMutation = useMutation(
    useContextService(putDiscussionComment),
    {
      onSuccess: (response) => {
        onSubmit(response?.data?.data!);
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const discussionDeleteImageMutation = useMutation(
    useContextService(deleteCommentImage),
    {
      onSuccess: (response) => {},
      onError: (e) => {
        setFormErrorMessage("Something went wrong");
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        const formData = new FormData();
        formData.append("id", discussion_id);
        formData.append("text", data.text);

        if (data.files) {
          const files = await Promise.all(
            data.files.map(async (file) => {
              const fileFetch = await fetch(file.file);
              return await fileFetch.blob();
            })
          );

          // @ts-ignore
          for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i], data.files[i].filename);
          }
        }

        if (comment_id) {
          formData.append("comment_id", comment_id);

          discussionEditCommentMutation.mutateAsync({
            id: discussion_id,
            comment_id,
            data: formData,
          });
        } else {
          if (reply_id) {
            formData.append("parent", reply_id);
          }

          await discussionCommentMutation.mutateAsync({
            id: discussion_id,
            data: formData,
          });

          // ReactGA.event({
          //   category: "Conversion",
          //   action: `${
          //     type === "blog"
          //       ? "blog/api"
          //       : type === "project"
          //       ? "projects"
          //       : "groups"
          //   } comment added`,
          //   value: 5,
          // });
        }
      })}
      className=""
    >
      <div className="flex items-center text-lg mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
        {comment_id && "Edit"}

        {onReplyCancel && (
          <Button
            variant="white"
            size="exSmall"
            type="button"
            onClick={onReplyCancel}
          >
            Cancel
          </Button>
        )}
        {onEditCancel && (
          <Button
            variant="white"
            size="exSmall"
            type="button"
            onClick={onEditCancel}
            className="ml-3"
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="mb-5">
        <RichEditorField
          label="Reply"
          name="text"
          error={errors?.text}
          required
          control={control}
        />
      </div>

      {images &&
        deletedImageId &&
        images.filter(({ id }) => !deletedImageId.includes(id)).length > 0 && (
          <div className="flex flex-wrap mb-5">
            {images
              .filter(({ id }) => !deletedImageId.includes(id))
              .map(({ id, image_thumbnail, type, filename }) => (
                <div key={id} className="relative">
                  {type !== "PDF" && (
                    <img
                      src={image_thumbnail}
                      alt="Img"
                      width={100}
                      className="mr-1"
                    />
                  )}
                  {type === "PDF" && (
                    <div className="w-24 h-24 flex flex-col items-center justify-center bg-white rounded mr-2">
                      <IconPdf className="w-6" />

                      <div className="pt-2 h-6 px-1 text-xs text-gray-600 w-full overflow-hidden text-center">
                        {filename}
                      </div>
                    </div>
                  )}

                  <button
                    type="button"
                    className="absolute top-1 right-1 rounded-full bg-white flex items-center justify-center w-6 h-6 text-red-600"
                    onClick={async () => {
                      await discussionDeleteImageMutation.mutateAsync({
                        id,
                        discussion_id,
                      });

                      const deletedImageIdNew = [...deletedImageId];
                      setDeletedImageId &&
                        setDeletedImageId([...deletedImageIdNew, id]);
                    }}
                  >
                    {discussionDeleteImageMutation.isLoading ? (
                      <LoadingSpinner className="w-3 h-3 text-violet-600" />
                    ) : (
                      <TrashIcon className="w-4" />
                    )}
                  </button>
                </div>
              ))}
          </div>
        )}

      <div className="mb-5">
        <FilesUploadField
          label="File"
          name="files"
          fileNameField="files"
          currentValue={files}
          control={control}
          setValue={setValue}
          onChangeProp={(val) => {
            console.log(val);
          }}
        />
      </div>

      {formError && (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {formError}
        </div>
      )}

      <Button
        type="submit"
        className="w-full"
        loading={
          discussionCommentMutation.isLoading ||
          discussionEditCommentMutation.isLoading
        }
        spinner
        disabled={
          discussionCommentMutation.isLoading ||
          discussionEditCommentMutation.isLoading
        }
      >
        Submit
      </Button>
    </form>
  );
};
