import React, {useState, useEffect, useContext} from "react";
import get from "lodash/get";
import some from "lodash/some";
import { NavLink } from "react-router-dom";

import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "services/notification";

import { ChangePasswordForm } from "./change-password-form/ChangePasswordForm";
import { PhoneNumberForm } from "domains/register/components/PhoneNumberForm";
import { SetupOtpForm } from "./setup-otp-form/SetupOtpForm";
import { Button } from "components/button/Button";
import { Modal } from "components/modal/Modal";
import { useModal } from "hooks/use-modal";
import { USER_QUERY_KEY, useUserQuery } from "queries/use-user-query";
import { useMutation, useQueryClient } from "react-query";
import { deleteOTP, deleteSSO } from "../../../api/auth/auth";
import { emailConfirmStart } from "api/account";
import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { Helmet } from "react-helmet";
import {AppContext} from "../../../context";

export const Account = () => {
  const { data: userResponse } = useUserQuery();
  const queryClient = useQueryClient();
  const showNotification = useContextService(showNotificationService);

  const { dispatch } = useContext(AppContext);

  const logout = () => dispatch({ type: "SET_AUTH_TOKEN", token: null });

  const emailConfirmStartMutation = useMutation(
    useContextService(emailConfirmStart)
  );

  const [providerToDelete, setProviderToDelete] = useState<
    { id: number; provider: string } | undefined
  >();

  const { modalVisible, openModal, closeModal } = useModal();
  const {
    modalVisible: modalVisibleDelete,
    openModal: openModalDelete,
    closeModal: closeModalDelete,
  } = useModal();

  const {
    modalVisible: modalVisibleConfirmEmail,
    openModal: openModalConfirmEmail,
    closeModal: closeModalConfirmEmail,
  } = useModal();

  const {
    modalVisible: modalVisibleConfirmPhone,
    openModal: openModalConfirmPhone,
    closeModal: closeModalConfirmPhone,
  } = useModal();

  const deleteOTPQuery = useMutation(deleteOTP, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);

      showNotification({
        title: "Successfully updated",
        message: "TOTP settings has been successfully updated",
      });
    },
  });

  const deleteSSOQuery = useMutation(deleteSSO, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);

      showNotification({
        title: "Successfully updated",
        message: "SSO settings has been successfully updated",
      });
      logout();
    },
    onError: (e: Error) => {
      showNotification({
        title: "Error",
        variant: "ERROR",
        message: get(e, 'response.data.messages.details'),
      });
    }
  });

  useEffect(() => {
    if (emailConfirmStartMutation.isSuccess) {
      openModalConfirmEmail();
    }
  }, [openModalConfirmEmail, emailConfirmStartMutation.isSuccess]);

  return (
    <div className="flex flex-col justify-between md:flex-row">
      <Helmet>
        <title>Let’s Get Proof | Account</title>
      </Helmet>
      <div className="flex-1 md:mr-4">
        <div className="text-xs text-gray-500">Email</div>
        <div className="mb-4">{userResponse?.data?.email}</div>
        {userResponse?.data && <ChangePasswordForm user={userResponse?.data} />}
      </div>

      <div className="flex-1 md:ml-4">
        <div className="pb-6 mb-6 border-b border-gray-200">
          {userResponse?.data?.oauth_associations &&
            userResponse?.data?.oauth_associations.length > 0 && (
              <div className="pb-6 mb-6 border-b border-gray-200">
                {userResponse?.data?.oauth_associations.map(
                  ({ id, provider }) => (
                    <div className="mb-2" key={id}>
                      <Button
                        type="button"
                        variant="danger"
                        size="exSmall"
                        className="mt-2"
                        onClick={() => {
                          setProviderToDelete({ id, provider });
                        }}
                      >
                        Disconnect {provider} account
                      </Button>
                    </div>
                  )
                )}
              </div>
            )}
          <div className="mb-4">
            <div className="flex items-center text-gray-700 text-sm">
              {userResponse?.data?.phone_confirmed ? (
                <div className="flex items-center justify-center rounded-full w-5 h-5 bg-green-500 mr-2">
                  <CheckIcon className="text-white w-4" />
                </div>
              ) : (
                <div className="flex items-center justify-center rounded-full w-5 h-5 bg-yellow-500 mr-2">
                  <XIcon className="text-white w-3" />
                </div>
              )}
              {userResponse?.data?.phone_confirmed ? (
                <span>Phone number confirmed</span>
              ) : (
                <span>Phone number is not confirmed</span>
              )}

              {userResponse?.data?.phone_number && (
                <span className="pl-1 font-medium">
                  ({userResponse?.data?.phone_number})
                </span>
              )}
            </div>

            <Button
              type="button"
              variant="primary"
              size="exSmall"
              className="mt-2"
              onClick={openModalConfirmPhone}
            >
              {userResponse?.data?.phone_confirmed
                ? "Change phone number"
                : userResponse?.data?.phone_number
                ? "Confirm phone number"
                : "Add phone number"}
            </Button>
          </div>
          <div>
            <div className="flex items-center text-gray-700 text-sm">
              {userResponse?.data?.email_confirmed ? (
                <div className="flex items-center justify-center rounded-full w-5 h-5 bg-green-500 mr-2">
                  <CheckIcon className="text-white w-4" />
                </div>
              ) : (
                <div className="flex items-center justify-center rounded-full w-5 h-5 bg-yellow-500 mr-2">
                  <XIcon className="text-white w-3" />
                </div>
              )}
              {userResponse?.data?.email_confirmed ? (
                <span>Email confirmed</span>
              ) : (
                <span>Email is not confirmed</span>
              )}
            </div>

            {!userResponse?.data?.email_confirmed && (
              <Button
                type="button"
                variant="primary"
                size="exSmall"
                className="mt-2"
                onClick={() => {
                  emailConfirmStartMutation.mutate();
                }}
                spinner
                loading={emailConfirmStartMutation.isLoading}
                disabled={emailConfirmStartMutation.isSuccess}
              >
                Confirm email
              </Button>
            )}
          </div>
        </div>

        <div className="pb-6 mb-6 border-b border-gray-200">
          <h2 className="text-lg font-medium text-black mb-2">Security</h2>
          {some(
            get(userResponse, "data.devices"),
            ({ confirmed }) => !!confirmed
          ) ? (
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                deleteOTPQuery.mutate();
              }}
            >
              Disable two-factor authentication
            </Button>
          ) : (
            <Button type="button" onClick={openModal}>
              Enable two-factor authentication
            </Button>
          )}
        </div>
        <h2 className="text-lg font-medium text-black mt-5 mb-2">
          Delete account
        </h2>

        <Button type="button" variant="danger" onClick={openModalDelete}>
          Delete account
        </Button>
      </div>

      <Modal visible={modalVisible} onClose={closeModal}>
        <SetupOtpForm onSubmit={closeModal} />
      </Modal>

      <Modal
        visible={!!providerToDelete}
        onClose={() => {
          setProviderToDelete(undefined);
        }}
        className="max-w-xs"
      >
        <div className="flex flex-col items-center">
          <h2 className="text-lg font-medium text-black mb-2">
            Confirm action
          </h2>

          <Button
            type="button"
            variant="danger"
            loading={deleteSSOQuery.isLoading}
            onClick={() => {
              deleteSSOQuery.mutate(providerToDelete?.id!);
            }}
          >
            Disconnect {providerToDelete?.provider} account
          </Button>
        </div>
      </Modal>

      <Modal
        visible={modalVisibleConfirmPhone}
        onClose={closeModalConfirmPhone}
      >
        <PhoneNumberForm
          phone_number={userResponse?.data?.phone_number}
          closeModal={() => {
            closeModalConfirmPhone();
          }}
        />
      </Modal>

      <Modal
        visible={modalVisibleConfirmEmail}
        onClose={closeModalConfirmEmail}
        className="sm:max-w-xl p-0 sm:p-0"
      >
        <div className="w-full px-4 py-2 text-sm text-center text-green-700 bg-green-100 font-medium rounded">
          Please check your email (including your spam folder).
        </div>
      </Modal>

      <Modal visible={modalVisibleDelete} onClose={closeModalDelete}>
        <h2 className="text-lg font-medium text-black mb-2">Delete account</h2>
        If you want to delete the account please contact{" "}
        <NavLink to="/contact-us" className="text-violet-700">
          our support
        </NavLink>
        .
      </Modal>
    </div>
  );
};
