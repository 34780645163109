import React, { forwardRef, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { Controller, FieldError } from "react-hook-form";
import { UploadIcon } from "@heroicons/react/outline";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { ReactComponent as IconPdf } from "assets/icons/pdf.svg";
import { ReactComponent as IconCsv } from "assets/icons/csv.svg";
import { ReactComponent as IconText } from "assets/icons/text.svg";
import { ReactComponent as IconPpt } from "assets/icons/ppt.svg";
import { ReactComponent as IconZip } from "assets/icons/zip.svg";
import { ReactComponent as IconDoc } from "assets/icons/doc.svg";
import { ReactComponent as IconXls } from "assets/icons/xls.svg";
import { FieldGroup } from "../FieldGroup";
import { useContextService } from "../../hooks/use-context-service";
import { showNotification as showNotificationService } from "../../services/notification";

export interface CustomUploadProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  view?: "default" | "small-rounded";
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
  setValue: Function;
  aspect?: number;
  loaded?: number;
  initialFileType?: string;
  fileNameField?: string;
  fileNameValue?: string;
  onChangeProp?: (val: string) => void;
}

export const fileIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    case "pdf":
      return <IconPdf className="mr-2 w-6" />;
    case "csv":
      return <IconCsv className="mr-2 w-6" />;
    case "powerpoint":
      return <IconPpt className="mr-2 w-6" />;
    case "zip":
      return <IconZip className="mr-2 w-6" />;
    case "excel":
      return <IconXls className="mr-2 w-6" />;
    case "doc":
      return <IconDoc className="mr-2 w-6" />;
    default:
      return <IconText className="mr-2 w-6" />;
  }
};

export const FileUploadField = forwardRef(
  (
    {
      control,
      name,
      aspect,
      disabled,
      rules,
      required,
      optional,
      setValue,
      isLoading,
      view = "default",
      onChangeProp,
      initialFileType,
      fileNameValue,
      fileNameField,
      loaded,
      ...props
    }: CustomUploadProps,
    ref
  ) => {
    const showNotification = useContextService(showNotificationService);
    const [fileName, setFileName] = useState("");
    const [fileType, setFileType] = useState("");

    const onDrop = useCallback(
      (acceptedFiles, rejectedFiles) => {
        if (acceptedFiles.length) {
          setFileName(acceptedFiles[0].name);
          setFileType(acceptedFiles[0].type);
          const reader = new FileReader();
          reader.readAsDataURL(acceptedFiles[0]);
          reader.onload = function () {
            setValue(name, reader.result);
            if (fileNameField) {
              setValue(fileNameField, acceptedFiles[0].name);
            }
            onChangeProp && onChangeProp(`${reader.result}`);
          };
        }

        if (rejectedFiles.length) {
          rejectedFiles.forEach(
            ({ errors }: { errors: { message: string }[] }) => {
              errors.forEach(({ message }: { message: string }) => {
                showNotification({
                  title: "Error",
                  message,
                  variant: "ERROR",
                });
              });
            }
          );
        }
      },
      [showNotification, setValue, onChangeProp, fileNameField, name]
    );

    const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      disabled,
      accept: "image/jpeg, image/png, application/pdf, image/gif",
      maxFiles: 1,
    });

    return (
      <>
        <FieldGroup
          required={required}
          optional={optional}
          name={name}
          hasShadow={false}
          {...props}
        >
          {(computedProps) => (
            <Controller
              rules={rules}
              name={name}
              control={control}
              render={({ field: { onChange, onBlur, value, name } }) => (
                <>
                  <input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value || ""}
                    type="text"
                    {...computedProps}
                    className="hidden"
                  />

                  {!(
                    value &&
                    value.includes("s3.amazonaws.com") &&
                    disabled
                  ) && (
                    <div className={classNames("relative")} {...getRootProps()}>
                      <button
                        type="button"
                        className={classNames(
                          "bg-white border-gray-300 cursor-pointer flex relative items-center overflow-hidden form-control block w-full px-3 py-1.5 text-sm font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0",
                          {
                            "hover:text-blue-600 hover:bg-white hover:border-blue-600 hover:outline-none focus:text-blue-600 focus:bg-white focus:border-blue-600 focus:outline-none":
                              !disabled && !props.error,
                            "border-red-300 bg-red-100": props.error,
                          }
                        )}
                      >
                        <span className="flex items-center relative z-10">
                          {isLoading ? (
                            <div className="ml-0 mr-2">
                              <IconLoading className="w-5 h-5" />
                            </div>
                          ) : (
                            <div className="ml-0 mr-2">
                              <UploadIcon className="w-5 h-5" />
                            </div>
                          )}
                          {fileName ? fileName : "Choose file"}
                        </span>
                        <span
                          className={classNames(
                            "absolute h-full left-0 bottom-0 z-0",
                            {
                              "bg-blue-100": !props.error,
                              "bg-red-100": props.error,
                            }
                          )}
                          style={{ width: `${loaded}%` }}
                        />
                      </button>
                      <div className="text-xs pt-1 text-gray-500">
                        Supported file types: .jpg, .png, .gif, .pdf
                      </div>
                      <div>
                        <input {...getInputProps()} />
                      </div>
                    </div>
                  )}

                  {(["image/jpeg", "image/png", "image"].includes(fileType) ||
                    (!fileType &&
                      initialFileType &&
                      ["image/jpeg", "image/png", "image"].includes(
                        initialFileType
                      ))) &&
                    value &&
                    !props.error && (
                      <div className="mt-3">
                        <img src={value} width={360} alt="" />
                      </div>
                    )}

                  {initialFileType &&
                    value &&
                    disabled &&
                    !["image"].includes(initialFileType) && (
                      <div>
                        <a
                          href={value}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center text-violet-600 text-sm"
                        >
                          {fileIcon({ fileType: initialFileType })}
                          {(fileNameValue || value).slice(0, 30)}
                        </a>
                      </div>
                    )}
                </>
              )}
            />
          )}
        </FieldGroup>
      </>
    );
  }
);
