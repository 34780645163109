import React, { useContext } from "react";
import { FolderIcon } from "@heroicons/react/outline";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { Button } from "components/button/Button";

import { QuizBrowseContext } from "./QuizBrowseContext";
import { List } from "./List";
import { Helmet } from "react-helmet";
import { useContextService } from "../../../hooks/use-context-service";
import { getBanner } from "../../../api/dashboard";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";

export const Quizzes = () => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
  } = useContext(QuizBrowseContext);

  const getBannerService = useContextService(getBanner);

  const { data: bannerData, isFetched: bannerIsFetched } = useQuery(
    "GET_BANNER",
    getBannerService,
    {
      suspense: false,
    }
  );

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Certificates</title>
      </Helmet>
      <div className="">
        <h2 className="font-nunito font-bold text-xl lg:text-3xl">
          Certificates
        </h2>
      </div>

      {bannerData?.data?.banner && (
        <div className="my-4">
          <NavLink to={bannerData?.data?.banner_link || "/"}>
            <img src={bannerData?.data?.banner} className="w-full" />
          </NavLink>
        </div>
      )}

      {isLoading ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          {listData.length > 0 ? (
            <div>
              <List listData={listData} />

              {hasNextPage && (
                <div className="flex justify-center mt-8">
                  <Button
                    loading={isFetchingNextPage}
                    spinner
                    disabled={!hasNextPage}
                    onClick={handleFetchNextPage}
                  >
                    Show me more
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="text-center">
                <FolderIcon className="w-10 mx-auto text-gray-500" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No certificates found
                </h3>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
