import classNames from "classnames";
import { forwardRef, PropsWithoutRef } from "react";

type Variant = "primary" | "dark" | "danger";

export interface TextButtonProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["button"]>, "className"> {
  variant?: Variant;
  loading?: boolean;
}

const variantClassNames: Record<Variant, string> = {
  primary: "text-violet-600 focus:ring-violet-500",
  danger: "text-red-500 focus:ring-red-500",
  dark: "text-white",
};

export const getTextButtonClassNames = ({
  variant,
  loading,
}: Required<Pick<TextButtonProps, "variant">> &
  Pick<TextButtonProps, "loading">) =>
  classNames(
    "text-sm focus:outline-none disabled:opacity-50 font-medium focus:ring-2 rounded inline-flex items-center px-1 py-1 leading-4 focus:ring-offset-2",
    {
      "cursor-wait": loading,
      "disabled:cursor-not-allowed": !loading,
    },
    variantClassNames[variant]
  );

export const getTextButtonBlockClassNames = ({
  variant,
  loading,
}: Required<Pick<TextButtonProps, "variant">> &
  Pick<TextButtonProps, "loading">) =>
  classNames(
    "text-sm focus:outline-none disabled:opacity-50 font-medium focus:ring-2 block items-center px-4 py-3 leading-4 focus:ring-offset-2 hover:bg-violet-100",
    {
      "cursor-wait": loading,
      "disabled:cursor-not-allowed": !loading,
    },
    variantClassNames[variant]
  );

export const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ children, variant = "primary", loading, ...props }, ref) => (
    <button
      ref={ref}
      {...props}
      className={getTextButtonClassNames({
        variant,
        loading,
      })}
    >
      {children}
    </button>
  )
);
