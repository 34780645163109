import React, { useState, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";

import { registerRequest } from "api/auth/auth";
import { setFormError } from "utils/setError";
import { routes } from "routes";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";

export const RegisterLite = ({ redirect_to }: { redirect_to?: string }) => {
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location?.search), [
    location,
  ]);

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required("Required")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email"
        ),
      name: yup.string().required("Required"),
      password: yup
        .string()
        .required("Required")
        .min(
          8,
          "This password is too short. It must contain at least 8 characters."
        ),
    });
  }, []);

  const { register, handleSubmit, formState, setError } = useForm<{
    name: string;
    email: string;
    password: string;
  }>({
    mode: "onChange",
    defaultValues: {
      email: params.get("email") || undefined,
      name: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const [isRegistred, setIsRegistred] = useState<boolean>(false);
  const [formError, setFormErrorMessage] = useState<string | undefined>();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const registerQuery = useMutation(registerRequest, {
    onSuccess: (response) => {
      // if (process.env.REACT_APP_GTAG) {
      //   ReactGA.gtag("config", process.env.REACT_APP_GTAG);
      //   ReactGA.gtag("event", "conversion", {
      //     send_to: "AW-10977792147/qTvOCM7cjbgYEJOhz_Io",
      //     value: 2.0,
      //     currency: "CAD",
      //   });
      // }

      // ReactGA.event({
      //   category: "Conversion",
      //   action: "Completion of sign up",
      //   value: 15,
      // });
      setIsRegistred(true);
    },
    onError: (e) => {
      setFormErrorMessage("Something went wrong");
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const onSubmit = (data: any) => {
    registerQuery.mutate({
      captcha: recaptchaValue,
      email: data.email,
      password: data.password,
      name: data.name,
      redirect_to,
    });
  };

  return (
    <div className="flex items-center h-full max-w-2xl mx-auto">
      <Helmet>
        <title>Let’s Get Proof | Sign up</title>
      </Helmet>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col items-center w-full max-w-screen-lg my-auto"
      >
        <div className={classNames("w-96 px-4 sm:p-0")}>
          <div className="w-full p-5 bg-white rounded sm:p-8">
            <div className="flex flex-col items-center">
              <div className="flex items-center text-sm">
                Already part of the team?
                <NavLink
                  to={routes.login}
                  className="inline-flex items-center ml-2 font-medium transition-colors text-violet-500 hover:text-violet-800"
                >
                  Sign in
                </NavLink>
              </div>

              <h2 className="mt-4 mb-3 text-xl font-bold">
                Please sign up to continue
              </h2>

              {isRegistred ? (
                <div className="w-full px-4 py-2 text-sm text-center text-green-700 bg-green-100 rounded">
                  You have successfully registered.
                  <br />
                  Please check your email (including your spam folder)
                </div>
              ) : (
                <div className="w-full">
                  <div className="w-full mb-5">
                    <TextField
                      label="Name"
                      placeholder="John Doe"
                      error={formState.errors?.name}
                      {...register("name", { required: true })}
                    />
                  </div>
                  <div className="w-full mb-5">
                    <TextField
                      label="Email"
                      placeholder="john.doe@gmail.com"
                      error={formState.errors?.email}
                      {...register("email", { required: true })}
                    />
                  </div>
                  <div className="w-full mb-5">
                    <TextField
                      label="Password"
                      type="password"
                      placeholder="Password"
                      error={formState.errors?.password}
                      {...register("password", { required: true })}
                    />
                  </div>

                  {formError && (
                    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded text-center">
                      {formError}
                    </div>
                  )}

                  <div className="mb-4">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      onChange={(token) => {
                        setRecaptchaValue(token);
                      }}
                      sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY!}
                    />
                  </div>

                  <Button
                    type="submit"
                    className="w-full"
                    loading={registerQuery.isLoading}
                    spinner
                    disabled={!recaptchaValue}
                  >
                    Create account
                  </Button>

                  <div className="mt-5 text-sm text-center text-gray-700">
                    By signing up, you agree to our Privacy Policy, Cookie
                    Policy and Terms of Use
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
