import React, { useState, useMemo, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";

import { Button } from "components/button/Button";
import queryString from "query-string";

import { confirmAccountSmsRequest, resendSmsCodeRequest } from "api/auth/auth";
import { setFormError } from "utils/setError";
import { AuthCodeInput } from "components/auth-code-input/AuthCodeInput";
import { withParams } from "utils/url";
import { routes } from "routes";
import { AppContext } from "context";
import { USER_QUERY_KEY } from "queries/use-user-query";

export const ConfirmSmsForm = ({
  smsConfirmToken,
  closeModal,
}: {
  smsConfirmToken?: string;
  closeModal?: () => void;
}) => {
  const schema = useMemo(() => {
    return yup.object().shape({
      confirmation_code: yup.string().required("Required"),
    });
  }, []);

  const { handleSubmit, control, setError, clearErrors } = useForm<{
    confirmation_code: string;
  }>({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const queryClient = useQueryClient();
  const history = useHistory();
  const { dispatch } = useContext(AppContext);

  const { sms_confirm_token } = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  const [resendResponse, setResendResponse] = useState<string | undefined>();
  const [formError, setFormErrorMessage] = useState<string | undefined>();
  const [formResponse, setFormResponse] = useState<boolean>(false);

  const resendSmsCodeRequestQuery = useMutation(resendSmsCodeRequest, {
    onError: (e) => {
      setResendResponse(get(e, "response.data.messages.error", ""));
    },
  });

  const confirmAccountSmsQuery = useMutation(confirmAccountSmsRequest, {
    onSuccess: (response) => {
      setFormResponse(response.data?.message);

      if (closeModal) {
        queryClient.invalidateQueries(USER_QUERY_KEY);
      }

      setTimeout(() => {
        if (!closeModal) {
          dispatch({ type: "SET_AUTH_TOKEN", token: response.data?.access! });
          history.push(
            withParams(routes.dashboard, { openPersonalization: "true" })
          );
        } else {
          closeModal();
        }
      }, 2000);
    },
    onError: (e) => {
      setFormErrorMessage(get(e, "response.data.messages.error", "Something went wrong"));
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const onSubmit = (data: any) => {
    setFormErrorMessage("");
    clearErrors();
    confirmAccountSmsQuery.mutate({
      confirmation_code: data.confirmation_code,
      sms_confirm_token: smsConfirmToken || sms_confirm_token,
    });
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center w-full max-w-screen-lg my-auto"
    >
      <div className="w-full">
        <div className="flex justify-center items-center text-sm text-gray-600 mb-3">
          Didn't receive code?
          <Button
            type="button"
            size="exSmall"
            className="ml-2"
            loading={resendSmsCodeRequestQuery.isLoading}
            spinner
            onClick={() => {
              setResendResponse(undefined);
              resendSmsCodeRequestQuery.mutate({
                sms_confirm_token: smsConfirmToken || sms_confirm_token,
              });
            }}
          >
            Resend SMS code
          </Button>
        </div>

        {resendResponse && (
          <div className="text-sm -mt-1 mb-3 text-red-400 text-center">
            {resendResponse}
          </div>
        )}

        <div className="w-full mb-2">
          <Controller
            name="confirmation_code"
            control={control}
            render={({
              field: { onChange, value, name, ref },
              fieldState: { error },
            }) => (
              <AuthCodeInput
                onChange={onChange}
                value={value}
                error={error}
                label="SMS Code"
              />
            )}
          />
        </div>

        {formError && (
          <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded text-center">
            {formError}
          </div>
        )}

        {formResponse && (
          <div className="w-full px-4 py-2 mb-5 text-sm text-green-700 bg-green-100 rounded text-center">
            {formResponse}
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          loading={confirmAccountSmsQuery.isLoading}
          spinner
        >
          Confirm phone number
        </Button>
      </div>
    </form>
  );
};
