import classNames from "classnames";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { AppContext } from "context";
import { useUserQuery } from "queries/use-user-query";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuButton, MenuLink } from "./Menu";
import { routes } from "routes";

export const AccountDropdownMenu = ({
  theme = "light",
}: {
  theme?: "dark" | "light";
}) => {
  const { data: userResponse } = useUserQuery();

  const { t } = useTranslation();

  const { dispatch } = useContext(AppContext);

  const logout = () => dispatch({ type: "SET_AUTH_TOKEN", token: null });

  return (
    <Menu
      content={
        <>
          <MenuLink to={routes.myProfileEditProfile}>{t("My profile")}</MenuLink>
          {/*<MenuLink to={routes.myDonations}>{t("My pledges")}</MenuLink>*/}
          {/*<MenuLink to={routes.myProjects}>{t("My projects")}</MenuLink>*/}
          <MenuLink to={routes.workingGroupsMy}>
            {t("My working Groups")}
          </MenuLink>
          <MenuButton onClick={logout}>{t("Sign out")}</MenuButton>
        </>
      }
    >
      <button
        className={classNames(
          "flex items-center text-sm font-medium focus:outline-none",
          {
            "text-gray-900": theme === "light",
            "text-white": theme === "dark",
          }
        )}
      >
        <span className="sm:max-w-[12rem] max-w-[6rem] truncate">
          {userResponse?.data?.name}
        </span>
        <span className="inline-block ml-1">
          <ChevronDownIcon
            className={classNames("w-5", {
              "text-gray-500": theme === "light",
              "text-white": theme === "dark",
            })}
          />
        </span>
      </button>
    </Menu>
  );
};
