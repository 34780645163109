import { STORAGE_TOKEN_KEY } from "context";
import { CreateApiClient } from "types";

export const createApiClient: CreateApiClient = (
  resolver,
  errorHandler
) => async (options) => {
  try {
    const authToken = localStorage.getItem(STORAGE_TOKEN_KEY);

    const { data, status, statusText, headers } = await resolver({
      ...options,
      url: process.env.REACT_APP_API_URI + options?.url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
        ...options?.headers,
      },
    });

    return { data, status, statusText, headers };
  } catch (error) {
    throw errorHandler(error);
  }
};
