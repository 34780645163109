import React, { forwardRef } from "react";
import ReactPlayer from "react-player";
import { Controller, FieldError } from "react-hook-form";
import { FieldGroup } from "../FieldGroup";

export interface CustomSelectProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  defaultFirst?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
}

export const PlayerField = forwardRef(
  (
    {
      control,
      name,
      disabled,
      rules,
      required,
      optional,
      ...props
    }: CustomSelectProps,
    ref
  ) => {
    return (
      <FieldGroup
        required={required}
        optional={optional}
        name={name}
        {...props}
      >
        {(computedProps) => (
          <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <>
                <input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ""}
                  type="text"
                  {...computedProps}
                />

                {value &&
                  [
                    "youtube",
                    "vimeo",
                    "facebook",
                    "twitch",
                    "dailymotion",
                  ].some((v) => value.includes(v)) && (
                    <div className="mt-3 overflow-hidden">
                      <ReactPlayer
                        url={value}
                        className="w-full"
                        width="100%"
                        height="300px"
                      />
                    </div>
                  )}
              </>
            )}
          />
        )}
      </FieldGroup>
    );
  }
);
