import { useEffect } from "react";
import queryString from "query-string";
import { formatLink } from "components/notifications/NotificationList";
import { useHistory } from "react-router-dom";

export const Reroute = () => {
  const history = useHistory();

  const params = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  useEffect(() => {
    // @ts-ignore:next-line
    history.push(formatLink({ ...params }));
  }, [history, params]);

  return <div />;
};
