import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useInfiniteQuery } from "react-query";

import { getQuizList, QuizData } from "api/quiz";
import { useDebouncedValue } from "hooks/use-debounced-value";
import { useContextService } from "../../../hooks/use-context-service";
// import { useLocation } from "react-router-dom";
import queryString from "query-string";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";

export const LIST_QUERY_KEY = "QUIZ/LIST";

export const initialState = {
  dispatch: (_action: Actions) => {},
  listData: [] as QuizData[],
  debouncedSearchQuery: "",
  isLoading: false,
  isFetching: false,
  hasNextPage: false as boolean | undefined,
  isFetchingNextPage: false as boolean | undefined,
  columnSorting: {},
  handleFetchNextPage: () => {},
};

export type Actions = {
  type: "SET_COLUMN_SORTING";
  columnSorting: {};
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case "SET_COLUMN_SORTING":
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const QuizBrowseContext = createContext<typeof initialState>(
  initialState
);

export interface QuizListFields {
  searchQuery: string;
  page: number;
  ordering: string;
  categories: string[];
}

export const QuizBrowseContextProvider: FC = ({ children }) => {
  // const { pathname } = useLocation();

  const defaultValues = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });
  if (defaultValues.categories && !Array.isArray(defaultValues.categories)) {
    defaultValues.categories = [defaultValues.categories];
  }
  if (defaultValues.tags && !Array.isArray(defaultValues.tags)) {
    defaultValues.tags = [defaultValues.tags];
  }

  const methods = useForm<QuizListFields>({
    defaultValues: {
      searchQuery: "",
      page: 1,
      ordering: "-created_at",
      ...defaultValues,
    },
  });

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { setValue } = methods;
  const [searchQuery, page, ordering, categories] = methods.watch([
    "searchQuery",
    "page",
    "ordering",
    "categories",
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(
      // @ts-ignore:next-line
      omitBy(
        {
          categories,
        },
        isEmpty
      )
    ).toString();

    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  }, [categories]);

  const debouncedSearchQuery = useDebouncedValue<string>(searchQuery, 750);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === "development") {
      console.info("Dispatch Action", {
        ...action,
        context: LIST_QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  const getQuizListService = useContextService(getQuizList);

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    LIST_QUERY_KEY,
    (params) => {
      return getQuizListService({
        searchQuery: debouncedSearchQuery,
        ordering,
        categories,
        page,
        perPage: 9,
      });
    },
    {
      suspense: false,
      retry: false,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination.currentPage! <
        lastPage?.data?.pagination.total!,
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (page && page > 1) {
      fetchNextPage();
    }
    // if (page === 1) {
    //   remove();
    //   refetch();
    // }
  }, [page, fetchNextPage, remove]); // eslint-disable-line

  useEffect(() => {
    setValue("page", 1);
    setTimeout(() => {
      remove();
      refetch();
    }, 0);
  }, [debouncedSearchQuery, ordering, categories, setValue, refetch, remove]);

  const listData = useMemo(() => {
    return (
      data?.pages
        .map((quizItem) =>
          (quizItem?.data?.data || []).map((item) => ({
            ...item,
          }))
        )
        .flat() || []
    );
  }, [data]);

  const handleFetchNextPage = useCallback(() => {
    setValue("page", page + 1);
  }, [setValue, page]);

  return (
    <FormProvider {...methods}>
      <QuizBrowseContext.Provider
        value={{
          ...state,
          dispatch,
          listData,
          isFetching,
          isLoading,
          hasNextPage,
          debouncedSearchQuery,
          isFetchingNextPage,
          handleFetchNextPage,
        }}
      >
        {children}
      </QuizBrowseContext.Provider>
    </FormProvider>
  );
};
