import { Context } from "types";
import { withParams } from "utils/url";

export const getUserAccount = ({ apiClient }: Context) => () =>
  apiClient<{
    id: number;
    email: string;
    name: string;
    avatar: string;
    affiliation: string;
    date_joined: string;
    phone_confirmed?: boolean;
    email_confirmed?: boolean;
    is_password_set?: boolean;
    phone_number?: string;
    oauth_associations: {
      id: number
      provider: string
      created: string
    }[];
  }>({
    url: "/me",
    method: "GET",
  });

export const getUserAccountById = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient<{
    data: {
      email: string;
      name: string;
      profile_background: string;
      avatar: string;
      affiliation: string;
      date_joined: string;
      title: string;
      bio: string;
      profile_is_private: boolean;
      created_projects: { slug: string; title: string }[];
      backed_projects: { slug: string; title: string }[];
      created_working_groups: { slug: string; title: string }[];
    };
  }>({
    url: withParams("/profile/:id", { id }),
    method: "GET",
  });

export type Notification = {
  title: string;
  message: string;
  in_app_route: string;
  content_type: string;
  object_slug: string;
  read_at: string;
  created_at: string;
};

export const getUserNotifications = ({ apiClient }: Context) => () =>
  apiClient<{
    data: Notification[];
  }>({
    url: "/me/notifications",
    method: "GET",
  });

export const confirmUser = ({ apiClient }: Context) => (data: {
  token: string;
}) =>
  apiClient<{ access: string }>({
    url: "/confirm-user",
    method: "POST",
    data,
    headers: {
      Authorization: null,
    },
  });

export const confirmAffiliation = ({ apiClient }: Context) => (data: {
  token: string;
}) =>
  apiClient<{ access: string }>({
    url: "/me/confirm-university-email",
    method: "POST",
    data,
    headers: {
      Authorization: null,
    },
  });

export const emailConfirmStart = ({ apiClient }: Context) => () =>
  apiClient<{ access: string }>({
    url: "/me/email-confirm-start",
    method: "POST",
    data: {},
  });

export const phoneConfirmStart = ({ apiClient }: Context) => (data: {
  phone_number: string;
  current_password: string;
}) =>
  apiClient<{
    new_token: string;
    message: string;
  }>({
    url: "/me/phone-confirm-start",
    method: "POST",
    data,
  });
