import React, { useState } from "react";

import { Contact } from "./Contact";
// import { FAQ } from "./FAQ";
import { FooterHome } from "components/footer/FooterHome";
import { HeroGrant } from "./grant/HeroGrant";
import { TopBar } from "components/topbar/TopBar";
import { SubscribeForm } from "components/SubscribeForm/SubscribeForm";

import { ReactComponent as IconDollar } from "assets/icons/dollar-minimalistic.svg";
import { ReactComponent as IconComments } from "assets/icons/comments.svg";
import { ReactComponent as IconLike } from "assets/icons/like.svg";
import { ReactComponent as IconIdea } from "assets/icons/grant-idea.svg";
import { ReactComponent as IconTech } from "assets/icons/grant-tech.svg";
import { ReactComponent as IconTime } from "assets/icons/grant-time.svg";
import { ReactComponent as IconMd } from "assets/icons/grant-md.svg";
import { ReactComponent as IconPersonal } from "assets/icons/grant-personal.svg";
import { ReactComponent as IconRefine } from "assets/icons/grant-refine.svg";
import { ReactComponent as IconCrowdfund } from "assets/icons/grant-crowfund.svg";
import { ReactComponent as IconExpand } from "assets/icons/grant-expand.svg";
import { ReactComponent as IconResearchers } from "assets/icons/grant-researchers.svg";
import { ReactComponent as IconGenerate } from "assets/icons/grant-generate.svg";
import { ReactComponent as IconLab } from "assets/icons/grant-lab.svg";
import GrantCooperationImage from "../../assets/images/grant-cooperation.png";
import { Button } from "../button/Button";
import { getTextButtonClassNames } from "../TextButton";
import { NavLink } from "react-router-dom";

export const LandingGrant = () => {
  return (
    <div className="font-nunito">
      <div className="w-full relative z-50">
        <TopBar />
      </div>
      <div className="relative z-10">
        <HeroGrant />

        <div className="">
          <div className="w-full max-w-screen-xl py-12 md:py-16 px-4 mx-auto md:px-8 relative z-10">
            <div className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
              What would you do with $25k to spend <br />
              on your own research?
            </div>

            <div className="mt-8 md:mt-12 flex flex-wrap justify-center">
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconIdea className="h-28" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Maybe you have an idea that needs a proof of concept study?
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconTime className="h-28" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Maybe you have the team, but not the time to execute your
                  research?
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconTech className="h-28" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Maybe you have a lab, but lack access to datasets or
                  technology?
                </div>
              </div>
            </div>

            <div className="text-center text-lg text-gray-600 max-w-screen-lg mx-auto pt-4 md:pt-8">
              When you’re early in your career in medical research, it’s
              expected that you’ll have more ideas than you will have resources
              to pursue them, but{" "}
              <b>
                how are you supposed to compete for grant money with less
                experience, less data, and fewer resources?
              </b>
            </div>
          </div>
        </div>

        <div className="">
          <div className="blue-gradient py-10">
            <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
              <h2 className="text-white font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                Let’s Get Proof is here to help you get the funds and the <br />
                feedback you need to move your medical research forward.
              </h2>
            </div>
          </div>
        </div>

        <div className="">
          <div className="py-10 md:my-12">
            <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
              <div className="flex flex-wrap flex-col md:flex-row justify-between items-center">
                <div className="w-full md:w-1/2">
                  <img
                    src={GrantCooperationImage}
                    alt=""
                    className="w-full border-b-2 border-red-500"
                  />
                </div>

                <div className="w-full text-center md:text-left pt-8 md:pt-0 md:w-2/5">
                  <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl font-semibold">
                    Why a grant/crowdfunding hybrid prize?
                  </h2>

                  <div className="pt-4 md:pt-8 text-lg text-gray-600">
                    This will democratize the granting process by: <br />
                    (1) making the competition publicly facing so that everyone can see what proposals are submitted versus awarded; <br />
                    (2) giving the public (including people with lived experience) a chance to voice their opinions and support; and <br />
                    (3) giving all researchers, regardless of their profile or institution, the opportunity to raise funds for their project from supporters while still being in contention for the prize.
                  </div>
                  <div className="pt-4 text-lg text-gray-600">
                    What this means is that while only one person or team will win the prize, everyone will have the chance to walk away from the competition with whatever funds they raise for their project! So for example, if a researcher successfully crowdfunds $10,000 on the platform and wins the $25,000 prize, they will in fact walk away with $35,000 for their research!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="contact-reverse-bg-gradient py-10">
            <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
              <h2 className="text-white font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                Our first-ever Catalyst Grant Award will be given to <br />
                a researcher with a compelling project who <br />
                leverages the features on Let’s Get Proof to <br />
                generate momentum for their work.
              </h2>

              <div className="py-12 font-nunito text-white text-center font-bold text-xl lg:text-2xl">
                Eligibility criteria <br />
                Any university-affiliated researcher or health professional, including trainees (*see &nbsp;
                  <NavLink
                    to={"/lgp/prize-terms-and-conditions"}
                  >
                    Terms & Conditions
                  </NavLink>
                ) is free to submit a proposal, but to become eligible you must achieve the following community engagement metrics:
              </div>


              <div className="flex flex-wrap flex-col items-center md:items-start md:flex-row justify-center">
                <div className="w-full md:w-1/3 p-5 relative flex flex-col items-center">
                  <IconDollar className="h-20 text-white" />

                  <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                    $1,000 CAD crowdfunded
                  </div>
                </div>
                <div className="w-full md:w-1/3 p-5 relative flex flex-col items-center">
                  <IconComments className="h-20 text-white" />
                  <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                    10 comments from unique users
                  </div>
                </div>
                <div className="w-full md:w-1/3 p-5 relative flex flex-col items-center">
                  <IconLike className="h-20 text-white" />

                  <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                    25 likes
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="w-full max-w-screen-xl py-12 md:py-16 px-4 mx-auto md:px-8 relative z-10">
            <div className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
              We offer the only platform where researchers at all levels of
              their <br />
              careers can collaborate with patients, donors, and each other to:
            </div>

            <div className="mt-8 md:mt-12 flex flex-wrap justify-center">
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconRefine className="h-24" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Refine and improve your research practice
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconCrowdfund className="h-24" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Crowdfund to finance your own projects
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconExpand className="h-24" />

                <div className="font-nunito text-blue-550 text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Expand your opportunity to do truly impactful work
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="propose-gradient">
          <div className="w-full max-w-screen-xl py-12 md:py-16 px-4 mx-auto md:px-8 relative z-10">
            <div className="text-white font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
              Who is able to propose and lead a medical research project that:
            </div>

            <div className="mt-8 md:mt-12 flex flex-wrap justify-center">
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconResearchers className="h-24" />

                <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Engages researchers, patients, and donors in collaborative
                  discussion
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconGenerate className="h-24" />

                <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Generates crowdfunding pledges to cover some of your needs
                </div>
              </div>
              <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
                <IconLab className="h-24" />

                <div className="font-nunito text-white text-center font-bold text-xl lg:text-2xl pt-4 pb-4 md:pt-8">
                  Passes our Scientific Review Committee standards for
                  excellence
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="pt-12 pb-12 md:pt-20">
            <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
              <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                Timeline
              </h2>

              <div className="md:mt-4 flex flex-wrap flex-col items-center md:items-start md:flex-row justify-center text-lg md:text-xl text-gray-650">
                <div className="w-full md:w-1/3 p-5 relative flex flex-col">
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">February 5 - Prize Announcement</div>
                  </div>
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">
                      March 1 - Start of Competition (researchers can submit
                      proposals on a rolling basis)
                    </div>
                  </div>
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">
                      May 31 - Deadline to Reach Eligibility Targets
                    </div>
                  </div>
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">
                      June 15 - Award Announced and Feedback Provided
                    </div>
                  </div>
                </div>
                {/*<div className="w-full md:w-1/3 p-5 relative flex flex-col items-center">
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">Approved copy will go here</div>
                  </div>
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">Approved copy will go here</div>
                  </div>
                  <div className="flex pb-2">
                    <div className="w-2 h-2 bg-red-500 rounded-full mt-2 mr-2 relative top-0.5" />
                    <div className="flex-1">Approved copy will go here</div>
                  </div>
                </div>*/}
              </div>

              <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold pt-8">
                Read Prize Terms & Conditions for more information
              </h2>

              <div className="pt-8 flex justify-center">
                <NavLink
                  to={"/lgp/prize-terms-and-conditions"}
                >
                  <Button
                    type="button"
                    size="large"
                    rounded="rounded-full"
                    variant="red"
                  >
                    TERMS & CONDITIONS
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="">
          <div className="mt-8 mb-8 md:mb-24">
            <div className="relative mx-auto max-w-md md:max-w-7xl">
              <div className="mx-2 px-4 sm:px-6 lg:px-8  border-2 border-red-500 py-8">
              <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                Let’s Get Proof solves these problems with our <br />
                innovative, inclusive, and exciting approach to <br />
                medical research funding.
              </h2>

              <div className="text-center text-lg md:text-xl text-gray-650 max-w-3xl mt-8 mx-auto">
                Subscribe now for more information about how we engage students
                and early-career researchers to increase their opportunity and
                their potential for impact!
              </div>

              <div className="mx-auto max-w-3xl mt-5">
                <SubscribeForm />
              </div>

              <div className="text-gray-650 pt-5 text-center">
                Let’s Get Proof will never sell or share your contact
                information without your permission.
              </div>
            </div>
            </div>
          </div>
        </div>*/}
        <FooterHome />
      </div>
    </div>
  );
};
