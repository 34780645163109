import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useInfiniteQuery } from "react-query";

import { getWorkingGroupsList } from "api/workingGroups";
import { useDebouncedValue } from "hooks/use-debounced-value";
import { WorkingGroupData } from "api/workingGroups";
import { useContextService } from "../../../hooks/use-context-service";

export const LIST_QUERY_KEY = "WORKING_GROUP_BROWSE/LIST";

export const initialState = {
  dispatch: (_action: Actions) => {},
  listData: [] as WorkingGroupData[],
  debouncedSearchQuery: "",
  isLoading: false,
  isFetching: false,
  hasNextPage: false as boolean | undefined,
  isFetchingNextPage: false as boolean | undefined,
  columnSorting: {},
  handleFetchNextPage: () => {},
};

export type Actions = {
  type: "SET_COLUMN_SORTING";
  columnSorting: {};
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case "SET_COLUMN_SORTING":
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const WorkingGroupBrowseContext = createContext<typeof initialState>(
  initialState
);

interface WorkingGroupListFilters {}

export interface WorkingGroupListFields {
  searchQuery: string;
  page: number;
  filters: WorkingGroupListFilters;
  ordering: string;
  tags: string[];
}

export const WorkingGroupBrowseContextProvider: FC = ({ children }) => {
  const methods = useForm<WorkingGroupListFields>({
    defaultValues: {
      searchQuery: "",
      page: 1,
      ordering: "-created_at",
    },
  });

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { setValue } = methods;
  const [searchQuery, page, ordering, tags] = methods.watch([
    "searchQuery",
    "page",
    "ordering",
    "tags",
  ]);

  const debouncedSearchQuery = useDebouncedValue<string>(searchQuery, 750);

  const dispatch = useCallback((action: Actions) => {
    if (process.env.NODE_ENV === "development") {
      console.info("Dispatch Action", {
        ...action,
        context: LIST_QUERY_KEY,
      });
    }

    dispatchAction(action);
  }, []);

  const getWorkingGroupsListService = useContextService(getWorkingGroupsList);

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
    remove,
  } = useInfiniteQuery(
    LIST_QUERY_KEY,
    (params) => {
      return getWorkingGroupsListService({
        searchQuery: debouncedSearchQuery,
        ordering,
        tags,
        page,
        perPage: 9,
      });
    },
    {
      suspense: false,
      retry: false,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination.currentPage! <
        lastPage?.data?.pagination.total!,
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (page && page > 1) {
      fetchNextPage();
    }
    // if (page === 1) {
    //   remove();
    //   refetch();
    // }
  }, [page, fetchNextPage, remove]); // eslint-disable-line

  useEffect(() => {
    setValue("page", 1);
    setTimeout(() => {
      remove();
      refetch();
    }, 0);
  }, [debouncedSearchQuery, ordering, tags, setValue, refetch, remove]);

  const listData = useMemo(() => {
    return (
      data?.pages
        .map((groupItem) =>
          (groupItem?.data?.data || []).map((item) => ({
            ...item,
          }))
        )
        .flat() || []
    );
  }, [data]);

  const handleFetchNextPage = useCallback(() => {
    setValue("page", page + 1);
  }, [setValue, page]);

  return (
    <FormProvider {...methods}>
      <WorkingGroupBrowseContext.Provider
        value={{
          ...state,
          dispatch,
          listData,
          isFetching,
          isLoading,
          hasNextPage,
          debouncedSearchQuery,
          isFetchingNextPage,
          handleFetchNextPage,
        }}
      >
        {children}
      </WorkingGroupBrowseContext.Provider>
    </FormProvider>
  );
};
