import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

import { Modal } from "components/modal/Modal";
import { Button } from "components/button/Button";
import { useModal } from "../../hooks/use-modal";

export const AuthenticatorInfo = () => {
  const { modalVisible, openModal, closeModal } = useModal();

  return (
    <div>
      <button
        onClick={openModal}
        type="button"
        className="flex items-center justify-center mx-auto hover:text-violet-500 focus:outline-none text-gray-500 text-sm"
      >
        <QuestionMarkCircleIcon className="mx-1 w-4" />
      </button>

      <Modal visible={modalVisible} onClose={closeModal}>
        <div className="p-6">
          <div className="mb-2 text-gray-900 uppercase text-center text-sm tracking-wide">
            How it works?
          </div>

          <div className="text-sm text-gray-600">
            If you scanned the QR code with an iPhone camera app, try looking
            for Let’s Get Proof in the “passwords” manager in iOs.
          </div>

          <div className="text-gray-900 text-sm">
            <div className="flex border-b border-gray-200 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                1
              </div>
              <div className="pt-1">
                Open the authenticator app that you scanned the code with during
                MFA setup.
              </div>
            </div>
            <div className="flex items-center border-b border-gray-200 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                2
              </div>
              <div className="pt-1">Find Let’s Get Proof in the list</div>
            </div>

            <div className="flex py-6 items-center">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                3
              </div>
              <div>Enter the code</div>
            </div>
          </div>

          <div className="mt-4">
            <Button onClick={closeModal} type="button" className="w-full">
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
