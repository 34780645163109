import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import * as z from "zod";
import { get } from "lodash";

import { Button } from "components/button/Button";

import { putQuiz, QuizQuestion } from "api/quiz";
import { setFormError } from "utils/setError";
import { RadioField } from "components/radio-field/RadioField";
import { CheckboxesField } from "components/checkboxes-field/CheckboxesField";
import { SortingField } from "components/sorting-field/SortingField";
import { useContextService } from "hooks/use-context-service";

import classNames from "classnames";
import { routes } from "../../../routes";
import { NavLink } from "react-router-dom";

export const QuizForm = ({
  data,
  onSubmit,
  onBack,
  step,
  quizId,
  setFormResultMessage,
}: {
  data: QuizQuestion;
  onSubmit: () => void;
  onBack: () => void;
  step: number;
  quizId: number;
  setFormResultMessage: (arg: any) => void;
}) => {
  const useFormSchema = () =>
    z.object({
      [`${data.id}`]:
        data.type === "DRAG_AND_DROP"
          ? z.number().array().nonempty("Required")
          : data.multiple_choices
          ? z.string().array().nonempty("Required")
          : z.string().nonempty("Required"),
      // first: z.string().nonempty("Required"),
      // second: z.string().nonempty("Required"),
    });

  // type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

  const { control, handleSubmit, formState, setError, watch } = useForm({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      [`${data.id}`]:
        data.type === "DRAG_AND_DROP"
          ? data.answer_options
              .sort((a, b) => a.ordered_by_user - b.ordered_by_user)
              .map(({ id }) => id)
          : data.multiple_choices
          ? data.answer_options
              .filter(({ selected_by_user }) => selected_by_user)
              .map(({ id }) => id.toString())
          : `${get(
              data.answer_options.find(
                ({ selected_by_user }) => selected_by_user
              ),
              "id",
              ""
            )}`,
    },
  });

  const formValues = watch();
  const [isIframeScrolling, setIsIframeScrolling] = useState(false);

  const quizService = useContextService(putQuiz);

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();

  const [isDisabled, setIsDisabled] = useState(false);
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const quizSaveQuery = useMutation(
    (result: any) => quizService(quizId!, result),
    {
      onSuccess: (response) => {
        if (!response?.data?.completed) {
          onSubmit();
          return;
        }

        if (response?.data?.quiz_passed) {
          setFormResultMessage({
            message: `Quiz passed. ${response?.data?.percent_correct_answers}% сorrect answers.`,
            type: "success",
            certificate_url: response?.data?.certificate,
            retry: false,
          });
        } else {
          setFormResultMessage({
            message: `Quiz not passed. ${response?.data?.percent_correct_answers}% сorrect answers.`,
            type: "error",
            retry: response?.data?.remaining_attempts! > 0,
          });
        }

        setIsDisabled(true);
      },
      onError: (e) => {
        setFormError(
          get(e, "response.data", get(e, "data")),
          setFormErrorMessage,
          setError
        );
      },
    }
  );

  const handleFormSubmit = async (values: any) => {
    const result = {
      question: data.id,
      chosen_answers:
        data.type === "DRAG_AND_DROP"
          ? values[`${data.id}`]
          : data.multiple_choices
          ? values[`${data.id}`].map((k: string) => +k)
          : [+values[`${data.id}`]],
    };

    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    quizSaveQuery.mutate(result);
  };

  return (
    <div className="">
      <div className="" style={{ maxWidth: "767px" }}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="mt-8">
          <div>
            <div className="w-full mb-5">
              <div
                className="wysiwyg-editor mb-4"
                dangerouslySetInnerHTML={{ __html: data.text }}
              />
              {data.video_url && (
                <div className="mb-4 iframe-overscroll">
                  <iframe
                    width="767"
                    height="480"
                    src={
                      data.video_url.includes("youtube")
                        ? `https://www.youtube.com/embed/${
                            data.video_url.match(/v=(.*)/)![1]
                          }`
                        : data.video_url
                    }
                  />
                </div>
              )}
              {data.iframe_url && (
                <div
                  className="mb-4 iframe-overscroll"
                  onMouseEnter={() => {
                    setIsIframeScrolling(true);
                  }}
                  onMouseLeave={() => {
                    setIsIframeScrolling(false);
                  }}
                >
                  <iframe width="767" height="480" src={data.iframe_url} />
                </div>
              )}

              {data.type === "DRAG_AND_DROP" ? (
                <SortingField
                  disabled={isDisabled}
                  label={""}
                  options={data.answer_options.map(({ id, text }) => ({
                    value: id,
                    label: text,
                  }))}
                  // @ts-ignore:next-line
                  error={formState.errors[`${data.id}`]}
                  control={control}
                  name={`${data.id}`}
                />
              ) : data.multiple_choices ? (
                <CheckboxesField
                  disabled={isDisabled}
                  label={""}
                  options={data.answer_options.map(({ id, text }) => ({
                    value: `${id}`,
                    label: text,
                  }))}
                  // @ts-ignore:next-line
                  error={formState.errors[`${data.id}`]}
                  control={control}
                  name={`${data.id}`}
                />
              ) : (
                <RadioField
                  disabled={isDisabled}
                  label={""}
                  options={data.answer_options.map(({ id, text }) => ({
                    value: `${id}`,
                    label: text,
                  }))}
                  // @ts-ignore:next-line
                  error={formState.errors[`${data.id}`]}
                  control={control}
                  name={`${data.id}`}
                />
              )}
            </div>
          </div>

          {formErrorMessage && (
            <div className="w-full mt-5 mb-3 py-2 px-4 rounded bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full mt-5 mb-3 py-2 px-4 rounded bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <div>
            <div className="flex mt-2">
              <Button
                type="button"
                className="w-32 mr-2"
                variant="white"
                disabled={step === 0}
                onClick={onBack}
              >
                Back
              </Button>
              <Button
                type="button"
                onClick={handleSubmit(handleFormSubmit)}
                className="w-32"
                disabled={quizSaveQuery.isLoading || isDisabled}
                loading={quizSaveQuery.isLoading}
                spinner
              >
                Next
              </Button>
            </div>
          </div>
        </form>
      </div>

      <div
        className={classNames(
          "iframe-helper bg-blue-100 text-blue-700 font-medium",
          { open: isIframeScrolling }
        )}
      >
        <span>
          Scrolling in iframe, Scroll outside to scroll page.
          <br />
          Click inside to use controls: up, down keys scroll change slices;
          left, right change sequences.
        </span>
      </div>
    </div>
  );
};
