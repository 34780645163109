import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";

import { Button } from "components/button/Button";

import { setFormError } from "utils/setError";
import { useContextService } from "hooks/use-context-service";
import { phoneConfirmStart } from "api/account";
import { PhoneInputField } from "components/phone-input-field/PhoneInputField";
import { TextField } from "components/text-field/TextField";
import { ConfirmSmsForm } from "./ConfirmSmsForm";

const useFormSchema = () =>
  z.object({
    phone_number: z.string().nonempty("Required"),
    current_password: z.string().nonempty("Required"),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const PhoneNumberForm = ({
  phone_number,
  closeModal,
}: {
  phone_number?: string;
  closeModal?: () => void;
}) => {
  const {
    handleSubmit,
    setError,
    control,
    register,
    formState,
    clearErrors,
  } = useForm<FormSchema>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),

    // @ts-ignore
    defaultValues: {
      phone_number: phone_number || "",
      current_password: "",
    },
  });

  const [sms_confirm_token, setSMSConfirmToken] = useState<
    string | undefined
  >();

  const phoneConfirmStartMutation = useMutation(
    useContextService(phoneConfirmStart),
    {
      onSuccess: (response) => {
        console.log(response?.data?.new_token);
        setFormResponse(response?.data?.message);

        setTimeout(() => {
          setSMSConfirmToken(response?.data?.new_token!);
        }, 2000);
      },
      onError: (e) => {
        setFormErrorMessage(get(e, "response.data.messages.error", "Something went wrong"));
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const [formError, setFormErrorMessage] = useState<string | undefined>();
  const [formResponse, setFormResponse] = useState<string | undefined>();

  const onSubmit = (data: FormSchema) => {
    clearErrors();
    setFormErrorMessage("");
    phoneConfirmStartMutation.mutate({
      phone_number: data.phone_number,
      current_password: data.current_password,
    });
  };

  return (
    <div>
      {!sms_confirm_token && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col items-center w-full max-w-screen-lg my-auto"
        >
          <h2 className="text-xl font-medium text-black mb-2 text-center">
            Confirm phone number
          </h2>

          <div className="w-full">
            <div className="w-full">
              <Controller
                name="phone_number"
                control={control}
                render={({
                  field: { onChange, value, name, ref },
                  fieldState: { error },
                }) => (
                  <PhoneInputField
                    onChange={onChange}
                    value={value}
                    error={error}
                    label="Phone number"
                  />
                )}
              />
            </div>
            <div className="w-full mb-5">
              <TextField
                label="Password"
                type="password"
                required
                placeholder="Password"
                error={formState.errors?.current_password}
                {...register("current_password", { required: true })}
              />
            </div>

            {formError && (
              <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded text-center">
                {formError}
              </div>
            )}

            {formResponse && (
              <div className="w-full px-4 py-2 mb-5 text-sm text-green-700 bg-green-100 rounded text-center">
                {formResponse}
              </div>
            )}

            <Button
              type="submit"
              className="w-full"
              loading={phoneConfirmStartMutation.isLoading}
              disabled={phoneConfirmStartMutation.isLoading}
              spinner
            >
              Confirm phone number
            </Button>
          </div>
        </form>
      )}

      {sms_confirm_token && (
        <div className="w-96 p-8 mx-auto text-center">
          <ConfirmSmsForm
            smsConfirmToken={sms_confirm_token}
            closeModal={closeModal}
          />
        </div>
      )}
    </div>
  );
};
