import React from "react";
import classNames from "classnames";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { getUserRequest } from "api/user/user";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { AffiliationsForm } from "./AffiliationsForm";
import { ExclamationIcon, CheckIcon } from "@heroicons/react/outline";
import { Button } from "../../../components/button/Button";
import { affiliationSetupRequest } from "../../../api/auth/auth";
import { USER_QUERY_KEY } from "../../../queries/use-user-query";
import { useContextService } from "../../../hooks/use-context-service";
import { showNotification as showNotificationService } from "../../../services/notification";
import {Helmet} from "react-helmet";

export const Affiliations = () => {
  const { data, isLoading } = useQuery("user", () => getUserRequest(), {
    suspense: false,
  });

  const queryClient = useQueryClient();
  const showNotification = useContextService(showNotificationService);

  const affiliationSetupQuery = useMutation(affiliationSetupRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      showNotification({
        title: "Success",
        message: "Please check your email (including your spam folder)",
      });
    },
    onError: (e) => {
      showNotification({
        title: "Error",
        message: "Something went wrong",
        variant: "ERROR",
      });
    },
  });

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <div className="flex flex-wrap justify-between">
      <Helmet>
        <title>Let’s Get Proof | My Profile | Affiliations</title>
      </Helmet>
      <div className="w-full md:w-1/2 md:pr-4 mb-8">
        <div
          className={classNames(
            "w-full px-4 py-2 font-medium border mb-4 rounded",
            {
              "border-yellow-400 text-yellow-800 bg-yellow-100":
                data?.data?.affiliation_status === "not_approved",
              "border-green-400 text-green-800 bg-green-100":
                data?.data?.affiliation_status === "approved",
            }
          )}
        >
          <h3 className="font-semibold text-lg text-gray-900">
            Affiliation status
          </h3>
          {data?.data?.affiliation_status === "not_approved" &&
            !data?.data?.university_email_confirmed &&
            !data?.data?.university_affiliate_email && (
              <p>Please, enter an affiliation email</p>
            )}
          {data?.data?.affiliation_status === "not_approved" &&
            !data?.data?.university_email_confirmed &&
            data?.data?.university_affiliate_email && (
              <p>Please, confirm the affiliation email</p>
            )}
          {data?.data?.affiliation_status === "not_approved" &&
            data?.data?.university_email_confirmed && <p>Pending moderation</p>}
          {data?.data?.affiliation_status === "approved" && <p>Approved</p>}
        </div>

        <AffiliationsForm user={data?.data} />
      </div>
      <div className="w-full md:w-1/2 md:pl-4">
        {data?.data?.university_affiliate_email && (
          <div>
            <h2
              className={classNames(
                "flex items-center text-lg font-medium mb-2",
                {
                  "text-yellow-500": !data?.data?.university_email_confirmed,
                  "text-green-500": data?.data?.university_email_confirmed,
                }
              )}
            >
              {!data?.data?.university_email_confirmed ? (
                <ExclamationIcon className="w-5 mr-2" />
              ) : (
                <CheckIcon className="w-5 mr-2" />
              )}
              Confirm affiliation email
            </h2>
            <div>
              {!data?.data?.university_email_confirmed && (
                <Button
                  type="button"
                  spinner
                  disabled={
                    affiliationSetupQuery.isSuccess ||
                    affiliationSetupQuery.isLoading
                  }
                  loading={affiliationSetupQuery.isLoading}
                  onClick={() => {
                    affiliationSetupQuery.mutate({
                      affiliation: data?.data?.affiliation,
                      university_affiliate_email:
                        data?.data?.university_affiliate_email,
                      university_profile_page_url:
                        data?.data?.university_profile_page_url,
                    });
                  }}
                >
                  Resend email to {data?.data?.university_affiliate_email}
                </Button>
              )}

              {affiliationSetupQuery.isSuccess && (
                <div className="mt-4 font-medium text-green-600">
                  Success. Please check your email (including your spam folder)
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
