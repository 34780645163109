import React, { forwardRef } from "react";
import { Controller, FieldError } from "react-hook-form";
import ReactQuill, { Quill } from "react-quill";
import MagicUrl from 'quill-magic-url';

import { FieldGroup } from "../FieldGroup";
import "react-quill/dist/quill.snow.css";

Quill.register('modules/magicUrl', MagicUrl);


export interface CustomSelectProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  defaultFirst?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  isLoading?: boolean;
  size?: "normal" | "large" | "small" | "xs";
  minWidth?: number;
  inputlength?: number;
  max?: number;
}

export const RichEditorField = forwardRef(
  (
    {
      control,
      name,
      disabled,
      rules,
      required,
      optional,
      placeholder,
      ...props
    }: CustomSelectProps,
    ref
  ) => {
    return (
      <FieldGroup
        required={required}
        optional={optional}
        name={name}
        {...props}
      >
        {() => (
          <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <ReactQuill
                formats={[
                  "bold",
                  "italic",
                  "header",
                  "underline",
                  "strike",
                  "list",
                  "blockquote",
                  "link",
                ]}
                modules={{
                  magicUrl: true,
                  toolbar: {
                    container: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike", "link"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["clean"],
                    ],
                  },
                }}
                placeholder={placeholder}
                theme="snow"
                onBlur={onBlur}
                value={value || ""}
                onChange={onChange}
              />
            )}
          />
        )}
      </FieldGroup>
    );
  }
);
