import React, { useMemo, useState, Dispatch, SetStateAction } from "react";
import map from "lodash/map";
import { NavLink } from "react-router-dom";

import { Article } from "../../../api/static-pages";

const perPage = 10;

export const PaginationSection = ({
  articles,
  articles_in_line,
  first_alone,
  setIsIframeScrolling,
  setIsIframeClicked,
}: {
  articles: Article[];
  articles_in_line: number;
  first_alone: boolean;
  setIsIframeScrolling: Dispatch<SetStateAction<boolean>>;
  setIsIframeClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = useMemo(() => Math.ceil(articles.length / perPage), [
    articles,
  ]);

  return (
    <div>
      <div className="flex flex-wrap">
        {map(
          articles.slice(perPage * currentPage, perPage * (currentPage + 1)),
          (article, i) => (
            <div
              key={i}
              className={
                first_alone && i === 0
                  ? "pb-8 w-full"
                  : articles_in_line === 2
                  ? "p-8 w-1/2"
                  : "pb-8 w-full"
              }
            >
              <NavLink to={`/pages/aspect-in-stroke/${article.slug}`}>
                <h2 className="text-2xl">{article.title}</h2>
              </NavLink>

              {article.image && (
                <div className="mb-4 flex justify-center">
                  <img src={article.image} alt="" className="mx-auto" />
                </div>
              )}

              {article.iframe && (
                <div
                  className="mb-4 flex justify-center iframe-overscroll"
                  onMouseEnter={() => {
                    setIsIframeScrolling(true);
                  }}
                  onMouseLeave={() => {
                    setIsIframeScrolling(false);
                    // setIsIframeClicked(false);
                  }}
                  onMouseDown={() => { setIsIframeClicked(true); console.log('MoseDown'); }}
                >
                  <iframe width="100%" height="480" src={article.iframe}onMouseDown={() => { setIsIframeClicked(true); console.log('MoseDown'); }} />
                </div>
              )}

              {article.description && (
                <div className="text-sm text-gray-600 mb-4">
                  <div
                    className="wysiwyg-editor"
                    dangerouslySetInnerHTML={{ __html: article.description }}
                  />
                </div>
              )}

              {article.hits > 0 && (
                <div className="text-sm text-gray-600 pt-4">
                  Hits: {article.hits}
                </div>
              )}
            </div>
          )
        )}
      </div>

      <div className="pt-4">
        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
          <div className="hidden md:-mt-px md:flex">
            {map([...Array(totalPages)], (_, index) => (
              <button
                type="button"
                key={index}
                className={
                  index === currentPage
                    ? "inline-flex items-center border-t-2 border-indigo-500 px-4 pt-4 text-sm font-medium text-indigo-600"
                    : "inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                }
                onClick={() => {
                  setCurrentPage(index);
                }}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};
