import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useLocation } from "react-router-dom";
import { useMutation } from "react-query";
import { z } from "zod";
import { get } from "lodash";
import { zodResolver } from "@hookform/resolvers/zod";
import { pipe } from "fp-ts/lib/function";

import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";

import {
  forgotPasswordValidateTokenRequest,
  forgotPasswordConfirmRequest,
} from "api/auth/auth";

import { setFormError } from "utils/setError";
import { useStringRequired } from "hooks/use-validation-input";

import { ReactComponent as IconLogo } from "assets/icons/logo.svg";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

const routes = require("routes/routes.json");

const useFormSchema = () =>
  z
    .object({
      password: pipe(
        z
          .string()
          .min(
            8,
            "This password is too short. It must contain at least 8 characters."
          ),
        useStringRequired()
      ),
      confirmPassword: pipe(z.string(), useStringRequired()),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ["confirmPassword"],
    });

export const ForgotPasswordConfirm = () => {
  const params = new URLSearchParams(useLocation()?.search);

  const { register, handleSubmit, formState, setError } = useForm({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),
  });

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const forgotPasswordValidateTokenQuery = useMutation(
    forgotPasswordValidateTokenRequest
  );
  const forgotPasswordConfirmQuery = useMutation(forgotPasswordConfirmRequest, {
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const onSubmit = (data: any) => {
    forgotPasswordConfirmQuery.mutate({
      token: params.get("token")!,
      password: data.password,
    });
  };

  useEffect(() => {
    forgotPasswordValidateTokenQuery.mutate({ token: params.get("token")! });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div className="px-4 my-auto w-96 sm:p-0">
        <div className="w-full p-5 bg-white rounded sm:p-8">
          <IconLogo className="w-40 mx-auto mb-8" />

          {forgotPasswordConfirmQuery.isSuccess ? (
            <div className="w-full">
              <div className="w-full px-8 py-3 mb-5 text-center text-green-700 bg-green-100 rounded">
                Your password has been successfully updated.
              </div>

              <NavLink to={routes.public.login}>
                <Button type="submit" className="w-full">
                  Proceed to login
                </Button>
              </NavLink>
            </div>
          ) : forgotPasswordValidateTokenQuery.isSuccess ? (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center"
            >
              <div className="w-full mb-5">
                <TextField
                  label="New Password"
                  placeholder="New password"
                  type="password"
                  error={formState.errors?.password}
                  {...register("password", { required: true })}
                />
              </div>
              <div className="w-full mb-5">
                <TextField
                  label="Confirm Password"
                  placeholder="Confirm password"
                  type="password"
                  error={formState.errors?.confirmPassword}
                  {...register("confirmPassword", { required: true })}
                />
              </div>

              {formError && (
                <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
                  {formError}
                </div>
              )}

              <Button
                type="submit"
                className="w-full"
                loading={forgotPasswordConfirmQuery.isLoading}
              >
                Reset Password
              </Button>
            </form>
          ) : forgotPasswordValidateTokenQuery.isLoading ? (
            <IconLoading className="w-10" />
          ) : forgotPasswordValidateTokenQuery.isError ? (
            <div className="w-full px-8 py-3 text-center text-red-700 bg-red-100 rounded">
              Invalid or expired token.
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
};
