import React from "react";
import { WorkingGroups } from "./WorkingGroups";
import { WorkingGroupBrowseContextProvider } from "./WorkingGroupBrowseContext";

export const WorkingGroupsBrowseContainer = () => {
  return (
    <WorkingGroupBrowseContextProvider>
      <WorkingGroups />
    </WorkingGroupBrowseContextProvider>
  );
};
