import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import debounce from "lodash/debounce";

import { NavLink } from "react-router-dom";

import BettercommunicationImage from "../../assets/images/bettercommunication.png";
import CrowdfundingresearchImage from "../../assets/images/crowdfundingresearch.png";
import FundingaccessImage from "../../assets/images/fundingaccess.png";
import ResearchFundsImage from "../../assets/images/researchfunds1.png";
import ResearcherfeedbackImage from "../../assets/images/researcherfeedback.png";
import FundingResearchIcon from "../../assets/images/funding-research.png";
import { ReactComponent as FundingTimeIcon } from "../../assets/icons/funding-time.svg";
import { ReactComponent as FundingCommunicationIcon } from "../../assets/icons/funding-communication.svg";
import { ReactComponent as FundingEqualIcon } from "../../assets/icons/funding-equal.svg";
import { ReactComponent as ResearchersubmitIcon } from "../../assets/icons/researchersubmit.svg";
import { ReactComponent as DynamicreviewIcon } from "../../assets/icons/dynamicreview.svg";
import { ReactComponent as DynamicreviewWhiteIcon } from "../../assets/icons/dynamicreviewwhite.svg";
import { ReactComponent as CrowdfindingIcon } from "../../assets/icons/crowdfinding.svg";
import { ReactComponent as ResearchFundsIcon } from "../../assets/icons/researchfunds.svg";
// import { ReactComponent as IconLandingLogoBg } from "assets/icons/landing-logo-bg.svg";

import { routes } from "routes";

export const HeroNew = () => {
  return (
    <div>
      <div className="relative overflow-hidden bg-white hero-gradient">
        <div className="w-full max-w-screen-xl px-4 py-5 mx-auto md:px-8 ">
          <div className="my-12 lg:my-24">
            <h1
              className="font-nunitoexpanded text-2xl md:text-4xl lg:text-5xl leading-snug md:leading-snug lg:leading-snug font-semibold text-white"
              style={{ maxWidth: "750px" }}
            >
              Bridging the gap between medical research and YOU through
              engagement and collaboration
            </h1>

            <div className="mt-3 italic text-lg md:text-xl font-nunito font-semibold text-white">
              Join us in driving impactful and cutting-edge research
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse lg:flex-row-reverse justify-center items-center pt-24 lg:pt-32">
            <div
                className="text-center lg:text-left"
                style={{ maxWidth: "500px" }}
            >
              <div className="font-nunitoexpanded text-2xl lg:text-3xl text-white font-semibold">
                Most People Feel Disconnected From Medical Research - Even
                Though We All Pay For It
              </div>
              <div className="font-nunito lg:leading-tight text-lg lg:text-xl font-medium text-white pt-3">
                There is a need for better communication about
                medical research ideas and findings (Knowledge
                Translation) to improve the impact of research.
              </div>
            </div>

            <div className="w-16 lg:w-24 mr-0 lg:mr-24 mb-6 lg:ml-12 lg:mb-0">
              <ResearchFundsIcon className="w-20 lg:w-28" />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse lg:flex-row justify-center items-center pt-24">
            <div
              className="text-center lg:text-left"
              style={{ maxWidth: "500px" }}
            >
              <div className="font-nunitoexpanded text-2xl lg:text-3xl text-white font-semibold">
                Collaboration is the key to Better Medicine
              </div>
              <div className="font-nunito lg:leading-tight text-lg lg:text-xl font-medium text-white pt-3">
                Fostering International Partnerships among
                researchers, patients, and the public in medical research
                and education is crucial to achieve meaningful progress.
              </div>
            </div>

            <div className="w-24 lg:w-32 ml-0 lg:ml-24 mb-6 lg:mb-0">
              <FundingCommunicationIcon className="w-24 lg:w-36" />
            </div>
          </div>

          <div className="flex flex-wrap flex-col-reverse lg:flex-row-reverse justify-center items-center pt-24 lg:pt-32">
            <div
              className="text-center lg:text-left"
              style={{ maxWidth: "500px" }}
            >
              <div className="font-nunitoexpanded text-2xl lg:text-3xl text-white font-semibold">
                Collaboration thrives on Transparency and Inclusivity
              </div>
              <div className="font-nunito lg:leading-tight text-lg lg:text-xl font-medium text-white pt-3">
                Our working groups encourage researchers,
                patients, and interested members of the public to share
                ideas, perspectives, and data. Everyone can contribute their
                own expertise.
              </div>
            </div>

            <div className="w-24 lg:w-36 mr-0 lg:mr-16 mb-6 lg:ml-4 lg:mb-0">
              <FundingEqualIcon className="w-24 lg:w-36" />
            </div>
          </div>

          <div className="pb-72" />
        </div>
      </div>

      <div className="w-full max-w-screen-xl px-4 -mt-8 mb-8 mx-auto md:px-8 relative z-10">
        <div className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
          We strive to improve medical research collaboration, public engagement,
          and knowledge translation on our platform:
        </div>

        <div className="flex flex-wrap mt-8 justify-center">
          <div
            className="w-full sm:w-1/2 md:w-1/4 p-1.5 relative"
            style={{ maxWidth: "300px" }}
          >
            <div className="border-b-4 border-red-500 w-full">
              <img src={CrowdfundingresearchImage} alt="" className="w-full" />

              <div className="absolute bottom-10 font-nunito text-white font-bold text-xl lg:text-2xl px-6">
                We Promote Constructive Discussions on Study Designs and Findings
              </div>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/4 p-1.5 relative"
            style={{ maxWidth: "300px" }}
          >
            <div className="border-b-4 border-red-500 w-full">
              <img src={ResearcherfeedbackImage} alt="" className="w-full" />

              <div className="absolute bottom-10 font-nunito text-white font-bold text-xl lg:text-2xl px-6">
                We Encourage Clear Communication of Data and Feedback
              </div>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/4 p-1.5 relative "
            style={{ maxWidth: "300px" }}
          >
            <div className="border-b-4 border-red-500 w-full">
              <img src={BettercommunicationImage} alt="" className="w-full" />

              <div className="absolute bottom-10 font-nunito text-white font-bold text-xl lg:text-2xl px-6">
                We Allow Respectful Communication Among all Stakeholders
              </div>
            </div>
          </div>
          <div
            className="w-full sm:w-1/2 md:w-1/4 p-1.5 relative "
            style={{ maxWidth: "300px" }}
          >
            <div className="border-b-4 border-red-500 w-full">
              <img src={FundingaccessImage} alt="" className="w-full" />

              <div className="absolute bottom-10 font-nunito text-white font-bold text-xl lg:text-2xl px-6">
                We Empower People With Lived Experiences
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="mt-16 grayscale opacity-40">
        <div className="w-full max-w-screen-xl px-4 py-5 mx-auto md:px-8 ">
          <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
            How We Allow Crowdfunding of Medical Research
          </h2>

          <div className="mt-8 flex flex-wrap justify-center">
            <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
              <ResearchersubmitIcon className="w-32" />

              <div className="mt-4 border-b border-blue-550 pb-2 flex items-center">
                <div className="w-16 h-24 relative border-r border-blue-550">
                  <div className="-rotate-90 absolute w-32 text-red-500 text-3xl font-medium top-3 -left-12 -ml-1.5">
                    STEP 1
                  </div>
                </div>

                <div className="font-nunito text-blue-550 font-bold text-xl lg:text-2xl ml-4">
                  Researchers submit their funding proposal submission
                </div>
              </div>

              <div className="mt-2 text-gray-600 text-lg lg:text-xl font-medium">
                Researchers submit a medical research project that they want to
                get funded, including their desired budget and a description of
                their project.
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
              <DynamicreviewIcon className="w-32" />

              <div className="mt-4 border-b border-blue-550 pb-2 flex items-center">
                <div className="w-16 h-24 relative border-r border-blue-550">
                  <div className="-rotate-90 absolute w-32 text-red-500 text-3xl font-medium top-3 -left-12 -ml-1.5">
                    STEP 2
                  </div>
                </div>

                <div className="font-nunito text-blue-550 font-bold text-lg lg:text-xl lg:text-2xl ml-4">
                  Dynamic review of medical research projects
                </div>
              </div>

              <div className="mt-2 text-gray-600 text-xl font-medium">
                Experts, patients, researchers, and members of our Scientific
                Review Committee evaluate proposals on an ongoing basis.
              </div>
            </div>
            <div className="w-full sm:w-1/2 md:w-1/3 p-5 relative flex flex-col items-center">
              <CrowdfindingIcon className="w-32" />

              <div className="mt-4 border-b border-blue-550 pb-2 flex items-center">
                <div className="w-16 h-24 relative border-r border-blue-550">
                  <div className="-rotate-90 absolute w-32 text-red-500 text-3xl font-medium top-3 -left-12 -ml-1.5">
                    STEP 3
                  </div>
                </div>

                <div className="font-nunito text-blue-550 font-bold text-xl lg:text-2xl ml-4">
                  Crowdfunding for medical research
                </div>
              </div>

              <div className="mt-2 text-gray-600 text-lg lg:text-xl font-medium">
                Patients, friends, family members, and other engaged users
                choose the projects that get funded.
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};
