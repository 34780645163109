import React, { useContext, useMemo, useState } from "react";
import { format } from "date-fns";
import get from "lodash/get";
import classNames from "classnames";
import {
  ThumbDownIcon,
  ThumbUpIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/solid";
import {
  SearchIcon,
  ThumbDownIcon as ThumbDownOutlineIcon,
  ThumbUpIcon as ThumbUpOutlineIcon,
} from "@heroicons/react/outline";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

import { Button } from "components/button/Button";
import { LightboxComponent } from "components/lightbox/LightboxComponent";
import {
  Discussion,
  putDiscussionVote,
  deleteDiscussionComment,
} from "api/discussions";
import { DiscussionsContext } from "./DiscussionsContext";
import { CommentForm } from "./CommentForm";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import { withParams } from "../../utils/url";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";
import { useMutation } from "react-query";
import { useContextService } from "../../hooks/use-context-service";
import { AppContext } from "../../context";
import { ReactComponent as IconPdf } from "../../assets/icons/pdf.svg";
import ReactGA from "react-ga4";

export const CommentSimple = ({
  id,
  created_at,
  updated_at,
  deleted_at,
  created_by,
  text,
  user_reaction,
  votes,
  quotes,
  images,
}: Partial<Pick<
  Discussion,
  | "id"
  | "created_at"
  | "updated_at"
  | "deleted_at"
  | "created_by"
  | "text"
  | "user_reaction"
  | "votes"
  | "is_owner"
  | "quotes"
  | "images"
>> & {
  replyName?: string;
}) => {
  const [showAllReplay, setShowAllReplay] = useState(false);

  return (
    <div className="flex items-start relative z-10 font-nunito">
      <NavLink
        to={withParams(routes.researcherProfile, {
          id: created_by?.id,
        })}
        className="flex justify-center items-center rounded-full bg-gray-300 w-10 h-10 text-white font-medium bg-center bg-cover"
        style={{ backgroundImage: `url(${created_by?.avatar})` }}
      >
        {!created_by?.avatar ? get(created_by, "name[0]") : ""}
      </NavLink>
      <div className="flex-1 ml-3">
        <div className="flex text-xs text-gray-500">
          Now
        </div>
        <div className="flex justify-between items-center">
          <NavLink
            to={withParams(routes.researcherProfile, {
              id: created_by?.id,
            })}
            className="text-lg font-bold text-blue-550 hover:text-violet-700 pb-1"
          >
            {created_by?.name}
          </NavLink>
        </div>


        {(
          <div className="text-sm text-gray-700">
            {quotes?.created_by?.name && !deleted_at && (
              <div className="inline-block bg-gray-200 rounded-xl px-2 py-1 mt-1 mr-1 text-gray-700 text-sm w-full">
                <div className="font-semibold">
                  @{quotes?.created_by?.name}:{" "}
                </div>
                <div
                  className="wysiwyg-editor text-lg font-medium"
                  dangerouslySetInnerHTML={{
                    __html:
                      quotes?.text?.length > 100 && !showAllReplay
                        ? `${quotes?.text.substring(0, 100)}…`
                        : quotes?.text,
                  }}
                />
                {quotes?.text?.length > 100 && !showAllReplay && (
                  <Button
                    size="exSmall"
                    variant="white"
                    className="my-1"
                    onClick={() => {
                      setShowAllReplay(true);
                    }}
                  >
                    Show more
                  </Button>
                )}
              </div>
            )}
            <div
              className={classNames("pt-2", {
                "italic text-gray-500": !!deleted_at,
              })}
            >
              <div
                className="wysiwyg-editor text-lg font-medium"
                dangerouslySetInnerHTML={{ __html: (text!).replace(/<p><br><\/p>/g, '') }}
              />
            </div>
            {(images) && !deleted_at && (
              <div className="flex flex-wrap mt-2">
                <LightboxComponent
                  images={(images)
                    .map(({ id, image, image_thumbnail }) => ({
                      src: image,
                      alt: id,
                    }))}
                />
              </div>
            )}
            {(images) && !deleted_at && (
              <div className="mt-2">
                {(images)
                  .map(({ id, image, filename }) => (
                    <a
                      key={id}
                      href={image}
                      target="_blank"
                      className="flex text-violet-600 py-1 items-center"
                    >
                      <IconPdf className="mr-2 w-6" />{" "}
                      <span className="pt-1">{filename}</span>
                    </a>
                  ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const Comment = ({
  id,
  created_at,
  updated_at,
  deleted_at,
  created_by,
  text,
  user_reaction,
  votes,
  discussion_id,
  setToReply,
  is_owner,
  quotes,
  images,
}: Pick<
  Discussion,
  | "id"
  | "created_at"
  | "updated_at"
  | "deleted_at"
  | "created_by"
  | "text"
  | "user_reaction"
  | "votes"
  | "is_owner"
  | "quotes"
  | "images"
> & {
  replyName?: string;
  discussion_id: string;
  setToReply: (param: string | undefined) => void;
}) => {
  const [userReaction, setUserReaction] = useState<string | undefined>(
    user_reaction
  );
  const { authToken } = useContext(AppContext);

  const [vote, setVote] = useState(0);
  const [deletedImageId, setDeletedImageId] = useState<string[]>([]);
  const [editImages, setEditImages] = useState<
    {
      id: string;
      image: string;
      image_thumbnail: string;
      type: string;
      filename: string;
    }[]
  >();
  const [editValue, setEditValue] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [showAllReplay, setShowAllReplay] = useState(false);

  const discussionVoteMutation = useMutation(
    useContextService(putDiscussionVote)
  );

  const deleteDiscussionCommentMutation = useMutation(
    useContextService(deleteDiscussionComment)
  );

  const voteResult = useMemo(
    () => (votes?.up || 0) - (votes?.down || 0) + vote,
    [votes, vote]
  );

  const voteUp = useMemo(
    () => (votes?.up || 0) + (userReaction === 'up' && vote ? 1 : !userReaction && vote < 0 ? -1 : userReaction === 'down' && vote < 0 && user_reaction ? -1 : 0),
    [votes, vote, userReaction, user_reaction]
  );
  const voteDown = useMemo(
    () => (votes?.down || 0) + (userReaction === 'down' && vote ? 1 : !userReaction && vote > 0 ? -1 : userReaction === 'up' && vote > 0 && user_reaction ? -1 : 0),
    [votes, vote, userReaction, user_reaction]
  );

  return deleteDiscussionCommentMutation.isSuccess ? (
    <div className="bg-red-100 text-red-500 p-4 rounded-lg py-3 relative z-10 -mb-8">
      Successfully deleted{" "}
    </div>
  ) : (
    <div className="flex items-start relative z-10">
      <NavLink
        to={withParams(routes.researcherProfile, {
          id: created_by?.id,
        })}
        className="flex justify-center items-center rounded-full bg-gray-300 w-10 h-10 text-white font-medium bg-center bg-cover"
        style={{ backgroundImage: `url(${created_by?.avatar})` }}
      >
        {!created_by?.avatar ? get(created_by, "name[0]") : ""}
      </NavLink>
      <div className="flex-1 ml-3">
        <div className="flex text-xs text-gray-500">
          {created_at === "now"
            ? "Now"
            : format(new Date(created_at), "MMM dd YYY, HH:mm")}

          {is_owner && !deleted_at && (
            <button
              type="button"
              className="flex items-center text-violet-500 text-xs ml-3 pl-1 hover:text-violet-700"
              onClick={() => {
                setIsEdit(true);
                setToReply(undefined);
              }}
            >
              <PencilIcon className="mr-1 w-3" />
              Edit
            </button>
          )}

          {is_owner && !isConfirmDelete && !deleted_at && (
            <button
              type="button"
              className="flex items-center text-red-500 text-xs ml-2 pl-1 hover:text-red-600"
              onClick={() => {
                setIsConfirmDelete(true);
              }}
            >
              <TrashIcon className="mr-1 w-3" />
              Delete
            </button>
          )}

          {is_owner && isConfirmDelete && (
            <div className="inline-flex items-center ml-4">
              Confirm{" "}
              <Button
                size="exSmall"
                variant="danger"
                loading={deleteDiscussionCommentMutation.isLoading}
                disabled={deleteDiscussionCommentMutation.isLoading}
                className="ml-2"
                onClick={() => {
                  deleteDiscussionCommentMutation.mutateAsync({
                    id: discussion_id,
                    comment_id: id,
                  });
                }}
              >
                Delete comment
              </Button>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center">
          <NavLink
            to={withParams(routes.researcherProfile, {
              id: created_by?.id,
            })}
            className="text-lg font-bold text-blue-550 hover:text-violet-700 pb-1"
          >
            {created_by.name}
          </NavLink>
          <div className="flex items-center">
            {!is_owner && authToken ? (
              <button
                disabled={is_owner || discussionVoteMutation.isLoading}
                className={classNames(
                  {
                    "text-red-500": userReaction === "down",
                    "text-gray-300":
                      (userReaction && userReaction !== "down") || is_owner,
                  },
                  "p-1 text-gray-400 disabled:cursor-default"
                )}
                type="button"
                onClick={async () => {
                  try {
                    await discussionVoteMutation.mutateAsync({
                      id,
                      reaction: "down",
                    });

                    if (!userReaction) {
                      setVote(vote - 1);
                      setUserReaction("down");
                      // ReactGA.event({
                      //   category: 'User',
                      //   action: 'Dislike'
                      // });
                    } else if (userReaction === "down") {
                      setVote(vote + 1);
                      setUserReaction(undefined);
                    } else if (userReaction === "up") {
                      setVote(vote - 2);
                      setUserReaction("down");
                      // ReactGA.event({
                      //   category: 'User',
                      //   action: 'Dislike'
                      // });
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {userReaction === "down" ? (
                  <ThumbDownIcon className="w-4 mt-1" />
                ) : (
                  <ThumbDownOutlineIcon className="w-4 mt-1" />
                )}
              </button>
            ) : (
              <div className="w-6" />
            )}
            <div
              className={classNames(
                "flex items-center justify-center w-10 h-6 rounded-full text-xs font-semibold",
                {
                  "bg-green-100 text-green-500": voteResult > 0,
                  "bg-red-100 text-red-500": voteResult < 0,
                  "bg-gray-200 text-gray-500": voteResult === 0,
                }
              )}
            >
              {voteDown ? '-' : ''}{(voteDown || 0)} / {(voteUp || 0)}
            </div>
            {!is_owner && authToken ? (
              <button
                disabled={is_owner || discussionVoteMutation.isLoading}
                className={classNames(
                  {
                    "text-green-500": userReaction === "up",
                    "text-gray-300":
                      (userReaction && userReaction !== "up") || is_owner,
                  },
                  "p-1 text-gray-400 disabled:cursor-default"
                )}
                type="button"
                onClick={async () => {
                  try {
                    await discussionVoteMutation.mutateAsync({
                      id,
                      reaction: "up",
                    });

                    if (!userReaction) {
                      setVote(vote + 1);
                      setUserReaction("up");
                      // ReactGA.event({
                      //   category: 'User',
                      //   action: 'Like'
                      // });
                    } else if (userReaction === "down") {
                      setVote(vote + 2);
                      setUserReaction("up");
                      // ReactGA.event({
                      //   category: 'User',
                      //   action: 'Like'
                      // });
                    } else if (userReaction === "up") {
                      setVote(vote - 1);
                      setUserReaction(undefined);
                    }
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {userReaction === "up" ? (
                  <ThumbUpIcon className="w-4" />
                ) : (
                  <ThumbUpOutlineIcon className="w-4" />
                )}
              </button>
            ) : (
              <div className="w-6" />
            )}
          </div>
        </div>

        {editValue ? (
          <div className="mb-2">
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
              Edited on {format(new Date(), "MMM dd YYY, HH:mm")}
            </span>
          </div>
        ) : (
          updated_at &&
          created_at !== "now" &&
          format(new Date(created_at), "MMM dd YYY, HH:mm:ss") !==
            format(new Date(updated_at), "MMM dd YYY, HH:mm:ss") && (
            <div className="mb-2">
              <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                Edited on {format(new Date(updated_at), "MMM dd YYY, HH:mm")}
              </span>
            </div>
          )
        )}

        {deleted_at && (
          <div className="mb-2">
            <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-600">
              Deleted on {format(new Date(deleted_at), "MMM dd YYY, HH:mm")}
            </span>
          </div>
        )}

        {!isEdit && (
          <div className="text-sm text-gray-700">
            {quotes?.created_by?.name && !deleted_at && (
              <div className="inline-block bg-gray-200 rounded-xl px-2 py-1 mt-1 mr-1 text-gray-700 text-sm w-full">
                <div className="font-semibold">
                  @{quotes?.created_by?.name}:{" "}
                </div>
                <div
                  className="wysiwyg-editor text-lg font-medium"
                  dangerouslySetInnerHTML={{
                    __html:
                      quotes?.text?.length > 100 && !showAllReplay
                        ? `${quotes?.text.substring(0, 100)}…`
                        : quotes?.text,
                  }}
                />
                {quotes?.text?.length > 100 && !showAllReplay && (
                  <Button
                    size="exSmall"
                    variant="white"
                    className="my-1"
                    onClick={() => {
                      setShowAllReplay(true);
                    }}
                  >
                    Show more
                  </Button>
                )}
              </div>
            )}{" "}
            <div
              className={classNames("pt-2", {
                "italic text-gray-500": !!deleted_at,
              })}
            >
              <div
                className="wysiwyg-editor text-lg font-medium"
                dangerouslySetInnerHTML={{ __html: (editValue || text).replace(/<p><br><\/p>/g, '') }}
              />
            </div>
            {(editImages || images) && !deleted_at && (
              <div className="flex flex-wrap mt-2">
                <LightboxComponent
                  images={(editImages || images)
                    .filter(
                      ({ id, type }) =>
                        !deletedImageId.includes(id) && type !== "PDF"
                    )
                    .map(({ id, image, image_thumbnail }) => ({
                      src: image,
                      alt: id,
                    }))}
                />
                {/*<LightGallery
                  plugins={[lgZoom, lgVideo]}
                  mode="lg-fade"
                  elementClassNames="flex flex-wrap"
                >
                  {(editImages || images)
                    .filter(
                      ({ id, type }) =>
                        !deletedImageId.includes(id) && type !== "PDF"
                    )
                    .map(({ id, image, image_thumbnail }) => (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        key={id}
                        className="gallery-item mr-1 mb-1 relative cursor-pointer"
                        data-src={image}
                      >
                        <span className="absolute w-full h-full bg-black bg-opacity-40 opacity-0 hover:opacity-100 flex items-center justify-center">
                          <SearchIcon className="w-6 text-white" />
                        </span>
                        <img className="img-responsive" src={image_thumbnail} alt="" />
                      </a>
                    ))}
                </LightGallery>*/}
              </div>
            )}
            {(editImages || images) && !deleted_at && (
              <div className="mt-2">
                {(editImages || images)
                  .filter(
                    ({ id, type }) =>
                      !deletedImageId.includes(id) && type === "PDF"
                  )
                  .map(({ id, image, filename }) => (
                    <a
                      key={id}
                      href={image}
                      target="_blank"
                      className="flex text-violet-600 py-1 items-center"
                    >
                      <IconPdf className="mr-2 w-6" />{" "}
                      <span className="pt-1">{filename}</span>
                    </a>
                  ))}
              </div>
            )}
          </div>
        )}

        {isEdit && (
          <div className="mt-2 -mb-8">
            {" "}
            <CommentForm
              discussion_id={discussion_id}
              comment_id={id}
              onSubmit={(comment: Discussion) => {
                setEditValue(comment.text);
                setEditImages(comment.images);
                setIsEdit(false);
              }}
              onEditCancel={() => {
                setIsEdit(false);
              }}
              defaultValues={{ text: editValue || text }}
              images={editImages || images}
              deletedImageId={deletedImageId}
              setDeletedImageId={setDeletedImageId}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const DiscussionsList = ({
  id,
  canWrite = true,
  type,
}: {
  id: string;
  canWrite?: boolean;
  type?: string;
}) => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
  } = useContext(DiscussionsContext);
  const { authToken } = useContext(AppContext);

  const [commentsAdded, setCommentsAdded] = useState<Discussion[]>([]);
  const [isAddComment, setIsAddComment] = useState(false);
  const [repliesAdded, setRepliesAdded] = useState<{
    [k: string]: Discussion[];
  }>({});
  const [toReply, setToReply] = useState<string | undefined>();

  return (
    <div>
      <div className="mt-8 font-extrabold tracking-tight text-gray-900">
        Replies
      </div>

      {isLoading ? (
        <div className="flex justify-center mt-8">
          <IconLoading />
        </div>
      ) : (
        <div>
          {authToken && canWrite && (
            <div>
              {!toReply && isAddComment ? (
                <div className="my-4">
                  <CommentForm
                    type={type}
                    discussion_id={id}
                    onSubmit={(newComment: Discussion) => {
                      setCommentsAdded([
                        { ...newComment, created_at: "now" },
                        ...commentsAdded,
                      ]);

                      setIsAddComment(false);
                    }}
                  />
                </div>
              ) : (
                <Button
                  size="exSmall"
                  className="mt-2 mb-4"
                  onClick={() => {
                    setIsAddComment(true);
                  }}
                >
                  Add reply
                </Button>
              )}
            </div>
          )}

          {[...commentsAdded, ...listData].map(
            (
              {
                id: comment_id,
                text,
                user_reaction,
                created_at,
                updated_at,
                deleted_at,
                created_by,
                replies,
                votes,
                quotes,
                is_owner,
                images,
              },
              i
            ) => (
              <div
                key={comment_id}
                className={classNames("py-2 my-2", {
                  "border-gray-200 border-t": i,
                })}
              >
                <Comment
                  discussion_id={id}
                  id={comment_id}
                  text={text}
                  created_at={created_at}
                  updated_at={updated_at}
                  deleted_at={deleted_at}
                  created_by={created_by}
                  user_reaction={user_reaction}
                  is_owner={is_owner}
                  votes={votes}
                  quotes={quotes}
                  setToReply={setToReply}
                  images={images}
                />
                {authToken && canWrite && !deleted_at && (
                  <button
                    type="button"
                    className="text-gray-500 hover:text-violet-700 text-xs mt-3 ml-12 pl-1"
                    onClick={() => {
                      setToReply(comment_id);
                    }}
                  >
                    Reply
                  </button>
                )}

                {toReply === comment_id && (
                  <div className="mt-8 mb-8 ml-12 pl-1">
                    <CommentForm
                      discussion_id={id}
                      onReplyCancel={() => {
                        setToReply(undefined);
                      }}
                      reply_id={comment_id}
                      onSubmit={(newComment: Discussion) => {
                        setRepliesAdded({
                          ...repliesAdded,
                          [comment_id]: repliesAdded[comment_id]
                            ? [
                                ...repliesAdded[comment_id],
                                { ...newComment, created_at: "now" },
                              ]
                            : [{ ...newComment, created_at: "now" }],
                        });
                        setToReply(undefined);
                      }}
                    />
                  </div>
                )}

                {replies && replies.length > 0 && (
                  <div className="mx-2 md:mx-5 px-2 md:px-6 border-l-2 border-gray-200">
                    {[...replies].map(
                      (
                        {
                          id: reply_comment_id,
                          text,
                          created_at,
                          updated_at,
                          deleted_at,
                          user_reaction,
                          created_by: created_by_reply,
                          votes,
                          is_owner,
                          quotes,
                          images,
                        },
                        k
                      ) => (
                        <div
                          key={reply_comment_id}
                          className={classNames(
                            // "pt-4 mt-4 border-gray-200 border-t"
                            "pt-4 mt-4"
                          )}
                        >
                          <Comment
                            discussion_id={id}
                            id={reply_comment_id}
                            quotes={quotes}
                            text={text}
                            user_reaction={user_reaction}
                            is_owner={is_owner}
                            created_at={created_at}
                            updated_at={updated_at}
                            deleted_at={deleted_at}
                            created_by={created_by_reply}
                            replyName={created_by.name}
                            votes={votes}
                            setToReply={setToReply}
                            images={images}
                          />

                          {authToken && canWrite && (
                            <button
                              type="button"
                              className="text-gray-500 hover:text-violet-700 text-xs mt-3 ml-12 pl-1"
                              onClick={() => {
                                setToReply(reply_comment_id);
                              }}
                            >
                              Reply
                            </button>
                          )}

                          {toReply === reply_comment_id && (
                            <div className="mt-8 mb-8 ml-12 pl-1">
                              <CommentForm
                                discussion_id={id}
                                onReplyCancel={() => {
                                  setToReply(undefined);
                                }}
                                reply_id={reply_comment_id}
                                onSubmit={(newComment: Discussion) => {
                                  setRepliesAdded({
                                    ...repliesAdded,
                                    [comment_id]: repliesAdded[comment_id]
                                      ? [
                                          ...repliesAdded[comment_id],
                                          { ...newComment, created_at: "now" },
                                        ]
                                      : [{ ...newComment, created_at: "now" }],
                                  });
                                  setToReply(undefined);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </div>
                )}

                <div className="mx-2 md:mx-5 px-2 md:px-6 border-l-2 border-gray-200">
                  {repliesAdded[comment_id] &&
                    [...repliesAdded[comment_id]].map(
                      (
                        {
                          id: reply_comment_id,
                          text,
                          created_at,
                          updated_at,
                          deleted_at,
                          user_reaction,
                          created_by: created_by_reply,
                          votes,
                          is_owner,
                          quotes,
                          images,
                        },
                        k
                      ) => (
                        <div
                          key={reply_comment_id}
                          className={classNames(
                            "pt-4 mt-4 border-gray-200 border-t"
                          )}
                        >
                          <Comment
                            discussion_id={id}
                            id={reply_comment_id}
                            text={text}
                            quotes={quotes}
                            user_reaction={user_reaction}
                            is_owner={is_owner}
                            created_at={created_at}
                            updated_at={updated_at}
                            deleted_at={deleted_at}
                            created_by={created_by_reply}
                            replyName={created_by.name}
                            votes={votes}
                            setToReply={setToReply}
                            images={images}
                          />

                          {authToken && canWrite && (
                            <button
                              type="button"
                              className="text-gray-500 hover:text-violet-700 text-xs mt-3 ml-12 pl-1"
                              onClick={() => {
                                setToReply(reply_comment_id);
                              }}
                            >
                              Reply
                            </button>
                          )}

                          {toReply === reply_comment_id && (
                            <div className="mt-8 mb-8 ml-12 pl-1">
                              <CommentForm
                                discussion_id={id}
                                onReplyCancel={() => {
                                  setToReply(undefined);
                                }}
                                reply_id={reply_comment_id}
                                onSubmit={(newComment: Discussion) => {
                                  setRepliesAdded({
                                    ...repliesAdded,
                                    [comment_id]: repliesAdded[comment_id]
                                      ? [
                                          ...repliesAdded[comment_id],
                                          { ...newComment, created_at: "now" },
                                        ]
                                      : [{ ...newComment, created_at: "now" }],
                                  });
                                  setToReply(undefined);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      )
                    )}
                </div>
              </div>
            )
          )}

          {listData.length === 0 && commentsAdded.length === 0 && (
            <div className="text-gray-500">No replies yet.</div>
          )}
        </div>
      )}

      {hasNextPage && (
        <div className="flex justify-center mt-8">
          <Button
            loading={isFetchingNextPage}
            spinner
            size="small"
            variant="secondary"
            disabled={!hasNextPage}
            onClick={handleFetchNextPage}
          >
            Load more replies
          </Button>
        </div>
      )}
    </div>
  );
};
