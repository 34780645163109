import { Transition } from "@headlessui/react";
import { forwardRef, PropsWithoutRef, useState } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { FieldProps } from "types/field";
import { FieldGroup } from "../FieldGroup";
import { LoadingSpinner } from "../loading-spinner/LoadingSpinner";

export type TextFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["input"]>,
  "className" | "children"
> &
  FieldProps & {
    loading?: boolean;
  };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      type = "text",
      inputlength,
      required,
      optional,
      loading = false,
      ...props
    },
    ref
  ) => {
    const [typeSelected, setTypeSelected] = useState(type);

    return (
      <FieldGroup
        required={required}
        optional={optional}
        inputlength={inputlength}
        {...props}
      >
        {(computedProps) => (
          <>
            <input
              ref={ref}
              type={typeSelected}
              {...computedProps}
              {...props}
            />
            {type === "password" && (
              <button
                type="button"
                className="absolute right-2 top-2 pt-0.5"
                onClick={() => {
                  setTypeSelected(
                    typeSelected === "password" ? "text" : "password"
                  );
                }}
              >
                {typeSelected === "password" ? (
                  <EyeIcon className="w-5 text-gray-600" />
                ) : (
                  <EyeOffIcon className="w-5 text-gray-600" />
                )}
              </button>
            )}

            {
              <Transition
                show={loading}
                enter="ease-out duration-300 transition"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 flex items-center h-full px-2 pointer-events-none">
                  <div className="px-2 py-1 bg-white">
                    <LoadingSpinner className="w-3 h-3 text-violet-900" />
                  </div>
                </div>
              </Transition>
            }
          </>
        )}
      </FieldGroup>
    );
  }
);
