import axios from "axios";

export const googleLogin = async (
  accesstoken: string
): Promise<{
  data: { token: string };
}> => {
  return await axios.post(`${process.env.REACT_APP_API_URI}/oauth/google/`, {
    code: accesstoken,
  });
};

export const facebookLogin = async (
  accesstoken: string
): Promise<{
  data: { token: string };
}> => {
  return await axios.post(`${process.env.REACT_APP_API_URI}/oauth/facebook/`, {
    code: accesstoken,
  });
};
