import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "tailwindcss/tailwind.css";
import { Container } from "./Container";
import { assertEnvVar } from "./env";
import "./i18n";
import { reportWebVitals } from "./report-web-vitals";

import "./styles/auth-code.css";
import "./styles/quill.css";
import "./styles/font.css";
import "./styles/multiselect.css";
import "./styles/slick.css";
import "./styles/common.css";
import "./styles/design.css";

assertEnvVar("REACT_APP_API_URI");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Container />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
