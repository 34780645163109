import { Context } from "types";

export const login = ({ apiClient }: Context) => (data: {
  email: string;
  password: string;
  token?: string;
  device_id?: string;
}) =>
  apiClient<{ access: string; devices?: [] }>({
    url: "/login",
    method: "POST",
    data,
  });
