import React from "react";
import { useQuery } from "react-query";

import { getUserRequest } from "api/user/user";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { EditPersonalizationForm } from "./EditPersonalizationForm";

export const EditPersonalization = ({
  onSubmit,
}: {
  onSubmit?: () => void;
}) => {
  const { data, isLoading } = useQuery("user", () => getUserRequest(), {
    suspense: false,
  });

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <EditPersonalizationForm
      onSubmit={() => {
        onSubmit && onSubmit();
      }}
      user={data?.data}
    />
  );
};
