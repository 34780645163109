import { Context } from "../types";
import { withParams } from "../utils/url";

export interface QuizQuestion {
  id: number;
  text: string;
  video_url?: string;
  iframe_url?: string;
  multiple_choices: boolean;
  answer_options: { id: string; text: string; selected_by_user: boolean; ordered_by_user: number }[];
  type: string;
}

export interface QuizData {
  id?: number;
  name?: string;
  attempts?: number;
  completed?: boolean;
  correct_answers?: number;
  delayed_until?: string;
  description?: string;
  percent_correct_answers?: string;
  certificate?: string;
  quiz_passed?: string;
  remaining_attempts?: number;
  current_question_id?: number;
  question_answered_len?: number;
  total_question_len?: number;
  certificate_url?: string;
  questions: QuizQuestion[];
}

export const getQuizList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 1,
  searchQuery,
  ordering,
  categories,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  ordering?: string;
  categories?: string[];
}) =>
  apiClient<{
    data: QuizData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/quiz`, {
      page,
      perPage,
      search: searchQuery,
      ordering,
      categories,
    }).replace(/(%5B1%5D|%5B0%5D)/g, ""),
    method: "GET",
  });

export const getQuiz = ({ apiClient }: Context) => ({ id }: { id: string }) =>
  apiClient<{ data: QuizData }>({
    url: withParams(`/quiz/:id`, { id }),
    method: "GET",
  });

export const putQuiz = ({ apiClient }: Context) => (id: number, data: any) =>
  apiClient<{
    percent_correct_answers: number;
    quiz_passed: boolean;
    remaining_attempts: number;
    completed: boolean;
    certificate?: string;
  }>({
    url: withParams(`/quiz/:id/complete-quiz`, { id }),
    method: "PUT",
    data,
  });
