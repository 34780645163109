import React, { useContext, useMemo, useState } from "react";
import get from "lodash/get";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { routes } from "routes";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { login } from "api/auth";
import { Button } from "components/button/Button";
import { TextField } from "components/text-field/TextField";
import { getTextButtonClassNames } from "components/TextButton";
import { AuthenticatorInfo } from "components/mfa-info/AuthenticatorInfo";
import { AppContext } from "context";
import { pipe } from "fp-ts/lib/function";
import { useContextService } from "hooks/use-context-service";
import { useStringRequired } from "hooks/use-validation-input";
import { AuthCodeInput } from "components/auth-code-input/AuthCodeInput";
import { ReactComponent as IconLogo } from "../../../assets/icons/logo.svg";
import { setFormError } from "../../../utils/setError";
import ReactGA from "react-ga4";
import { SSO } from "../../register/sso/SSO";

const useFormSchema = (isTotp = false) =>
  z.object({
    email: pipe(z.string(), useStringRequired()),
    password: pipe(z.string(), useStringRequired()),
    token: z
      .string()
      .optional()
      .refine((val) => (isTotp ? Boolean(val) : true), { message: "Required" }),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const LoginForm = () => {
  const location = useLocation();

  const [totpDevice, setTotpDevice] = useState();
  const params = useMemo(() => new URLSearchParams(location?.search), [
    location,
  ]);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
    control,
  } = useForm<FormSchema>({
    resolver: zodResolver(useFormSchema(totpDevice)),
  });

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const { dispatch } = useContext(AppContext);

  const loginMutation = useMutation(useContextService(login), {
    onSuccess: (response) => {
      const token = response?.data?.access;

      if (
        !token &&
        response?.data?.devices &&
        response?.data?.devices.length > 0
      ) {
        setTotpDevice(get(response, "data.devices[0]"));
        return;
      }

      if (token) {
        dispatch({ type: "SET_AUTH_TOKEN", token });

        if (params.get("redirect_to")) {
          history.push(params.get("redirect_to")!);
        }
      }
    },
    onError: (e) => {
      if (get(e, "response.data.messages.locked_until[0]")) {
        setFormErrorMessage(
          `Locked due ${get(
            e,
            "response.data.messages.failure_count[0]"
          )} failed attempts till ${format(
            new Date(get(e, "response.data.messages.locked_until[0]")),
            "MMM dd YYY, HH:mm"
          )}`
        );

        return;
      }

      const errorMessage =
        get(e, "response.data.messages.detail") === "not confirmed user"
          ? "Please confirm your email address"
          : t("Invalid credentials");
      setFormErrorMessage(errorMessage);
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);

      return new Promise(() => {});
    },
  });

  const { t } = useTranslation();

  return (
    <div>
      <div className="flex flex-col items-center space-y-4 relative">
        <IconLogo className="w-40 my-2" />

        <div className="mb-8 flex justify-center">
          <SSO />
        </div>

        <div className="w-full text-center">
          <span className="text-sm font-medium bg-white relative z-10 px-4">
            OR
          </span>
          <div className="h-px bg-gray-200 w-full absolute -mt-3 z-1" />
        </div>
      </div>
      <form
        onSubmit={handleSubmit((data) => {
          setFormErrorMessage(undefined);
          loginMutation.mutateAsync({
            email: data.email,
            password: data.password,
            token: data.token || undefined,
            device_id: get(totpDevice, "device_id", undefined),
          });
        })}
        className="flex flex-col items-center space-y-4 relative"
      >
        <div className="w-full mb-5">
          <TextField
            {...register("email")}
            error={errors?.email}
            label={t("Email")}
            placeholder="john.doe@gmail.com"
            required
          />
        </div>
        <div className="w-full mb-5">
          <TextField
            {...register("password")}
            error={errors?.password}
            label={t("Password")}
            type="password"
            placeholder="Password"
            required
          />
        </div>
        {totpDevice && (
          <div className="w-full mb-5">
            <Controller<FormSchema>
              name="token"
              control={control}
              render={({
                field: { onChange, value, name, ref },
                fieldState: { error },
              }) => (
                <AuthCodeInput
                  onChange={onChange}
                  value={value}
                  error={error}
                  label={
                    <span className="inline-flex">
                      Authenticator Code <AuthenticatorInfo />
                    </span>
                  }
                />
              )}
            />
          </div>
        )}

        {formError && (
          <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
            {formError}
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          loading={isSubmitting}
          spinner
          disabled={isSubmitting}
        >
          {t("Login")}
        </Button>

        <div className="flex justify-between w-full mt-4">
          <NavLink
            to={routes.forgotPassword}
            className={getTextButtonClassNames({ variant: "primary" })}
          >
            {t("Forgot password")}
          </NavLink>
          <NavLink
            to={routes.register}
            className={getTextButtonClassNames({ variant: "primary" })}
            onClick={() => {
              // ReactGA.event({
              //   category: "User",
              //   action: "Clicked the sign up button",
              // });
            }}
          >
            {t("Sign up")}
          </NavLink>
        </div>
      </form>
    </div>
  );
};
