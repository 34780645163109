import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";

import { Button } from "components/button/Button";

import { inviteMemberWorkingGroup } from "api/workingGroups";
import { setFormError } from "utils/setError";
import { TextField } from "components/text-field/TextField";
import { TextareaField } from "components/textarea-field/TextareaField";
import { useContextService } from "hooks/use-context-service";

interface InviteMember {
  email: string;
  message: string;
}

const useFormSchema = () =>
  z.object({
    email: z.string().email().nonempty("Required"),
    message: z.string().nonempty("Required"),
  });

export const InviteMemberForm = ({
  slug,
  onSubmit,
}: {
  slug: string;
  onSubmit: () => void;
}) => {
  const { register, handleSubmit, formState, setError } = useForm<InviteMember>(
    {
      mode: "onChange",
      resolver: zodResolver(useFormSchema()),
    }
  );

  const inviteMemberWorkingGroupService = useContextService(
    inviteMemberWorkingGroup
  );

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const inviteMemberWorkingGroupQuery = useMutation(
    ({ email, message }: { email: string; message: string }) =>
      inviteMemberWorkingGroupService(slug, { email, message }),
    {
      onSuccess: () => {
        setFormSuccessMessage("Invite has been successfully sent.");
        setTimeout(() => {
          onSubmit();
        }, 1500);
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const handleFormSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    inviteMemberWorkingGroupQuery.mutate({
      ...data,
    });
  };

  return (
    <div className="">
      <div className="" style={{ maxWidth: "600px" }}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="">
          <h2 className="text-lg font-medium text-black mb-2">
            Invite new member
          </h2>

          <div className="w-full mb-5">
            <TextField
              label="Email"
              placeholder="Email"
              error={formState.errors?.email}
              {...register("email")}
            />
          </div>

          <div className="w-full mb-5">
            <TextareaField
              label="Message"
              placeholder="Message"
              error={formState.errors?.message}
              {...register("message")}
            />
          </div>

          {formErrorMessage && (
            <div className="w-full mt-5 mb-3 py-2 px-4 rounded bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full mt-5 mb-3 py-2 px-4 rounded bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="submit"
            className="w-full mt-2"
            disabled={inviteMemberWorkingGroupQuery.isLoading}
            loading={inviteMemberWorkingGroupQuery.isLoading}
            spinner
          >
            Send invite
          </Button>
        </form>
      </div>
    </div>
  );
};
