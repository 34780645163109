import React from "react";
import classNames from "classnames";
import PhoneInput from "react-phone-number-input";

import { FieldError } from "react-hook-form";

import { ErrorMessage } from "components/ErrorMessage";

import "react-phone-number-input/style.css";

interface InputProps {
  error?: FieldError;
  label?: string;
  className?: string;
  onChange: (value: any) => void;
  value?: string;
  optional?: boolean;
}

export const PhoneInputField = ({
  onChange,
  value,
  label,
  error,
  className,
  optional,
}: InputProps) => {
  return (
    <div className={classNames("relative pb-4", className)}>
      <div className="auth-code">
        <div
          className={classNames("auth-code-wrapper relative", {
            "mb-2": error,
          })}
        >
          {label && (
            <div
              className={classNames("flex mb-2 font-medium text-gray-700 text-sm")}
            >
              {label}

              {optional ? (
                <span className="text-xs text-gray-500 font-normal ml-auto pt-0.5">optional</span>
              ) : (
                <sup className="text-xs text-red-400">*</sup>
              )}
            </div>
          )}
          <div className="block">
            <PhoneInput
              international
              placeholder="Enter phone number"
              onChange={onChange}
              value={value}
            />
          </div>
        </div>
      </div>
      <div className="">
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </div>
  );
};
