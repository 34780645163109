/* This example requires Tailwind CSS v2.0+ */
import { KeyIcon } from "@heroicons/react/outline";
import { PlayIcon } from "@heroicons/react/solid";
import { getLandingProjectsRequest } from "api/project/project";
import { getWorkingGroupsList } from "api/workingGroups";
import { ProjectsListFeature } from "domains/projects/project-list/ProjectsListFeature";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { ListHome } from "../../domains/working-groups/working-group-browse/list/ListHome";
import { ListBlogHome } from "../../domains/blog/list/ListHome";
import { useContextService } from "../../hooks/use-context-service";
import { getBlogCategories, getBlogsList } from "../../api/blogs";
import { Button } from "../button/Button";
import { NavLink } from "react-router-dom";
import { routes } from "../../routes";
import map from "lodash/map";
import get from "lodash/get";

const features = [
  {
    description:
      "To provide a global platform to facilitate discovery in the field of medicine by and for doctors/healthcare personnel, patients, citizen donors, and other interested stakeholders.",
  },
  {
    description:
      "To broaden the scientific process to include a stage of dynamic review where members of the platform can discuss, collaborate, and/or financially contribute to medical research.",
  },
  {
    description:
      "To promote education and continuing professional development in the field of medical research.",
  },
  {
    description:
      "To promote high quality intellectual, ethical, and professional standards.",
  },
  {
    description:
      "To ensure transparency (of how funds are being used, of the design process).",
  },
  {
    description:
      "To host large-scale competitions to tackle particularly relevant medical challenges/topics.",
  },
];

export const CurrentSystem = () => {
  return (
    <div>
      <div className="px-4 pt-16 mx-auto max-w-7xl sm:px-6 lg:pt-24 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-gray-900">Objectives</h2>
        </div>
        <dl className="mt-12 space-y-10">
          {features.map((feature, index) => (
            <div key={index} className="relative">
              <dt>
                <p className="text-base text-center text-gray-500">
                  <KeyIcon
                    className="inline w-6 h-6 mr-3 text-indigo-900 opacity-30"
                    aria-hidden="true"
                  />
                  {feature.description}
                </p>
              </dt>
            </div>
          ))}
        </dl>
      </div>
    </div>
  );
};

export const FeaturesNew = () => {
  const [videoPlaying, setVideoPlaying] = useState(false);

  const playVideo = () => {
    setVideoPlaying(true);

    videoRef.current.play();
  };

  const videoRef = useRef<any>();

  // const { data, isLoading } = useQuery(
  //   "landing-projects",
  //   () =>
  //     getLandingProjectsRequest({
  //       perPage: 3,
  //     }),
  //   {
  //     suspense: false,
  //     onError: () => {},
  //   }
  // );

  const getWorkingGroupsListService = useContextService(getWorkingGroupsList);

  const { data: dataWG, isLoading: isLoadingWG } = useQuery(
    "landing-wg",
    () =>
      getWorkingGroupsListService({
        perPage: 3,
      }),
    {
      suspense: false,
      onError: () => {},
    }
  );

  const getBlogsListService = useContextService(getBlogsList);

  const { data: dataBlogs, isLoading: isLoadingBlogs } = useQuery(
    "landing-blogs",
    () =>
      getBlogsListService({
        perPage: 3,
        ordering: "-created_at",
      }),
    {
      suspense: false,
      onError: () => {},
    }
  );

  const { data: blogCategories } = useQuery(
    `BLOG_CATEGORIES`,
    useContextService(getBlogCategories),
    {
      suspense: false,
    }
  );

  return (
    <div>
      <div className="mt-16">
        {/*<div className="py-8 hero-radial-gradient mb-16">
          <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
            <div className="relative my-8">
              {(isLoading || Boolean(data?.data.data.length)) && (
                <>
                  <h2 className="text-white font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                    Open Medical Research Projects to Fund
                  </h2>
                  <div className="text-white font-nunito mt-2 text-xl font-medium mx-auto max-w-screen-sm text-center">
                    View our selection of medical research projects that need
                    funding, including stroke research, dementia studies, and
                    more.
                  </div>
                  <div className="mt-16 lg:px-5">
                    {isLoading ? (
                      <div>
                        <IconLoading />
                      </div>
                    ) : (
                      <ProjectsListFeature listData={data?.data.data!} />
                    )}
                    <div className="flex justify-center mt-8">
                      <NavLink
                        to={routes.projects}
                        className="flex items-center"
                      >
                        <Button
                          variant="transparentWhite"
                          size="small"
                          rounded="rounded-full"
                        >
                          <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                            view all
                          </div>
                        </Button>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>*/}

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
          <div className="relative my-8 lg:px-5">
            {(isLoadingWG || Boolean(dataWG?.data?.data?.length)) && (
              <>
                <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                  Working Groups at Let’s Get Proof
                </h2>
                <div className="text-gray-600 font-nunito mt-2 text-xl font-medium mx-auto max-w-screen-md text-center">
                  Collaborate with research teams to learn more about the
                  projects they’re working on and how you can help support them.
                </div>
                <div className="mb-8 mt-16">
                  {isLoadingWG ? (
                    <div>
                      <IconLoading />
                    </div>
                  ) : (
                    <ListHome listData={dataWG?.data?.data!} />
                  )}

                  <div className="flex justify-center mt-9 pb-1">
                    <NavLink
                      to={routes.workingGroups}
                      className="flex items-center"
                    >
                      <Button
                        variant="violet550"
                        size="small"
                        rounded="rounded-full"
                      >
                        <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                          view all
                        </div>
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="hr" />

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-md md:max-w-7xl">
          <div className="relative mt-8 mb-16 lg:px-5">
            {(isLoadingBlogs || Boolean(dataBlogs?.data?.data?.length)) && (
              <>
                <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold">
                  Medical Research Blogs
                </h2>
                <div className="text-gray-600 font-nunito mt-2 text-xl font-medium mx-auto max-w-screen-md text-center">
                  Our team has written a number of articles to help you
                  understand current medical research in various areas including
                  stroke recovery, clinical psychedelic use, dementia, heart
                  disease, Alzheimer’s, and more. The learning is boundless.
                </div>

                <div className="flex justify-center">
                  <div className="flex justify-center items-center mx-auto border-t border-b border-gray-400 py-2 px-4 mt-8">
                    <div className="text-gray-600 text-xl font-semibold font-nunito mr-4">
                      Blog tags:
                    </div>
                    {map(get(blogCategories, "data.items"), ({ id, name }) => (
                      <div
                        key={id}
                        className="m-2 rounded-full bg-red-500 px-5 py-1 text-lg text-white font-semibold font-nunito"
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="mb-8 mt-12">
                  {isLoadingBlogs ? (
                    <div>
                      <IconLoading />
                    </div>
                  ) : (
                    <ListBlogHome listData={dataBlogs?.data?.data!} />
                  )}

                  <div className="flex justify-center mt-9 pb-1">
                    <NavLink to={routes.blogList} className="flex items-center">
                      <Button
                        variant="violet550"
                        size="small"
                        rounded="rounded-full"
                      >
                        <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                          view all
                        </div>
                      </Button>
                    </NavLink>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {/*<div className="text-white bg-violet-450 py-12">
          <h2 className="font-nunitoexpanded text-2xl lg:text-3xl text-center font-semibold text-white pt-1">
            How Crowdfunding for Research Works
          </h2>
          <p className="font-nunito mt-2 text-xl font-medium mx-auto max-w-screen-md text-center pb-1">
            Let’s Get Proof brings together Dynamic Review<sup>TM</sup> and PrecisionFunding<sup>TM</sup> to make medical research more efficient, inclusive, and impactful.
          </p>
        </div>*/}
        {/*<div className="relative max-w-screen-lg mx-auto my-12">
          <video
            ref={videoRef}
            src="/assets/demo.mp4"
            preload="auto"
            controls
            className={`w-full mx-auto rounded-md shadow-xl ${
              videoPlaying ? "opacity-100" : "opacity-0"
            }`}
          />
          {!videoPlaying && (
            <>
              <img
                alt=""
                src="/assets/video-thumbnail.png"
                className="absolute top-0 w-full h-full rounded-md"
              />
              <button
                onClick={playVideo}
                className="absolute top-0 flex items-center justify-center w-full h-full overflow-hidden transition-all duration-200 bg-purple-100 bg-opacity-50 border-0 rounded-md shadow-xl outline-none hover:bg-opacity-60 hover:bg-violet-200 group hover:shadow-2xl"
              >
                <PlayIcon className="w-24 h-24 text-[#5d39af] transition-all bg-violet-200 rounded-full opacity-90 group-hover:w-28 group-hover:h-28" />
              </button>
            </>
          )}
        </div>*/}
        {/*<CurrentSystem />*/}
      </div>
    </div>
  );
};
