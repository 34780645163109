import React, { useState } from "react";
import Cookies from "js-cookie";
import { NavLink } from "react-router-dom";

import { Button } from "components/button/Button";

const COOKIE_NAME = "consent";

export const Consent = () => {
  const [consentGiven, setConsentGiven] = useState(!!Cookies.get(COOKIE_NAME));

  const giveConsent = () => {
    setConsentGiven(true);

    Cookies.set(COOKIE_NAME, "true", { expires: 365 });
  };

  if (consentGiven) {
    return null;
  }

  return (
    <div
      className="fixed bottom-0 left-0 w-full px-5 py-5 md:px-10 xl:px-24 lg:py-8 z-50 border-t border-gray-700"
      style={{ background: "#1f1b28" }}
    >
      <div className="flex flex-wrap items-center">
        <div className="w-full pr-5 mb-5 text-white lg:pr-0 lg:w-1/2 xl:w-3/5 lg:mb-0">
          <span>
            This site uses cookies to offer you a better browsing experience.
            <br className="hidden xl:inline-block" />
            <NavLink to="/cookie-policy" className="text-indigo-400">
              Find out more on how we use cookies and how you can change your
              settings.
            </NavLink>
          </span>
        </div>
        <div className="flex flex-wrap w-full text-base text-white lg:w-1/2 xl:w-2/5 lg:justify-end">
          <Button type="button" variant="primary" onClick={giveConsent}>
            I accept cookies
          </Button>
        </div>
      </div>
    </div>
  );
};
