import { forwardRef, PropsWithoutRef } from "react";
import { FieldProps } from "types/field";
import { FieldGroup } from "../FieldGroup";

export type TextAreaFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["textarea"]>,
  "className" | "children"
> &
  FieldProps;

export const TextareaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(({ required, optional, ...props }, ref) => (
  <FieldGroup required={required} optional={optional} {...props}>
    {(computedProps) => (
      <textarea rows={4} ref={ref} {...computedProps} {...props} />
    )}
  </FieldGroup>
));
