import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "react-query";
import get from "lodash/get";
import map from "lodash/map";
import classNames from "classnames";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { useContextService } from "../../../hooks/use-context-service";
import { getStaticMenu } from "../../../api/static-pages";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";
import { NavLink } from "react-router-dom";

const AIS_MENU = "AIS_MENU";

export const Menu = () => {
  const getStaticPageService = useContextService(getStaticMenu);
  const { data, isFetching, error } = useQuery(
    [AIS_MENU],
    () => getStaticPageService(),
    {
      suspense: false,
    }
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isHover, setIsHover] = useState<undefined | number>();

  return error ? (
    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
      {get(error, "response.data.messages.detail", "Something went wrong")}
    </div>
  ) : isFetching ? (
    <div className="flex justify-center mt-24">
      <IconLoading />
    </div>
  ) : (
    <div className="flex flex-wrap items-center lg:flex-nowrap py-6 px-8 bg-white">
      {map(data?.data, (item, i) => (
        <div key={i} className="mr-8 w-full pb-3 lg:pb-0 lg:w-1/5">
          {item.sub_menus && item.sub_menus.length > 0 ? (
            <Popover
              className="relative"
              onMouseOver={() => setIsHover(i)}
              onMouseLeave={() => {
                setIsHover(undefined);
              }}
            >
              <Popover.Button className="flex items-center gap-x-1 font-medium text-lg hover:text-red-600">
                {item.image && (
                  <img src={item.image} alt="" className="w-10 mr-2" />
                )}

                <span className="block text-left">{item.title}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </Popover.Button>

              <Transition
                show={isHover === i}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute left-1/2 z-10 flex w-screen max-w-min -translate-x-1/2 px-2">
                  <div
                    className={classNames(
                      "flex rounded-xl bg-white p-2 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5",
                      {
                        "w-56": item.sub_menus.length <= 4,
                        "w-96": item.sub_menus.length > 4,
                      }
                    )}
                  >
                    <div
                      className={classNames({
                        "w-1/2": item.sub_menus.length > 4,
                      })}
                    >
                      {map(item.sub_menus.slice(0, 4), (item, key) =>
                        item.link_url ? (
                          <a
                            key={key}
                            href={item.link_url}
                            className="flex items-center font-medium text-sm hover:text-red-600 p-2 my-2 hover:bg-gray-50"
                          >
                            {item.image && (
                              <img
                                src={item.image}
                                alt=""
                                className="w-8 mr-2"
                              />
                            )}
                            {item.title}
                          </a>
                        ) : (
                          <NavLink
                            key={key}
                            to={`/pages/aspect-in-stroke/${item.page_slug}`}
                            className="flex items-center font-medium text-sm hover:text-red-600 p-2 my-2 hover:bg-gray-50"
                          >
                            {item.image && (
                              <img
                                src={item.image}
                                alt=""
                                className="w-8 mr-2"
                              />
                            )}
                            {item.title}
                          </NavLink>
                        )
                      )}
                    </div>
                    {item.sub_menus.length > 4 && (
                      <div
                        className={classNames({
                          "w-1/2": item.sub_menus.length > 4,
                        })}
                      >
                        {map(
                          item.sub_menus.slice(4, item.sub_menus.length),
                          (item, key) =>
                            item.link_url ? (
                              <a
                                key={key}
                                href={item.link_url}
                                className="flex items-center font-medium text-sm hover:text-red-600 p-2 my-2 hover:bg-gray-50"
                              >
                                {item.image && (
                                  <img
                                    src={item.image}
                                    alt=""
                                    className="w-8 mr-2"
                                  />
                                )}
                                {item.title}
                              </a>
                            ) : (
                              <NavLink
                                key={key}
                                to={`/pages/aspect-in-stroke/${item.page_slug}`}
                                className="flex items-center font-medium text-sm hover:text-red-600 p-2 my-2 hover:bg-gray-50"
                              >
                                {item.image && (
                                  <img
                                    src={item.image}
                                    alt=""
                                    className="w-8 mr-2"
                                  />
                                )}
                                {item.title}
                              </NavLink>
                            )
                        )}
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          ) : (
            <div className="mr-4">
              {item.link_url ? (
                <a
                  href={item.link_url}
                  className="flex items-center font-medium text-lg hover:text-red-600"
                >
                  {item.image && (
                    <img src={item.image} alt="" className="w-10 mr-2" />
                  )}
                  {item.title}
                </a>
              ) : (
                <NavLink
                  to={`/pages/aspect-in-stroke/${item.page_slug}`}
                  className="flex items-center font-medium text-lg hover:text-red-600"
                >
                  {item.image && (
                    <img src={item.image} alt="" className="w-10 mr-2" />
                  )}
                  {item.title}
                </NavLink>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
