import React, {
  createContext,
  FC,
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  ReactNode,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useInfiniteQuery } from "react-query";

import { useContextService } from "hooks/use-context-service";
import { Discussion, getTopics } from "api/discussions";

export const initialState = {
  dispatch: (_action: Actions) => {},
  listData: [] as Discussion[],
  isLoading: false,
  isFetching: false,
  hasNextPage: false as boolean | undefined,
  isFetchingNextPage: false as boolean | undefined,
  columnSorting: {},
  handleFetchNextPage: () => {},
};

export type Actions = {
  type: "SET_COLUMN_SORTING";
  columnSorting: {};
};

export const reducer = (
  state: typeof initialState,
  action: Actions
): typeof initialState => {
  switch (action.type) {
    case "SET_COLUMN_SORTING":
      return { ...state, columnSorting: action.columnSorting };
    default:
      throw new Error();
  }
};

export const TopicsDiscussionsContext = createContext<typeof initialState>(
  initialState
);

export interface DiscussionsFields {
  page: number;
}

export const LIST_QUERY = 'TOPICS/LIST';

export const TopicsDiscussionsContextProvider: FC<{
  children: ReactNode;
  id: string;
  type?: string;
}> = ({ children, id, type }) => {
  const methods = useForm<DiscussionsFields>({
    defaultValues: {
      page: 1,
    },
  });

  const LIST_QUERY_KEY = `DISCUSSIONS/LIST`;

  const getDiscussionCommentsQuery = useContextService(getTopics);

  const [state, dispatchAction] = useReducer(reducer, initialState);
  const { setValue } = methods;
  const [page] = methods.watch(["page"]);

  const dispatch = useCallback(
    (action: Actions) => {
      if (process.env.NODE_ENV === "development") {
        console.info("Dispatch Action", {
          ...action,
          context: LIST_QUERY_KEY,
        });
      }

      dispatchAction(action);
    },
    [LIST_QUERY_KEY]
  );

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [LIST_QUERY, id],
    () =>
      getDiscussionCommentsQuery({
        id,
        page,
        perPage: 10,
        topicType: type === "blog" ? "blog/api" : type === "project" ? "projects" : "groups",
      }),
    {
      suspense: false,
      retry: false,
      cacheTime: 0,
      keepPreviousData: false,
      getNextPageParam: (lastPage) =>
        lastPage?.data?.pagination.currentPage! <
        lastPage?.data?.pagination.total!,
      onError: (err) => console.log(err),
    }
  );

  useEffect(() => {
    if (page && page > 1) {
      fetchNextPage();
    }
  }, [page, fetchNextPage]);

  const listData = useMemo(() => {
    return (
      data?.pages
        .map((groupItem) =>
          (groupItem?.data?.data || []).map((comments) => ({
            ...comments,
          }))
        )
        .flat() || []
    );
  }, [data]);

  const handleFetchNextPage = useCallback(() => {
    setValue("page", page + 1);
  }, [setValue, page]);

  return (
    <FormProvider {...methods}>
      <TopicsDiscussionsContext.Provider
        value={{
          ...state,
          dispatch,
          listData,
          isFetching,
          isLoading,
          hasNextPage,
          isFetchingNextPage,
          handleFetchNextPage,
        }}
      >
        {children}
      </TopicsDiscussionsContext.Provider>
    </FormProvider>
  );
};
