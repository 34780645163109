import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/solid";
import { FolderIcon } from "@heroicons/react/outline";

import { Button } from "components/button/Button";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { List } from "./list/List";
import { WorkingGroupMyListContext } from "./WorkingGroupMyListContext";

import { routes } from "routes";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

export const WorkingGroups = () => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
    debouncedSearchQuery,
  } = useContext(WorkingGroupMyListContext);

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | My Working Groups</title>
      </Helmet>
      {/*<div
        className="flex flex-col items-center justify-center"
        style={{ minHeight: "300px" }}
      >
        <h2 className="text-3xl font-bold mb-8">Working groups</h2>

        <p className="text-gray-700">
          Is there a research question you've been struggling with? A clinical
          challenge or scenario you'd like input on? A general desire to learn
          more about stroke? You're likely not alone! The aim of the Working
          Group is to generate more inclusive discussion, facilitate deeper
          understanding of a research topic, and to catalyze innovative ideas.
          Together, you can help each other turn your questions into testable
          hypotheses - to accelerate stroke research and keep us moving forward.
          <br />
          Coming Soon!
        </p>
      </div>*/}

      {isLoading ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          {listData.length > 0 ? (
            <div>
              <List listData={listData} />

              <div className="flex justify-center mt-8">
                <Button
                  loading={isFetchingNextPage}
                  spinner
                  disabled={!hasNextPage}
                  onClick={handleFetchNextPage}
                >
                  Show me more
                </Button>
              </div>
            </div>
          ) : (
            <div className='border-4 border-dashed border-gray-200 rounded-lg p-8 max-w-screen-sm mx-auto'>
              <div className="text-center">
                <FolderIcon className="w-10 mx-auto text-gray-500" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No working groups found
                </h3>
                {!debouncedSearchQuery && (
                  <div>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by creating a new working group.
                    </p>
                    <div className="mt-6">
                      <NavLink to={routes.workingGroupCreate}>
                        <button
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            // ReactGA.event({
                            //   category: 'Conversion',
                            //   value: 20,
                            //   action: 'Click on Create a working group'
                            // });
                          }}
                        >
                          <PlusIcon
                            className="-ml-1 mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                          Create a Working Group
                        </button>
                      </NavLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
