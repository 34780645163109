import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import map from "lodash/map";
import { NavLink } from "react-router-dom";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";

import { Article } from "../../../api/static-pages";

export const SingleArticle = ({
  articles,
  setIsIframeScrolling,
  setIsIframeClicked,
}: {
  articles: Article[];
  setIsIframeScrolling: Dispatch<SetStateAction<boolean>>;
  setIsIframeClicked: Dispatch<SetStateAction<boolean>>;
}) => {
  const [articleIndex, setArticleIndex] = useState(0);

  return (
    <div>
      <div className="mb-8">
        <h2 className="text-2xl">{articles[articleIndex].title}</h2>

        {articles[articleIndex].image && (
          <div className="mb-4 flex justify-center">
            <img
              src={articles[articleIndex].image}
              alt=""
              className="mx-auto"
            />
          </div>
        )}

        {articles[articleIndex].iframe && (
          <div
            className="mb-4 flex justify-center iframe-overscroll"
            onMouseEnter={() => {
              setIsIframeScrolling(true);
            }}
            onMouseLeave={() => {
              setIsIframeScrolling(false);
              setIsIframeClicked(false);
            }}
            onMouseDown={() => { setIsIframeClicked(true); }}
          >
            <iframe
              width="100%"
              height="480"
              src={articles[articleIndex].iframe}
            />
          </div>
        )}

        {articles[articleIndex].description && (
          <div className="text-sm text-gray-600 mb-4">
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{
                __html: articles[articleIndex].description,
              }}
            />
          </div>
        )}


        {articles[articleIndex].hits > 0 && (
          <div className="text-sm text-gray-600 pt-4">
            Hits: {articles[articleIndex].hits}
          </div>
        )}
      </div>

      <div className="flex justify-between">
        <div className="flex justify-start">
          {articleIndex !== 0 && (
            <button
              type="button"
              className="flex text-indigo-600 items-center"
              onClick={() => {
                setArticleIndex(articleIndex - 1);
              }}
            >
              <ChevronLeftIcon className="w-8 mr-2" /> Prev
            </button>
          )}
        </div>

        {articleIndex < articles.length - 1 && (
          <div className="flex justify-end">
            <button
              type="button"
              className="flex text-indigo-600 items-center"
              onClick={() => {
                setArticleIndex(articleIndex + 1);
              }}
            >
              Next <ChevronRightIcon className="w-8 ml-2" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
