import React from "react";
import ReactPlayer from "react-player";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import { useQuery } from "react-query";
import Slider from "react-slick";

import { getProjectsTagsRequest } from "api/project/project";
import { WorkingGroupData } from "api/workingGroups";

const sliderSettings = {
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  focusOnSelect: true,
};


export const WorkingGroupPreview = ({
  workingGroup,
  created_by,
}: {
  workingGroup: WorkingGroupData;
  created_by: any;
}) => {
  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  return (
    <div className="flex flex-wrap pb-8">
      <div className="w-full pr-4">
        <h2 className="text-3xl font-bold mt-2 mb-6">{workingGroup.title}</h2>

        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full pb-8 lg:pb-0 lg:w-1/3">
            {/*{workingGroup.image ? (
              <div
                className="w-full h-60 bg-center bg-cover bg-gray-100"
                style={{ backgroundImage: `url(${workingGroup.image})` }}
              />
            ) : workingGroup.embedded_link ? (
              <ReactPlayer
                url={workingGroup.embedded_link}
                className="w-full max-w-xl mx-auto"
                width="100%"
                height="240px"
              />
            ) : null}*/}

            <Slider {...sliderSettings}>
              {workingGroup.embedded_link && (
                <div className='overflow-hidden p-1'>
                  <ReactPlayer
                    url={workingGroup.embedded_link}
                    className="w-full max-w-xl mx-auto"
                    width="100%"
                    height="300px"
                  />
                </div>
              )}

              <div className='overflow-hidden p-1'>
                <div
                  className="w-full bg-center bg-no-repeat bg-contain h-96"
                  style={{
                    backgroundImage: `url(${workingGroup.image})`,
                  }}
                />
              </div>
            </Slider>

            <div className="flex flex-wrap justify-center mt-8">
              {map(workingGroup.tags, (tag, i) => (
                <span
                  key={i}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mx-1 mb-1"
                >
                  {get(
                    find(get(projectTags, "data", []), ({ id }) => id === tag),
                    "title"
                  )}
                </span>
              ))}
            </div>
          </div>

          <div className="w-full pb-8 pl-0 lg:pl-8 lg:pb-0 lg:w-1/3">
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
              Summary
            </div>

            {workingGroup.summary && (
              <div className="text-gray-700 text-sm">
                <div
                  className='wysiwyg-editor'
                  dangerouslySetInnerHTML={{ __html: workingGroup.summary }}
                />
              </div>
            )}

            {workingGroup.additional_info && (
              <div className="text-sm text-gray-700">
                <div className="mt-5 mb-3 text-xl font-extrabold tracking-tight text-gray-900 sm:text-xl">
                  Additional info
                </div>
                <div
                  className="wysiwyg-editor"
                  dangerouslySetInnerHTML={{
                    __html: workingGroup.additional_info,
                  }}
                />
              </div>
            )}
          </div>

          <div className="w-full pb-8 pl-0 lg:pl-8 lg:pb-0 lg:w-1/3">
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
              Goals
            </div>

            {map(workingGroup.goals_of_working_group, (tag, i) => (
              <span
                key={i}
                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mr-2 mb-2"
              >
                {tag}
              </span>
            ))}

            {workingGroup.other_goals_of_wg && (
              <div className="pt-4 text-gray-700 text-sm">
                {workingGroup.other_goals_of_wg}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap justify-between pb-8">
          <div className="w-full pb-8 lg:pb-0 lg:w-1/3">
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
              Members
            </div>

            <div className="flex items-center">
              <div
                className="flex justify-center items-center rounded-full bg-gray-300 w-12 h-12 text-white font-medium bg-center bg-cover"
                style={{ backgroundImage: `url(${created_by?.data?.avatar})` }}
              >
                {!created_by?.data?.avatar
                  ? get(created_by, "data.name[0]")
                  : ""}
              </div>
              <div className="ml-3 text-sm font-medium">
                <div className="mt-3 text-center md:mt-0 md:flex md:items-center">
                  <div className="text-sm font-medium text-gray-900">
                    {created_by?.data?.name}
                  </div>

                  <svg
                    className="hidden md:block h-4 w-4 text-indigo-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z"></path>
                  </svg>

                  <div className="text-sm font-medium text-gray-500">
                    {created_by?.data?.email}
                  </div>
                </div>
                <div>{created_by?.data?.affiliation}</div>
              </div>
            </div>
          </div>

          <div className="w-full pb-8 pl-0 lg:pl-8 lg:pb-0 lg:w-1/3">
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
              Discussion
            </div>

            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
          </div>

          <div className="w-full pb-8 pl-0 lg:pl-8 lg:pb-0 lg:w-1/3">
            <div className="text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
              Updates
            </div>

            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
            <div className="bg-gray-200 w-full h-3 mb-2 rounded-lg" />
          </div>
        </div>
      </div>
    </div>
  );
};
