import React from "react";
import { useQuery } from "react-query";
import get from "lodash/get";

import { SupplementaryMaterialsForm } from "./SupplementaryMaterialsForm";
import { supplementaryItemsGet } from "api/workingGroups";
import { useContextService } from "hooks/use-context-service";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

const SUPPLEMENTARY_ITEMS_GET = "SUPPLEMENTARY_ITEMS_GET";

export const SupplementaryMaterialsEdit = ({ id, onSuccess }: { id: string; onSuccess: () => void }) => {
  const supplementaryItemsGetQuery = useContextService(supplementaryItemsGet);

  const {
    data: supplementaryItemsData,
    isFetching: supplementaryItemsIsFetching,
    error,
  } = useQuery(
    [SUPPLEMENTARY_ITEMS_GET, id],
    () => supplementaryItemsGetQuery({ group_id: id }),
    {
      suspense: false,
      cacheTime: 0,
    }
  );

  return (
    <div>
      {id &&
      ((supplementaryItemsIsFetching && !supplementaryItemsData) ||
        (supplementaryItemsIsFetching && !supplementaryItemsData)) ? (
        <div className="flex items-center justify-between min-h-full">
          <IconLoading />
        </div>
      ) : error ? (
        <div className="w-full py-3 px-4 rounded mb-1 bg-red-100 text-sm text-red-800 mt-5">
          {get(error, "response.data.messages.detail")}
        </div>
      ) : (
        <SupplementaryMaterialsForm
          id={id}
          onSuccess={onSuccess}
          supplementary_items={supplementaryItemsData?.data!}
        />
      )}
    </div>
  );
};
