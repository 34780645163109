import { Context } from "types";
import { withParams } from "utils/url";

export type LGP_MENU = {
  title: string;
  page_slug: string;
  page_link: string | null;
  image: string | null;
  link_text: string;
  link_url: string;
  sub_menus: LGP_MENU[];
}

export const getDashboardCounters =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      total_amount_raised: number;
      total_amount_contributed: number;
      total_contributors: number;
      total_projects: number;
      total_successful_projects: number;
      total_supported_projects: number;
      total_favorite_projects: number;
      total_working_groups: number;
      total_working_groups_joined: number;
    }>({
      url: withParams("/dashboard/counters"),
      method: "GET",
    });

export const getBanner =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      banner: string;
      banner_link: string;
    }>({
      url: withParams("/fe-style"),
      method: "GET",
    });

export const getBackedProjects =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      data: {
        title: string;
        slug: string;
      }[];
    }>({
      url: withParams("/me/backed-projects", { perPage: 99 }),
      method: "GET",
    });


export const getFavoriteProjects =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      data: {
        title: string;
        slug: string;
      }[];
    }>({
      url: withParams("/me/favorite-projects", { perPage: 99 }),
      method: "GET",
    });


export const getLGPMenu =
  ({ apiClient }: Context) =>
  () =>
    apiClient<LGP_MENU[]>({
      url: withParams("/menus/lgp"),
      method: "GET",
    });
