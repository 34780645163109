import { MailIcon } from "@heroicons/react/outline";
import { contactUs } from "api/contact";
import { useContextService } from "hooks/use-context-service";
import React, { useState } from "react";

import { Button } from "components/button/Button";
import { CheckField } from "../check-field/CheckField";

export const Contact = () => {
  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    privacy: false,
  });

  const [loading, setLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [error, setError] = useState(false);

  const contactUsService = useContextService(contactUs);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      await contactUsService({
        name: form.name,
        email: form.email,
        text: form.message,
        // phone_number: form.phone,
      });

      setSubmitted(true);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 " />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5 contact-bg-gradient xl:my-16">
        <div className="px-4 py-16 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
              Get ready to make an impact
            </h2>
            <p className="mt-3 text-lg leading-6 text-white">
              Sign up for updates
            </p>
            <dl className="mt-8 text-base text-white">
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 w-6 h-6 text-white"
                    aria-hidden="true"
                  />
                  <a href="mailto:contact@letsgetproof.com" className="ml-3">
                    contact@letsgetproof.com
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {error && (
              <p className="inline-block px-3 py-px mx-auto font-medium text-red-800 bg-red-200 rounded-full">
                Something went wrong.
              </p>
            )}
            {submitted && (
              <p className="inline-block px-3 py-px mx-auto font-medium text-teal-800 bg-teal-200 rounded-full">
                Thank you for subscribing!
              </p>
            )}
            {!submitted && (
              <form
                method="POST"
                className="grid grid-cols-1 gap-y-6"
                onSubmit={onSubmit}
              >
                <div>
                  <label htmlFor="full-name" className="sr-only">
                    Full name
                  </label>
                  <input
                    type="text"
                    name="full-name"
                    id="full-name"
                    autoComplete="name"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Full name"
                    required
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </div>
                <div>
                  <label htmlFor="email" className="sr-only">
                    Email
                  </label>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Email"
                    required
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                </div>
                {/* <div>
                  <label htmlFor="phone" className="sr-only">
                    Phone
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Phone"
                    onChange={(e) =>
                      setForm({ ...form, phone: e.target.value })
                    }
                  />
                </div> */}
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder="Message"
                    defaultValue={""}
                    required
                    onChange={(e) =>
                      setForm({ ...form, message: e.target.value })
                    }
                  />
                </div>

                <div>
                  <CheckField
                    label="You consent to the processing of your personal data for marketing and promotional communications, as described in our Privacy Policy"
                    checked
                    name="check"
                    labelClassName="text-white"
                  />
                </div>

                <div>
                  <Button type="submit" variant="transparentWhite" rounded='rounded-full' spinner loading={loading}>
                    <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                      sign up
                    </div>
                  </Button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
