import React from "react";
import { WorkingGroups } from "./WorkingGroups";
import { WorkingGroupMyListContextProvider } from "./WorkingGroupMyListContext";

export const WorkingGroupsContainer = () => {
  return (
    <WorkingGroupMyListContextProvider>
      <WorkingGroups />
    </WorkingGroupMyListContextProvider>
  );
};
