import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { useContextService } from "hooks/use-context-service";
import { RefreshIcon } from "@heroicons/react/outline";
import get from "lodash/get";

import { getBlog } from "api/blogs";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { Button } from "components/button/Button";
import { LIST_QUERY } from "components/discussions/TopicsDiscussions/TopicsDiscussionsContext";
import { TopicsDiscussions } from "components/discussions/TopicsDiscussions/TopicsDiscussions";
import { format } from "date-fns";
import { withParams } from "../../../utils/url";
import { routes } from "../../../routes";
import {Helmet} from "react-helmet";

const GET_BLOG = "GET_BLOG";

interface ParamTypes {
  id: string;
}

export const BlogSingle = () => {
  const { id } = useParams<ParamTypes>();
  const [showDiscussions, setShowDiscussions] = useState(true);
  const queryClient = useQueryClient();

  const getBlogService = useContextService(getBlog);

  const { data, isFetching, error } = useQuery(
    [GET_BLOG, id],
    () => getBlogService({ id }),
    {
      suspense: false,
    }
  );

  return (
    <div>
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail")}
        </div>
      ) : !data || isFetching ? (
        <div className="flex justify-center w-full mt-24">
          <IconLoading />
        </div>
      ) : (
        <div className="w-full pr-4">
          <Helmet>
            <title>Let’s Get Proof | Blog | {data?.data?.title}</title>
          </Helmet>

          <nav className="flex mb-8" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div className="flex items-center">
                  <NavLink
                    to={routes.blogList}
                    className="mr-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  >
                    Blogs
                  </NavLink>

                  <svg
                    className="h-5 w-5 flex-shrink-0 text-gray-300"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    aria-hidden="true"
                  >
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                  </svg>
                </div>
              </li>
            </ol>
          </nav>

          <div className="my-2">
            {data?.data?.categories?.map(({ name, id }, i) => (
              <a
                href={withParams(routes.blogList, { categories: id })}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                key={i}
                className="rounded-md mr-2 mt-2 bg-yellow-500 bg-opacity-90 py-0.5 px-1 text-xs uppercase leading-4 tracking-wide text-white"
              >
                {name}
              </a>
            ))}
          </div>

          <h2 className="mt-2 mb-2 text-3xl font-bold">{data?.data?.title}</h2>

          <NavLink
            to={withParams(routes.researcherProfile, {
              id: data?.data?.author?.id,
            })}
            className="flex items-center space-x-1 text-gray-600"
          >
            <div
              className="flex items-center justify-center w-10 h-10 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full mr-1"
              style={{
                backgroundImage: `url(${data?.data?.author?.image})`,
              }}
            >
              {!data?.data?.author?.image
                ? get(data?.data?.author?.name, "[0]")
                : ""}
            </div>

            <div>
              <div className="-mb-1">{data?.data?.author?.name}</div>
              {data?.data?.date && (
                <time
                  dateTime={data?.data?.date}
                  className="text-sm text-gray-400"
                >
                  {format(new Date(data?.data?.date), "MMM dd, YYY")}
                </time>
              )}
            </div>
          </NavLink>

          {data?.data?.header_image && (
            <div className="ml-auto my-8 flex justify-center">
              <img
                src={data?.data?.header_image}
                alt={data?.data?.title}
                className="w-full"
                style={{ maxWidth: "760px" }}
              />
            </div>
          )}

          {data?.data?.body && (
            <div className="text-gray-700 mt-6">
              <div
                className="wysiwyg-editor"
                dangerouslySetInnerHTML={{ __html: data?.data?.body }}
              />
            </div>
          )}
        </div>
      )}

      <div className="w-full pt-8">
        {get(data, "data.id") && (
          <div className="pt-8 mt-8 border-t border-gray-200">
            <div className="lg:w-2/3">
              <div className="flex items-center text-xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                Discussion
                <Button
                  type="button"
                  variant="white"
                  size="exSmall"
                  className="ml-4"
                  onClick={() => {
                    setShowDiscussions(false);
                    queryClient.invalidateQueries(LIST_QUERY);
                    setTimeout(() => {
                      setShowDiscussions(true);
                    }, 0);
                  }}
                >
                  <RefreshIcon className="w-3 mr-1" />
                  Refresh
                </Button>
              </div>
              {showDiscussions && (
                <TopicsDiscussions
                  id={get(data, "data.id")}
                  is_member={true}
                  type="blog"
                  can_create_discussion={!!data?.data?.can_create_discussion}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
