import { Context } from "../types";

type Lecture = {
  id: string;
  title: string;
  summary: string;
  video_file_url: string;
  author: string;
  image: string;
  tags: { title: string }[];
};

export const getLectureSeries =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      data: Lecture[];
    }>({
      url: "/expert-lectures",
      method: "GET",
    });

export const getLectureSeriesSingle =
  ({ apiClient }: Context) =>
  (id: string) =>
    apiClient<{
      data: Lecture;
    }>({
      url: `/expert-lectures/${id}`,
      method: "GET",
    });

export const getLectureSeriesLatest =
  ({ apiClient }: Context) =>
  () =>
    apiClient<{
      data: Lecture;
    }>({
      url: "/expert-lectures/latest",
      method: "GET",
    });
