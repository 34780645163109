import React, { forwardRef } from "react";
import { Controller, FieldError } from "react-hook-form";

import { FieldGroup } from "../FieldGroup";
import { SelectOption } from "../../types/field";
import classNames from "classnames";

export interface CustomSelectProps {
  name: string;
  label: string;
  required?: boolean;
  optional?: boolean;
  control: any;
  rules?: any;
  error?: FieldError;
  defaultFirst?: boolean;
  defaultValue?: unknown;
  disabled?: boolean;
  isSearchable?: boolean;
  options: SelectOption[];
  isLoading?: boolean;
}

export const RadioField = forwardRef(
  (
    {
      control,
      name,
      disabled,
      rules,
      required,
      options,
      optional,
      ...props
    }: CustomSelectProps,
    ref
  ) => {
    return (
      <FieldGroup
        required={required}
        optional={optional}
        hasShadow={false}
        name={name}
        {...props}
      >
        {() => (
          <Controller
            rules={rules}
            name={name}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <>
                {options &&
                  options.map((option, index) => (
                    <div className="flex items-center mb-2" key={index}>
                      <input
                        id={`opt-${name}-${option.value}`}
                        type="radio"
                        value={option.value}
                        checked={value == option.value}
                        disabled={disabled}
                        onChange={onChange}
                        name={`opt-${name}`}
                        className="w-4 h-4 text-blue-600 bg-white border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor={`opt-${name}-${option.value}`}
                        className={classNames("ml-2 text-sm font-medium", [ disabled ? 'text-gray-500' : 'text-gray-900' ])}
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
              </>
            )}
          />
        )}
      </FieldGroup>
    );
  }
);
