import React from "react";
import classNames from "classnames";
import { format } from "date-fns";
import { useQuery, useQueryClient } from "react-query";

import { useContextService } from "hooks/use-context-service";
import { getUserNotifications, Notification } from "api/account";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";
import { USER_QUERY_KEY } from "queries/use-user-query";
import { withParams } from "utils/url";
import { routes } from "routes";

const NotificationItem = ({
  notification: { title, message, read_at, created_at },
  hasLink,
}: {
  notification: Notification;
  hasLink?: boolean;
}) => {
  return (
    <div
      className={classNames("m-4 px-3 py-2 rounded shadow", [
        read_at ? "bg-white" : "bg-yellow-100",
        hasLink ? "hover:bg-gray-100" : "",
      ])}
    >
      <div className="flex items-start justify-between">
        <div className="flex-1 text-gray-800 mb-3">
          <b>{title}</b>
        </div>
        <div className="w-12 text-gray-500 text-xs text-right pl-1">
          {format(new Date(created_at), "MMM dd")}
          <br />
          {format(new Date(created_at), "HH:mm")}
        </div>
      </div>
      <div className="text-gray-600 mt-2">{message}</div>
    </div>
  );
};

export const formatLink = ({
  in_app_route,
  object_slug,
  content_type,
  invite_token,
}: {
  in_app_route: string;
  object_slug: string;
  content_type: string;
  invite_token?: string;
}) => {
  if (in_app_route) {
    return in_app_route;
  }
  if (content_type === "project") {
    return withParams(routes.project, { id: object_slug, invite_token });
  }
  if (content_type === "workinggroup") {
    return withParams(routes.workingGroupSingle, {
      id: object_slug,
      invite_token,
    });
  }
  if (content_type === "entrypage") {
    return withParams(routes.blogSingle, {
      id: object_slug,
    });
  }

  return "/";
};

export const NotificationList = () => {
  const queryClient = useQueryClient();

  const { data: notifications, isFetching } = useQuery(
    "me/notifications",
    useContextService(getUserNotifications),
    {
      suspense: false,
      onSuccess: () => {
        queryClient.invalidateQueries(USER_QUERY_KEY);
      },
    }
  );

  return (
    <div
      className="overflow-y-auto rounded-lg bg-gray-50 shadow-lg ring-1 ring-black ring-opacity-5"
      style={{ maxHeight: "350px" }}
    >
      <div className="text-lg font-bold pt-5 px-6 -mb-2">Notifications</div>
      {!notifications || isFetching ? (
        <div className="flex justify-center my-3">
          <IconLoading />
        </div>
      ) : (
        <div>
          {notifications?.data?.data?.map((notification, i) =>
            notification.in_app_route || notification.object_slug ? (
              <a
                href={formatLink({
                  in_app_route: notification.in_app_route,
                  object_slug: notification.object_slug,
                  content_type: notification.content_type,
                })}
                key={i}
              >
                <NotificationItem notification={notification} hasLink />
              </a>
            ) : (
              <NotificationItem notification={notification} key={i} />
            )
          )}

          {notifications?.data?.data?.length === 0 && (
            <div className="py-4 px-6 text-gray-500">No notifications</div>
          )}
        </div>
      )}
    </div>
  );
};
