import React, { Suspense } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import classNames from "classnames";
import { routes } from "../../routes";
import { PrivateRoute } from "components/PrivateRoute";

import { WorkingGroupsDescription } from "./WorkingGroupsDescription";
import { WorkingGroupCreate } from "./working-group-create/WorkingGroupCreate";
import { WorkingGroupEdit } from "./working-group-edit/WorkingGroupEdit";
import { WorkingGroupsContainer } from "./working-group-my-list/WorkingGroupsContainer";
import { WorkingGroupMyJoined } from "./working-group-my-joined/WorkingGroupMyJoined";
import { WorkingGroupMyApplied } from "./working-group-my-applied/WorkingGroupMyApplied";
import { WorkingGroupsBrowseContainer } from "./working-group-browse/WorkingGroupsBrowseContainer";
import { WorkingGroupSingle } from "./working-group-single/WorkingGroupSingle";
import { Button } from "../../components/button/Button";
import ReactGA from "react-ga4";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";

const tabs = [
  {
    route: routes.workingGroupsMy,
    name: "Created",
  },
  {
    route: routes.workingGroupsMyJoined,
    name: "Joined",
  },
  {
    route: routes.workingGroupsMyApplied,
    name: "My applications",
  },
];

export const WorkingGroups = () => {
  return (
    <Switch>
      <PrivateRoute path={routes.workingGroupCreate}>
        <WorkingGroupCreate />
      </PrivateRoute>
      <PrivateRoute path={routes.workingGroupEdit}>
        <WorkingGroupEdit />
      </PrivateRoute>
      <Route path={routes.workingGroupsList}>
        <WorkingGroupsBrowseContainer />
      </Route>
      <PrivateRoute path={routes.workingGroupsMy}>
        <div>
          <h2 className="flex justify-between text-2xl sm:text-3xl font-bold mb-4">
            My Working Groups
            <NavLink to={routes.workingGroupCreate}>
              <Button
                size="small"
                onClick={() => {
                  // ReactGA.event({
                  //   category: "Conversion",
                  //   value: 20,
                  //   action: "Click on Create a working group",
                  // });
                }}
              >
                Start a working group
              </Button>
            </NavLink>
          </h2>

          <div className="flex w-full flex-wrap mb-8">
            {tabs.map(({ route, name }) => (
              <NavLink
                key={route}
                to={route}
                activeClassName="border-b-4 border-indigo-600 text-black"
                exact
                className={classNames(
                  "md:w-auto flex items-center justify-center h-full text-sm relative font-medium mr-6 py-3 md:py-4 text-gray-500 text-base"
                )}
              >
                {name}
              </NavLink>
            ))}
          </div>

          <Switch>
            <Route path={routes.workingGroupsMy} exact>
              <WorkingGroupsContainer />
            </Route>

            <Route path={routes.workingGroupsMyJoined}>
              <WorkingGroupMyJoined />
            </Route>
            <Route path={routes.workingGroupsMyApplied}>
              <WorkingGroupMyApplied />
            </Route>
          </Switch>
        </div>
      </PrivateRoute>
      <Route path={routes.workingGroupSingle}>
        <Suspense
          fallback={
            <div className="flex justify-center my-8">
              <IconLoading />
            </div>
          }
        >
          <WorkingGroupSingle />
        </Suspense>
      </Route>
      <Route path={routes.workingGroups}>
        <WorkingGroupsDescription />
      </Route>
    </Switch>
  );
};
