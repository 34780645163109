import React, { useContext } from "react";
import { FolderIcon, SearchIcon } from "@heroicons/react/outline";
import { useFormContext } from "react-hook-form";
import map from "lodash/map";
import get from "lodash/get";
import { useQuery } from "react-query";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { Button } from "components/button/Button";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";
import { SelectField } from "components/select-field/SelectField";
import { TextField } from "components/text-field/TextField";

import { WorkingGroupBrowseContext } from "./WorkingGroupBrowseContext";
import { List } from "./list/List";
import { AppContext } from "../../../context";
import { getProjectsTagsRequest } from "api/project/project";
import {Helmet} from "react-helmet";

const sortedList = [
  { value: "-created_at", label: "Newest" },
  { value: "title", label: "Title" },
  { value: "popular", label: "Popular" },
];

export const WorkingGroups = () => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
  } = useContext(WorkingGroupBrowseContext);

  const { authToken } = useContext(AppContext);
  const { register, control } = useFormContext();

  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  return (
    <div className='font-nunito'>
      <Helmet>
        <title>Let’s Get Proof | Working Groups</title>
      </Helmet>

      <h2 className="font-nunito font-bold text-2xl lg:text-3xl text-blue-550 mb-8">Browse Working Groups</h2>

      <div className="flex flex-wrap items-center mb-8 bg-white py-3 px-4 rounded border border-gray-200">
        <div className="flex items-center w-full mb-3 lg:mb-0 lg:w-48 lg:mr-8">
          <div className="flex-1 w-full">
            <MultiSelectField
              label="Topics"
              name="tags"
              control={control}
              defaultFirst
              options={map(get(projectTags, "data"), ({ slug, title }) => ({
                value: slug,
                label: title,
              }))}
            />
          </div>
        </div>
        <div className="flex items-center w-full mb-3 lg:mb-0 lg:w-48">
          <div className="flex-1 w-full">
            <SelectField
              label="Sorted by"
              options={
                authToken
                  ? [
                      ...sortedList,
                      {
                        value: "recommended",
                        label: "Recommended for you",
                      },
                    ]
                  : sortedList
              }
              {...register("ordering")}
            />
          </div>
        </div>
        <div className="w-full lg:w-64 ml-auto relative">
          <TextField
            label="&nbsp;"
            loading={isLoading}
            placeholder="Search"
            {...register("searchQuery")}
          />

          {!isLoading && (
            <SearchIcon className="text-gray-500 absolute bottom-2 right-2 w-5" />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          {listData.length > 0 ? (
            <div>
              <List listData={listData} />

              <div className="flex justify-center mt-8">
                <Button
                  loading={isFetchingNextPage}
                  spinner
                  disabled={!hasNextPage}
                  onClick={handleFetchNextPage}
                >
                  Show me more
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-center">
                <FolderIcon className="w-10 mx-auto text-gray-500" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No working groups found
                </h3>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
