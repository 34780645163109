import { withParams } from "utils/url";
import { getHeaders, apiUrl, axiosInstance } from "../index";

const api = require("./api.json");

export const getUserRequest = () =>
  axiosInstance.get(`${apiUrl()}${withParams(api.me)}`, getHeaders());

export const getOtpRequest = () =>
  axiosInstance.get(`${apiUrl()}${withParams(api.otp)}`, getHeaders());

export const getUserNotificationsRequest = () =>
  axiosInstance.get(
    `${apiUrl()}${withParams(api.notifications)}`,
    getHeaders()
  );

export const meSetAvatarRequest = (data: any) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.meSetAvatar)}`, data, {
    headers: {
      ...getHeaders().headers,
      "content-type": "multipart/form-data",
    },
  });

export const meSetBackgroundRequest = (data: any) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.meSetBackground)}`, data, {
    headers: {
      ...getHeaders().headers,
      "content-type": "multipart/form-data",
    },
  });
