import React from "react";
import { NavLink } from "react-router-dom";
import ReactGA from "react-ga4";

import { Button } from "../../button/Button";

import { routes } from "routes";

export const HeroGrant = () => {
  return (
    <div>
      <div className="relative overflow-hidden bg-white grant-hero">
        {/*<div className="w-full h-full grant-hero-image" />*/}
        <div className="w-full max-w-screen-xl px-4 py-16 mx-auto md:px-8 ">
          <h1
            className="font-nunitoexpanded text-2xl md:text-4xl lg:text-5xl leading-snug md:leading-snug lg:leading-snug font-semibold text-white"
            style={{ maxWidth: "750px" }}
          >
            Win $25,000 CAD <br />
            in catalyst grant <br />
            funding for new <br />
            medical research!
          </h1>

          <div className="mt-10 italic text-xl md:text-2xl font-nunito font-medium text-white">
            What would you do with $25k to spend on your <br /> own research?
          </div>

          <div className="mt-10">
            <NavLink
              to={routes.register}
              onClick={() => {
                // ReactGA.event({
                //   category: "User",
                //   action: "Clicked the sign up button",
                // });
              }}
            >
              <Button
                variant={"red"}
                size="small"
                rounded="rounded-full"
              >
                <div className="font-semibold px-4 py-1">SIGN UP</div>
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
