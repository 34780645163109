import React from "react";
import classNames from "classnames";
import AuthCode from "react-auth-code-input";
import { FieldError } from "react-hook-form";

import { ErrorMessage } from "components/ErrorMessage";

interface InputProps {
  error?: FieldError;
  label?: string | JSX.Element;
  className?: string;
  onChange: (value: any) => void;
  value?: string;
}

export const AuthCodeInput = ({
  onChange,
  value,
  label,
  error,
  className,
}: InputProps) => {
  return (
    <div className={classNames("relative pb-4", className)}>
      <div className="auth-code">
        <div
          className={classNames("auth-code-wrapper relative", {
            "mb-2": error,
          })}
        >
          {label && (
            <div
              className={classNames("mb-2 font-medium text-gray-700 text-sm")}
            >
              {label}

              <sup className="text-xs text-red-400">*</sup>
            </div>
          )}
          <div className="block">
            <AuthCode
              onChange={onChange}
              characters={6}
              allowedCharacters={/(?=.*\d)/}
              inputStyle={{
                width: "50px",
                height: "50px",
                boxSizing: "border-box",
                padding: "8px",
                borderRadius: "2px",
                fontSize: "16px",
                textAlign: "center",
                textTransform: "uppercase",
              }}
            />
          </div>
        </div>
      </div>
      <div className="absolute bottom-0">
        {error && <ErrorMessage>{error.message}</ErrorMessage>}
      </div>
    </div>
  );
};
