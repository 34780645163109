import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";

import { TextareaField } from "components/textarea-field/TextareaField";
import { Button } from "components/button/Button";

import { joinRequestWorkingGroup } from "api/workingGroups";
import { setFormError } from "utils/setError";
import { pipe } from "fp-ts/function";
import { useContextService } from "../../../hooks/use-context-service";
import { useStringRequired } from "../../../hooks/use-validation-input";
import ReactGA from "react-ga4";

const JOIN_WORKING_GROUP_REQUEST = "JOIN_WORKING_GROUP_REQUEST";

interface JoinRequestFormSchema {
  message: string;
}

const invalidType = { invalid_type_error: "This field is required" };

const useFormSchema = () =>
  z.object({
    message: pipe(z.string(invalidType), useStringRequired()),
  });

export const JoinRequestForm = ({
  slug,
  onSuccess,
}: {
  slug: string;
  onSuccess: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
  } = useForm<JoinRequestFormSchema>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),
  });

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const joinRequestWorkingGroupService = useContextService(
    joinRequestWorkingGroup
  );

  const joinRequestWorkingGroupMutation = useMutation(
    [JOIN_WORKING_GROUP_REQUEST],
    (message: string) => joinRequestWorkingGroupService(slug, message),
    {
      onSuccess: () => {
        onSuccess();
        // ReactGA.event({
        //   category: 'Conversion',
        //   value: 10,
        //   action: 'Working Group Private Member Join'
        // });
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const onSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    joinRequestWorkingGroupMutation.mutate(data.message);
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="w-full mb-5">
            <TextareaField
              label="Message"
              placeholder="Message"
              error={formState.errors?.message}
              {...register("message", { required: true })}
            />
          </div>

          {formErrorMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="submit"
            disabled={joinRequestWorkingGroupMutation.isLoading}
            className="w-full"
            loading={joinRequestWorkingGroupMutation.isLoading}
            spinner
          >
            Private Member join
          </Button>
        </form>
      </div>
    </div>
  );
};
