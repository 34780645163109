import React, { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { pipe } from "fp-ts/lib/function";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";

import {
  Discussion,
  postTopicDiscussionComment,
  putTopicDiscussionComment,
} from "api/discussions";
import { Button } from "components/button/Button";
import { RichEditorField } from "components/rich-editor-field/RichEditorField";
import { useContextService } from "hooks/use-context-service";
import { useStringRequired } from "hooks/use-validation-input";
import { CheckField } from "../../check-field/CheckField";
import ReactGA from "react-ga4";

const useFormSchema = () =>
  z.object({
    text: pipe(z.string(), useStringRequired()).refine(
      (val) => val !== "<p><br></p>" || !val,
      {
        message: "This field is required",
      }
    ),
    is_public: z.boolean().nullable().optional(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const TopicsCommentForm = ({
  group_id,
  comment_id,
  reply_id,
  onSubmit,
  onReplyCancel,
  onEditCancel,
  type,
  defaultValues,
  can_set_private,
}: {
  group_id: string;
  comment_id?: string;
  reply_id?: string;
  type?: string;
  can_set_private?: boolean;
  defaultValues?: { text: string; is_public?: boolean | null };
  onSubmit: (comment: Discussion) => void;
  onReplyCancel?: () => void;
  onEditCancel?: () => void;
}) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormSchema>({
    defaultValues: {
      ...defaultValues,
      is_public: !(defaultValues?.is_public ?? true),
    },
    resolver: zodResolver(useFormSchema()),
  });

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const discussionCommentMutation = useMutation(
    useContextService(postTopicDiscussionComment),
    {
      onSuccess: (response) => {
        onSubmit(response?.data?.data!);
        reset();
      },
      onError: (e) => {
        setFormErrorMessage("Something went wrong");
        return new Promise(() => {});
      },
    }
  );
  const discussionEditCommentMutation = useMutation(
    useContextService(putTopicDiscussionComment),
    {
      onSuccess: (response) => {
        onSubmit(response?.data?.data!);
      },
      onError: (e) => {
        setFormErrorMessage("Something went wrong");
        return new Promise(() => {});
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        if (comment_id) {
          discussionEditCommentMutation.mutateAsync({
            id: group_id,
            text: data.text,
            is_public: !data.is_public,
            comment_id,
            topicType:
              type === "blog"
                ? "blog/api"
                : type === "project"
                ? "projects"
                : "groups",
          });
        } else {
          discussionCommentMutation.mutateAsync({
            id: group_id,
            text: data.text,
            is_public: !data.is_public,
            parent: reply_id,
            topicType:
              type === "blog"
                ? "blog/api"
                : type === "project"
                ? "projects"
                : "groups",
          });

          // ReactGA.event({
          //   category: "User",
          //   action: `${
          //     type === "blog"
          //       ? "blog/api"
          //       : type === "project"
          //       ? "projects"
          //       : "groups"
          //   } topic added`,
          // });
        }
      })}
      className=""
    >
      <div className="flex items-center text-lg mb-3 font-extrabold tracking-tight text-gray-900 sm:text-xl">
        {comment_id ? "Edit" : reply_id ? "Reply" : "Add topic"}

        {onReplyCancel && (
          <Button
            variant="white"
            size="exSmall"
            type="button"
            onClick={onReplyCancel}
          >
            Cancel
          </Button>
        )}
        {onEditCancel && (
          <Button
            variant="white"
            size="exSmall"
            type="button"
            onClick={onEditCancel}
            className="ml-3"
          >
            Cancel
          </Button>
        )}
      </div>

      <div className="mb-5">
        <RichEditorField
          label="Topic"
          name="text"
          error={errors?.text}
          required
          control={control}
        />
      </div>
      {can_set_private && (
        <div className="mb-5">
          <CheckField
            label="Make private"
            error={errors.is_public}
            {...register(`is_public`)}
          />
        </div>
      )}

      {formError && (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {formError}
        </div>
      )}

      <Button
        type="submit"
        className="w-full"
        loading={
          discussionCommentMutation.isLoading ||
          discussionEditCommentMutation.isLoading
        }
        spinner
        disabled={
          discussionCommentMutation.isLoading ||
          discussionEditCommentMutation.isLoading
        }
      >
        Submit
      </Button>
    </form>
  );
};
