import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { pipe } from "fp-ts/function";
import { useStringRequired } from "hooks/use-validation-input";
import {
  ProjectUpdates,
  postProjectUpdate,
  patchProjectUpdate,
  projectUpdateSetImageRequest,
} from "api/project";
import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";
import { useContextService } from "hooks/use-context-service";
import { setFormError } from "../../../utils/setError";
import get from "lodash/get";
import { NavLink, useHistory } from "react-router-dom";
import { routes } from "../../../routes";
import { withParams } from "../../../utils/url";
import { HomeIcon } from "@heroicons/react/outline";
import { PlayerField } from "../../../components/player-field/PlayerField";
import { ImageUploadField } from "../../../components/image-upload-field/ImageUploadField";
import { RichEditorField } from "../../../components/rich-editor-field/RichEditorField";

const useFormSchema = () =>
  z.object({
    title: pipe(z.string(), useStringRequired()),
    text: pipe(z.string(), useStringRequired()),
    image: z.string().optional().nullable(),
    video_url: z.string().optional().nullable(),
  });

export const ProjectUpdatesForm = ({
  project_id,
  id,
  defaultValues,
}: {
  project_id: string;
  id: string;
  defaultValues: ProjectUpdates;
}) => {
  const history = useHistory();

  const {
    formState,
    register,
    handleSubmit,
    setError,
    setValue,
    control,
  } = useForm<ProjectUpdates>({
    defaultValues: id ? defaultValues : undefined,
    resolver: zodResolver(useFormSchema()),
  });

  const postProjectUpdateQuery = useMutation(
    useContextService(postProjectUpdate),
    {
      onSuccess: (response) => {
        console.log(response);
        // setCreatedId(response.data.data.id);
        // setFormSuccessMessage("Success");
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const patchProjectUpdateQuery = useMutation(
    useContextService(patchProjectUpdate),
    {
      onSuccess: (response) => {
        console.log(response);
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const uploadImageQuery = useMutation(projectUpdateSetImageRequest);

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const onSubmit = async (submitData: ProjectUpdates) => {
    const data = { ...submitData };
    data.image = undefined;

    try {
      const response = await (id
        ? patchProjectUpdateQuery.mutateAsync({ id, project_id, data })
        : postProjectUpdateQuery.mutateAsync({ project_id, data }));

      if (submitData.image && !submitData.image.includes("s3.amazonaws.com")) {
        const image = await fetch(submitData.image);

        const formData = new FormData();
        formData.append("file", await image.blob(), "project");

        try {
          await uploadImageQuery.mutateAsync({
            id: get(response, "data.data.id", ""),
            project_id,
            data: formData,
          });
        } catch (e) {
          setFormError(get(e, "response.data"), setFormErrorMessage, setError);
        }
      }

      if (!id) {
        setTimeout(() => {
          history.push(
            withParams(routes.projectUpdatesEdit, {
              id: get(response, "data.data.id"),
              project_id,
            })
          );
        }, 2000);
      }

      setFormSuccessMessage("Success");
    } catch (e) {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full max-w-screen-sm">
      <h2 className="text-3xl font-bold mb-4">
        {id ? "Edit updates" : "Add an update"}
      </h2>

      <nav className="flex mb-8" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div className="flex items-center">
              <NavLink
                to={routes.dashboard}
                className="mr-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                <HomeIcon className="w-5" />
              </NavLink>

              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <NavLink
                to={routes.myProjects}
                className="mr-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Projects
              </NavLink>

              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <NavLink
                to={withParams(routes.projectUpdatesList, { project_id })}
                className="mr-2 text-sm font-medium text-gray-500 hover:text-gray-700"
              >
                Projects updates
              </NavLink>

              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
            </div>
          </li>

          <li>
            <div className="flex items-center text-sm font-medium text-gray-900">
              {id ? "Edit" : "Add"}
            </div>
          </li>
        </ol>
      </nav>

      <div className="mb-5">
        <TextField
          label="Title"
          placeholder="Title"
          error={formState.errors?.title}
          {...register("title", { required: true })}
        />
      </div>

      <div className="mb-5">
        <RichEditorField
          label="Text of update"
          name="text"
          error={formState.errors?.text}
          required
          control={control}
        />
      </div>

      <div className="mb-5">
        <ImageUploadField
          label="Project image"
          name="image"
          error={formState.errors?.image}
          control={control}
          setValue={setValue}
        />
      </div>

      <div className="mb-5">
        <PlayerField
          label="Project video"
          optional
          name="video_url"
          error={formState.errors?.video_url}
          control={control}
        />
      </div>

      {formErrorMessage && (
        <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
          {formErrorMessage}
        </div>
      )}

      {formSuccessMessage && (
        <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
          {formSuccessMessage}
        </div>
      )}

      <Button
        type="button"
        onClick={handleSubmit(onSubmit)}
        className="w-full"
        loading={
          postProjectUpdateQuery.isLoading ||
          patchProjectUpdateQuery.isLoading ||
          uploadImageQuery.isLoading
        }
        disabled={
          postProjectUpdateQuery.isLoading ||
          patchProjectUpdateQuery.isLoading ||
          uploadImageQuery.isLoading
        }
        spinner
      >
        Save
      </Button>
    </form>
  );
};
