import React, { useMemo, useRef } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "../../components/text-field/TextField";
import { TextareaField } from "../../components/textarea-field/TextareaField";
import { Button } from "../../components/button/Button";
import { useMutation } from "react-query";
import { useContextService } from "../../hooks/use-context-service";
import { contactUs } from "api/contact";
import {Helmet} from "react-helmet";

export interface ContactUsForm {
  name: string;
  email: string;
  text: string;
}

export const ContactUs = () => {
  const schema = useMemo(() => {
    return z.object({
      name: z.string().nonempty("Required"),
      email: z.string().email().nonempty("Required"),
      text: z.string().nonempty("Required"),
    });
  }, []);

  const { register, handleSubmit, formState } = useForm<ContactUsForm>({
    mode: "onChange",
    resolver: zodResolver(schema),
  });

  const contactUsMutation = useMutation(useContextService(contactUs));

  const contactUsMutationRef = useRef(contactUsMutation);

  const onSubmit = (data: ContactUsForm) => {
    contactUsMutationRef.current.mutate(data);
  };

  return (
    <div className="flex justify-center">
      <Helmet>
        <title>Let’s Get Proof | Contact Us</title>
      </Helmet>

      <div className="max-w-lg text-center">
        {contactUsMutation.isSuccess ? (
          <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Thank you for getting in touch!</span>
              <span className="block text-lg tracking-normal pt-4 text-gray-700 font-normal">
                We appreciate you contacting us. One of our colleagues will get
                back in touch with you soon!Have a great day!
              </span>
            </h2>
          </div>
        ) : (
          <div>
            <h1 className="text-3xl font-bold mb-3">Contact Our Medical Research Collaboration Team</h1>
            <div className="text-gray-700 mb-4">
              Have a question about the way we approach medical research funding? Want to give feedback on your experience with us?
              <br/>
              Let's Get Proof is here to help! Fill out the form below, and we'll get back to you as soon as possible.
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-5 text-left">
                <TextField
                  label="Name"
                  placeholder="Name"
                  error={formState.errors?.name}
                  {...register("name", { required: true })}
                />
              </div>
              <div className="mb-5 text-left">
                <TextField
                  label="Email"
                  placeholder="Email"
                  error={formState.errors?.email}
                  {...register("email", { required: true })}
                />
              </div>

              <div className="mb-5 text-left">
                <TextareaField
                  label="Message"
                  error={formState.errors?.text}
                  {...register("text", {
                    required: true,
                  })}
                />
              </div>

              <Button
                type="submit"
                loading={contactUsMutation.isLoading}
                className="mt-4 w-full"
                spinner
              >
                Submit
              </Button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
