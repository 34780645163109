import React, { PropsWithoutRef } from "react";

export interface ErrorMessageProps
  extends PropsWithoutRef<JSX.IntrinsicElements["span"]> {}

export const ErrorMessage = React.forwardRef<
  HTMLSpanElement,
  ErrorMessageProps
>(({ children, ...props }, ref) => (
  <span ref={ref} {...props} className="text-sm text-red-600">
    {children}
  </span>
));
