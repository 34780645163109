import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";
import map from "lodash/map";

import { Button } from "components/button/Button";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";

import { changePersonalizationRequest } from "api/auth/auth";
import { User } from "api/types";
import { setFormError } from "utils/setError";
import { USER_QUERY_KEY } from "queries/use-user-query";
import { getProjectsTagsRequest } from "api/project/project";
import { SelectField } from "../../../components/select-field/SelectField";

interface MeForm {
  area_of_interest?: any;
  looking_for?: any;
  gender?: any;
  race?: any;
  ethnicity?: string;
  age_group?: string;
  career_stage?: string;
}

const useFormSchema = () =>
  z.object({
    area_of_interest: z.number().array(),
    looking_for: z.string().array(),
    race: z.string().array(),
    gender: z.string(),
    ethnicity: z.string(),
    career_stage: z.string(),
    age_group: z.string(),
  });

export const EditPersonalizationForm = ({
  user,
  onSubmit,
}: {
  user: User;
  onSubmit: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
    control,
  } = useForm<MeForm>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),

    // @ts-ignore
    defaultValues: {
      area_of_interest: map(
        get(user, "area_of_interest", []),
        (item) => item.id
      ),
      looking_for: get(user, "looking_for", []),
      race: get(user, "race").length
        ? get(user, "race", [])
        : ["prefer_not_to_disclose"],
      gender: get(user, "gender") || "prefer_not_to_disclose",
      ethnicity: get(user, "ethnicity") || "prefer_not_to_disclose",
      career_stage: get(user, "career_stage") || "prefer_not_to_disclose",
      age_group: get(user, "age_group") || "prefer_not_to_disclose",
    },
  });

  const queryClient = useQueryClient();

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  const changeMeQuery = useMutation(changePersonalizationRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      setFormSuccessMessage("Profile has been successfully updated.");
      setTimeout(() => {
        onSubmit();
      }, 1500);
    },
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const handleFormSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    changeMeQuery.mutate({
      ...data,
    });
  };

  return (
    <div className="">
      <div className="" style={{ maxWidth: "600px" }}>
        <form onSubmit={handleSubmit(handleFormSubmit)} className="">
          <h2 className="text-lg font-medium text-black mb-2">
            Personalization
          </h2>

          <div className="w-full mb-5">
            <MultiSelectField
              label="Area(s) of interest"
              error={formState.errors?.area_of_interest}
              name="area_of_interest"
              control={control}
              options={map(get(projectTags, "data"), ({ id, title }) => ({
                value: id,
                label: title,
              }))}
            />
          </div>

          <div className="w-full mt-5">
            <MultiSelectField
              label="What are you looking for with Let’s Get Proof?"
              error={get(formState, "errors.looking_for[0]")}
              name="looking_for"
              control={control}
              options={[
                {
                  value: "want_to_fund_research",
                  label: "I am looking to fund research",
                },
                {
                  value: "interested_in_collaboration",
                  label:
                    "I am interested in advancing the understanding of a medical problem/issue through collaboration",
                },
                {
                  value: "looking_for_help",
                  label: "I am looking for help/guidance/mentorship",
                },
                {
                  value: "want_to_help",
                  label: "I would like to help researchers with study designs",
                },
              ]}
            />
          </div>

          {get(user, "is_project_creator", false) && (
            <div className="mt-5">
              <div className="w-full mt-8 px-4 py-2 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                <h3 className="font-semibold text-lg text-gray-900">
                  Why are we asking this?
                </h3>
                <div className="text-sm">
                  A major goal of Let’s Get Proof is to promote equity, diversity,
                  and inclusion. Collecting this information will give a sense
                  of how we are doing and guide us on how we can improve. For
                  more information, click here
                </div>
              </div>

              <div className="w-full mt-5">
                <SelectField
                  label="Gender"
                  placeholder="Select"
                  options={[
                    {
                      value: "male",
                      label: "Male",
                    },
                    {
                      value: "female",
                      label: "Female",
                    },
                    {
                      value: "non-binary",
                      label: "Non-binary",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.gender}
                  {...register("gender", { required: true })}
                />
              </div>

              <div className="w-full mt-5">
                <SelectField
                  label="Age group"
                  placeholder="Select"
                  options={[
                    {
                      value: "18_to_20",
                      label: "18 to 20",
                    },
                    {
                      value: "21_to_29",
                      label: "21 to 29",
                    },
                    {
                      value: "30_to_39",
                      label: "30 to 39",
                    },
                    {
                      value: "40_to_49",
                      label: "40 to 49",
                    },
                    {
                      value: "50_to_59",
                      label: "50 to 59",
                    },
                    {
                      value: "60_to_older",
                      label: "60 or older",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.age_group}
                  {...register("age_group", { required: true })}
                />
              </div>

              {/*<div className="w-full mt-5">
              <MultiSelectField
                label="Race"
                name="race"
                control={control}
                defaultFirst
                options={[
                  {
                    value: "american_indian_or_alaska_native",
                    label: "American Indian or Alaska Native",
                  },
                  {
                    value: "asian",
                    label: "Asian",
                  },
                  {
                    value: "black_or_african_american",
                    label: "Black or African American",
                  },
                  {
                    value: "native_hawalan_or_other_pacific_islander",
                    label: "Native Hawaiian or Other Pacific Islander",
                  },
                  {
                    value: "white",
                    label: "White",
                  },
                  {
                    value: "prefer_not_to_disclose",
                    label: "Prefer not to disclose",
                  },
                ]}
                onChange={(val) => {
                  if (
                    val.length &&
                    val[val.length - 1].value === "prefer_not_to_disclose"
                  ) {
                    setValue("race", ["prefer_not_to_disclose"]);
                  } else if (
                    val.length &&
                    val.some((item) => item.value === "prefer_not_to_disclose")
                  ) {
                    setValue(
                      "race",
                      val
                        .filter(
                          (item) => item.value !== "prefer_not_to_disclose"
                        )
                        .map((item) => item.value)
                    );
                  }
                }}
              />
            </div>*/}

              {/*<div className="w-full mt-5">
              <SelectField
                label="Ethnicity"
                placeholder="Select"
                options={[
                  {
                    value: "",
                    label: "Select",
                  },
                  {
                    value: "hispanic_or_latino",
                    label: "Hispanic or Latino",
                  },
                  {
                    value: "not_hispanic_or_latino",
                    label: "Not Hispanic or Latino",
                  },
                  {
                    value: "prefer_not_to_disclose",
                    label: "Prefer not to disclose",
                  },
                ]}
                error={formState.errors?.ethnicity}
                {...register("ethnicity", { required: true })}
              />
            </div>*/}

              <div className="w-full mt-5">
                <SelectField
                  label="Career stage"
                  placeholder="Select"
                  options={[
                    {
                      value: "early_career_researcher",
                      label: "Early career researcher",
                    },
                    {
                      value: "junior_staff",
                      label: "Junior staff",
                    },
                    {
                      value: "senior_staff",
                      label: "Senior staff",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.career_stage}
                  {...register("career_stage", { required: true })}
                />
              </div>
            </div>
          )}

          {formErrorMessage && (
            <div className="w-full mt-5 py-2 px-4 rounded bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full mt-5 py-2 px-4 rounded bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="submit"
            className="w-full mt-5"
            disabled={changeMeQuery.isLoading}
            loading={changeMeQuery.isLoading}
            spinner
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
