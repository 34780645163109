import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-gray-900">
      <Helmet>
        <title>Let’s Get Proof | Privace Policy</title>
      </Helmet>
      <h1 className="text-3xl font-bold mb-2">Let’s Get Proof Privacy Policy</h1>
      <div className="text-gray-600 text-sm mb-8">
        Last updated: February 18, 2022
      </div>

      <div className="pb-4">
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </div>
      <div className="pb-4">
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Interpretation and Definitions
      </h2>

      <h3 className="text-lg font-semibold mt-4 mb-2">Interpretation</h3>
      <div className="pb-4">
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">Definitions</h3>
      <div className="pb-4">For the purposes of this Privacy Policy:</div>

      <ul className="ml-8 pb-4">
        <li className="pb-2">
          <b className="font-medium">Account</b> means a unique account created
          for You to access our Service or parts of our Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">Business</b>, for the purpose of the CCPA
          (California Consumer Privacy Act), refers to the Company as the legal
          entity that collects Consumers' personal information and determines
          the purposes and means of the processing of Consumers' personal
          information, or on behalf of which such information is collected and
          that alone, or jointly with others, determines the purposes and means
          of the processing of consumers' personal information, that does
          business in the State of California.
        </li>
        <li className="pb-2">
          <b className="font-medium">Company</b> (referred to as either "the
          Company", "We", "Us" or "Our" in this Agreement) refers to
          Let’s Get Proof Inc., Calgary, Alberta, Canada.
          <br />
          For the purpose of the GDPR, the Company is the Data Controller.
        </li>
        <li className="pb-2">
          <b className="font-medium">Consumer</b>, for the purpose of the CCPA
          (California Consumer Privacy Act), means a natural person who is a
          California resident. A resident, as defined in the law, includes (1)
          every individual who is in the USA for other than a temporary or
          transitory purpose, and (2) every individual who is domiciled in the
          USA who is outside the USA for a temporary or transitory purpose.
        </li>
        <li className="pb-2">
          <b className="font-medium">Cookies</b> are small files that are placed
          on Your computer, mobile device or any other device by a website,
          containing the details of Your browsing history on that website among
          its many uses.
        </li>
        <li className="pb-2">
          <b className="font-medium">Country</b> refers to: Alberta, Canada
        </li>
        <li className="pb-2">
          <b className="font-medium">Data Controller</b>, for the purposes of
          the GDPR (General Data Protection Regulation), refers to the Company
          as the legal person which alone or jointly with others determines the
          purposes and means of the processing of Personal Data.
        </li>
        <li className="pb-2">
          <b className="font-medium">Device</b> means any device that can access
          the Service such as a computer, a cellphone or a digital tablet.
        </li>
        <li className="pb-2">
          <b className="font-medium">Do Not Track</b> (DNT) is a concept that
          has been promoted by US regulatory authorities, in particular the U.S.
          Federal Trade Commission (FTC), for the Internet industry to develop
          and implement a mechanism for allowing internet users to control the
          tracking of their online activities across websites.
        </li>
        <li className="pb-2">
          <b className="font-medium">Personal Data</b> is any information that
          relates to an identified or identifiable individual.
          <br />
          For the purposes of GDPR, Personal Data means any information relating
          to You such as a name, an identification number, location data, online
          identifier or to one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social identity.
          <br />
          For the purposes of the CCPA, Personal Data means any information that
          identifies, relates to, describes or is capable of being associated
          with, or could reasonably be linked, directly or indirectly, with You.
        </li>
        <li className="pb-2">
          <b className="font-medium">Sale</b>, for the purpose of the CCPA
          (California Consumer Privacy Act), means selling, renting, releasing,
          disclosing, disseminating, making available, transferring, or
          otherwise communicating orally, in writing, or by electronic or other
          means, a Consumer's personal information to another business or a
          third party for monetary or other valuable consideration.
        </li>
        <li className="pb-2">
          <b className="font-medium">Service</b> refers to the Website.
        </li>
        <li className="pb-2">
          <b className="font-medium">Service Provider</b> means any natural or
          legal person who processes the data on behalf of the Company. It
          refers to third-party companies or individuals employed by the Company
          to facilitate the Service, to provide the Service on behalf of the
          Company, to perform services related to the Service or to assist the
          Company in analyzing how the Service is used. For the purpose of the
          GDPR, Service Providers are considered Data Processors.
        </li>
        <li className="pb-2">
          <b className="font-medium">Third-party Social Media Service</b> refers
          to any website or any social network website through which a User can
          log in or create an account to use the Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">Usage Data</b> refers to data collected
          automatically, either generated by the use of the Service or from the
          Service infrastructure itself (for example, the duration of a page
          visit).
        </li>
        <li className="pb-2">
          <b className="font-medium">Website</b> refers to Let’s Get Proof,
          accessible from www.letsgetproof.com
        </li>
        <li className="pb-2">
          <b className="font-medium">You</b> means the individual accessing or
          using the Service, or the company, or other legal entity on behalf of
          which such individual is accessing or using the Service, as
          applicable.
          <br />
          Under GDPR (General Data Protection Regulation), You can be referred
          to as the Data Subject or as the User as you are the individual using
          the Service.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Collecting and Using Your Personal Data
      </h2>
      <h3 className="text-lg font-semibold mt-4 mb-2">
        Types of Data Collected
      </h3>

      <div className="font-semibold pb-2">Personal Data</div>

      <div className="pb-4">
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </div>
      <div className="pb-4">
        Email address <br />
        First name and last name
        <br />
        Phone number
        <br />
        Address, State, Province, ZIP/Postal code, City
        <br />
        Usage Data
      </div>

      <div className="font-semibold pb-2">Usage Data</div>
      <div className="pb-4">
        Usage Data is collected automatically when using the Service.
      </div>
      <div className="pb-4">
        Usage Data may include information such as Your Device's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that You visit, the time and date of Your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </div>
      <div className="pb-4">
        When You access the Service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of Your mobile device, Your mobile operating system, the type
        of mobile Internet browser You use, unique device identifiers and other
        diagnostic data.
      </div>
      <div className="pb-4">
        We may also collect information that Your browser sends whenever You
        visit our Service or when You access the Service by or through a mobile
        device.
      </div>

      <div className="font-semibold pb-2">
        Information from Third-Party Social Media Services
      </div>
      <div className="pb-2">
        The Company allows You to associate the following Third-party Social
        Media Services with your profile:
      </div>

      <ul className="list-disc ml-8">
        <li>Google</li>
        <li>Twitter</li>
        <li>LinkedIn</li>
        <li>Instagram</li>
      </ul>

      <div className="pb-2">
        If You decide to grant us access to a Third-Party Social Media Service,
        We may collect Personal data that is already associated with Your
        Third-Party Social Media Service's account, such as Your name, Your
        email address, Your activities or Your contact list associated with that
        account.
      </div>

      <div className="pb-4">
        You may also have the option of sharing additional information with the
        Company through Your Third-Party Social Media Service's account. If You
        choose to provide such information and Personal Data, during
        registration or otherwise, You are giving the Company permission to use,
        share, and store it in a manner consistent with this Privacy Policy.
      </div>

      <div className="font-semibold pb-2">
        Tracking Technologies and Cookies
      </div>
      <div className="pb-2">
        We use Cookies and similar tracking technologies to track the activity
        on Our Service and store certain information. Tracking technologies used
        are beacons, tags, and scripts to collect and track information and to
        improve and analyze Our Service. The technologies We use may include:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li>
          <b className="font-medium">Cookies or Browser Cookies.</b> A cookie is
          a small file placed on Your Device. You can instruct Your browser to
          refuse all Cookies or to indicate when a Cookie is being sent.
          However, if You do not accept Cookies, You may not be able to use some
          parts of our Service. Unless you have adjusted Your browser setting so
          that it will refuse Cookies, our Service may use Cookies.
        </li>
        <li>
          <b className="font-medium">Flash Cookies.</b> Certain features of our
          Service may use local stored objects (or Flash Cookies) to collect and
          store information about Your preferences or Your activity on our
          Service. Flash Cookies are not managed by the same browser settings as
          those used for Browser Cookies. For more information on how You can
          delete Flash Cookies, please read "Where can I change the settings for
          disabling, or deleting local shared objects?" available at
          <a
            href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_"
            className="text-indigo-700"
            target="_blank"
            rel="noreferrer"
          >
            https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
          </a>
        </li>
        <li>
          <b className="font-medium">Web Beacons.</b> Certain sections of our
          Service and our emails may contain small electronic files known as web
          beacons (also referred to as clear gifs, pixel tags, and single-pixel
          gifs) that permit the Company, for example, to count users who have
          visited those pages or opened an email and for other related website
          statistics (for example, recording the popularity of a certain section
          and verifying system and server integrity).
        </li>
      </ul>

      <div className="pb-4">
        Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
        remain on Your personal computer or mobile device when You go offline,
        while Session Cookies are deleted as soon as You close Your web browser.
        You can learn more about cookies here: Cookies by TermsFeed Generator.
      </div>

      <div className="pb-4">
        We use both Session and Persistent Cookies for the purposes set out
        below:
      </div>

      <div className="font-semibold pb-2">Necessary / Essential Cookies</div>
      <div className="pb-2">Type: Session Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-4">
        Purpose: These Cookies are essential to provide You with services
        available through the Website and to enable You to use some of its
        features. They help to authenticate users and prevent fraudulent use of
        user accounts. Without these Cookies, the services that You have asked
        for cannot be provided, and We only use these Cookies to provide You
        with those services.
      </div>

      <div className="font-semibold pb-2">
        Cookies Policy / Notice Acceptance Cookies
      </div>
      <div className="pb-2">Type: Persistent Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-4">
        Purpose: These Cookies identify if users have accepted the use of
        cookies on the Website.
      </div>

      <div className="font-semibold pb-2">Functionality Cookies</div>
      <div className="pb-2">Type: Persistent Cookies</div>
      <div className="pb-2">Administered by: Us</div>
      <div className="pb-2">
        Purpose: These Cookies allow us to remember choices You make when You
        use the Website, such as remembering your login details or language
        preference. The purpose of these Cookies is to provide You with a more
        personal experience and to avoid You having to re-enter your preferences
        every time You use the Website.
      </div>

      <div className="pb-4">
        For more information about the cookies we use and your choices regarding
        cookies, please visit our Cookies Policy or the Cookies section of our
        Privacy Policy.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Use of Your Personal Data
      </h3>

      <div className="pb-4">
        The Company may use Personal Data for the following purposes:
      </div>

      <ul className="ml-8 mb-4">
        <li className="pb-2">
          <b className="font-medium">To provide and maintain our Service</b>,
          including to monitor the usage of our Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">To manage Your Account</b>: to manage Your
          registration as a user of the Service. The Personal Data You provide
          can give You access to different functionalities of the Service that
          are available to You as a registered user.
        </li>
        <li className="pb-2">
          <b className="font-medium">For the performance of a contract</b>: the
          development, compliance and undertaking of the purchase contract for
          the products, items or services You have purchased or of any other
          contract with Us through the Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">To contact You</b>: To contact You by
          email, telephone calls, SMS, or other equivalent forms of electronic
          communication, such as a mobile application's push notifications
          regarding updates or informative communications related to the
          functionalities, products or contracted services, including the
          security updates, when necessary or reasonable for their
          implementation.
        </li>
        <li className="pb-2">
          <b className="font-medium">To provide You</b> with news, special
          offers and general information about other goods, services and events
          which we offer that are similar to those that you have already
          purchased or enquired about unless You have opted not to receive such
          information.
        </li>
        <li className="pb-2">
          <b className="font-medium">To manage Your requests</b>: To attend and
          manage Your requests to Us.
        </li>
        <li className="pb-2">
          <b className="font-medium">For business transfers</b>: We may use Your
          information to evaluate or conduct a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of Our assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which Personal Data
          held by Us about our Service users is among the assets transferred.
        </li>
        <li className="pb-2">
          <b className="font-medium">For other purposes</b>: We may use Your
          information for other purposes, such as data analysis, identifying
          usage trends, determining the effectiveness of our promotional
          campaigns and to evaluate and improve our Service, products, services,
          marketing and your experience.
        </li>
      </ul>

      <div className="mb-4">
        We may share Your personal information in the following situations:
      </div>

      <ul className="list-disc ml-8">
        <li className="pb-2">
          <b className="font-medium">With Service Providers</b>: We may share
          Your personal information with Service Providers to monitor and
          analyze the use of our Service, for payment processing, to contact
          You.
        </li>
        <li className="pb-2">
          <b className="font-medium">For business transfers</b>: We may share or
          transfer Your personal information in connection with, or during
          negotiations of, any merger, sale of Company assets, financing, or
          acquisition of all or a portion of Our business to another company.
        </li>
        <li className="pb-2">
          <b className="font-medium">With Affiliates</b>: We may share Your
          information with Our affiliates, in which case we will require those
          affiliates to honor this Privacy Policy. Affiliates include Our parent
          company and any other subsidiaries, joint venture partners or other
          companies that We control or that are under common control with Us.
        </li>
        <li className="pb-2">
          <b className="font-medium">With business partners</b>: We may share
          Your information with Our business partners to offer You certain
          products, services or promotions.
        </li>
        <li className="pb-2">
          <b className="font-medium">With other users</b>: when You share
          personal information or otherwise interact in the public areas with
          other users, such information may be viewed by all users and may be
          publicly distributed outside. If You interact with other users or
          register through a Third-Party Social Media Service, Your contacts on
          the Third-Party Social Media Service may see Your name, profile,
          pictures and description of Your activity. Similarly, other users will
          be able to view descriptions of Your activity, communicate with You
          and view Your profile.
        </li>
        <li className="pb-2">
          <b className="font-medium">With Your consent</b>: We may disclose Your
          personal information for any other purpose with Your consent.
        </li>
      </ul>

      <div className="font-semibold pb-2">Retention of Your Personal Data</div>
      <div className="mb-4">
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        our legal obligations (for example, if we are required to retain your
        data to comply with applicable laws), resolve disputes, and enforce our
        legal agreements and policies.
      </div>
      <div className="mb-4">
        The Company will also retain Usage Data for internal analysis purposes.
        Usage Data is generally retained for a shorter period of time, except
        when this data is used to strengthen the security or to improve the
        functionality of Our Service, or We are legally obligated to retain this
        data for longer time periods.
      </div>
      <div className="font-semibold pb-2">Transfer of Your Personal Data</div>
      <div className="mb-4">
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </div>
      <div className="mb-4">
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </div>
      <div className="mb-4">
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Disclosure of Your Personal Data
      </h3>
      <div className="font-semibold pb-2">Business Transactions</div>
      <div className="mb-4">
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </div>
      <div className="font-semibold pb-2"> Law enforcement</div>
      <div className="mb-4">
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </div>
      <div className="font-semibold pb-2">Other legal requirements</div>
      <div className="mb-4">
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </div>

      <ul className="list-disc ml-8">
        <li className="pb-2">Comply with a legal obligation</li>
        <li className="pb-2">
          Protect and defend the rights or property of the Company
        </li>
        <li className="pb-2">
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li className="pb-2">
          Protect the personal safety of Users of the Service or the public
        </li>
        <li className="pb-2">Protect against legal liability</li>
      </ul>

      <div className="font-semibold pb-2">Security of Your Personal Data</div>
      <div className="mb-4">
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Detailed Information on the Processing of Your Personal Data
      </h2>

      <div className="mb-4">
        The Service Providers We use may have access to Your Personal Data.
        These third-party vendors collect, store, use, process and transfer
        information about Your activity on Our Service in accordance with their
        Privacy Policies.
      </div>

      <div className="font-semibold pb-2">Analytics</div>
      <div className="pb-4">
        We may use third-party Service providers to monitor and analyze the use
        of our Service.
      </div>

      <div className="ml-8 pb-4">
        <b className="font-medium">Google Analytics</b> <br /> Google Analytics
        is a web analytics service offered by Google that tracks and reports
        website traffic. Google uses the data collected to track and monitor the
        use of our Service. This data is shared with other Google services.
        Google may use the collected data to contextualize and personalize the
        ads of its own advertising network. You can opt-out of having made your
        activity on the Service available to Google Analytics by installing the
        Google Analytics opt-out browser add-on. The add-on prevents the Google
        Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing
        information with Google Analytics about visits activity. For more
        information on the privacy practices of Google, please visit the Google
        Privacy & Terms web page: https://policies.google.com/privacy
      </div>

      <div className="font-semibold pb-2">Payments</div>
      <div className="pb-4">
        We may provide paid products and/or services within the Service. In that
        case, we may use third-party services for payment processing (e.g.
        payment processors).
      </div>
      <div className="pb-4">
        We will not store or collect Your payment card details. That information
        is provided directly to Our third-party payment processors whose use of
        Your personal information is governed by their Privacy Policy. These
        payment processors adhere to the standards set by PCI-DSS as managed by
        the PCI Security Standards Council, which is a joint effort of brands
        like Visa, Mastercard, American Express and Discover. PCI-DSS
        requirements help ensure the secure handling of payment information.
      </div>
      <div className="pb-4 pl-4">
        <b className="font-medium">Stripe</b> <br />
        Their Privacy Policy can be viewed at{" "}
        <a
          href="https://stripe.com/us/privacy"
          className="text-indigo-700"
          target="_blank"
          rel="noreferrer"
        >
          https://stripe.com/us/privacy
        </a>
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">GDPR Privacy</h2>

      <div className="font-semibold pb-2">
        Legal Basis for Processing Personal Data under GDPR
      </div>
      <div className="pb-4">
        We may process Personal Data under the following conditions:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">
          <b className="font-medium">Consent:</b> You have given Your consent
          for processing Personal Data for one or more specific purposes.
        </li>
        <li className="pb-2">
          <b className="font-medium">Performance of a contract:</b> Provision of
          Personal Data is necessary for the performance of an agreement with
          You and/or for any pre-contractual obligations thereof.
        </li>
        <li className="pb-2">
          <b className="font-medium">Legal obligations:</b> Processing Personal
          Data is necessary for compliance with a legal obligation to which the
          Company is subject.
        </li>
        <li className="pb-2">
          <b className="font-medium">Vital interests:</b> Processing Personal
          Data is necessary in order to protect Your vital interests or of
          another natural person.
        </li>
        <li className="pb-2">
          <b className="font-medium">Public interests</b>: Processing Personal
          Data is related to a task that is carried out in the public interest
          or in the exercise of official authority vested in the Company.
        </li>
        <li className="pb-2">
          <b className="font-medium">Legitimate interests:</b> Processing
          Personal Data is necessary for the purposes of the legitimate
          interests pursued by the Company.
        </li>
      </ul>
      <div className="pb-4">
        In any case, the Company will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </div>

      <div className="font-semibold pb-2">Your Rights under the GDPR</div>
      <div className="pb-4">
        The Company undertakes to respect the confidentiality of Your Personal
        Data and to guarantee You can exercise Your rights.
      </div>
      <div className="pb-4">
        You have the right under this Privacy Policy, and by law if You are
        within the EU, to:
      </div>
      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">
          <b className="font-medium">Request access to Your Personal Data.</b>{" "}
          The right to access, update or delete the information We have on You.
          Whenever made possible, you can access, update or request deletion of
          Your Personal Data directly within Your account settings section. If
          you are unable to perform these actions yourself, please contact Us to
          assist You. This also enables You to receive a copy of the Personal
          Data We hold about You.
        </li>
        <li className="pb-2">
          <b className="font-medium">Request correction of the Personal Data</b>{" "}
          that We hold about You. You have the right to have any incomplete or
          inaccurate information We hold about You corrected.
        </li>
        <li className="pb-2">
          <b className="font-medium">
            Object to processing of Your Personal Data.
          </b>{" "}
          This right exists where We are relying on a legitimate interest as the
          legal basis for Our processing and there is something about Your
          particular situation, which makes You want to object to our processing
          of Your Personal Data on this ground. You also have the right to
          object where We are processing Your Personal Data for direct marketing
          purposes.
        </li>
        <li className="pb-2">
          <b className="font-medium">Request erasure of Your Personal Data.</b>{" "}
          You have the right to ask Us to delete or remove Personal Data when
          there is no good reason for Us to continue processing it.
        </li>
        <li className="pb-2">
          <b className="font-medium">
            Request the transfer of Your Personal Data.
          </b>{" "}
          We will provide to You, or to a third-party You have chosen, Your
          Personal Data in a structured, commonly used, machine-readable format.
          Please note that this right only applies to automated information
          which You initially provided consent for Us to use or where We used
          the information to perform a contract with You.
        </li>
        <li className="pb-2">
          <b className="font-medium">Withdraw Your consent.</b> You have the
          right to withdraw Your consent on using your Personal Data. If You
          withdraw Your consent, We may not be able to provide You with access
          to certain specific functionalities of the Service.
        </li>
      </ul>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Exercising of Your GDPR Data Protection Rights
      </h3>
      <div className="pb-4">
        You may exercise Your rights of access, rectification, cancellation and
        opposition by contacting Us. Please note that we may ask You to verify
        Your identity before responding to such requests. If You make a request,
        We will try our best to respond to You as soon as possible.
      </div>
      <div className="pb-4">
        You have the right to complain to a Data Protection Authority about Our
        collection and use of Your Personal Data. For more information, if You
        are in the European Economic Area (EEA), please contact Your local data
        protection authority in the EEA.
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">CCPA Privacy</h2>

      <div className="pb-4">
        This privacy notice section for California residents supplements the
        information contained in Our Privacy Policy and it applies solely to all
        visitors, users, and others who reside in the State of California.
      </div>

      <div className="font-semibold pb-2">
        Categories of Personal Information Collected
      </div>
      <div className="pb-4">
        We collect information that identifies, relates to, describes,
        references, is capable of being associated with, or could reasonably be
        linked, directly or indirectly, with a particular Consumer or Device.
        The following is a list of categories of personal information which we
        may collect or may have been collected from California residents within
        the last twelve (12) months.
      </div>
      <div className="pb-4">
        Please note that the categories and examples provided in the list below
        are those defined in the CCPA. This does not mean that all examples of
        that category of personal information were in fact collected by Us, but
        reflects our good faith belief to the best of our knowledge that some of
        that information from the applicable category may be and may have been
        collected. For example, certain categories of personal information would
        only be collected if You provided such personal information directly to
        Us.
      </div>

      <div className="ml-8">
        <div className="font-medium mb-2"> Category A: Identifiers.</div>
        <div className="mb-2">
          Examples: A real name, alias, postal address, unique personal
          identifier, online identifier, Internet Protocol address, email
          address, account name, driver's license number, passport number, or
          other similar identifiers.
        </div>
        <div className="mb-4">Collected: Yes.</div>
        <div className="font-medium mb-2">
          Category B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e)).
        </div>
        <div className="mb-2">
          Examples: A name, signature, Social Security number, physical
          characteristics or description, address, telephone number, passport
          number, driver's license or state identification card number,
          insurance policy number, education, employment, employment history,
          bank account number, credit card number, debit card number, or any
          other financial information, medical information, or health insurance
          information. Some personal information included in this category may
          overlap with other categories.
        </div>
        <div className="mb-4">Collected: Yes.</div>

        <div className="font-medium mb-2">
          Category C: Protected classification characteristics under California
          or federal law.
        </div>
        <div className="mb-2">
          Examples: Age (40 years or older), race, color, ancestry, national
          origin, citizenship, religion or creed, marital status, medical
          condition, physical or mental disability, sex (including gender,
          gender identity, gender expression, pregnancy or childbirth and
          related medical conditions), sexual orientation, veteran or military
          status, genetic information (including familial genetic information).
        </div>
        <div className="mb-4">Collected: Yes.</div>

        <div className="font-medium mb-2">
          Category D: Commercial information.
        </div>
        <div className="mb-2">
          Examples: Records and history of products or services purchased or
          considered.
        </div>
        <div className="mb-4">Collected: Yes.</div>

        <div className="font-medium mb-2">
          Category E: Biometric information.
        </div>
        <div className="mb-2">
          Examples: Genetic, physiological, behavioral, and biological
          characteristics, or activity patterns used to extract a template or
          other identifier or identifying information, such as, fingerprints,
          faceprints, and voiceprints, iris or retina scans, keystroke, gait, or
          other physical patterns, and sleep, health, or exercise data.
        </div>
        <div className="mb-4">Collected: No.</div>

        <div className="font-medium mb-2">
          Category F: Internet or other similar network activity.
        </div>
        <div className="mb-2">
          Examples: Interaction with our Service or advertisement.
        </div>
        <div className="mb-4">Collected: Yes.</div>

        <div className="font-medium mb-2">Category G: Geolocation data.</div>
        <div className="mb-2">Examples: Approximate physical location.</div>
        <div className="mb-4">Collected: No.</div>

        <div className="font-medium mb-2">Category H: Sensory data.</div>
        <div className="mb-2">
          Examples: Audio, electronic, visual, thermal, olfactory, or similar
          information.
        </div>
        <div className="mb-4">Collected: No.</div>
        <div className="font-medium mb-2">
          Category I: Professional or employment-related information.
        </div>
        <div className="mb-2">
          Examples: Current or past job history or performance evaluations.
        </div>
        <div className="mb-4">Collected: No.</div>
        <div className="font-medium mb-2">
          Category J: Non-public education information (per the Family
          Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R.
          Part 99)).
        </div>
        <div className="mb-2">
          Examples: Education records directly related to a student maintained
          by an educational institution or party acting on its behalf, such as
          grades, transcripts, class lists, student schedules, student
          identification codes, student financial information, or student
          disciplinary records.
        </div>
        <div className="mb-4">Collected: No.</div>
        <div className="font-medium mb-2">
          Category K: Inferences drawn from other personal information.
        </div>
        <div className="mb-2">
          Examples: Profile reflecting a person's preferences, characteristics,
          psychological trends, predispositions, behavior, attitudes,
          intelligence, abilities, and aptitudes.
        </div>
        <div className="mb-4">Collected: No.</div>
      </div>

      <div className="pb-2">
        Under CCPA, personal information does not include:
      </div>
      <ul className="list-disc ml-8">
        <li className="pb-2">
          Publicly available information from government records
        </li>
        <li className="pb-2">
          Deidentified or aggregated consumer information
        </li>
        <li className="pb-2">
          Information excluded from the CCPA's scope, such as:
          <ul className="list-disc mt-2 ml-8">
            <li className="pb-2">
              Health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA) or
              clinical trial data
            </li>
            <li className="pb-2">
              Personal Information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FRCA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver's Privacy Protection Act of
              1994
            </li>
          </ul>
        </li>
      </ul>

      <div className="font-semibold pb-2">Sources of Personal Information</div>
      <div className="pb-4">
        We obtain the categories of personal information listed above from the
        following categories of sources:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">
          <b className="font-medium">Directly from You.</b> For example, from
          the forms You complete on our Service, preferences You express or
          provide through our Service, or from Your purchases on our Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">Indirectly from You.</b> For example, from
          observing Your activity on our Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">Automatically from You.</b> For example,
          through cookies We or our Service Providers set on Your Device as You
          navigate through our Service.
        </li>
        <li className="pb-2">
          <b className="font-medium">From Service Providers.</b> For example,
          third-party vendors for payment processing, or other third-party
          vendors that We use to provide the Service to You.
        </li>
      </ul>

      <div className="font-semibold pb-2">
        Use of Personal Information for Business Purposes or Commercial Purposes
      </div>
      <div className="pb-4">
        We may use or disclose personal information We collect for "business
        purposes" or "commercial purposes" (as defined under the CCPA), which
        may include the following examples:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">
          To operate our Service and provide You with our Service.
        </li>
        <li className="pb-2">
          To provide You with support and to respond to Your inquiries,
          including to investigate and address Your concerns and monitor and
          improve our Service.
        </li>
        <li className="pb-2">
          To fulfill or meet the reason You provided the information. For
          example, if You share Your contact information to ask a question about
          our Service, We will use that personal information to respond to Your
          inquiry. If You provide Your personal information to purchase a
          product or service, We will use that information to process Your
          payment and facilitate delivery.
        </li>
        <li className="pb-2">
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li className="pb-2">
          As described to You when collecting Your personal information or as
          otherwise set forth in the CCPA.
        </li>
        <li className="pb-2">
          For internal administrative and auditing purposes.
        </li>
        <li className="pb-2">
          To detect security incidents and protect against malicious, deceptive,
          fraudulent or illegal activity, including, when necessary, to
          prosecute those responsible for such activities.
        </li>
      </ul>

      <div className="pb-4">
        Please note that the examples provided above are illustrative and not
        intended to be exhaustive. For more details on how we use this
        information, please refer to the "Use of Your Personal Data" section.
        <br />
        If We decide to collect additional categories of personal information or
        use the personal information We collected for materially different,
        unrelated, or incompatible purposes We will update this Privacy Policy.
      </div>

      <div className="font-semibold pb-2">
        Disclosure of Personal Information for Business Purposes or Commercial
        Purposes
      </div>
      <div className="pb-4">
        We may use or disclose and may have used or disclosed in the last twelve
        (12) months the following categories of personal information for
        business or commercial purposes:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">Category A: Identifiers</li>
        <li className="pb-2">
          Category B: Personal information categories listed in the California
          Customer Records statute (Cal. Civ. Code § 1798.80(e))
        </li>
        <li className="pb-2">Category D: Commercial information</li>
        <li className="pb-2">
          Category F: Internet or other similar network activity
        </li>
      </ul>
      <div className="pb-4">
        Please note that the categories listed above are those defined in the
        CCPA. This does not mean that all examples of that category of personal
        information were in fact disclosed, but reflects our good faith belief
        to the best of our knowledge that some of that information from the
        applicable category may be and may have been disclosed.
      </div>
      <div className="pb-4">
        When We disclose personal information for a business purpose or a
        commercial purpose, We enter a contract that describes the purpose and
        requires the recipient to both keep that personal information
        confidential and not use it for any purpose except performing the
        contract.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Sale of Personal Information
      </h3>

      <div className="pb-4">
        As defined in the CCPA, "sell" and "sale" mean selling, renting,
        releasing, disclosing, disseminating, making available, transferring, or
        otherwise communicating orally, in writing, or by electronic or other
        means, a consumer's personal information by the business to a third
        party for valuable consideration. We do not engage in such activity and
        have not engaged in such activity in the past twelve (12) months from
        the effective date of this Policy.
      </div>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Share of Personal Information
      </h3>

      <div className="pb-4">
        We may share Your personal information identified in the above
        categories with the following categories of third parties:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">Service Providers</li>
        <li className="pb-2">Payment processors</li>
        <li className="pb-2">Our affiliates</li>
        <li className="pb-2">Our business partners</li>
        <li className="pb-2">
          Third party vendors to whom You or Your agents authorize Us to
          disclose Your personal information in connection with products or
          services We provide to You
        </li>
      </ul>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Your Rights under the CCPA
      </h3>
      <div className="pb-4">
        The CCPA provides California residents with specific rights regarding
        their personal information. If You are a resident of California, You
        have the following rights:
      </div>

      <ul className="list-disc ml-8 mb-4">
        <li className="pb-2">
          <b className="font-medium">The right to notice</b>. You have the right
          to be notified which categories of Personal Data are being collected
          and the purposes for which the Personal Data is being used.
        </li>
        <li className="pb-2">
          <b className="font-medium">The right to request</b>. Under CCPA, You
          have the right to request that We disclose information to You about
          Our collection, use, sale, disclosure for business purposes and share
          of personal information. Once We receive and confirm Your request, We
          will disclose to You:
          <ul className="list-disc ml-4 mb-4">
            <li className="pb-2">
              The categories of personal information We collected about You
            </li>
            <li className="pb-2">
              The categories of sources for the personal information We
              collected about You
            </li>
            <li className="pb-2">
              Our business or commercial purpose for collecting or selling that
              personal information
            </li>
            <li className="pb-2">
              The categories of third parties with whom We share that personal
              information
            </li>
            <li className="pb-2">
              The specific pieces of personal information We collected about You
            </li>
            <li className="pb-2">
              If we sold Your personal information or disclosed Your personal
              information for a business purpose, We will disclose to You:
            </li>
            <li className="pb-2">
              <ul className="list-disc ml-4 mb-4">
                <li className="pb-2">
                  The categories of personal information categories sold
                </li>
                <li className="pb-2">
                  The categories of personal information categories disclosed
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li className="pb-2">
          <b className="font-medium">
            The right to say no to the sale of Personal Data (opt-out).
          </b>{" "}
          We do not sell Personal Data.
        </li>
        <li className="pb-2">
          <b className="font-medium">The right to delete Personal Data.</b> You
          have the right to request the deletion of Your Personal Data, subject
          to certain exceptions. Once We receive and confirm Your request, We
          will delete (and direct Our Service Providers to delete) Your personal
          information from our records, unless an exception applies. We may deny
          Your deletion request if retaining the information is necessary for Us
          or Our Service Providers to:
          <ul className="list-disc ml-4 mb-4">
            <li className="pb-2">
              Complete the transaction for which We collected the personal
              information, provide a good or service that You requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with You, or otherwise perform our contract
              with You.
            </li>
            <li className="pb-2">
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li className="pb-2">
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li className="pb-2">
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li className="pb-2">
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code § 1546 et. seq.).
            </li>
            <li className="pb-2">
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if You previously provided informed
              consent.
            </li>
            <li className="pb-2">
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on Your relationship with Us.
            </li>
            <li className="pb-2">Comply with a legal obligation.</li>
            <li className="pb-2">
              Make other internal and lawful uses of that information that are
              compatible with the context in which You provided it.
            </li>
          </ul>
        </li>

        <li className="pb-2">
          <b className="font-medium">
            The right not to be discriminated against.
          </b>{" "}
          You have the right not to be discriminated against for exercising any
          of Your consumer's rights, including by:
          <ul className="list-disc ml-4 mb-4">
            <li className="pb-2">Denying goods or services to You</li>
            <li className="pb-2">
              Charging different prices or rates for goods or services,
              including the use of discounts or other benefits or imposing
              penalties
            </li>
            <li className="pb-2">
              Providing a different level or quality of goods or services to You
            </li>
            <li className="pb-2">
              Suggesting that You will receive a different price or rate for
              goods or services or a different level or quality of goods or
              services
            </li>
          </ul>
        </li>
      </ul>

      <h3 className="text-lg font-semibold mt-4 mb-2">
        Exercising Your CCPA Data Protection Rights
      </h3>

      <div className="pb-4">
        In order to exercise any of Your rights under the CCPA, and if You are a
        California resident, You can contact Us:
      </div>

      <div className="pb-2">
        By email:
        <br />
        <a href="mailto:contact@letsgetproof.com" className="text-indigo-700">
          contact@letsgetproof.com
        </a>
      </div>

      <div className="pb-4">
        Only You, or a person registered with the California Secretary of State
        that You authorize to act on Your behalf, may make a verifiable request
        related to Your personal information.
      </div>
      <div className="pb-4">Your request to Us must:</div>

      <ul className="list-disc ml-4 mb-4">
        <li className="pb-2">
          Provide sufficient information that allows Us to reasonably verify You
          are the person about whom We collected personal information or an
          authorized representative
        </li>
        <li className="pb-2">
          Describe Your request with sufficient detail that allows Us to
          properly understand, evaluate, and respond to it
        </li>
      </ul>
      <div className="pb-4">
        We cannot respond to Your request or provide You with the required
        information if We cannot:
      </div>

      <ul className="list-disc ml-4 mb-4">
        <li className="pb-2">
          Verify Your identity or authority to make the request
        </li>
        <li className="pb-2">
          And confirm that the personal information relates to You
        </li>
      </ul>

      <div className="pb-4">
        We will disclose and deliver the required information free of charge
        within 45 days of receiving Your verifiable request. The time period to
        provide the required information may be extended once by an additional
        45 days when reasonably necessary and with prior notice.
      </div>
      <div className="pb-4">
        Any disclosures We provide will only cover the 12-month period preceding
        the verifiable request's receipt.
      </div>
      <div className="pb-4">
        For data portability requests, We will select a format to provide Your
        personal information that is readily usable and should allow You to
        transmit the information from one entity to another entity without
        hindrance.
      </div>

      <h2 className="text-2xl font-semibold mt-4 mb-2">
        "Do Not Track" Policy as Required by California Online Privacy
        Protection Act (CalOPPA)
      </h2>
      <div className="pb-4">
        Our Service does not respond to Do Not Track signals.
      </div>
      <div className="pb-4">
        However, some third party websites do keep track of Your browsing
        activities. If You are visiting such websites, You can set Your
        preferences in Your web browser to inform websites that You do not want
        to be tracked. You can enable or disable DNT by visiting the preferences
        or settings page of Your web browser.
      </div>
      <h2 className="text-2xl font-semibold mt-4 mb-2">Children's Privacy</h2>
      <div className="pb-4">
        Our Service does not address anyone under the age of 18. We do not
        knowingly collect personally identifiable information from anyone under
        the age of 18. If You are a parent or guardian and You are aware that
        Your child has provided Us with Personal Data, please contact Us. If We
        become aware that We have collected Personal Data from anyone under the
        age of 18, We take steps to remove that information from Our servers.
      </div>
      <div className="pb-4">
        If We need to rely on consent as a legal basis for processing Your
        information and Your country requires consent from a parent, We may
        require Your parent's consent before We collect and use that
        information.
      </div>
      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Your California Privacy Rights (California's Shine the Light law)
      </h2>
      <div className="pb-4">
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes.
      </div>
      <div className="pb-4">
        If you'd like to request more information under the California Shine the
        Light law, and if You are a California resident, You can contact Us
        using the contact information provided below.
      </div>
      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Links to Other Websites
      </h2>
      <div className="pb-4">
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit.
      </div>
      <div className="pb-4">
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </div>
      <h2 className="text-2xl font-semibold mt-4 mb-2">
        Changes to this Privacy Policy
      </h2>
      <div className="pb-4">
        We may update Our Privacy Policy from time to time. We will notify You
        of any changes by posting the new Privacy Policy on this page.
      </div>
      <div className="pb-4">
        We will let You know via email and/or a prominent notice on Our Service,
        prior to the change becoming effective and update the "Last updated"
        date at the top of this Privacy Policy.
      </div>
      <div className="pb-4">
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </div>
      <h2 className="text-2xl font-semibold mt-4 mb-2">Contact Us</h2>
      <div className="pb-2">
        If you have any questions about this Cookies Policy, You can contact us:
        <br />
        By email:{" "}
        <a href="mailto:contact@letsgetproof.com" className="text-indigo-700">
          contact@letsgetproof.com
        </a>
      </div>
    </div>
  );
};
