import axios from "axios";

export const getHeaders = () => ({
  headers: { Authorization: `Bearer ${window.localStorage.getItem("token")}` },
});

export const apiUrl = () => process.env.REACT_APP_API_URI;

export const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem("token");
      window.location.replace("/login");
    }
    return Promise.reject(error);
  }
);
