import { Context } from "../types";
import { withParams } from "../utils/url";

export interface BlogData {
  id?: string;
  title?: string;
  date?: string;
  slug?: string;
  description?: string;
  header_image?: string;
  body?: string;
  tags?: { name: string }[];
  categories?: { name: string; id: string; }[];
  can_create_discussion: boolean;
  author: {
    id: string;
    image: string;
    name: string;
  }
}

export const getBlogCategories = ({ apiClient }: Context) => () =>
  apiClient<{ id: string; name: string }[]>({
    url: withParams("/blog/api/pages/categories"),
    method: "GET",
  });

export const getBlogsList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 1,
  searchQuery,
  ordering,
  categories,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  ordering?: string;
  categories?: string[];
}) =>
  apiClient<{
    data: BlogData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/blog/api/pages`, {
      page,
      perPage,
      search: searchQuery,
      ordering,
      categories,
    }).replace(/(%5B1%5D|%5B0%5D)/g, ""),
    method: "GET",
  });

export const getBlog = ({ apiClient }: Context) => ({ id }: { id: string }) =>
  apiClient<BlogData>({
    url: withParams(`/blog/api/pages/:id`, { id }),
    method: "GET",
  });
