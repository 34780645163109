export interface Env {
  REACT_APP_API_URI: string;
}

declare global {
  namespace NodeJS {
    interface ProcessEnv extends Env {}
  }
}

export const assertEnvVar = (envVar: keyof Env): never | undefined => {
  if (
    process.env[envVar] === undefined ||
    process.env[envVar].trim().length === 0
  ) {
    throw new Error(`Environment variable ${envVar} is required`);
  }

  return;
};
