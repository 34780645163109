import { AppContext } from "context";
import { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { routes } from "routes";

export const PublicRoute = (props: RouteProps) => {
  const { authToken } = useContext(AppContext);

  return !Boolean(authToken) ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: routes.dashboard,
      }}
    />
  );
};
