import { withParams } from "utils/url";
import { axiosInstance, apiUrl, getHeaders } from "../index";

const api = require("./api.json");

export const loginRequest = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.login)}`, {
    email,
    password,
  });

export const forgotPasswordRequest = ({ email }: { email: string }) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.forgotPassword)}`, { email });

export const changePasswordRequest = ({
  current_password,
  new_password,
  confirm_new_password,
  email,
  name,
}: {
  current_password?: string;
  new_password: string;
  confirm_new_password: string;
  email: string;
  name: string;
}) =>
  axiosInstance.patch(
    `${apiUrl()}${withParams(api.me)}`,
    {
      current_password,
      new_password,
      confirm_new_password,
      email,
      name,
    },
    getHeaders()
  );

export const changeMeRequest = (data: {
  current_password: string;
  title?: string;
  name?: string;
  affiliation?: string;
  location?: string;
  website?: string;
  social?: string;
  bio?: string;
  address?: string;
}) =>
  axiosInstance.patch(`${apiUrl()}${withParams(api.me)}`, data, getHeaders());

export const changePersonalizationRequest = (data: {}) =>
  axiosInstance.post(
    `${apiUrl()}/me/personalization-update`,
    data,
    getHeaders()
  );

export const affiliationSetupRequest = (data: {
  affiliation: string;
  university_affiliate_email: string;
  university_profile_page_url: string;
}) =>
  axiosInstance.put(
    `${apiUrl()}${withParams(api.meAffiliationSetup)}`,
    data,
    getHeaders()
  );

export const forgotPasswordConfirmRequest = ({
  password,
  token,
}: {
  password: string;
  token: string;
}) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.forgotPasswordConfirm)}`, {
    password,
    token,
  });

export const setNewPasswordConfirmRequest = ({
  new_password,
  confirm_password,
  token,
}: {
  new_password: string;
  confirm_password: string;
  token: string;
}) =>
  axiosInstance.post(`${apiUrl()}/set-password`, {
    new_password,
    confirm_password,
    token,
  });

export const forgotPasswordValidateTokenRequest = ({
  token,
}: {
  token: string;
}) =>
  axiosInstance.post(
    `${apiUrl()}${withParams(api.forgotPasswordValidateToken)}`,
    { token }
  );

export const confirmAccountSmsRequest = ({
  confirmation_code,
  sms_confirm_token,
}: {
  confirmation_code: string;
  sms_confirm_token: string | (string | null)[] | null;
}) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.confirmAccountSms)}`, {
    confirmation_code,
    sms_confirm_token,
  });

export const registerRequest = ({
  name,
  captcha,
  email,
  role,
  password,
  phone_number,
  looking_for,
  gender,
  race,
  ethnicity,
  age_group,
  career_stage,
  redirect_to,
  affiliation,
  university_profile_page_url,
  university_affiliate_email,
  is_project_creator,
  pronouns,
  find_about_by,
  location,
  bio,
  social,
  area_of_interest,
  tags,
  find_about_by_other_info,
}: {
  name: string;
  captcha?: string | null | undefined;
  email: string;
  role?: string[];
  looking_for?: string[];
  gender?: string;
  race?: string[];
  ethnicity?: string;
  career_stage?: string;
  password: string;
  phone_number?: string;
  redirect_to?: string;
  age_group?: string;
  affiliation?: string;
  university_profile_page_url?: string;
  university_affiliate_email?: string;
  pronouns?: string;
  find_about_by?: string;
  location?: string;
  bio?: string;
  social?: string;
  area_of_interest?: string[];
  tags?: string[];
  is_project_creator?: boolean;
  find_about_by_other_info?: string;
}): Promise<{
  data: {
    data: {
      sms_confirm_token?: string;
    };
  };
}> =>
  axiosInstance.post(`${apiUrl()}${withParams(api.register)}`, {
    captcha,
    name,
    role,
    looking_for,
    email,
    password,
    gender: gender,
    race,
    ethnicity: ethnicity,
    age_group,
    career_stage: career_stage,
    redirect_to,
    affiliation,
    university_profile_page_url,
    university_affiliate_email,
    is_project_creator,
    phone_number,
    pronouns,
    find_about_by,
    location,
    bio,
    social,
    area_of_interest,
    tags,
    find_about_by_other_info,
  });

export const resendSmsCodeRequest = ({
  sms_confirm_token,
}: {
  sms_confirm_token: any;
}) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.resendSmsCode)}`, {
    sms_confirm_token,
  });

export const confirmUserRequest = ({ token }: { token: string }) =>
  axiosInstance.post(`${apiUrl()}${withParams(api.confirmUser)}`, { token });

export const createOTP = ({ token }: { token: string }) =>
  axiosInstance.put(
    `${apiUrl()}${withParams(api.otpTotp)}`,
    { token },
    getHeaders()
  );

export const deleteOTP = () =>
  axiosInstance.delete(`${apiUrl()}${withParams(api.otpTotp)}`, getHeaders());


export const deleteSSO = (id: number) =>
  axiosInstance.delete(`${apiUrl()}${withParams(api.deleteSSO, { id })}`, getHeaders());
