import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getButtonClassNames } from "./button/Button";

export const NotFound = ({
  resetErrorBoundary,
}: Partial<Pick<FallbackProps, "resetErrorBoundary">>) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full py-16 md:py-32">
      <div className="w-full max-w-md text-center">
        <h1 className="mb-2 font-bold text-center text-gray-200 text-8xl">
          404
        </h1>
        <h3 className="text-xl font-medium leading-8 text-gray-900">
          {t("Not found")}
        </h3>
        <p className="mb-4 text-sm text-gray-500">
          {t("The page you're looking for doesn't exist.")}
        </p>
        <Link
          to="/"
          className={getButtonClassNames({ variant: "white" })}
          onClick={() => {
            if (resetErrorBoundary) {
              resetErrorBoundary();
            }
          }}
        >
          {t("Go to the main page")}
        </Link>
      </div>
    </div>
  );
};
