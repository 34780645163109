import React from "react";
import { NotificationsForm } from "./NotificationsForm";
import { useQuery } from "react-query";

import { useContextService } from "hooks/use-context-service";
import { getNotifications } from "api/notifications";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";
import {Helmet} from "react-helmet";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";

export const Notifications = () => {
  const getNotificationsQuery = useContextService(getNotifications);

  const { data: notificationData, isLoading } = useQuery(
    GET_NOTIFICATIONS,
    getNotificationsQuery,
    {
      suspense: false,
    }
  );

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | My Profile | Notifications</title>
      </Helmet>
      {isLoading || !notificationData?.data ? (
        <div className="flex justify-center my-12">
          <IconLoading />
        </div>
      ) : (
        <NotificationsForm notificationData={notificationData.data} />
      )}
    </div>
  );
};
