import React from "react";
import { NavLink } from "react-router-dom";
import map from "lodash/map";
import { format } from "date-fns";

import { Button } from "components/button/Button";

import { BlogData } from "api/blogs";
import { withParams } from "utils/url";

import { routes } from "routes";
import get from "lodash/get";

const BlogItem = ({
  title,
  id,
  tags,
  categories,
  date,
  description,
  header_image,
  author,
}: BlogData) => {
  return (
    <div className="flex flex-col overflow-hidden rounded-lg shadow-lg h-full bg-white">
      <div className="">
        <div className="flex-shrink-0">
          {header_image ? (
            <div className="ml-auto bg-gray-100">
              <img
                src={header_image}
                alt={title}
                className="h-56 w-full object-cover"
              />
            </div>
          ) : (
            <div className="h-56 bg-gray-100"></div>
          )}
        </div>

        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <div className="">
            {categories?.map(({ name }, i) => (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                }}
                href={withParams(routes.blogList, { categories: name })}
                key={i}
                className="rounded-md mr-2 mt-2 bg-yellow-500 bg-opacity-90 py-0.5 px-1 text-xs uppercase leading-4 tracking-wide text-white"
              >
                {name}
              </a>
            ))}

            <div className="mt-2 block">
              <p className="text-xl font-semibold text-gray-900">{title}</p>

              {description && (
                <div className="text-gray-500">
                  <div
                    className="wysiwyg-editor short-summary"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {/*<h2 className="font-bold text-xl text-gray-900">{title}</h2>

        {description && (
          <div className="text-gray-500">
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        )}

        <div className="text-xs text-gray-400 mt-2">
          {date && format(new Date(date), "MMM dd YYY, HH:mm")}
        </div>

        <div className="my-2">
          {tags?.map(({ name }, i) => (
            <span
              key={i}
              className="pointer-events-none rounded-md mr-2 mt-2 bg-sky-500 bg-opacity-90 py-0.5 px-1.5 text-sm uppercase leading-4 tracking-wide text-white"
            >
              {name}
            </span>
          ))}
        </div>

        <div className="my-2">
          {categories?.map(({ name }, i) => (
            <span
              key={i}
              className="pointer-events-none rounded-md mr-2 mt-2 bg-yellow-500 bg-opacity-90 py-0.5 px-1.5 text-sm uppercase leading-4 tracking-wide text-white"
            >
              {name}
            </span>
          ))}
        </div>*/}
      </div>

      <div className="mt-auto flex items-center px-6 pb-6">
        <div className="flex justify-between w-full items-center">
          <div>
            <Button variant="secondary" size="small">
              Read more
            </Button>
          </div>
          <div className="flex items-center space-x-1 text-gray-600">
            <div
              className="flex items-center justify-center w-10 h-10 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full mr-1"
              style={{
                backgroundImage: `url(${author?.image})`,
              }}
            >
              {!author?.image ? get(author?.name, "[0]") : ""}
            </div>

            <div>
              <div className="-mb-1">{author?.name}</div>
              {date && (
                <time dateTime={date} className="text-sm text-gray-400">
                  {format(new Date(date), "MMM dd, YYY")}
                </time>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const List = ({ listData }: { listData: BlogData[] }) => {
  return (
    <div>
      <div className="mx-auto mt-12 grid gap-5 lg:max-w-none sm:grid-cols-2 lg:grid-cols-3">
        {map(listData, (data) => (
          <NavLink
            to={withParams(routes.blogSingle, { id: data.slug })}
            key={data.id}
          >
            <BlogItem key={data.id} {...data} />
          </NavLink>
        ))}
      </div>
    </div>
  );
};
