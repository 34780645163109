import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import map from "lodash/map";

import { QuizData } from "api/quiz";
import { withParams } from "utils/url";

import { routes } from "routes";
import { format, isAfter, isSameDay } from "date-fns";

import { Button } from "components/button/Button";

const statuses = {
  Complete: "text-green-700 bg-green-50 ring-green-600/20",
  "In progress": "text-gray-600 bg-gray-50 ring-gray-500/10",
  Archived: "text-yellow-800 bg-yellow-50 ring-yellow-600/20",
};

const QuizItem = (data: QuizData) => {
  return (
    <div className="flex items-center overflow-hidden h-full bg-white py-5 px-6 border-b border-gray-200">
      <div>
        <div className="flex items-start gap-x-3">
          <p className="text-sm font-semibold leading-6 text-gray-900">
            {data.name}
          </p>
          <p
            className={classNames(
              statuses[data.quiz_passed ? "Complete" : "In progress"],
              "rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
            )}
          >
            {data.quiz_passed ? `Complete` : "In progress"}
          </p>
          {data.quiz_passed && (
            <p
              className={classNames(
                "text-green-700 bg-green-50 ring-green-600/20 rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset"
              )}
            >
              {data.percent_correct_answers}% correct answers
            </p>
          )}
        </div>
        {data.description && <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
          <div
            className="wysiwyg-editor"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </div>}
      </div>

      {!data.quiz_passed && (
        <div className="ml-8 flex items-center">
          <p className="text-xs leading-6 text-gray-500">Remaining attempts</p>
          <div className="ml-2 flex items-center gap-x-2 text-sm leading-5 font-semibold text-gray-900">
            <p className="whitespace-nowrap">{data.remaining_attempts}</p>
          </div>
        </div>
      )}

      {data.delayed_until && (
        <div className="ml-8 flex items-center">
          <p className="text-xs leading-6 text-gray-500">Delayed until</p>
          <div className="ml-2 flex items-center gap-x-2 text-sm leading-5 font-semibold text-gray-900">
            <p className="whitespace-nowrap">
              {format(new Date(data.delayed_until), "MMM dd YYY")}
            </p>
          </div>
        </div>
      )}

      {data.certificate_url && (
        <div className="ml-auto">
          <a href={data.certificate_url} target="_blank" rel="noreferrer" onClick={(e) => { e.stopPropagation(); }}>
            <Button size="small" variant="primary">
              Download certificate
            </Button>
          </a>
        </div>
      )}
    </div>
  );
};

export const List = ({ listData }: { listData: QuizData[] }) => {
  return (
    <div>
      <div className="mx-auto mt-8">
        {map(listData, (data) =>
          !data.quiz_passed && ((!data.delayed_until && data.remaining_attempts! > 0) ||
          (data.delayed_until &&
            isAfter(new Date(), new Date(data.delayed_until))) ||
          (data.delayed_until &&
            isSameDay(new Date(data.delayed_until), new Date()))) ? (
            <NavLink
              to={withParams(routes.quizSingle, { id: data.id })}
              key={data.id}
            >
              <QuizItem key={data.id} {...data} />
            </NavLink>
          ) : (
            <QuizItem key={data.id} {...data} />
          )
        )}
      </div>
    </div>
  );
};
