import React, { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";

import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";

import { forgotPasswordRequest } from "api/auth/auth";
import { setFormError } from "utils/setError";

import { ReactComponent as IconLogo } from "assets/icons/logo.svg";
import {Helmet} from "react-helmet";

const routes = require("routes/routes.json");

export const ForgotPassword = () => {
  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required("Required")
        .matches(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Invalid email"
        ),
    });
  }, []);

  const { register, handleSubmit, formState, setError } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [formError, setFormErrorMessage] = useState<string | undefined>();

  const forgotPasswordQuery = useMutation(forgotPasswordRequest, {
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const onSubmit = (data: any) => {
    forgotPasswordQuery.mutate({ email: data.email });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Helmet>
        <title>Let’s Get Proof | Restore Password</title>
      </Helmet>
      <div className="px-4 my-auto w-96 sm:p-0">
        <div className="w-full p-5 bg-white rounded sm:p-8">
          <IconLogo className="w-40 mx-auto mb-8" />

          {forgotPasswordQuery.isSuccess ? (
            <div className="w-full px-8 py-3 text-center text-green-700 bg-green-100 rounded">
              Success. Please check your email (including your spam folder)
            </div>
          ) : (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col items-center"
            >
              <div className="w-full mb-5">
                <TextField
                  label="Email"
                  placeholder="john.doe@gmail.com"
                  error={formState.errors?.email}
                  {...register("email", { required: true })}
                />
              </div>

              {formError && (
                <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
                  {formError}
                </div>
              )}

              <Button
                type="submit"
                className="w-full"
                spinner
                loading={forgotPasswordQuery.isLoading}
              >
                Reset Password
              </Button>

              <div className="mt-3">
                <NavLink
                  to={routes.public.login}
                  className="font-medium text-violet-600 transition-colors hover:text-violet-800"
                >
                  Login
                </NavLink>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
