import React, { useState, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get } from "lodash";

import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";

import { changePasswordRequest } from "api/auth/auth";
import { setFormError } from "utils/setError";
import {useUserQuery} from "../../../../queries/use-user-query";

export const ChangePasswordForm = ({
  user,
}: {
  user: { name: string; email: string };
}) => {
  const { data: userResponse } = useUserQuery();

  const schema = useMemo(() => {
    return yup.object().shape({
      current_password: userResponse?.data?.is_password_set ? yup.string().required("Required") : yup.string(),
      new_password: yup
        .string()
        .required("Required")
        .min(
          8,
          "This password is too short. It must contain at least 8 characters."
        ),
      confirm_new_password: yup
        .string()
        .required("Required")
        .oneOf([yup.ref("new_password")], "The password don't match"),
    });
  }, [userResponse]);

  const { register, handleSubmit, formState, setError, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const changePasswordQuery = useMutation(changePasswordRequest, {
    onSuccess: () => {
      reset();
      setFormSuccessMessage("Password has been successfully changed.");
    },
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const onSubmit = (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);
    changePasswordQuery.mutate({
      current_password: data.current_password,
      new_password: data.new_password,
      confirm_new_password: data.confirm_new_password,
      email: user.email,
      name: user.name,
    });
  };

  return (
    <div className="" style={{ maxWidth: "375px" }}>
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <h2 className="text-lg font-medium text-black mb-2">Change Password</h2>
        {userResponse?.data?.is_password_set && <div className="w-full mb-5">
          <TextField
            label="Old Password"
            placeholder="Old password"
            type="password"
            required
            error={formState.errors?.current_password}
            {...register("current_password", { required: true })}
          />
        </div>}
        <div className="w-full mb-5">
          <TextField
            label="New Password"
            placeholder="New password"
            type="password"
            required
            error={formState.errors?.new_password}
            {...register("new_password", { required: true })}
          />
        </div>
        <div className="w-full mb-5">
          <TextField
            label="Repeat new Password"
            placeholder="Repeat new password"
            type="password"
            required
            error={formState.errors?.confirm_new_password}
            {...register("confirm_new_password", { required: true })}
          />
        </div>

        {formErrorMessage && (
          <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
            {formErrorMessage}
          </div>
        )}

        {formSuccessMessage && (
          <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
            {formSuccessMessage}
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          loading={changePasswordQuery.isLoading}
          spinner
        >
          Change Password
        </Button>
      </form>
    </div>
  );
};
