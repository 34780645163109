import { getUserAccount } from "api/account";
import { AppContext } from "context";
import { useContextService } from "hooks/use-context-service";
import { useContext, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query";

export const USER_QUERY_KEY = "user";

export const useUserQuery = () => {
  const { authToken } = useContext(AppContext);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!authToken) {
      queryClient.removeQueries(USER_QUERY_KEY);
    }
  }, [authToken, queryClient]);

  return useQuery(USER_QUERY_KEY, useContextService(getUserAccount), {
    enabled: Boolean(authToken),
  });
};
