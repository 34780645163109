import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import classNames from "classnames";
import map from "lodash/map";
import get from "lodash/get";
import { useMutation } from "react-query";

import { Button } from "components/button/Button";
import { Modal } from "components/modal/Modal";
import { WorkingGroupData } from "api/workingGroups";
import { withParams } from "utils/url";
import { useContextService } from "hooks/use-context-service";
import { deleteWorkingGroup } from "api/workingGroups";
import { showNotification as showNotificationService } from "services/notification";

import { routes } from "routes";
import { useModal } from "../../../../hooks/use-modal";

const activeStatus: { [k: string]: string } = {
  active: "Active",
  suspended: "Suspended",
};

const approveStatus: { [k: string]: string } = {
  approved: "Approved",
  not_approved: "Not approved",
  waiting_for_approval: "Waiting for approval",
  pending_approval_for_changes: "Pending approval for changes",
};

const WorkingGroupItem = ({
  title,
  summary,
  created_by,
  image,
  active_status,
  approved_status,
  id,
  slug,
}: WorkingGroupData) => {
  const showNotification = useContextService(showNotificationService);
  const history = useHistory();
  const { modalVisible, openModal, closeModal } = useModal();

  const [isDeleted, setIsDeleted] = useState(false);

  const deleteWorkingGroupMutation = useMutation(
    useContextService(deleteWorkingGroup),
    {
      onSuccess: (response) => {
        showNotification({
          title: "Success",
          message: "Your working group has been successfully deleted",
        });
      },
      onError: (e) => {
        showNotification({
          title: "Error",
          message: "Something went wrong",
          variant: "ERROR",
        });
      },
    }
  );

  // {active_status === "active" && approved_status === "approved" && (

  return (
    <>
      <Modal visible={modalVisible} onClose={closeModal} className="max-w-lg">
        <div className="text-center pb-4 text-2xl font-bold">
          Confirm action
        </div>
        <div className="flex justify-center">
          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              closeModal();
            }}
            variant="secondary"
            className="mr-1 w-40"
          >
            Cancel
          </Button>

          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              try {
                await deleteWorkingGroupMutation.mutateAsync({ id: id! });
                setIsDeleted(true);
                closeModal();
              } catch (e) {}
            }}
            spinner
            loading={deleteWorkingGroupMutation.isLoading}
            variant="danger"
            className="ml-1 w-40"
          >
            Delete project
          </Button>
        </div>
      </Modal>

      <NavLink
        to={withParams(routes.workingGroupSingle, { id: slug })}
        key={id}
        className={classNames(
          "flex flex-col border border-gray-200 bg-white rounded",
          {
            "opacity-30": isDeleted,
            "cursor-default": !(
              active_status === "active" && approved_status === "approved"
            ),
          }
        )}
        onClick={(e) => {
          if (
            isDeleted ||
            !(active_status === "active" && approved_status === "approved")
          ) {
            e.preventDefault();
          }
        }}
      >
        <div
          className="relative w-full h-56 bg-gray-200 bg-center bg-no-repeat bg-contain rounded-t"
          style={{ backgroundImage: `url(${image})` }}
        >
          {!isDeleted ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                openModal();
              }}
              spinner
              loading={deleteWorkingGroupMutation.isLoading}
              type="button"
              className="absolute top-2 right-2"
              size="exSmall"
              variant="danger"
            >
              Delete Group
            </Button>
          ) : (
            <Button
              type="button"
              size="exSmall"
              variant="danger"
              className="absolute top-2 right-2"
            >
              Deleted
            </Button>
          )}
        </div>
        <div
          className="p-4 break-words md:p-8"
          style={{ wordBreak: "break-word" }}
        >
          <div className="flex pb-2">
            <span
              className={classNames(
                "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-white mr-1",
                {
                  "bg-green-600": active_status === "active",
                  "bg-yellow-500": active_status !== "active",
                }
              )}
            >
              {active_status && activeStatus[active_status]}
            </span>
            <span
              className={classNames(
                "inline-flex items-center px-3 py-1 rounded-full text-sm font-medium text-white mr-1",
                {
                  "bg-green-600": approved_status === "approved",
                  "bg-yellow-500":
                    approved_status !== "approved" &&
                    approved_status !== "not_approved",
                  "bg-red-500": approved_status === "not_approved",
                }
              )}
            >
              {approved_status && approveStatus[approved_status]}
            </span>
          </div>

          <h2 className="mb-1 text-lg font-bold">{title}</h2>
          {summary && (
            <div className="text-sm text-gray-700">
              <div
                className="wysiwyg-editor wg-summary"
                dangerouslySetInnerHTML={{ __html: summary }}
              />
            </div>
          )}
        </div>

        <div className="flex items-center px-4 pb-4 mt-auto md:px-8 md:pb-8">
          <div
            className="flex items-center justify-center w-8 h-8 font-medium text-white bg-gray-400 bg-center bg-cover rounded-full"
            style={{ backgroundImage: `url(${created_by?.avatar})` }}
          >
            {!created_by?.avatar ? get(created_by, "name[0]") : ""}
          </div>
          <div className="ml-2">{created_by?.name}</div>
        </div>

        <div
          className={classNames("flex px-4 py-4 md:px-8 bg-blue-100 rounded-b")}
        >
          <NavLink
            to={withParams("/group-updates/:group_id", {
              group_id: id,
            })}
          >
            <Button
              type="button"
              size="exSmall"
              variant="primary"
              disabled={approved_status !== "approved"}
            >
              Updates
            </Button>
          </NavLink>

          <span className="ml-auto">
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                history.push(withParams(routes.workingGroupEdit, { id }));
              }}
              size="exSmall"
            >
              Edit
            </Button>
          </span>
        </div>
      </NavLink>
    </>
  );
};

export const List = ({ listData }: { listData: WorkingGroupData[] }) => {
  return (
    <div>
      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {map(listData, (data) => (
          <WorkingGroupItem key={data.id} {...data} />
        ))}
      </div>
    </div>
  );
};
