import React from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import get from "lodash/get";
import { UserGroupIcon, ChatIcon } from "@heroicons/react/solid";

// import { joinRequestWorkingGroup } from "api/workingGroups";

// import { Button } from "components/button/Button";
import { WorkingGroupData } from "api/workingGroups";
import { withParams } from "utils/url";

import { routes } from "routes";
// import { useContextService } from "../../../../hooks/use-context-service";
// import { useMutation } from "react-query";
// import { showNotification as showNotificationService } from "../../../../services/notification";

// const JOIN_WORKING_GROUP_REQUEST = "JOIN_WORKING_GROUP_REQUEST";

const WorkingGroupItem = ({
  title,
  // is_member,
  // has_join_request,
  // slug,
  comments,
  members,
  summary,
  created_by,
  image,
  isVertical,
}: WorkingGroupData & { isVertical?: boolean }) => {
  // const joinRequestWorkingGroupService = useContextService(
  //   joinRequestWorkingGroup
  // );

  // const showNotification = useContextService(showNotificationService);

  // const joinRequestWorkingGroupMutation = useMutation(
  //   [JOIN_WORKING_GROUP_REQUEST],
  //   () => joinRequestWorkingGroupService(slug!),
  //   {
  //     onSuccess: () => {
  //       // setIsJoinRequested(true);
  //       showNotification({
  //         title: "Success",
  //         message: "Join request has been sent",
  //       });
  //     },
  //     onError: () => {
  //       showNotification({
  //         title: "Error",
  //         message: "Something went wrong",
  //       });
  //     },
  //   }
  // );

  // const [isJoinRequested, setIsJoinRequested] = useState(has_join_request);

  return (
    <div className={classNames("flex flex-col h-full wg-gradient")}>
      <div className={classNames("flex h-full hover:bg-white/10 transition duration-150 ease-in", { "flex-col": isVertical })}>
        <div className={classNames("p-3", { "w-56": !isVertical })}>
          <div
            className="relative w-full h-56 bg-gray-200 bg-center bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${image})` }}
          />
        </div>

        <div className="flex-grow flex flex-col">
          <div
            className="px-6 py-4 break-words"
            style={{ wordBreak: "break-word" }}
          >
            <h2 className="font-nunito font-bold text-xl lg:text-2xl text-white">
              {title}
            </h2>
          </div>

          <div className="mt-auto px-5 pb-5 font-nunito">
            <div className="flex items-center mb-4">
              <div
                className="flex justify-center items-center rounded-full bg-red-550 w-8 h-8 text-white font-medium bg-center bg-cover"
                style={{ backgroundImage: `url(${created_by?.avatar})` }}
              >
                {!created_by?.avatar ? get(created_by, "name[0]") : ""}
              </div>
              <div className="ml-2 font-semibold text-base text-white font-nunito">
                {created_by?.name}
              </div>
            </div>

            <div className="flex text-base text-red-500 font-semibold">
              <div className="flex items-center mr-4">
                <ChatIcon className="mr-1 w-5 text-white" /> {comments}
              </div>
              <div className="flex items-center">
                <UserGroupIcon className="-mt-0.5 mr-1 w-5 text-white" />{" "}
                {members}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ListHome = ({ listData }: { listData: WorkingGroupData[] }) => {
  return (
    <div>
      <div className="flex flex-wrap w-full items-center">
        <div className="w-full pb-3 md:pb-0 md:w-2/5 self-stretch md:pr-3">
          <NavLink
            to={withParams(routes.workingGroupSingle, { id: get(listData, '[0].slug') })}
            key={get(listData, '[0].slug')}
          >
            <WorkingGroupItem
              key={get(listData, '[0].id')}
              {...listData[0]}
              isVertical
            />
          </NavLink>
        </div>
        <div className="w-full md:w-3/5 md:pl-3">
          {listData[1] && <div className="pb-3">
            <NavLink
              to={withParams(routes.workingGroupSingle, {
                id: get(listData, '[1].slug'),
              })}
              key={get(listData, '[1].slug')}
            >
              <WorkingGroupItem key={get(listData, '[1].id')} {...listData[1]} />
            </NavLink>
          </div>}

          {listData[2] && <div className="pt-3">
            <NavLink
              to={withParams(routes.workingGroupSingle, {
                id: get(listData, '[2].slug'),
              })}
              key={get(listData, '[2].slug')}
            >
              <WorkingGroupItem key={get(listData, '[2].id')} {...listData[2]} />
            </NavLink>
          </div>}
        </div>
      </div>
    </div>
  );
};
