import React from "react";

import { DiscussionsList } from "./DiscussionsList";
import { DiscussionsContextProvider } from "./DiscussionsContext";

export const Discussions = ({
  id,
  canWrite,
  type,
}: {
  id: string;
  canWrite?: boolean;
  type?: string;
}) => {
  return (
    <DiscussionsContextProvider id={id}>
      <DiscussionsList id={id} canWrite={canWrite} type={type} />
    </DiscussionsContextProvider>
  );
};
