import React, { useCallback, useState } from "react";
import { useMutation } from "react-query";
import classNames from "classnames";
import get from "lodash/get";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from "react-hook-form";
import * as z from "zod";

import { Button } from "components/button/Button";
import { TextField } from "components/text-field/TextField";

import {
  WorkingGroupData,
  wgSetSupplementaryFileRequest,
  wgUpdateSupplementaryFileRequest,
  wgSupplementaryDeleteRequest,
} from "api/workingGroups";

import { useContextService } from "hooks/use-context-service";
import { showNotification as showNotificationService } from "../../../services/notification";
import { FileUploadField } from "../../../components/file-upload-field/FileUploadField";
import { TrashIcon } from "@heroicons/react/outline";
import { ReactComponent as IconLoading } from "../../../assets/icons/loading.svg";
import {validURL} from "../../../utils/url";

const useFormSchema = () =>
  z.object({
    supplementary_materials: z
      .object({
        item_id: z.string().optional().nullable(),
        name: z.string().optional().nullable(),
        url: z.string().optional().nullable(),
        label: z.string().optional().nullable(),
        file_type: z.string().optional().nullable(),
        file_url: z.string().optional().nullable(),
      })
      .array(),
  });

export const SupplementaryItem = ({
  group_id,
  index,
  id,
  initialFileType,
  item_id,
  removeSupplementary,
  hasFile,
  type,
  onSuccess,
}: {
  group_id: string;
  index: number;
  id: string;
  item_id: string;
  initialFileType: string;
  removeSupplementary: (index: number) => void;
  hasFile: boolean;
  type?: string;
  onSuccess: () => void;
}) => {
  const showNotification = useContextService(showNotificationService);

  const [loaded, setLoaded] = useState(0);
  const [isLinkSuccess, setIsLinkSuccess] = useState(false);
  const [smType, setSMType] = useState("document");
  const [error, setError] = useState<
    { type: string; message: string } | undefined
  >();

  const {
    getValues,
    formState,
    register,
    control,
    setError: setFormError,
    setValue,
  } = useFormContext();

  const wgSupplementaryDeleteQuery = useMutation(wgSupplementaryDeleteRequest);

  const uploadFileSupplementaryQuery = useMutation(
    wgSetSupplementaryFileRequest
  );
  const wgUpdateSupplementaryFileRequestQuery = useMutation(
    wgUpdateSupplementaryFileRequest
  );

  const uploadFileSupplementaryCallback = useCallback(
    async (uploadFile) => {
      setError(undefined);

      if (uploadFile) {
        const file = await fetch(uploadFile);

        const formData = new FormData();
        formData.append("file", await file.blob(), "group");
        formData.append(
          "file_name",
          getValues(`supplementary_materials.${index}.name`)
        );

        try {
          const result = await uploadFileSupplementaryQuery.mutateAsync({
            group_id,
            data: formData,
            setLoaded,
          });

          onSuccess();

          setValue(
            `supplementary_materials.${index}.item_id`,
            `${result.data.data.id}`
          );
        } catch (e) {
          showNotification({
            title: "Error",
            message: get(
              e,
              "response.data.messages.file[0]",
              "Something went wrong"
            ),
            variant: "ERROR",
          });

          setError({
            type: "error",
            message: get(e, "response.data.messages.file[0]", "Error"),
          });
        }
      }
    },
    [
      onSuccess,
      setValue,
      setError,
      group_id,
      uploadFileSupplementaryQuery,
      showNotification,
      getValues,
      index,
    ]
  );

  const saveLinkSupplementaryCallback = useCallback(async () => {
    setError(undefined);

    if (!getValues(`supplementary_materials.${index}.label`)) {
      setFormError(`supplementary_materials.${index}.label`, {
        type: "custom",
        message: "Required",
      });
    }

    if (!getValues(`supplementary_materials.${index}.url`)) {
      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: "Required",
      });
    }

    if (!validURL(getValues(`supplementary_materials.${index}.url`))) {
      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: "Not a valid url",
      });

      return;
    }

    if (
      !(
        getValues(`supplementary_materials.${index}.label`) &&
        getValues(`supplementary_materials.${index}.url`)
      )
    ) {
      return;
    }

    const formData = new FormData();
    formData.append(
      "label",
      getValues(`supplementary_materials.${index}.label`)
    );
    formData.append("url", getValues(`supplementary_materials.${index}.url`));
    formData.append("type", "link");

    try {
      const result = await (item_id
        ? wgUpdateSupplementaryFileRequestQuery
        : uploadFileSupplementaryQuery
      ).mutateAsync({
        id: item_id,
        group_id,
        data: formData,
        setLoaded,
      });

      onSuccess();

      setValue(
        `supplementary_materials.${index}.item_id`,
        `${result.data.data.id}`
      );

      setIsLinkSuccess(true);

      setTimeout(() => {
        setIsLinkSuccess(false);
      }, 1000);
    } catch (e) {
      showNotification({
        title: "Error",
        message: get(
          e,
          "response.data.messages.file[0]",
          "Something went wrong"
        ),
        variant: "ERROR",
      });

      setFormError(`supplementary_materials.${index}.url`, {
        type: "custom",
        message: JSON.stringify(get(e, "response.data.messages", "Error")),
      });
    }
  }, [
    onSuccess,
    item_id,
    setFormError,
    setValue,
    setError,
    group_id,
    wgUpdateSupplementaryFileRequestQuery,
    uploadFileSupplementaryQuery,
    showNotification,
    getValues,
    index,
  ]);

  return (
    <>
      <input
        type="hidden"
        {...register(`supplementary_materials.${index}.item_id`)}
      />

      <div className="flex-1 hidden">
        <TextField
          label="File name"
          placeholder="File name"
          required
          disabled={uploadFileSupplementaryQuery.isSuccess || hasFile}
          error={get(
            formState.errors,
            `supplementary_materials[${index}].name`
          )}
          {...register(`supplementary_materials.${index}.name`)}
        />
      </div>

      {!type &&
        !(uploadFileSupplementaryQuery.isSuccess || hasFile) &&
        !uploadFileSupplementaryQuery.isLoading && (
          <div className="pb-4">
            <div className="text-xs text-gray-600">Type</div>
            <label htmlFor="field-document" className="mr-3">
              <input
                type="radio"
                name="type"
                value="document"
                checked={smType === "document"}
                id="field-document"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSMType("document");
                  }
                }}
              />
              <span className="ml-1 text-sm font-medium text-gray-700">
                Document
              </span>
            </label>
            <label htmlFor="field-link">
              <input
                type="radio"
                name="type"
                value="link"
                checked={smType === "link"}
                id="field-link"
                onChange={(e) => {
                  if (e.target.checked) {
                    setSMType("link");
                  }
                }}
              />
              <span className="ml-1 text-sm font-medium text-gray-700">
                Link
              </span>
            </label>
          </div>
        )}

      {type === "link" || smType === "link" ? (
        <div className="w-full py-4" style={{ maxWidth: "420px" }}>
          <div className="flex mb-4">
            <div className="mr-4" style={{ width: "348px " }}>
              <div className="" style={{ width: "332px " }}>
                <TextField
                  label="Description"
                  placeholder="Description"
                  error={get(
                    formState.errors,
                    `supplementary_materials[${index}].label`
                  )}
                  {...register(`supplementary_materials.${index}.label`)}
                />
              </div>
            </div>

            <div className="w-16 pt-1 mt-6 ml-2">
              {!uploadFileSupplementaryQuery.isLoading && (
                <button
                  className="text-red-600 flex items-center mt-0.5"
                  type="button"
                  onClick={async () => {
                    if (item_id) {
                      await wgSupplementaryDeleteQuery.mutateAsync({
                        id: item_id,
                        group_id,
                      });
                    }
                    removeSupplementary(index);
                    onSuccess();
                  }}
                >
                  <TrashIcon className="w-5" />
                  {wgSupplementaryDeleteQuery.isLoading && (
                    <IconLoading className="w-4 h-4 ml-2" />
                  )}
                </button>
              )}
            </div>
          </div>

          <div className="mr-4 mb-4">
            <TextField
              label="URL"
              placeholder="URL"
              error={get(
                formState.errors,
                `supplementary_materials[${index}].url`
              )}
              {...register(`supplementary_materials.${index}.url`)}
            />
          </div>

          <div className="flex">
            <Button
              size="exSmall"
              type="button"
              disabled={
                uploadFileSupplementaryQuery.isLoading ||
                wgUpdateSupplementaryFileRequestQuery.isLoading
              }
              loading={
                uploadFileSupplementaryQuery.isLoading ||
                wgUpdateSupplementaryFileRequestQuery.isLoading
              }
              spinner
              onClick={() => {
                saveLinkSupplementaryCallback();
              }}
            >
              Save
            </Button>

            {isLinkSuccess && (
              <div className="rounded bg-green-100 text-green-700 text-xs flex items-center px-2 ml-2">
                Success
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full mr-4" style={{ maxWidth: "420px" }}>
          <div className="w-full mr-4" style={{ width: "420px" }}>
            <div className="flex">
              <div className="flex-1">
                <FileUploadField
                  label="File"
                  name={`supplementary_materials.${index}.url`}
                  fileNameField={`supplementary_materials.${index}.name`}
                  fileNameValue={getValues(
                    `supplementary_materials.${index}.name`
                  )}
                  error={error}
                  isLoading={uploadFileSupplementaryQuery.isLoading}
                  disabled={uploadFileSupplementaryQuery.isSuccess || hasFile}
                  control={control}
                  setValue={setValue}
                  initialFileType={initialFileType}
                  onChangeProp={(val) => {
                    uploadFileSupplementaryCallback(val);
                  }}
                  loaded={loaded}
                />
              </div>
              <div className="w-16 pt-1 mt-6 ml-2">
                {!uploadFileSupplementaryQuery.isLoading && (
                  <button
                    className="text-red-600 flex items-center mt-0.5"
                    type="button"
                    onClick={async () => {
                      if (item_id) {
                        await wgSupplementaryDeleteQuery.mutateAsync({
                          id: item_id,
                          group_id,
                        });
                      }
                      removeSupplementary(index);
                      onSuccess();
                    }}
                  >
                    <TrashIcon className="w-5" />
                    {wgSupplementaryDeleteQuery.isLoading && (
                      <IconLoading className="w-4 h-4 ml-2" />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const SupplementaryMaterialsForm = ({
  id,
  onSuccess,
  supplementary_items,
}: {
  id?: string;
  onSuccess: () => void;
  supplementary_items?: {
    id?: string;
    name: string;
    label?: string;
    type?: string;
    url: string;
    file_url?: string;
  }[];
}) => {
  const formMethods = useForm<WorkingGroupData>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),
    defaultValues: {
      supplementary_materials:
        supplementary_items && supplementary_items.length > 0
          ? supplementary_items.map((item) => ({
              item_id: `${item.id}`,
              name: `${item.name}`,
              label: `${item.label}`,
              file_type: `${item.type}`,
              url: `${item.url || item.file_url}`,
            }))
          : [],
    },
  });

  const { control, watch } = formMethods;

  const {
    fields: fieldsSupplementary,
    append: appendSupplementary,
    remove: removeSupplementary,
  } = useFieldArray({
    control,
    name: "supplementary_materials",
  });

  const formValues = watch();

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={() => {}}>
          <div>
            <div className="mb-8">
              <h4 className="text-base font-medium">Supplementary Materials</h4>

              <p className="mb-4 text-gray-700">
                Have something else you want to share (study protocols, images,
                figures, tables, etc.)? Upload it here!
              </p>

              <div className="w-full max-w-xl px-4 py-2 mt-2 mb-4 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                This information will be made public. <br /> Please ensure you
                have the appropriate permissions to upload these files.
              </div>

              <ul className="max-w-sm mb-8">
                {fieldsSupplementary.map((item, index) => (
                  <li
                    key={item.id}
                    className={classNames("my-2 py-2", {
                      "border-t border-gray-200": !!index,
                    })}
                  >
                    <SupplementaryItem
                      group_id={id!}
                      onSuccess={onSuccess}
                      id={item.id}
                      type={item.file_type}
                      hasFile={Boolean(
                        get(
                          formValues,
                          `supplementary_materials.${index}.url`,
                          ""
                        ).includes("s3.amazonaws.com")
                      )}
                      initialFileType={get(
                        formValues,
                        `supplementary_materials.${index}.file_type`
                      )}
                      item_id={get(
                        formValues,
                        `supplementary_materials.${index}.item_id`
                      )}
                      index={index}
                      removeSupplementary={removeSupplementary}
                    />
                  </li>
                ))}
              </ul>

              <div className="flex justify-center">
                <Button
                  size="exSmall"
                  type="button"
                  style={{ width: "348px" }}
                  onClick={() =>
                    appendSupplementary({
                      item_id: "",
                      name: "",
                      url: "",
                      label: "",
                    })
                  }
                >
                  Add item
                </Button>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
