import axios, { AxiosError } from "axios";
import { responseErrorCodes } from "constants/response-error-codes";
// import { BadRequest } from "errors/BadRequest";
import { Unauthenticated } from "errors/Unauthenticated";
import { Unconfirmed } from "errors/Unconfirmed";
import React from "react";
import { createApiClient } from "services/api-client";
import { Actions, Context } from "types";

axios.defaults.withCredentials = true;

export const STORAGE_TOKEN_KEY = "token";

export const defaultState: Context = {
  apiClient: createApiClient(axios.request, (error) => {
    const axiosError = error as AxiosError;

    switch (axiosError?.response?.status) {
      case 401: {
        if (axiosError.response.data?.code === responseErrorCodes.UNCONFIRMED) {
          localStorage.removeItem(STORAGE_TOKEN_KEY);
          window.location.replace("/login");
          throw new Unconfirmed();
        }

        throw new Unauthenticated();
      }
      case 404: {
        return axiosError?.response;
      }
      default: {
        throw error;
      }
    }
  }),
  authToken: localStorage.getItem(STORAGE_TOKEN_KEY),
  dispatch: (action: Actions) => {},
};

export const AppContext = React.createContext(defaultState);

export const reducer = (
  state: typeof defaultState,
  action: Actions
): typeof defaultState => {
  switch (action.type) {
    case "SHOW_NOTIFICATION": {
      const { type, ...notification } = action;

      return {
        ...state,
        notification: {
          ...notification,
          variant: notification.variant || "SUCCESS",
        },
      };
    }
    case "HIDE_NOTIFICATION": {
      return { ...state, notification: null };
    }
    case "SET_AUTH_TOKEN": {
      if (action.token) {
        localStorage.setItem(STORAGE_TOKEN_KEY, action.token);
      } else {
        localStorage.removeItem(STORAGE_TOKEN_KEY);
      }

      return {
        ...state,
        authToken: action.token,
      };
    }
  }
};
