import React, { useState } from "react";
import Lightbox, { ImagesListType } from "react-spring-lightbox";
import { SearchIcon } from "@heroicons/react/outline";

// const images: ImagesListType = [
//   {
//     src: 'https://timellenberger.com/static/blog-content/dark-mode/win10-dark-mode.jpg',
//     loading: 'lazy',
//     alt: 'Windows 10 Dark Mode Setting',
//   },
//   {
//     src: 'https://timellenberger.com/static/blog-content/dark-mode/macos-dark-mode.png',
//     loading: 'lazy',
//     alt: 'macOS Mojave Dark Mode Setting',
//   },
//   {
//     src: 'https://timellenberger.com/static/blog-content/dark-mode/android-9-dark-mode.jpg',
//     loading: 'lazy',
//     alt: 'Android 9.0 Dark Mode Setting',
//   },
// ];

export const LightboxComponent = ({ images }: { images: ImagesListType }) => {
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  return (
    <>
      <div>
        {images.map(({ src, alt }, i) => (
          <button
            key={i}
            className="mr-1 mb-1 relative cursor-pointer"
            onClick={() => {
              setCurrentIndex(i);
              setIsOpen(true);
            }}
          >
            <span className="absolute w-full h-full bg-black bg-opacity-40 opacity-0 hover:opacity-100 flex items-center justify-center">
              <SearchIcon className="w-6 text-white" />
            </span>
            <img className="h-24" src={src} />
          </button>
        ))}
      </div>
      <Lightbox
        isOpen={isOpen}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={currentImageIndex}
        onClose={() => {
          setIsOpen(false);
        }}
        renderImageOverlay={() => <div className='bg-black opacity-25' />}
      />
    </>
  );
};
