import React, {useContext} from "react";
import { Button } from "components/button/Button";
import { routes } from "../../routes";
import { NavLink } from "react-router-dom";
import { WorkingGroupsBrowseContainer } from "./working-group-browse/WorkingGroupsBrowseContainer";
import {AppContext} from "../../context";
import {withParams} from "../../utils/url";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

export const WorkingGroupsDescription = () => {
  const { authToken } = useContext(AppContext);

  return (
    <div className='font-nunito'>
      <Helmet>
        <title>Let’s Get Proof | Working Groups</title>
      </Helmet>

      {/*<div
        className="flex flex-col items-center justify-center"
        style={{ minHeight: "300px" }}
      >
        <h2 className="text-3xl font-bold mb-8">Working groups</h2>

        <p className="text-gray-700">
          Is there a research question you've been struggling with? A clinical
          challenge or scenario you'd like input on? A general desire to learn
          more about stroke? You're likely not alone! The aim of the Working
          Group is to generate more inclusive discussion, facilitate deeper
          understanding of a research topic, and to catalyze innovative ideas.
          Together, you can help each other turn your questions into testable
          hypotheses - to accelerate stroke research and keep us moving forward.
          <br />
          Coming Soon!
        </p>
      </div>*/}

      <div className="text-center max-w-3xl mx-auto">
        <h2 className="text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl font-semibold mb-8">
          Here at Let’s Get Proof, we are all about collaboration
        </h2>

        <div className="text-gray-700">
          <p className="pb-2">
            Is there a research question you've been struggling with? A clinical
            challenge or scenario you'd like input on? A general desire to learn
            more about medicine? You're likely not alone!
          </p>
          <p className="pb-2">
            Working group are here to generate more inclusive discussion and to
            help you turn your questions into testable hypotheses - to
            accelerate medical research and keep us moving forward.
          </p>
          {/*<p className="pb-4">How it works:</p>*/}
        </div>
      </div>

      <div className="flex justify-center">
        {/*<div className="max-w-lg w-full bg-gray-200 h-64"></div>*/}
      </div>

      <div className="flex justify-center items-center pt-2 pb-12">
        {/*<NavLink to={routes.workingGroupsList} className="mr-2">
          <Button>Browse working groups</Button>
        </NavLink>*/}

        <NavLink to={routes.wgLearnMore} className="mr-2">
          <Button type="button" variant="white">Learn More</Button>
        </NavLink>

        <NavLink to={authToken ? routes.workingGroupCreate : withParams(routes.login, { redirect_to: routes.workingGroupCreate })}>
          <Button className="ml-2" onClick={() => {
            // ReactGA.event({
            //   category: 'Conversion',
            //   value: 20,
            //   action: 'Click on Create a working group'
            // });
          }}>Start a working group</Button>
        </NavLink>
      </div>

      <WorkingGroupsBrowseContainer />
    </div>
  );
};
