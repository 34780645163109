import classNames from "classnames";
import { PropsWithoutRef } from "react";

export type BadgeVariant =
  | "success"
  | "warning"
  | "danger"
  | "secondary"
  | "primary";

const variantClassNames: Record<BadgeVariant, string> = {
  success: "bg-green-100 text-green-800",
  warning: "bg-yellow-100 text-yellow-800",
  danger: "bg-red-100 text-red-800",
  secondary: "bg-gray-200 text-gray-800",
  primary: "bg-violet-100 text-violet-800",
};

export interface BadgeProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements["span"]>, "className"> {
  variant?: BadgeVariant;
}

export const Badge = ({
  children,
  variant = "success",
  ...props
}: BadgeProps) => (
  <span
    {...props}
    className={classNames(
      "inline-flex items-center shadow-sm px-3 py-0.5 rounded-full text-sm font-medium",
      variantClassNames[variant]
    )}
  >
    {children}
  </span>
);
