import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";

import { SelectField } from "components/select-field/SelectField";
import { Button } from "components/button/Button";

import { changeMemberRoleWorkingGroup } from "api/workingGroups";
import { setFormError } from "utils/setError";
import { GET_WORKING_GROUP_MEMBERS } from "./WorkingGroupSingle";
import { pipe } from "fp-ts/function";
import { useContextService } from "../../../hooks/use-context-service";

export const VALIDATE_PROJECT_PERMISSION = "VALIDATE_PROJECT_PERMISSION";

interface EditMemberFormSchema {
  role: string;
}

const invalidType = { invalid_type_error: "This field is required" };

const useFormSchema = () =>
  z.object({
    role: pipe(z.string(invalidType)),
  });

export const EditMemberForm = ({
  role,
  slug,
  member_id,
  onSuccess,
}: {
  role: string;
  slug: string;
  member_id: string;
  onSuccess: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
  } = useForm<EditMemberFormSchema>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),

    // @ts-ignore
    defaultValues: {
      role,
    },
  });

  const queryClient = useQueryClient();

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const changeMemberRoleWorkingGroupService = useContextService(
    changeMemberRoleWorkingGroup
  );

  const changeMemberRoleWorkingGroupMutation = useMutation(
    "changeMemberRoleWorkingGroupMutations",
    ({ role }: { role: string }) =>
      changeMemberRoleWorkingGroupService(slug, member_id, role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(GET_WORKING_GROUP_MEMBERS);
        onSuccess();
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const onSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    changeMemberRoleWorkingGroupMutation.mutate({
      ...data,
    });
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <h2 className="text-lg font-medium text-black mb-2">
            Change member role
          </h2>

          <div className="w-full mb-5">
            <SelectField
              label="Role"
              placeholder="Select"
              options={[
                {
                  value: "member",
                  label: "Member",
                },
                {
                  value: "moderator",
                  label: "Moderator",
                },
              ]}
              error={formState.errors?.role}
              {...register("role", { required: true })}
            />
          </div>

          {formErrorMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="submit"
            disabled={changeMemberRoleWorkingGroupMutation.isLoading}
            className="w-full"
            loading={changeMemberRoleWorkingGroupMutation.isLoading}
            spinner
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
