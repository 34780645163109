import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { FolderIcon, SearchIcon } from "@heroicons/react/outline";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { Button } from "components/button/Button";

import { FeedContext } from "./FeedContext";
import { FeedList } from "./FeedList";
import { Helmet } from "react-helmet";
import { SelectField } from "../../components/select-field/SelectField";
import { TextField } from "../../components/text-field/TextField";

const objectTypes: string[] = [
  "PROJECT",
  "COMMENT",
  "DISCUSSION",
  "WORKING_GROUP",
  "BLOG",
  "PROJECT_POST",
  "WORKING_GROUP_POST",
];

const eventTypes: string[] = [
  "BLOG_CREATED",
  "PROJECT_CREATED",
  "WORKING_GROUP_CREATED",
  "BLOG_COMMENT_CREATED",
  "PROJECT_COMMENT_CREATED",
  "WORKING_GROUP_COMMENT_CREATED",
  "BLOG_DISCUSSION_CREATED",
  "PROJECT_DISCUSSION_CREATED",
  "WORKING_GROUP_DISCUSSION_CREATED",
  "PROJECT_UPDATE_CREATED",
  "WORKING_GROUP_UPDATE_CREATED",
  "PROJECT_FUNDING_COMPLETED",
  "PROJECT_REACHED_MILESTONE"
];

export const Feed = () => {
  const {
    handleFetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    listData,
  } = useContext(FeedContext);

  const { register, control } = useFormContext();

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Feed</title>
      </Helmet>

      <h2 className="font-nunito font-bold text-2xl lg:text-3xl text-blue-550 mb-8">
        Feed
      </h2>

      <div className="flex flex-wrap items-center mb-8 bg-white py-3 px-4 rounded border border-gray-200">
        <div className="flex items-center w-full mb-3 lg:mb-0 lg:w-48 lg:mr-8">
          <div className="flex-1 w-full">
            <SelectField
              label="Event"
              options={[
                { value: "", label: "" },
                ...eventTypes.map((item) => ({
                  value: item,
                  label: item.replace(/_/g, " "),
                })),
              ]}
              {...register("event_type")}
            />
          </div>
        </div>
        <div className="flex items-center w-full mb-3 lg:mb-0 lg:w-48">
          <div className="flex-1 w-full">
            <SelectField
              label="Type"
              options={[
                { value: "", label: "" },
                ...objectTypes.map((item) => ({
                  value: item,
                  label: item.replace(/_/g, " "),
                })),
              ]}
              {...register("object_type")}
            />
          </div>
        </div>
        <div className="w-full lg:w-64 ml-auto relative">
          <TextField
            label="&nbsp;"
            loading={isLoading}
            placeholder="Search"
            {...register("searchQuery")}
          />

          {!isLoading && (
            <SearchIcon className="text-gray-500 absolute bottom-2 right-2 w-5" />
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          {listData.length > 0 ? (
            <div>
              <FeedList listData={listData} />

              {hasNextPage && (
                <div className="flex justify-center mt-8">
                  <Button
                    loading={isFetchingNextPage}
                    spinner
                    disabled={!hasNextPage}
                    onClick={handleFetchNextPage}
                  >
                    Show me more
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <div>
              <div className="text-center">
                <FolderIcon className="w-10 mx-auto text-gray-500" />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No data
                </h3>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
