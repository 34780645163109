import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop, { Crop, centerCrop, makeAspectCrop, convertToPixelCrop } from "react-image-crop";
import { Button } from "components/button/Button";

import "react-image-crop/dist/ReactCrop.css";

const generateCroppedImage = (canvas: any, crop: any) => {
  if (!crop || !canvas) {
    return;
  }
  return canvas.toDataURL();
};

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export const ImageCropper = ({
  value,
  aspect,
  onCrop,
  onClose,
}: {
  value: string;
  aspect: number;
  onCrop: (v: string) => void;
  onClose: () => void;
}) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState<Crop>({
    height: 0,
    width: 0,
    x: 0,
    y: 0,
    unit: "%",
  });
  const [completedCrop, setCompletedCrop] = useState<Crop>();

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image: HTMLImageElement = imgRef.current;
    const canvas: HTMLCanvasElement = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio =
      image.naturalWidth > 1200 ? 1 / (image.naturalWidth / 1200) : 1;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx!.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx!.imageSmoothingQuality = "high";

    ctx!.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
  }, [completedCrop]);

  const onLoad = useCallback(
    (img) => {
      if (aspect) {
        const { width, height } = img.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
        setCompletedCrop(convertToPixelCrop(centerAspectCrop(width, height, aspect), width, height));
      }
    },
    [aspect]
  );

  return (
    <>
      <div className="flex justify-between pb-4">
        <Button
          type="button"
          variant="secondary"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            onCrop(
              generateCroppedImage(previewCanvasRef.current, completedCrop)
            );
          }}
        >
          Crop
        </Button>
      </div>
      <div className="flex justify-center">
        <ReactCrop
          crop={crop}
          onChange={(c) => {
            setCrop(c);
          }}
          onComplete={(c) => {
            setCompletedCrop(c);
          }}
          aspect={aspect}
        >
          <img ref={imgRef} alt="Crop me" src={value} onLoad={onLoad} />
        </ReactCrop>
      </div>
      <div className="hidden">
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
    </>
  );
};
