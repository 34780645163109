import React, { useCallback, useEffect, useState } from "react";
import get from "lodash/get";
import classNames from "classnames";
import {
  BriefcaseIcon,
  TrendingUpIcon,
  CurrencyDollarIcon,
  UserGroupIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import queryString from "query-string";
import { useQuery } from "react-query";

import { useUserQuery } from "queries/use-user-query";
import {
  getDashboardCounters,
  getBackedProjects,
  getFavoriteProjects,
  getBanner,
} from "api/dashboard";
import { useContextService } from "hooks/use-context-service";
import { Projects } from "domains/my-projects/Projects";
import { WorkingGroupsContainer } from "domains/working-groups/working-group-my-list/WorkingGroupsContainer";
import { WorkingGroupMyJoined } from "domains/working-groups/working-group-my-joined/WorkingGroupMyJoined";
import { EditPersonalization } from "domains/my-profile/edit-personalization/EditPersonalization";
import { LectureSeriesSingle } from "domains/lecture-series/LectureSeriesSingle";
import { withParams, clearQueryParams } from "utils/url";
import { Modal } from "components/modal/Modal";
import { Button } from "components/button/Button";
import { routes } from "routes";
import { Gamification } from "../my-profile/gamification/Gamification";

const Tab = ({
  title,
  isActive,
  children,
  setTab,
  tab,
}: {
  title: string;
  isActive: boolean;
  children: JSX.Element;
  setTab: (tab: number) => void;
  tab: number;
}) => {
  return (
    <article className="border-b">
      <div
        className={classNames([
          isActive
            ? "border-l-2 bg-grey-lightest border-blue-400"
            : "border-l-2 border-transparent",
        ])}
      >
        <header
          onClick={() => {
            setTab(isActive ? 0 : tab);
          }}
          className="flex justify-between items-center p-5 pl-8 pr-8 cursor-pointer select-none cursor-pointer"
        >
          <span className="text-indigo font-medium text-xl">{title}</span>
          <div
            className={classNames([
              isActive
                ? "rounded-full border border-indigo w-7 h-7 flex items-center justify-center bg-blue-400 text-white"
                : "rounded-full border border-grey w-7 h-7 flex items-center justify-center text-gray-700",
            ])}
          >
            {isActive ? (
              <ChevronUpIcon className="w-5" />
            ) : (
              <ChevronDownIcon className="w-5" />
            )}
          </div>
        </header>
        {isActive && <div className="px-8 pb-8">{children}</div>}
      </div>
    </article>
  );
};

export const DashboardContent = () => {
  const { data: userResponse, isFetched } = useUserQuery();
  const [tab, setTab] = useState(0);

  const getDashboardCountersService = useContextService(getDashboardCounters);
  const getBannerService = useContextService(getBanner);
  const getBackedProjectsService = useContextService(getBackedProjects);
  const getFavoriteProjectsService = useContextService(getFavoriteProjects);

  const {
    data: dashboardCountersData,
    isFetched: dashboardCountersIsFetched,
  } = useQuery("DASHBOARD_COUNTERS_GET", getDashboardCountersService, {
    suspense: false,
  });

  const {
    data: bannerData,
    isFetched: bannerIsFetched,
  } = useQuery("GET_BANNER", getBannerService, {
    suspense: false,
  });

  const {
    data: backedProjectsData,
    isFetched: backedProjectsIsFetched,
  } = useQuery("BACKED_PROJECT_GET", getBackedProjectsService, {
    suspense: false,
  });

  const {
    data: favoriteProjectsData,
    isFetched: favoriteProjectsIsFetched,
  } = useQuery("FAVORITE_PROJECT_GET", getFavoriteProjectsService, {
    suspense: false,
  });

  const params = queryString.parse(window.location.search, {
    arrayFormat: "comma",
  });

  const [isPersonalizationModalOpen, setIsPersonalizationModalOpen] = useState(
    !!params.openPersonalization
  );

  useEffect(() => {
    if (params.openPersonalization) {
      setIsPersonalizationModalOpen(true);
    }
  }, [params.openPersonalization]);

  const closePersonalizationModal = useCallback(() => {
    clearQueryParams();
    setIsPersonalizationModalOpen(false);
  }, []);

  return (
    <div>
      <Modal visible={isPersonalizationModalOpen} onClose={() => {}}>
        <EditPersonalization
          onSubmit={() => {
            closePersonalizationModal();
          }}
        />

        <Button
          type="button"
          variant="secondary"
          className="w-full mt-2"
          onClick={closePersonalizationModal}
        >
          Skip
        </Button>
      </Modal>

      {isFetched && (
        <div>
          <h2 className="font-nunito font-bold text-xl lg:text-3xl mb-4">
            Welcome, {userResponse?.data?.name}
          </h2>

          {bannerData?.data?.banner && (
            <div className="mb-4">
              <NavLink to={bannerData?.data?.banner_link || "/"}>
                <img src={bannerData?.data?.banner} className="w-full" />
              </NavLink>
            </div>
          )}

          <div className="lg:w-1/3 mb-12 mx-auto">
            <Gamification />
          </div>

          <div className="flex flex-wrap flex-col md:flex-row justify-center">
            <div className="">
              <div
                className="flex justify-center items-center rounded-full bg-gray-300 w-20 h-20 text-white font-medium bg-center bg-cover"
                style={{
                  backgroundImage: `url(${userResponse?.data?.avatar})`,
                }}
              >
                {!userResponse?.data?.avatar
                  ? get(userResponse?.data, "name[0]")
                  : ""}
              </div>

              <div className="text-sm text-gray-600 w-24 pt-4">
                Member since{" "}
                {format(new Date(userResponse?.data?.date_joined!), "MMM YYY")}
              </div>
            </div>

            {dashboardCountersIsFetched && (
              <div className="flex-1 md:ml-12">
                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/*<div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <CurrencyDollarIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total amount raised
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        US$&nbsp;
                        {dashboardCountersData?.data?.total_amount_raised
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </p>
                    </dd>
                  </div>*/}
                  <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <TrendingUpIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total contributors
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        {dashboardCountersData?.data?.total_contributors}
                      </p>
                    </dd>
                  </div>
                  <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <UserGroupIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Working groups
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        {dashboardCountersData?.data?.total_working_groups}
                      </p>
                    </dd>
                  </div>
                  {/*<div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <BriefcaseIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total projects
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        {dashboardCountersData?.data?.total_projects}
                      </p>
                    </dd>
                  </div>*/}
                  {/*<div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <BriefcaseIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total successful projects
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        {dashboardCountersData?.data?.total_successful_projects}
                      </p>
                    </dd>
                  </div>*/}
                </dl>

                {/*<dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <BriefcaseIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total supported projects
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        {dashboardCountersData?.data?.total_supported_projects}
                      </p>
                    </dd>
                  </div>
                  <div className="relative bg-white pt-5 px-4 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
                    <dt>
                      <div className="absolute bg-indigo-500 rounded-md p-3">
                        <CurrencyDollarIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                        Total amount contributed
                      </p>
                    </dt>
                    <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
                      <p className="text-2xl font-semibold text-gray-900">
                        US$&nbsp;
                        {dashboardCountersData?.data?.total_amount_contributed
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                      </p>
                    </dd>
                  </div>
                </dl>*/}
              </div>
            )}
          </div>

          <section className="shadow bg-white mt-8">
            {/*<Tab
              title={`My projects (${dashboardCountersData?.data?.total_projects})`}
              isActive={tab === 1}
              tab={1}
              setTab={setTab}
            >
              <Projects />
            </Tab>*/}
            {/*<Tab
              title={`Supported projects (${dashboardCountersData?.data?.total_supported_projects})`}
              isActive={tab === 2}
              tab={2}
              setTab={setTab}
            >
              <div className="text-gray-700">
                {backedProjectsIsFetched && (
                  <div>
                    {backedProjectsData?.data?.data &&
                    backedProjectsData?.data?.data.length > 0 ? (
                      backedProjectsData?.data?.data?.map(({ title, slug }) => (
                        <NavLink
                          to={withParams(routes.project, { id: slug })}
                          key={slug}
                          className="block text-lg font-medium text-indigo-600 hover:text-indigo-800 my-2"
                        >
                          {title}
                        </NavLink>
                      ))
                    ) : (
                      <div className="text-center text-gray-700">
                        No backed projects
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Tab>*/}
            <Tab
              title={`Working groups (${dashboardCountersData?.data?.total_working_groups})`}
              isActive={tab === 3}
              tab={3}
              setTab={setTab}
            >
              <WorkingGroupsContainer />
            </Tab>
            <Tab
              title={`Working groups joined (${dashboardCountersData?.data?.total_working_groups_joined})`}
              isActive={tab === 4}
              tab={4}
              setTab={setTab}
            >
              <WorkingGroupMyJoined />
            </Tab>
            {/*<Tab
              title={`Favorite projects (${dashboardCountersData?.data?.total_favorite_projects})`}
              isActive={tab === 5}
              tab={5}
              setTab={setTab}
            >
              <div className="text-gray-700">
                {favoriteProjectsIsFetched && (
                  <div>
                    {favoriteProjectsData?.data?.data &&
                    favoriteProjectsData?.data?.data.length > 0 ? (
                      favoriteProjectsData?.data?.data?.map(
                        ({ title, slug }) => (
                          <NavLink
                            to={withParams(routes.project, { id: slug })}
                            key={slug}
                            className="block text-lg font-medium text-indigo-600 hover:text-indigo-800 my-2"
                          >
                            {title}
                          </NavLink>
                        )
                      )
                    ) : (
                      <div className="text-center text-gray-700">
                        No favorite projects
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Tab>*/}
          </section>

          <div className="mt-16 max-w-screen-md">
            <h2 className="text-3xl font-bold mb-8">
              While you're here, check out our newest content:
            </h2>
            <LectureSeriesSingle isLatest />
          </div>
        </div>
      )}
    </div>
  );
};
