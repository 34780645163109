import React, { Suspense, useEffect, useReducer } from "react";
import ReactGA from "react-ga4";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { ErrorBoundary } from "components/ErrorBoundary";
import { Layout } from "components/Layout";
import { LayoutProject } from "components/LayoutProject";
import { PrivateRoute } from "components/PrivateRoute";
import { PublicRoute } from "components/PublicRoute";
import { Notification } from "components/Notification";
import { AppContext, defaultState, reducer } from "context";
import { ConfirmUser } from "domains/confirm-user/ConfirmUser";
import { ConfirmAffiliationEmail } from "domains/confirm-affiliation-email/ConfirmAffiliationEmail";
import { Dashboard } from "domains/dashboard/Dashboard";
import { ForgotPassword } from "domains/forgot-password/ForgotPassword";
import { ForgotPasswordConfirm } from "domains/forgot-password/ForgotPasswordConfirm";
import { SetPasswordConfirm } from "domains/forgot-password/SetPasswordConfirm";
import { AisHomepage } from "domains/aspectsinstroke/ais-homepage/AisHomepage";
import { Page } from "domains/pages/Page";
import { PageLanding } from "domains/pages/PageLanding";
import { Login } from "domains/login";
import { MyProfile } from "domains/my-profile/MyProfile";
import { NotFound } from "domains/not-found/NotFound";
import { Register } from "domains/register";
import { RegisterLite } from "domains/register/components/RegisterLite";
import { SubmitProject } from "domains/submit-project/SubmitProject";
import { WGLearnMore } from "domains/wg-learn-more/WGLearnMore";
import { SubmitProject as SubmitProjectSingle } from "domains/submit-project-single/SubmitProject";
import { Projects as AllProjects } from "domains/projects/Projects";
import { MyProjectsContainer } from "domains/my-projects/MyProjectsContainer";
import { Project } from "domains/project/Project";
import { WorkingGroups } from "domains/working-groups/WorkingGroups";
import { ContactUs } from "domains/contact-us/ContactUs";
import { ResearcherProfile } from "domains/researcher-profile/ResearcherProfile";
import { About } from "domains/about/About";
import { OurModel } from "domains/our-model/OurModel";
import { PressAndMedia } from "domains/press-and-media/PressAndMedia";
import { GetInvolved } from "domains/get-involved/GetInvolved";
import { MeetTheTeam } from "domains/meet-the-team/MeetTheTeam";
import { EditPersonalization } from "domains/my-profile/edit-personalization/EditPersonalization";
import { Reroute } from "domains/reroute/Reroute";
// import { OurRules } from "domains/our-rules/OurRules";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useQueryErrorResetBoundary } from "react-query";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { routes } from "routes";
import { Landing } from "components/landing/Landing";
import { LandingGrant } from "components/landing/LandingGrant";
import { Consent } from "domains/consent/Consent";
import { CookiePolicy } from "domains/consent/CookiePolicy";
import { WgFaq } from "domains/wg-faq/WgFaq";
import { PrivacyPolicy } from "domains/consent/PrivacyPolicy";
import { FAQ } from "domains/consent/FAQ";
import { TermsOfUse } from "domains/consent/TermsOfUse";
import { ResearcherGuide } from "domains/researcher-guide/ResearcherGuide";
import { LectureSeries } from "domains/lecture-series/LectureSeries";
import { Donations } from "domains/my-donations/Donations";
import { LectureSeriesSingle } from "domains/lecture-series/LectureSeriesSingle";
import { ScientificReviewCommittee } from "domains/scientific-review-committee/ScientificReviewCommittee ";
import { BlogBrowseContainer } from "domains/blog/list/BlogBrowseContainer";
import { BlogSingle } from "domains/blog/single/BlogSingle";
import { QuizBrowseContainer } from "domains/quiz/list/QuizBrowseContainer";
import { QuizSingle } from "domains/quiz/single/QuizSingle";
import { FeedContainer } from "domains/feed/FeedContainer";
import { ProjectUpdatesList } from "domains/project-updates/project-updates-list/ProjectUpdatesList";
import { ProjectUpdatesContainer } from "domains/project-updates/project-updates-form/ProjectUpdatesContainer";
import { GroupUpdatesList } from "domains/group-updates/group-updates-list/GroupUpdatesList";
import { GroupUpdatesContainer } from "domains/group-updates/group-updates-form/GroupUpdatesContainer";
import ScrollToTop from "utils/scrollToTop";
import { ReactComponent as IconLoading } from "./assets/icons/loading.svg";

export const Container = () => {
  const { reset } = useQueryErrorResetBoundary();

  const [state, dispatch] = useReducer(reducer, defaultState);

  // useEffect(() => {
  //   ReactGA.initialize(process.env.REACT_APP_GA!);
  // }, []);

  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      <ReactErrorBoundary
        onReset={reset}
        fallbackRender={(props) => <ErrorBoundary {...props} />}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <Router>
            <ScrollToTop />
            <Switch>
              <PublicRoute path={routes.login} exact>
                <Layout>
                  <Login />
                </Layout>
              </PublicRoute>
              <PublicRoute path={routes.forgotPassword} exact>
                <Layout>
                  <ForgotPassword />
                </Layout>
              </PublicRoute>
              <PublicRoute path={routes.forgotPasswordConfirm} exact>
                <Layout>
                  <ForgotPasswordConfirm />
                </Layout>
              </PublicRoute>
              <PublicRoute path={routes.setNewPasswordConfirm} exact>
                <Layout>
                  <SetPasswordConfirm />
                </Layout>
              </PublicRoute>
              <PublicRoute path={routes.register} exact>
                <Layout>
                  <Register />
                </Layout>
              </PublicRoute>
              <PublicRoute path={routes.registerLite} exact>
                <Layout>
                  <RegisterLite />
                </Layout>
              </PublicRoute>
              <Route path={routes.confirmUser} exact>
                <ConfirmUser />
              </Route>
              <Route path={routes.confirmAffiliation} exact>
                <ConfirmAffiliationEmail />
              </Route>
              <PrivateRoute path={routes.dashboard} exact>
                <Layout>
                  <Dashboard />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.myProfile}>
                <Layout>
                  <MyProfile />
                </Layout>
              </PrivateRoute>
              {/*<PrivateRoute path={routes.submitProject} exact>
                <Layout>
                  <Suspense
                    fallback={
                      <div className="flex justify-center mt-24">
                        <IconLoading />
                      </div>
                    }
                  >
                    <SubmitProject />
                  </Suspense>
                </Layout>
              </PrivateRoute>*/}
              {/*<PrivateRoute path={routes.submitProjectSingle} exact>
                <Layout>
                  <SubmitProjectSingle />
                </Layout>
              </PrivateRoute>*/}
              {/*<PrivateRoute path={routes.myProjects}>
                <Layout>
                  <MyProjectsContainer />
                </Layout>
              </PrivateRoute>*/}
              <Route path={routes.researcherProfile} exact>
                <Layout>
                  <ResearcherProfile />
                </Layout>
              </Route>
              <Route path={routes.wgLearnMore} exact>
                <Layout>
                  <WGLearnMore />
                </Layout>
              </Route>
              <PrivateRoute path="/personalization" exact>
                <Layout>
                  <EditPersonalization />
                </Layout>
              </PrivateRoute>
              {/*<Route path={routes.projects} key="projects" exact>
                <Layout>
                  <AllProjects />
                </Layout>
              </Route>
              <Route path={routes.fundedProjects} key="fundedProjects" exact>
                <Layout>
                  <AllProjects />
                </Layout>
              </Route>
              <Route path={routes.project} exact>
                <LayoutProject>
                  <Project />
                </LayoutProject>
              </Route>*/}
              <Route path={routes.workingGroups}>
                <LayoutProject>
                  <WorkingGroups />
                </LayoutProject>
              </Route>
              <Route path={routes.blogList} exact>
                <Layout>
                  <BlogBrowseContainer />
                </Layout>
              </Route>
              <Route path={routes.blogSingle} exact>
                <Layout>
                  <BlogSingle />
                </Layout>
              </Route>
              <PrivateRoute path={routes.quiz} exact>
                <Layout>
                  <QuizBrowseContainer />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.quizSingle} exact>
                <Layout>
                  <QuizSingle />
                </Layout>
              </PrivateRoute>
              <Route path={routes.contactUs} exact>
                <Layout>
                  <ContactUs />
                </Layout>
              </Route>
              <Route path={routes.about} exact>
                <Layout>
                  <About />
                </Layout>
              </Route>
              <Route path={routes.ourModel} exact>
                <Layout>
                  <OurModel />
                </Layout>
              </Route>
              <Route path={routes.pressAndMedia} exact>
                <Layout>
                  <PressAndMedia />
                </Layout>
              </Route>
              <Route path={routes.getInvolved} exact>
                <Layout>
                  <GetInvolved />
                </Layout>
              </Route>
              <Route path={routes.meetTheTeam} exact>
                <Layout>
                  <MeetTheTeam />
                </Layout>
              </Route>
              {/*<Route path={routes.ourRules} exact>
              <Layout>
                <OurRules />
              </Layout>
            </Route>*/}
              <PrivateRoute path={routes.lectureSeries} exact>
                <Layout>
                  <LectureSeries />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.lectureSeriesSingle} exact>
                <Layout>
                  <LectureSeriesSingle />
                </Layout>
              </PrivateRoute>
              {/*<PrivateRoute path={routes.myDonations} exact>
                <Layout>
                  <Donations />
                </Layout>
              </PrivateRoute>*/}
              <PrivateRoute path={routes.projectUpdatesList} exact>
                <Layout>
                  <ProjectUpdatesList />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.projectUpdatesEdit} exact>
                <Layout>
                  <ProjectUpdatesContainer />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.projectUpdatesAdd} exact>
                <Layout>
                  <ProjectUpdatesContainer />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.groupUpdatesList} exact>
                <Layout>
                  <GroupUpdatesList />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.groupUpdatesEdit} exact>
                <Layout>
                  <GroupUpdatesContainer />
                </Layout>
              </PrivateRoute>
              <PrivateRoute path={routes.groupUpdatesAdd} exact>
                <Layout>
                  <GroupUpdatesContainer />
                </Layout>
              </PrivateRoute>
              <Route path="/reroute">
                <Layout>
                  <Reroute />
                </Layout>
              </Route>
              <Route path="/cookie-policy">
                <Layout>
                  <CookiePolicy />
                </Layout>
              </Route>
              <Route path="/privacy-policy">
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              </Route>
              <Route path="/researcher-guide">
                <Layout>
                  <ResearcherGuide />
                </Layout>
              </Route>
              <Route path="/terms-of-use">
                <Layout>
                  <TermsOfUse />
                </Layout>
              </Route>
              <Route path="/scientific-review-committee">
                <Layout>
                  <ScientificReviewCommittee />
                </Layout>
              </Route>
              <Route path="/pages/aspect-in-stroke/:path">
                <Layout>
                  <AisHomepage />
                </Layout>
              </Route>
              <Route path="/lgp/:path">
                <Layout>
                  <Page />
                </Layout>
              </Route>
              <Route path="/faq">
                <Layout>
                  <FAQ />
                </Layout>
              </Route>
              <Route path="/working-group-faq">
                <Layout>
                  <WgFaq />
                </Layout>
              </Route>
              <Route path="/lpg-prize" exact>
                <Redirect
                  to='/lgp-prize'
                />
              </Route>
              <Route path="/lgp-prize" exact>
                <LandingGrant />
              </Route>
              <Route path="/feed" exact>
                <Layout>
                  <FeedContainer />
                </Layout>
              </Route>
              <Route path="/lgp-prize-page">
                <PageLanding />
              </Route>
              <Route path="/" exact>
                <Landing />
              </Route>
              <Route>
                <Layout>
                  <NotFound />
                </Layout>
              </Route>
            </Switch>
            <Consent />
          </Router>
        </GoogleOAuthProvider>
      </ReactErrorBoundary>

      <Notification />
    </AppContext.Provider>
  );
};
