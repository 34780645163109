import React from "react";
import { useQuery } from "react-query";

import { getWGUpdatesSingle } from "api/workingGroups";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useParams } from "react-router-dom";
import { useContextService } from "../../../hooks/use-context-service";
import { GroupUpdatesForm } from "./GroupUpdatesForm";

interface ParamTypes {
  group_id: string;
  id: string;
  slug: string;
}

export const GroupUpdatesContainer = () => {
  const { group_id, id, slug } = useParams<ParamTypes>();
  const getWGUpdatesSingleQuery = useContextService(
    getWGUpdatesSingle
  );

  const { data, isLoading, isError } = useQuery(
    `WG_UPDATES_SINGLE_${group_id}_${id}`,
    () => getWGUpdatesSingleQuery({ group_id, id }),
    {
      suspense: false,
      enabled: !!(group_id && id),
    }
  );

  return isLoading ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : isError ? (
    <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
      Error
    </div>
  ) : (
    <div className="flex justify-between flex-col-reverse sm:flex-row">
      <GroupUpdatesForm
        group_id={group_id}
        slug={slug}
        id={id}
        defaultValues={{
          title: data?.data?.data?.title,
          text: data?.data?.data?.text,
          video_url: data?.data?.data?.video_url,
          image: data?.data?.data?.image,
        }}
      />
    </div>
  );
};
