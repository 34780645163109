import React from "react";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";

import { Modal } from "components/modal/Modal";
import { Button } from "components/button/Button";
import { useModal } from "../../hooks/use-modal";

import { ReactComponent as IconMS } from "assets/icons/ms.svg";
import { ReactComponent as IconGA } from "assets/icons/ga.svg";

export const MfaInfo = () => {
  const { modalVisible, openModal, closeModal } = useModal();

  return (
    <div>
      <button
        onClick={openModal}
        type="button"
        className="flex items-center justify-center mx-auto hover:text-violet-500 focus:outline-none text-gray-500 text-sm"
      >
        How to setup MFA?
        <QuestionMarkCircleIcon className="ml-2 w-4" />
      </button>

      <Modal visible={modalVisible} onClose={closeModal}>
        <div className="p-6">
          <div className="mb-2 text-gray-900 uppercase text-center text-sm tracking-wide">
            How to setup MFA?
          </div>

          <div className="text-gray-900 text-sm">
            <div className="flex border-b border-gray-200 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                1
              </div>
              <div className="pt-1">
                Install an Authenticator App of your choice. Here are the ones
                we recommend:
                <div className="flex mt-4">
                  <IconGA />

                  <div className="ml-3">
                    <div className="text-gray-900 leading-4 pb-1">
                      Google Authenticator
                    </div>
                    <div className="text-xs text-gray-500">
                      for{" "}
                      <a
                        href="https://apps.apple.com/us/app/google-authenticator/id388497605"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        iPhone
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        Android
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex mt-4">
                  <IconMS />

                  <div className="ml-3">
                    <div className="text-gray-900 leading-4 pb-1">
                      Microsoft Authenticator
                    </div>
                    <div className="text-xs text-gray-500">
                      for{" "}
                      <a
                        href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        iPhone
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.azure.authenticator"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        Android
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex border-b border-gray-200 py-6">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                2
              </div>
              <div className="pt-1">
                Scan the QR Code or enter the Shared Secret Key inside the
                Authenticator App
              </div>
            </div>

            <div className="flex py-6 items-center">
              <div
                className="flex items-center justify-center rounded-full border border-gray-200 text-gray-900 text-xs mr-3 bg-gray-100"
                style={{ flex: "0 0 48px", height: "48px" }}
              >
                3
              </div>
              <div>Copy the code from the App</div>
            </div>
          </div>

          <div className="mt-8">
            <Button onClick={closeModal} type="button" className="w-full">
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
