// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

export const uploadImage = async (
  data_image: string | null,
  uploadImageQuery: any
) => {
  if (data_image && !data_image.includes("s3.amazonaws.com")) {
    const formData = new FormData();
    formData.append("file", DataURIToBlob(data_image), "project");

    const res = await uploadImageQuery.mutateAsync(formData);
    return res.data.url;
  }

  return;
};
