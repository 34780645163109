import { Context } from "../types";
import { withParams } from "../utils/url";

export type NotificationType = {
  active_status: string;
  aggregation_rules: string[];
  default_aggregation_rules: string[];
  delivery_methods: string[];
  enabled_aggregation_rules: string[];
  enabled_delivery_methods: string[];
  is_active: boolean;
  name: string;
  type: string;
  description: string;
};

export const getNotifications = ({ apiClient }: Context) => () =>
  apiClient<NotificationType[]>({
    url: withParams("/me/notification-settings"),
    method: "GET",
  });

export const putNotifications = ({ apiClient }: Context) => (
  data: NotificationType[]
) =>
  apiClient({
    url: withParams("/me/notification-settings-update"),
    method: "PUT",
    data,
  });
