import { Actions, Context } from "types";

export const showNotification = ({ dispatch }: Context) => (
  options: Omit<Extract<Actions, { type: "SHOW_NOTIFICATION" }>, "type">
) => {
  dispatch({
    type: "HIDE_NOTIFICATION",
  });

  setTimeout(
    () =>
      dispatch({
        type: "SHOW_NOTIFICATION",
        ...options,
      }),
    300
  );
};
