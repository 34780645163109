import React, { useEffect } from "react";
import { useQuery } from "react-query";
import get from "lodash/get";
import map from "lodash/map";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import { useContextService } from "../../hooks/use-context-service";
import { getStaticPage } from "../../api/static-pages";
import { ReactComponent as IconLoading } from "../../assets/icons/loading.svg";

const WG_LEARN_MORE = "WG_LEARN_MORE";

export const WGLearnMore = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [WG_LEARN_MORE],
    () => getStaticPageService({ slug: `learn-more-working-group` }),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Let’s Get Proof | {get(data, "data.title", "")}</title>
      </Helmet>

      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div className="pb-12 mx-auto">
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.title}</title>
          </Helmet>

          {data?.data?.before_title && (
            <h3
              className={classNames("my-2 text-xl font-bold", {
                "text-center": data?.data?.title_align === "CENTER",
                "text-right": data?.data?.title_align === "RIGHT",
              })}
            >
              {data?.data?.before_title}
            </h3>
          )}
          <h2
            className={classNames("my-2 text-3xl font-bold", {
              "text-center": data?.data?.title_align === "CENTER",
              "text-right": data?.data?.title_align === "RIGHT",
            })}
          >
            {data?.data?.title}
          </h2>

          {data?.data && (
            <div
              className="wysiwyg-editor mt-4"
              dangerouslySetInnerHTML={{ __html: data?.data?.body }}
            />
          )}
        </div>
      )}
    </div>
  );
};
