import React from "react";
import { Feed } from "./Feed";
import { FeedContextProvider } from "./FeedContext";

export const FeedContainer = () => {
  return (
    <FeedContextProvider>
      <Feed />
    </FeedContextProvider>
  );
};
