import React, { useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { EventType, Feed } from "../../api/feed";
import { CommentForm } from "../../components/discussions/CommentForm";
import { CommentSimple } from "../../components/discussions/DiscussionsList";
import { Discussion } from "../../api/discussions";
import { Button } from "../../components/button/Button";

import { ChatIcon, UserIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import { withParams } from "../../utils/url";
import { routes } from "../../routes";

const getColor = (event: string) => {
  return event.includes("PROJECT")
    ? "bg-blue-500"
    : event.includes("WORKING_GROUP")
    ? "bg-violet-500"
    : "bg-green-500";
};

const FeedItem: React.FC<{ feed: Feed }> = ({ feed }) => {
  const [isAddComment, setIsAddComment] = useState(false);
  const [commentsAdded, setCommentsAdded] = useState<Discussion[]>([]);

  const feedItem = feed?.related_content || feed?.content;

  return (
    <div className="bg-white p-5">
      <NavLink
        to={withParams(
          feed.event_type.includes("PROJECT")
            ? routes.project
            : feed.event_type.includes("WORKING_GROUP")
            ? routes.workingGroupSingle
            : routes.blogSingle,
          { id: feedItem?.slug }
        )}
        className="flex flex-col sm:flex-row"
      >
        <div className="w-full sm:w-52 mb-4 mr-0 sm:mb-0 sm:mr-4">
          <img
            src={feedItem?.image_link_thumbnail || feedItem?.image}
            alt={feedItem?.title}
            className="w-full"
          />
        </div>
        <div className='flex-1'>
          <div className="font-nunito font-bold text-xl lg:text-2xl text-blue-550 mb-1">
            {feedItem?.title}
          </div>

          <div
            className="wysiwyg-editor"
            dangerouslySetInnerHTML={{ __html: feedItem?.summary }}
          />
        </div>
      </NavLink>
      <div className="mt-2">
        <span
          className={classNames(
            "inline-flex items-center px-2 py-1 rounded-full text-xs font-medium text-white mr-1 mb-1 capitalize",
            getColor(feed?.event_type)
          )}
        >
          {feed?.event_type.replace(/_/g, " ")}
        </span>
      </div>
      <div
        className="wysiwyg-editor"
        dangerouslySetInnerHTML={{ __html: feed?.content?.text }}
      />

      {[
        EventType.PROJECT_DISCUSSION_CREATED,
        EventType.PROJECT_COMMENT_CREATED,
        EventType.WORKING_GROUP_COMMENT_CREATED,
        EventType.WORKING_GROUP_DISCUSSION_CREATED,
        EventType.BLOG_DISCUSSION_CREATED,
        EventType.BLOG_COMMENT_CREATED,
      ].includes(feed.event_type) &&
        commentsAdded.length === 0 && (
          <div>
            {isAddComment ? (
              <div className="my-4 max-w-2xl">
                <div className="mb-2">
                  <Button
                    variant="white"
                    size="exSmall"
                    type="button"
                    onClick={() => {
                      setIsAddComment(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <CommentForm
                  type={"project"}
                  discussion_id={`${
                    feed?.content?.discussion_id || feed?.content?.id
                  }`}
                  reply_id={
                    feed?.content?.discussion_id
                      ? `${feed?.content?.id}`
                      : undefined
                  }
                  onSubmit={(newComment: Discussion) => {
                    setCommentsAdded([
                      { ...newComment, created_at: "now" },
                      ...commentsAdded,
                    ]);

                    setIsAddComment(false);
                  }}
                />
              </div>
            ) : (
              <Button
                size="exSmall"
                className="mt-2 mb-4"
                variant="secondary"
                rounded="rounded-full"
                onClick={() => {
                  setIsAddComment(true);
                }}
              >
                Add reply
              </Button>
            )}
          </div>
        )}

      {[...commentsAdded].map(
        (
          {
            id: comment_id,
            text,
            user_reaction,
            created_at,
            updated_at,
            deleted_at,
            created_by,
            replies,
            votes,
            quotes,
            is_owner,
            images,
          },
          i
        ) => (
          <div key={i} className="ml-8">
            <div className="text-lg font-medium font-nunito text-blue-550 my-2">
              Reply
            </div>
            <CommentSimple
              text={text}
              created_at={created_at}
              updated_at={updated_at}
              deleted_at={deleted_at}
              created_by={created_by}
              user_reaction={user_reaction}
              is_owner={is_owner}
              votes={votes}
              quotes={quotes}
              images={images}
            />
          </div>
        )
      )}
    </div>
  );
};

export const FeedList = ({ listData }: { listData: Feed[] }) => {
  return (
    <div>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {listData.map((item, eventIdx) => (
            <li key={item.id}>
              <div className="relative pb-8">
                {eventIdx !== listData.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}

                <div className="relative flex space-x-3">
                  <div>
                    {[
                      EventType.PROJECT_DISCUSSION_CREATED,
                      EventType.PROJECT_COMMENT_CREATED,
                      EventType.WORKING_GROUP_COMMENT_CREATED,
                      EventType.WORKING_GROUP_DISCUSSION_CREATED,
                      EventType.BLOG_DISCUSSION_CREATED,
                      EventType.BLOG_COMMENT_CREATED,
                    ].includes(item.event_type) ? (
                      <span
                        className={classNames(
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
                          getColor(item?.event_type)
                        )}
                      >
                        <ChatIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    ) : (
                      <span
                        className={classNames(
                          "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white",
                          getColor(item?.event_type)
                        )}
                      >
                        <UserIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col-reverse sm:flex-row min-w-0 flex-1 justify-between sm:space-x-4 pt-1 bg-white">
                    <div>
                      <FeedItem feed={item} />
                    </div>
                    <div className="whitespace-nowrap sm:text-right text-sm text-gray-500 px-5 pt-5 sm:p-5">
                      <time dateTime={item.created_at}>
                        {format(new Date(item.created_at), "MMM dd YYY, HH:mm")}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
