import React from "react";
import { NavLink } from "react-router-dom";
import map from "lodash/map";
import { format } from "date-fns";

import { Button } from "components/button/Button";

import { BlogData } from "api/blogs";
import { withParams } from "utils/url";

import { routes } from "routes";
import get from "lodash/get";
import classNames from "classnames";

const BlogItem = ({ title, header_image }: BlogData) => {
  return (
    <div className="flex flex-col overflow-hidden h-full bg-white">
      <div className="flex justify-between items-center">
        <div className={classNames("w-56")}>
          <div
            className="relative w-full h-56 bg-gray-200 bg-center bg-cover bg-no-repeat"
            style={{ backgroundImage: `url(${header_image})` }}
          />
        </div>

        <div className="flex-1 p-6">
          <div className="">
            <div className="mt-2 block">
              <h3 className="font-nunito text-blue-550 font-bold text-xl mb-4">
                {title}
              </h3>

              <Button variant="violet550" size="small" rounded="rounded-full">
                <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                  read more
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const BlogItemVertical = ({ title, header_image }: BlogData) => {
  return (
    <div
      className="flex flex-col overflow-hidden h-full bg-white bg-center bg-contain bg-no-repeat p-4"
      style={{ backgroundImage: `url(${header_image})` }}
    >
      <div className="mt-auto flex justify-between items-center bg-white">
        <div className="flex-1 py-5 px-6">
          <div className="">
            <div className="block">
              <h3 className="font-nunito text-blue-550 font-bold text-xl mb-4">
                {title}
              </h3>

              <Button variant="violet550" size="small" rounded="rounded-full">
                <div className="font-nunito text-lg font-semibold -my-1.5 mx-2">
                  read more
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ListBlogHome = ({ listData }: { listData: BlogData[] }) => {
  return (
    <div>
      <div className="flex flex-wrap w-full items-center">
        <div className="w-full pb-3 md:pb-0 md:w-1/2 self-stretch md:pr-3">
          <NavLink to={withParams(routes.blogSingle, { id: listData[0].slug })}>
            <BlogItemVertical {...listData[0]} />
          </NavLink>
        </div>
        <div className="w-full md:w-1/2 md:pl-3">
          <div className="pb-3">
            <NavLink
              to={withParams(routes.blogSingle, { id: listData[1].slug })}
            >
              <BlogItem {...listData[1]} />
            </NavLink>
          </div>

          <div className="pt-3">
            <NavLink
              to={withParams(routes.blogSingle, { id: listData[2].slug })}
            >
              <BlogItem {...listData[2]} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
