import { withParams } from "utils/url";
import { getHeaders, apiUrl, axiosInstance } from "../index";
import { ProjectCreate, Project } from "../types";

const api = require("./api.json");

interface ProjectCategories {
  tags?: { id: number; tag: string }[];
  needs?: { id: number; title: string }[];
}

export interface ProjectType
  extends ProjectCategories,
    Omit<ProjectCreate, "tags" | "needs"> {}

export const getProjectsRequest = ({
  page = 1,
  perPage = 1,
  searchQuery,
  tags = [],
  needs = [],
  ordering,
  funding,
  my_favorite_projects,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  tags?: string[];
  needs?: string[];
  ordering?: string;
  funding?: string;
  my_favorite_projects?: string;
}): Promise<{
  data: {
    data: Project[];
    pagination: { currentPage: number; total: number; perPage: number };
  };
}> =>
  axiosInstance.get(
    `${apiUrl()}${withParams(api.projects, {
      page,
      tags,
      needs,
      perPage,
      search: searchQuery,
      ordering,
      funding,
      my_favorite_projects,
    }).replace(/(%5B1%5D|%5B0%5D)/g, "")}`,
    window.localStorage.getItem("token") ? getHeaders() : {}
  );

export const getLandingProjectsRequest = ({
  page = 1,
  perPage = 1,
  searchQuery,
  tags = [],
  needs = [],
  ordering,
  funding,
  my_favorite_projects,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  tags?: string[];
  needs?: string[];
  ordering?: string;
  funding?: string;
  my_favorite_projects?: string;
}): Promise<{
  data: {
    data: Project[];
    pagination: { currentPage: number; total: number; perPage: number };
  };
}> =>
  axiosInstance.get(
    `${apiUrl()}${withParams('/projects/homepage', {
      page,
      tags,
      needs,
      perPage,
      search: searchQuery,
      ordering,
      funding,
      my_favorite_projects,
    }).replace(/(%5B1%5D|%5B0%5D)/g, "")}`,
    window.localStorage.getItem("token") ? getHeaders() : {}
  );

export const getMyProjectsRequest = ({
  page = 1,
  perPage = 1,
  searchQuery,
  tags = [],
  needs = [],
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  tags?: string[];
  needs?: string[];
}): Promise<{
  data: {
    data: Project[];
    pagination: { currentPage: number; total: number; perPage: number };
  };
}> =>
  axiosInstance.get(
    `${apiUrl()}${withParams(api.myProjects, {
      page,
      tags,
      needs,
      perPage,
      search: searchQuery,
      ordering: "-created_at",
    }).replace(/(%5B1%5D|%5B0%5D)/g, "")}`,
    getHeaders()
  );

export const getProjectsNeedsRequest = (): Promise<{
  data: { id: string; title: string; slug: string; is_active: boolean };
}> => axiosInstance.get(`${apiUrl()}${withParams(api.needs)}`);

export const getProjectsTagsRequest = (): Promise<{
  data: {
    data: { id: string; title: string; slug: string; is_active: boolean };
  };
}> => axiosInstance.get(`${apiUrl()}${withParams(api.tags)}`);

export const getProjectByIdRequest = (
  id: string
): Promise<{ data: { data: Project } }> =>
  axiosInstance.get(
    `${apiUrl()}${withParams(api.projectsId, { id })}`,
    window.localStorage.getItem("token") ? getHeaders() : {}
  );

export const getMyProjectByIdRequest = (
  id: string
): Promise<{ data: { data: ProjectType } }> =>
  axiosInstance.get(
    `${apiUrl()}${withParams(api.myProjectsId, { id })}`,
    getHeaders()
  );

export const createProjectRequest = (project: ProjectCreate) =>
  axiosInstance.post(
    `${apiUrl()}${withParams(api.projectsIdCreate)}`,
    project,
    getHeaders()
  );

export const patchProjectRequest = (id: string, project: ProjectCreate) =>
  axiosInstance.patch(
    `${apiUrl()}${withParams(api.projectsIdUpdate, { id })}`,
    project,
    getHeaders()
  );

export const projectSetImageRequest = ({
  id,
  data,
}: {
  id: string;
  data: any;
}) =>
  axiosInstance.post(
    `${apiUrl()}${withParams(api.projectSetImage, { id })}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
    }
  );

export const projectSetSupplementaryFileRequest = ({
  project_id,
  data,
  setLoaded,
}: {
  project_id: string;
  data: any;
  setLoaded: (l: number) => void;
}): Promise<{
  data: {
    data: { id?: string; name: string; type: string; url: string };
  };
}> =>
  axiosInstance.post(
    `${apiUrl()}${withParams(
      "/me/projects/:project_id/supplementary-materials",
      { project_id }
    )}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setLoaded((progressEvent.loaded / progressEvent.total) * 100);
      },
    }
  );

export const projectSupplementaryDeleteRequest = ({
  project_id,
  id,
}: {
  project_id: string;
  id: string;
}) => {
  return axiosInstance.delete(
    `${apiUrl()}${withParams(api.projectSupplementaryItem, {
      project_id,
      id,
    })}`,
    getHeaders()
  );
};

export const projectSetRatingRequest = ({
  id,
  reaction,
}: {
  id: string;
  reaction: string;
}) =>
  axiosInstance.put(
    `${apiUrl()}${withParams(api.projectRating, { id })}`,
    { reaction },
    getHeaders()
  );

export const projectDeleteRatingRequest = ({ id }: { id: string }) =>
  axiosInstance.delete(
    `${apiUrl()}${withParams(api.projectRating, { id })}`,
    getHeaders()
  );
