import React from "react";
import { useQuery } from "react-query";

import { getUserRequest } from "api/user/user";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";

import { EditProfileForm } from "./EditProfileForm";
import { EditProjectRoleForm } from "./EditProjectRoleForm";
import {Helmet} from "react-helmet";

export const EditProfile = () => {
  const { data, isLoading } = useQuery("user", () => getUserRequest(), {
    suspense: false,
  });

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <div>
      <Helmet>
        <title>Let’s Get Proof | Edit profile</title>
      </Helmet>

      <div className="flex justify-between flex-col-reverse sm:flex-row">
        <div className="sm:w-1/2">
          <EditProfileForm user={data?.data} />
        </div>
        {/*<div className="sm:w-1/2 my-8">
          <EditProjectRoleForm user={data?.data} />
        </div>*/}
      </div>
    </div>
  );
};
