import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Slider from "react-slick";
import { ChatIcon, UserGroupIcon, PencilIcon } from "@heroicons/react/solid";

import { Button } from "components/button/Button";

import { getProjectsTagsRequest } from "api/project/project";
import {
  getWorkingGroupPublic,
  getWorkingGroupMembers,
  confirmMemberWorkingGroup,
  leaveMemberWorkingGroup,
  getJoinRequestsWorkingGroup,
  joinRequestApproveWorkingGroup,
  joinRequestDeclineWorkingGroup,
  removeMemberWorkingGroup,
  getInvitedMembersWorkingGroup,
  deleteInvitedMemberWorkingGroup,
  retryInvitedMemberWorkingGroup,
  joinRequestCancelWorkingGroup,
  getWGUpdatesPublicList,
  supplementaryWGItemsGet,
  getWorkingGroupRolesOfPartner,
} from "api/workingGroups";
import { useContextService } from "hooks/use-context-service";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import { TopicsDiscussions } from "../../../components/discussions/TopicsDiscussions/TopicsDiscussions";
import { SupplementaryMaterialsEdit } from "domains/working-groups/supplementary-materials/SupplementaryMaterialsEdit";
import { EditMemberForm } from "./EditMemberForm";
import { JoinRequestForm } from "./JoinRequestForm";
import { UserItem } from "./UserItem";
import { LIST_QUERY } from "components/discussions/TopicsDiscussions/TopicsDiscussionsContext";
import { HeadlineButton } from "domains/project/Project";
import { InviteMemberForm } from "../invite-member/InviteMemberForm";
import { AppContext } from "context";

import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useModal } from "../../../hooks/use-modal";
import { Modal } from "../../../components/modal/Modal";
import { showNotification as showNotificationService } from "../../../services/notification";
import { withParams, clearQueryParams } from "../../../utils/url";
import { routes } from "../../../routes";
import classNames from "classnames";
import { format } from "date-fns";
import { SearchIcon, RefreshIcon } from "@heroicons/react/outline";
import { fileIcon } from "../../../components/file-upload-field/FileUploadField";
import { RegisterLite } from "../../register/components/RegisterLite";
import { Helmet } from "react-helmet";
import { useUserQuery } from "queries/use-user-query";
import { LoginForm } from "../../login/components/LoginForm";

interface ParamTypes {
  id: string;
}

const GET_WORKING_GROUP = "GET_WORKING_GROUP";
export const GET_WORKING_GROUP_MEMBERS = "GET_WORKING_GROUP_MEMBERS";
const GET_WORKING_GROUPS_JOIN_REQUESTS = "GET_WORKING_GROUPS_JOIN_REQUESTS";
const GET_INVITED_MEMBERS_WORKING_GROUP = "GET_INVITED_MEMBERS_WORKING_GROUP";
const JOIN_WORKING_GROUP_CANCEL_REQUEST = "JOIN_WORKING_GROUP_CANCEL_REQUEST";
const SUPPLEMENTARY_ITEMS_GET_LIST = "SUPPLEMENTARY_ITEMS_GET_LIST";

export const WorkingGroupSingle = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    focusOnSelect: true,
    afterChange: (current: number) => setCurrentSlide(current),
  };

  const history = useHistory();
  const [memberToDelete, setMemberToDelete] = useState<string | undefined>();
  const [memberToEdit, setMemberToEdit] = useState<
    { id: string; role: string } | undefined
  >();
  const [isJoinRequested, setIsJoinRequested] = useState(false);
  const { id } = useParams<ParamTypes>();
  const location = useLocation();
  const queryClient = useQueryClient();
  const showNotification = useContextService(showNotificationService);
  const { data: userResponse } = useUserQuery();

  const { authToken } = useContext(AppContext);

  const joinRequestCancelWorkingGroupService = useContextService(
    joinRequestCancelWorkingGroup
  );

  const supplementaryWGItemsGetQuery = useContextService(
    supplementaryWGItemsGet
  );

  const joinRequestCancelWorkingGroupMutation = useMutation(
    [JOIN_WORKING_GROUP_CANCEL_REQUEST],
    () => joinRequestCancelWorkingGroupService(id),
    {
      onSuccess: () => {
        setIsJoinRequested(false);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "Something went wrong",
        });
      },
    }
  );

  const [activeTab, setActiveTab] = useState(0);
  const [showDiscussions, setShowDiscussions] = useState(true);

  const ref = useRef<HTMLDivElement | null>(null);
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const refTop = useRef<HTMLDivElement | null>(null);
  const handleClickTop = () => {
    refTop.current?.scrollIntoView({ behavior: "smooth" });
  };

  const params = useMemo(() => new URLSearchParams(location?.search), [
    location,
  ]);

  const [isJoinGroupModalOpen, setIsJoinGroupModalOpen] = useState(
    !!params.get("joinGroup")
  );

  useEffect(() => {
    if (params.get("joinGroup")) {
      setIsJoinGroupModalOpen(true);
    }
  }, [params]);

  const closeJoinGroupModal = useCallback(() => {
    clearQueryParams();
    setIsJoinGroupModalOpen(false);
  }, []);

  const { openModal, closeModal, modalVisible } = useModal();
  const memberJoinConfirmModal = useModal();
  const supplementaryMaterialsEditModal = useModal();
  const leaveConfirmModal = useModal();
  const removeMemberConfirmModal = useModal();
  const editMemberRoleModal = useModal();
  const unauthorizedJoinModal = useModal();

  const [isSummaryOpen, setIsSummaryOpen] = useState(true);
  const [isAdInfoOpen, setIsAdInfoOpen] = useState(false);
  const [isGoalsOpen, setIsGoalsOpen] = useState(false);

  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );
  const joinRequestApproveWorkingGroupService = useContextService(
    joinRequestApproveWorkingGroup
  );
  const getInvitedMembersWorkingGroupService = useContextService(
    getInvitedMembersWorkingGroup
  );
  const joinRequestDeclineWorkingGroupService = useContextService(
    joinRequestDeclineWorkingGroup
  );
  const getWorkingGroupMembersService = useContextService(
    getWorkingGroupMembers
  );
  const leaveMemberWorkingGroupService = useContextService(
    leaveMemberWorkingGroup
  );
  const confirmMemberWorkingGroupService = useContextService(
    confirmMemberWorkingGroup
  );
  const getJoinRequestsWorkingGroupService = useContextService(
    getJoinRequestsWorkingGroup
  );
  const removeMemberWorkingGroupService = useContextService(
    removeMemberWorkingGroup
  );
  const deleteInvitedMemberWorkingGroupService = useContextService(
    deleteInvitedMemberWorkingGroup
  );
  const retryInvitedMemberWorkingGroupService = useContextService(
    retryInvitedMemberWorkingGroup
  );

  const confirmMemberWorkingGroupMutation = useMutation(
    ["confirmMemberWorkingGroup", id],
    () => confirmMemberWorkingGroupService(id, params.get("invite_token")!),
    {
      onSuccess: () => {
        history.push(`/working-groups/${id}`);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "",
          variant: "ERROR",
        });
      },
    }
  );

  const { data: workingGroupsRoles } = useQuery(
    `getWGRoles`,
    useContextService(getWorkingGroupRolesOfPartner),
    {
      suspense: false,
    }
  );

  const joinRequestApproveWorkingGroupMutation = useMutation(
    [`joinRequestApproveWorkingGroup`],
    (member_id: string) => joinRequestApproveWorkingGroupService(id, member_id),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });
        queryClient.invalidateQueries(GET_WORKING_GROUPS_JOIN_REQUESTS);
        queryClient.invalidateQueries(GET_WORKING_GROUP_MEMBERS);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "",
          variant: "ERROR",
        });
      },
    }
  );

  const removeMemberWorkingGroupMutation = useMutation(
    [`removeMemberWorkingGroupService`],
    (member_id: string) => removeMemberWorkingGroupService(id, member_id),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });
        queryClient.invalidateQueries(GET_WORKING_GROUPS_JOIN_REQUESTS);
        queryClient.invalidateQueries(GET_WORKING_GROUP_MEMBERS);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "",
          variant: "ERROR",
        });
      },
    }
  );

  const deleteInvitedMemberWorkingGroupMutation = useMutation(
    [`deleteInvitedMemberWorkingGroup`],
    (member_id: string) =>
      deleteInvitedMemberWorkingGroupService({ slug: id, id: member_id }),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });
        queryClient.invalidateQueries(GET_INVITED_MEMBERS_WORKING_GROUP);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "",
          variant: "ERROR",
        });
      },
    }
  );

  const retryInvitedMemberWorkingGroupMutation = useMutation(
    [`retryInvitedMemberWorkingGroup`],
    (member_id: string) =>
      retryInvitedMemberWorkingGroupService({ slug: id, id: member_id }),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });
        queryClient.invalidateQueries(GET_INVITED_MEMBERS_WORKING_GROUP);
      },
      onError: (e) => {
        showNotification({
          title: "Error",
          message: get(e, "response.data.messages.detail"),
          variant: "ERROR",
        });
      },
    }
  );

  const joinRequestDeclineWorkingGroupMutation = useMutation(
    [`joinRequestDeclineWorkingGroup`],
    (member_id: string) => joinRequestDeclineWorkingGroupService(id, member_id),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });
        queryClient.invalidateQueries(GET_WORKING_GROUPS_JOIN_REQUESTS);
        queryClient.invalidateQueries(GET_WORKING_GROUP_MEMBERS);
      },
      onError: () => {
        showNotification({
          title: "Error",
          message: "",
          variant: "ERROR",
        });
      },
    }
  );

  const leaveMemberWorkingGroupMutation = useMutation(
    [`leaveMemberWorkingGroupService`, id],
    () => leaveMemberWorkingGroupService(id),
    {
      onSuccess: () => {
        showNotification({
          title: "Success",
          message: "",
        });

        setTimeout(() => {
          history.push("/working-groups/list");
        }, 1000);
        queryClient.invalidateQueries(GET_WORKING_GROUP_MEMBERS);
      },
    }
  );

  const getWorkingGroupPublicService = useContextService(getWorkingGroupPublic);

  const { data: workingGroup, isFetching, error } = useQuery(
    [GET_WORKING_GROUP, id],
    () => getWorkingGroupPublicService({ slug: id }),
    {
      suspense: false,
    }
  );

  const { data: workingGroupMembers } = useQuery(
    [GET_WORKING_GROUP_MEMBERS, id],
    () => getWorkingGroupMembersService({ slug: id }),
    {
      suspense: false,
    }
  );

  const { data: workingGroupInvitedMembers } = useQuery(
    [GET_INVITED_MEMBERS_WORKING_GROUP, id],
    () => getInvitedMembersWorkingGroupService({ slug: id }),
    {
      suspense: false,
      enabled: !!(
        !params.get("invite_token") &&
        (workingGroup?.data?.data?.is_owner ||
          workingGroup?.data?.data?.is_moderator)
      ),
    }
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (params.get("invite_token") && workingGroup) {
      if (!workingGroup?.data?.data?.is_member) {
        memberJoinConfirmModal.openModal();
      } else {
        history.push(`/working-groups/${id}`);
      }
    }
  }, [
    memberJoinConfirmModal,
    params,
    workingGroup,
    workingGroup?.data?.data?.is_member,
    history,
    id,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getProjectUpdatesListQuery = useContextService(getWGUpdatesPublicList);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: dataWGUpdates } = useQuery(
    `GET_WG_UPDATES_${workingGroup?.data?.data?.id}`,
    () =>
      getProjectUpdatesListQuery({
        group_id: `${workingGroup?.data?.data?.id!}`,
      }),
    {
      suspense: false,
      enabled: !!workingGroup?.data?.data?.id,
    }
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (workingGroup?.data?.data?.has_join_request) {
      setIsJoinRequested(workingGroup?.data?.data?.has_join_request);
    }
  }, [workingGroup]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data: workingGroupsJoinRequests } = useQuery(
    [GET_WORKING_GROUPS_JOIN_REQUESTS, id],
    () => getJoinRequestsWorkingGroupService({ slug: id }),
    {
      suspense: false,
      enabled: !!(
        !params.get("invite_token") &&
        (workingGroup?.data?.data?.is_owner ||
          workingGroup?.data?.data?.is_moderator)
      ),
    }
  );

  const {
    data: supplementaryItemsData,
    isFetching: supplementaryItemsIsFetching,
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useQuery(
    [SUPPLEMENTARY_ITEMS_GET_LIST, workingGroup?.data?.data?.id],
    () =>
      supplementaryWGItemsGetQuery({
        group_id: workingGroup?.data?.data?.id!,
      }),
    {
      suspense: false,
      enabled: !!workingGroup?.data?.data?.id,
    }
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [workingGroupTabs, setWorkingGroupTabs] = useState([
    "Overview",
    // "Members",
    "Supplementary Materials",
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      workingGroup?.data?.data?.is_owner ||
      workingGroup?.data?.data?.is_moderator
    ) {
      setWorkingGroupTabs([
        "Overview",
        "Members",
        "Join requests/invites",
        "Supplementary Materials",
        "Updates",
      ]);
    } else if (workingGroup?.data?.data?.is_member) {
      setWorkingGroupTabs([
        "Overview",
        "Members",
        "Supplementary Materials",
        "Updates",
      ]);
    }
  }, [
    workingGroup?.data?.data?.is_owner,
    workingGroup?.data?.data?.is_member,
    workingGroup?.data?.data?.is_moderator,
  ]);

  return (
    <div className="flex flex-wrap font-nunito" ref={refTop}>
      <Modal
        visible={editMemberRoleModal.modalVisible}
        onClose={editMemberRoleModal.closeModal}
        className="max-w-lg"
      >
        {memberToEdit && editMemberRoleModal.modalVisible && (
          <EditMemberForm
            role={memberToEdit?.role!}
            slug={id}
            member_id={memberToEdit?.id!}
            onSuccess={() => {
              editMemberRoleModal.closeModal();
              setMemberToEdit(undefined);
            }}
          />
        )}
      </Modal>
      <Modal
        visible={memberJoinConfirmModal.modalVisible}
        onClose={() => {}}
        className="max-w-lg"
      >
        {authToken ? (
          <div>
            <div className="pb-4 text-2xl font-bold text-center">
              By joining this working group, you are committing to:
            </div>

            {workingGroup?.data?.data?.member_obligations && (
              <div className="mb-8">
                <div
                  className="text-sm text-gray-700 wysiwyg-editor"
                  dangerouslySetInnerHTML={{
                    __html: workingGroup?.data?.data?.member_obligations,
                  }}
                />
              </div>
            )}

            <div className="my-4 text-sm font-semibold text-center">
              Do you consent to the above?
            </div>

            {confirmMemberWorkingGroupMutation.isError && (
              <div className="w-full px-4 py-3 mb-5 text-sm text-red-800 bg-red-100 rounded">
                {/*{JSON.stringify(
                  get(confirmMemberWorkingGroupMutation.error, "data.messages")
                )}*/}
                Token revoked.
                <br />
                If you want to join this Working Group, click on "Private member
                join" button.
              </div>
            )}

            <div className="flex justify-between">
              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  memberJoinConfirmModal.closeModal();
                  history.push(`/working-groups/${id}`);
                }}
                variant="secondary"
                className="w-40 mr-1"
              >
                Cancel
              </Button>

              <Button
                className="w-40"
                size="small"
                loading={confirmMemberWorkingGroupMutation.isLoading}
                spinner
                disabled={confirmMemberWorkingGroupMutation.isLoading}
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await confirmMemberWorkingGroupMutation.mutateAsync();
                  queryClient.invalidateQueries(GET_WORKING_GROUP);
                  memberJoinConfirmModal.closeModal();
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <LoginForm />
          </div>
        )}
      </Modal>
      <Modal
        visible={leaveConfirmModal.modalVisible}
        onClose={leaveConfirmModal.closeModal}
        className="max-w-lg"
      >
        <div className="pb-4 text-2xl font-bold text-center">
          Confirm action
        </div>
        <div className="flex justify-between">
          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              leaveConfirmModal.closeModal();
            }}
            variant="secondary"
            className="w-40 mr-1"
          >
            Cancel
          </Button>

          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              try {
                await leaveMemberWorkingGroupMutation.mutateAsync();
                leaveConfirmModal.closeModal();
              } catch (e) {}
            }}
            spinner
            loading={leaveMemberWorkingGroupMutation.isLoading}
            variant="danger"
            className="w-40 ml-1"
          >
            Leave
          </Button>
        </div>
      </Modal>
      <Modal
        visible={isJoinGroupModalOpen}
        onClose={closeJoinGroupModal}
        className="max-w-lg"
      >
        <div className="pb-4 text-2xl font-bold text-center">
          By joining this working group, you are committing to:
        </div>

        {workingGroup?.data?.data?.member_obligations && (
          <div className="mb-8">
            <div
              className="text-sm text-gray-700 wysiwyg-editor"
              dangerouslySetInnerHTML={{
                __html: workingGroup?.data?.data?.member_obligations,
              }}
            />
          </div>
        )}

        <div className="my-4 text-sm font-semibold text-center">
          Do you consent to the above?
        </div>

        <JoinRequestForm
          slug={id}
          onSuccess={() => {
            setIsJoinRequested(true);
            closeJoinGroupModal();
            showNotification({
              title: "Success",
              message: "Join request has been sent",
            });
          }}
        />
      </Modal>

      <Modal
        visible={removeMemberConfirmModal.modalVisible}
        onClose={removeMemberConfirmModal.closeModal}
        className="max-w-lg"
      >
        <div className="pb-4 text-2xl font-bold text-center">
          Confirm action
        </div>
        <div className="flex justify-between">
          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              removeMemberConfirmModal.closeModal();
            }}
            variant="secondary"
            className="w-40 mr-1"
          >
            Cancel
          </Button>

          <Button
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              try {
                await removeMemberWorkingGroupMutation.mutateAsync(
                  memberToDelete!
                );
              } catch (e) {}

              removeMemberConfirmModal.closeModal();
            }}
            spinner
            loading={removeMemberWorkingGroupMutation.isLoading}
            variant="danger"
            className="w-40 ml-1"
          >
            Remove
          </Button>
        </div>
      </Modal>

      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail")}
        </div>
      ) : !workingGroup || isFetching ? (
        <div className="flex justify-center w-full mt-24">
          <IconLoading />
        </div>
      ) : (
        <div className="w-full pr-4">
          <Helmet>
            <title>Let’s Get Proof | {workingGroup?.data?.data?.title}</title>
          </Helmet>

          <h2
            className="flex flex-col-reverse md:flex-row items-center mt-2 mb-5 break-words text-violet-550 font-nunitoexpanded text-2xl lg:text-3xl font-semibold leading-normal	lg:leading-normal"
            style={{ wordBreak: "break-word" }}
          >
            {workingGroup?.data?.data?.title}
          </h2>
          <div>
            <div className="flex flex-wrap justify-between pb-8">
              <div className="w-full pb-8 lg:pb-0 lg:w-2/3">
                <Slider {...sliderSettings}>
                  {workingGroup?.data?.data?.embedded_link && (
                    <div>
                      {currentSlide === 0 && (
                        <ReactPlayer
                          url={workingGroup?.data?.data?.embedded_link}
                          className="w-full max-w-xl mx-auto"
                          width="100%"
                          height="400px"
                          controls
                        />
                      )}
                    </div>
                  )}

                  <div>
                    <div
                      className="w-full bg-center bg-no-repeat bg-contain h-96"
                      style={{
                        backgroundImage: `url(${workingGroup?.data?.data?.image})`,
                      }}
                    />
                  </div>
                </Slider>

                {/*{workingGroup?.data?.data?.image ? (
                  <img
                    src={workingGroup?.data?.data?.image}
                    className="w-full"
                    alt={workingGroup?.data?.data?.title}
                  />
                ) : workingGroup?.data?.data?.embedded_link ? (
                  <ReactPlayer
                    url={workingGroup?.data?.data?.embedded_link}
                    className="w-full max-w-xl mx-auto"
                    width="100%"
                    height="240px"
                  />
                ) : null}*/}

                <div className="flex flex-wrap justify-center mt-8">
                  {map(workingGroup?.data?.data?.tags, (tag, i) => (
                    <span
                      key={i}
                      className="inline-flex items-center px-3 py-1 mx-1 mb-1 text-sm font-medium text-blue-800 bg-blue-100 rounded-full"
                    >
                      {get(
                        find(
                          get(projectTags, "data", []),
                          ({ id }) => id === tag
                        ),
                        "title"
                      )}
                    </span>
                  ))}
                </div>
              </div>

              <div className="w-full pb-8 pl-0 lg:pl-8 lg:pb-0 lg:w-1/3">
                <NavLink
                  to={withParams(routes.researcherProfile, {
                    id: workingGroup?.data?.data?.created_by?.id,
                  })}
                  className=""
                >
                  <div
                    className="flex justify-center items-center rounded-full bg-gray-300 w-16 h-16 text-white font-medium bg-center bg-cover"
                    style={{
                      backgroundImage: `url(${workingGroup?.data?.data?.created_by?.avatar})`,
                    }}
                  >
                    {!workingGroup?.data?.data?.created_by?.avatar
                      ? get(workingGroup?.data?.data?.created_by, "name[0]")
                      : ""}
                  </div>
                  <div className="mt-2">
                    <div className="font-bold font-nunito text-lg text-violet-550 hover:text-violet-700">
                      {workingGroup?.data?.data?.created_by?.name}
                    </div>

                    {get(workingGroup?.data?.data?.created_by, "location") && (
                      <div className="text-blue-550 font-nunito mt-1">
                        Location:{" "}
                        <span className="font-bold">
                          {get(
                            workingGroup?.data?.data?.created_by,
                            "location"
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </NavLink>

                <div className="block my-1 text-lg font-semibold font-nunito text-red-500">
                  Team Members:{" "}
                  <span className="">{workingGroup?.data?.data?.members}</span>
                </div>

                <div className="flex text-lg text-blue-550">
                  <div className="flex items-center text-blue-550">
                    <ChatIcon className="mr-1 w-5" /> Comments:{" "}
                    <span className="font-medium pl-1">
                      {workingGroup?.data?.data?.comments}
                    </span>
                  </div>
                </div>

                {!workingGroup?.data?.data?.is_member && (
                  <div className="flex pb-4 mt-8">
                    <Button
                      size="medium"
                      spinner
                      variant="violet550"
                      rounded="rounded-full"
                      disabled={isJoinRequested}
                      onClick={async () => {
                        if (authToken) {
                          setIsJoinGroupModalOpen(true);
                        } else {
                          unauthorizedJoinModal.openModal();
                        }
                      }}
                    >
                      {isJoinRequested
                        ? "Join request has been sent"
                        : "Private Member join"}
                    </Button>
                    {isJoinRequested && (
                      <Button
                        size="medium"
                        spinner
                        rounded="rounded-full"
                        loading={
                          joinRequestCancelWorkingGroupMutation.isLoading
                        }
                        disabled={
                          joinRequestCancelWorkingGroupMutation.isLoading
                        }
                        variant="white"
                        className="ml-2"
                        onClick={async () => {
                          joinRequestCancelWorkingGroupMutation.mutate();
                        }}
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                )}

                {workingGroup?.data?.data?.is_member && (
                  <div className="mt-8">
                    <div className="mb-3 text-xl font-extrabold tracking-tight text-blue-550 sm:text-xl">
                      Status
                    </div>
                    <div>
                      {workingGroup?.data?.data?.is_owner
                        ? "Administrator"
                        : workingGroup?.data?.data?.is_moderator
                        ? "Moderator"
                        : "Member"}
                    </div>
                  </div>
                )}

                <div className="h-1 bg-violet-550 opacity-10 my-8" />

                <div className="">
                  <div className="mb-3 mt-8 font-nunito text-blue-550 font-bold text-xl lg:text-2xl">
                    Discussion
                  </div>
                  <div className="mt-1 text-gray-650 text-lg lg:text-xl font-medium font-nunito">
                    Thoughts, ideas, comments? <br /> Ask the researchers
                    directly by posting a comment below!
                  </div>

                  <div className="flex flex-col xl:flex-row justify-between items-center mt-4">
                    <Button
                      type="button"
                      variant="violet550"
                      rounded="rounded-full"
                      className="px-3 w-full xl:w-auto"
                      onClick={handleClick}
                    >
                      <div className="font-nunito text-xl font-semibold my-1.5">
                        View Discussion
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap justify-between">
              <div className="w-full pb-8 lg:pb-0">
                <div>
                  <div className="xl:w-full xl:mx-0  md:h-14 md:block bg-white">
                    <ul className="flex flex-col md:flex-row pt-2 font-bold text-base">
                      {workingGroupTabs.map((value, i) => (
                        <>
                          {i !== 0 && (
                            <div className="hidden md:block w-px h-8 mt-2 bg-gray-300" />
                          )}
                          <li
                            key={i}
                            onClick={() => setActiveTab(i)}
                            className={classNames(
                              activeTab === i
                                ? "border-red-500 md:pr-8 md:pt-2 text-blue-550 mt-3 md:mt-0 relative grow"
                                : "text-blue-550  md:pr-8 md:pt-2 hover:text-blue-550 cursor-pointer mt-3 md:mt-0 relative grow",
                              {
                                "md:pl-8": i !== 0,
                              }
                            )}
                          >
                            <div className="flex items-center mb-1 md:mb-2 text-xl lg:text-2xl">
                              {value}
                            </div>
                            {activeTab === i ? (
                              <div className="w-full h-1 bg-red-500" />
                            ) : (
                              <div className="w-full h-1" />
                            )}
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>

                <div
                  className="w-full mt-8 break-words"
                  style={{ wordBreak: "break-word" }}
                >
                  {activeTab === 0 && (
                    <div>
                      <HeadlineButton
                        name="Summary"
                        isOpen={isSummaryOpen}
                        setIsOpen={setIsSummaryOpen}
                      />

                      {isSummaryOpen && (
                        <div className="mt-1 mb-8 text-gray-700">
                          {workingGroup?.data?.data?.summary && (
                            <div className="">
                              <div
                                className="wysiwyg-editor  text-gray-650 text-xl lg:text-2xl font-medium"
                                dangerouslySetInnerHTML={{
                                  __html: workingGroup?.data?.data?.summary,
                                }}
                              />
                            </div>
                          )}

                          <div className="questions-gradient h-1 w-full my-5" />
                        </div>
                      )}

                      {workingGroup?.data?.data?.additional_info && (
                        <div className="text-lg text-gray-700">
                          <HeadlineButton
                            name="Additional info"
                            isOpen={isAdInfoOpen}
                            setIsOpen={setIsAdInfoOpen}
                          />
                          {isAdInfoOpen && (
                            <div>
                              <div
                                className="wysiwyg-editor text-gray-650 text-xl lg:text-2xl font-medium"
                                dangerouslySetInnerHTML={{
                                  __html:
                                    workingGroup?.data?.data?.additional_info,
                                }}
                              />
                              <div className="questions-gradient h-1 w-full my-5" />
                            </div>
                          )}
                        </div>
                      )}

                      <HeadlineButton
                        name="Goals"
                        isOpen={isGoalsOpen}
                        setIsOpen={setIsGoalsOpen}
                      />

                      {isGoalsOpen && (
                        <div>
                          {map(
                            workingGroup?.data?.data?.goals_of_working_group,
                            (tag, i) => (
                              <span
                                key={i}
                                className="inline-flex items-center px-3 py-1 mb-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded-full"
                              >
                                {tag}
                              </span>
                            )
                          )}

                          {workingGroup?.data?.data?.other_goals_of_wg && (
                            <div className="pt-4 text-sm text-gray-700 mb-3">
                              {workingGroup?.data?.data?.other_goals_of_wg}
                            </div>
                          )}

                          {workingGroup?.data?.data?.industry_partner_likely ===
                            "yes" &&
                            workingGroup?.data?.data?.role_of_industry_partner
                              ?.length && (
                              <div>
                                <div className="mt-4 text-xl font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                                  Role of the industry partner
                                </div>

                                {map(
                                  workingGroup?.data?.data
                                    ?.role_of_industry_partner,
                                  (tag, i) => (
                                    <span
                                      key={i}
                                      className="inline-flex items-center px-3 py-1 mb-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded-full"
                                    >
                                      {get(
                                        workingGroupsRoles?.data?.find(
                                          ({ id }) => id === tag
                                        ),
                                        "title"
                                      )}
                                    </span>
                                  )
                                )}

                                {workingGroup?.data?.data
                                  ?.other_role_of_industry_partner && (
                                  <div className="pt-4 text-sm text-gray-700 mb-3">
                                    {
                                      workingGroup?.data?.data
                                        ?.other_role_of_industry_partner
                                    }
                                  </div>
                                )}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}

                  {activeTab === 1 && workingGroup?.data?.data?.is_member && (
                    <div>
                      <div className="text-xl mb-4 font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                        Members
                      </div>

                      {workingGroup?.data?.data?.member_obligations && (
                        <div className="mb-8">
                          <div className="font-semibold">
                            Member obligations
                          </div>
                          <div
                            className="text-sm text-gray-700 wysiwyg-editor"
                            dangerouslySetInnerHTML={{
                              __html:
                                workingGroup?.data?.data?.member_obligations,
                            }}
                          />
                        </div>
                      )}

                      {workingGroupMembers?.data?.map(
                        ({
                          id,
                          name,
                          role,
                          avatar,
                          avatar_thumbnail,
                          email,
                          user_id,
                          affiliation,
                          blurb,
                        }) => (
                          <div className="mb-4" key={id}>
                            <UserItem
                              name={name}
                              avatar={avatar_thumbnail}
                              email={email}
                              user_id={user_id}
                              role={role}
                              affiliation={affiliation}
                            />

                            <div className="ml-12 pl-3">
                              {/*{blurb && (
                                <div className="text-sm text-gray-600 font-normal mb-2">
                                  <div className="font-medium">
                                    Blurb
                                  </div>
                                  {blurb}
                                </div>
                              )}*/}
                              <div className="flex">
                                {workingGroup?.data?.data?.is_owner &&
                                  role !== "admin" && (
                                    <div className="pt-2">
                                      <Button
                                        type="button"
                                        variant="primary"
                                        size="exSmall"
                                        className="mr-2"
                                        onClick={() => {
                                          setMemberToEdit({ id, role });
                                          editMemberRoleModal.openModal();
                                        }}
                                      >
                                        Edit role
                                      </Button>
                                    </div>
                                  )}

                                {workingGroup?.data?.data?.is_owner &&
                                  role !== "admin" && (
                                    <div className="pt-2">
                                      <Button
                                        type="button"
                                        variant="danger"
                                        size="exSmall"
                                        onClick={() => {
                                          setMemberToDelete(id);
                                          removeMemberConfirmModal.openModal();
                                        }}
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )
                      )}

                      {!workingGroup?.data?.data?.is_owner &&
                        workingGroup?.data?.data?.is_member && (
                          <div className="pt-4">
                            <Button
                              type="button"
                              variant="danger"
                              size="small"
                              className="w-40"
                              onClick={leaveConfirmModal.openModal}
                            >
                              Leave
                            </Button>
                          </div>
                        )}
                    </div>
                  )}

                  {activeTab === 2 &&
                    (workingGroup?.data?.data?.is_owner ||
                      workingGroup?.data?.data?.is_moderator) && (
                      <div>
                        {(workingGroup?.data?.data?.is_owner ||
                          workingGroup?.data?.data?.is_moderator) && (
                          <div className="pt-2">
                            <Button
                              type="button"
                              variant="primary"
                              size="small"
                              className="w-40"
                              onClick={openModal}
                            >
                              Invite new member
                            </Button>
                          </div>
                        )}

                        {(workingGroup?.data?.data?.is_owner ||
                          workingGroup?.data?.data?.is_moderator) && (
                          <div>
                            <div className="text-xl mt-4 mb-4 font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                              Join requests
                            </div>

                            {workingGroupsJoinRequests?.data?.map(
                              ({
                                id,
                                name,
                                role,
                                avatar,
                                email,
                                blurb,
                                message,
                                user_id,
                                affiliation,
                              }) => (
                                <div className="mb-4" key={id}>
                                  <UserItem
                                    name={name}
                                    avatar={avatar}
                                    email={email}
                                    user_id={user_id}
                                    role={role}
                                    affiliation={affiliation}
                                  />

                                  <div className="ml-12 pl-3">
                                    {/*{blurb && (
                                      <div className="text-sm text-gray-600 font-normal mb-2">
                                        <div className="font-medium">Blurb</div>
                                        {blurb}
                                      </div>
                                    )}*/}
                                    {message && (
                                      <div className="text-sm text-gray-600 font-normal mb-2">
                                        <div className="font-medium">
                                          Message
                                        </div>
                                        {message}
                                      </div>
                                    )}

                                    <div className="">
                                      <Button
                                        className=""
                                        size="exSmall"
                                        loading={
                                          joinRequestApproveWorkingGroupMutation.isLoading
                                        }
                                        disabled={
                                          joinRequestApproveWorkingGroupMutation.isLoading
                                        }
                                        spinner
                                        onClick={() => {
                                          joinRequestApproveWorkingGroupMutation.mutate(
                                            id
                                          );
                                        }}
                                      >
                                        Approve
                                      </Button>
                                      <Button
                                        className="ml-4"
                                        variant="danger"
                                        size="exSmall"
                                        loading={
                                          joinRequestDeclineWorkingGroupMutation.isLoading
                                        }
                                        disabled={
                                          joinRequestDeclineWorkingGroupMutation.isLoading
                                        }
                                        spinner
                                        onClick={() => {
                                          joinRequestDeclineWorkingGroupMutation.mutate(
                                            id
                                          );
                                        }}
                                      >
                                        Decline
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {workingGroupsJoinRequests?.data &&
                          workingGroupsJoinRequests?.data?.length === 0 && (
                            <div className="text-gray-500">
                              No join requests yet.
                            </div>
                          )}

                        {(workingGroup?.data?.data?.is_owner ||
                          workingGroup?.data?.data?.is_moderator) && (
                          <div>
                            <div className="text-xl mt-4 mb-4 font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                              Invited members
                            </div>

                            {workingGroupInvitedMembers?.data?.map(
                              ({
                                id,
                                name,
                                role,
                                avatar,
                                email,
                                blurb,
                                can_resend_invitation,
                                user_id,
                                affiliation,
                              }) => (
                                <div className="mb-4" key={id}>
                                  <UserItem
                                    name={name}
                                    avatar={avatar}
                                    email={email}
                                    user_id={user_id}
                                    role={role}
                                    affiliation={affiliation}
                                  />

                                  <div className="ml-12 pl-3 text-sm font-medium">
                                    {/*{blurb && (
                                      <div className="text-sm text-gray-600 font-normal mb-2">
                                        <div className="font-medium">Blurb</div>
                                        {blurb}
                                      </div>
                                    )}*/}

                                    <div className="">
                                      <Button
                                        variant="white"
                                        size="exSmall"
                                        loading={
                                          retryInvitedMemberWorkingGroupMutation.isLoading
                                        }
                                        disabled={
                                          retryInvitedMemberWorkingGroupMutation.isLoading ||
                                          !can_resend_invitation
                                        }
                                        spinner
                                        onClick={() => {
                                          retryInvitedMemberWorkingGroupMutation.mutate(
                                            id
                                          );
                                        }}
                                        className="mr-2"
                                      >
                                        Resend
                                      </Button>
                                      <Button
                                        variant="redPale"
                                        size="exSmall"
                                        loading={
                                          deleteInvitedMemberWorkingGroupMutation.isLoading
                                        }
                                        disabled={
                                          deleteInvitedMemberWorkingGroupMutation.isLoading
                                        }
                                        spinner
                                        onClick={() => {
                                          deleteInvitedMemberWorkingGroupMutation.mutate(
                                            id
                                          );
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}

                        {workingGroupInvitedMembers?.data &&
                          workingGroupInvitedMembers?.data?.length === 0 && (
                            <div className="text-gray-500">
                              No invited members.
                            </div>
                          )}
                      </div>
                    )}

                  {activeTab ===
                    (workingGroup?.data?.data?.is_member
                      ? workingGroup?.data?.data?.is_owner ||
                        workingGroup?.data?.data?.is_moderator
                        ? 3
                        : 2
                      : 1) && (
                    <div>
                      <div className="text-xl font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                        Supplementary materials
                      </div>

                      {!supplementaryItemsData ||
                      supplementaryItemsIsFetching ? (
                        <div className="flex justify-center my-4">
                          <IconLoading />
                        </div>
                      ) : (
                        <div className="w-full">
                          {supplementaryItemsData?.data?.data?.map(
                            (item, index) => (
                              <div
                                key={index}
                                className={classNames("py-4", {
                                  "border-t border-gray-200": !!index,
                                })}
                              >
                                {item.type === "image" ? (
                                  <a
                                    href={item.file_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="relative inline-block"
                                  >
                                    <span className="absolute flex items-center justify-center w-full h-full bg-black opacity-0 bg-opacity-40 hover:opacity-100">
                                      <SearchIcon className="w-6 text-white" />
                                    </span>
                                    <img
                                      src={item.file_url}
                                      alt={item.name}
                                      width={240}
                                    />
                                  </a>
                                ) : item.type === "link" ? (
                                  <div className="">
                                    <div className="font-medium pb-2">
                                      {item.label}
                                    </div>
                                    <a
                                      href={item.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="text-violet-700"
                                    >
                                      {item.url}
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <a
                                      href={item.file_url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="flex items-center text-sm text-violet-600"
                                    >
                                      {fileIcon({ fileType: item.type })}
                                      {item.name}
                                    </a>
                                  </div>
                                )}

                                <NavLink
                                  to={withParams(routes.researcherProfile, {
                                    id: item.created_by?.id,
                                  })}
                                  className="flex items-center mt-4"
                                >
                                  <div
                                    className="flex items-center justify-center w-6 h-6 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full"
                                    style={{
                                      backgroundImage: `url(${item.created_by?.avatar})`,
                                    }}
                                  >
                                    {!item.created_by?.avatar
                                      ? get(item.created_by, "name[0]")
                                      : ""}
                                  </div>
                                  <div className="ml-2 text-sm font-medium text-violet-600 hover:text-violet-700">
                                    <div className="text-xs text-gray-500 font-normal mt-2">
                                      {format(
                                        new Date(item.created_at!),
                                        "MMM dd YYY, HH:mm"
                                      )}
                                    </div>

                                    {item.created_by?.name}
                                  </div>
                                </NavLink>
                              </div>
                            )
                          )}

                          {(!supplementaryItemsData?.data?.data ||
                            supplementaryItemsData?.data?.data?.length ===
                              0) && (
                            <div className="w-full text-center text-gray-600 mt-4">
                              No supplementary materials
                            </div>
                          )}
                        </div>
                      )}

                      {(workingGroup?.data?.data?.is_owner ||
                        workingGroup?.data?.data?.is_moderator) &&
                        workingGroup?.data?.data?.id && (
                          <Button
                            onClick={supplementaryMaterialsEditModal.openModal}
                            className="mt-4"
                          >
                            <PencilIcon className="mr-2 w-4" />
                            Edit Supplementary Materials
                          </Button>
                        )}

                      {(workingGroup?.data?.data?.is_owner ||
                        workingGroup?.data?.data?.is_moderator) &&
                        workingGroup?.data?.data?.id && (
                          <Modal
                            visible={
                              supplementaryMaterialsEditModal.modalVisible
                            }
                            onClose={supplementaryMaterialsEditModal.closeModal}
                          >
                            {supplementaryMaterialsEditModal.modalVisible && (
                              <SupplementaryMaterialsEdit
                                id={workingGroup?.data?.data?.id}
                                onSuccess={() => {
                                  queryClient.invalidateQueries(
                                    SUPPLEMENTARY_ITEMS_GET_LIST
                                  );
                                }}
                              />
                            )}

                            <Button
                              className="w-full mt-2"
                              variant="white"
                              onClick={
                                supplementaryMaterialsEditModal.closeModal
                              }
                            >
                              Close
                            </Button>
                          </Modal>
                        )}
                    </div>
                  )}

                  {activeTab ===
                    (workingGroup?.data?.data?.is_member
                      ? workingGroup?.data?.data?.is_owner ||
                        workingGroup?.data?.data?.is_moderator
                        ? 4
                        : 3
                      : 2) && (
                    <div>
                      <div className="text-xl mb-4 font-bold tracking-tight text-xl lg:text-2xl text-violet-550">
                        Updates
                      </div>

                      <div className="w-full">
                        {dataWGUpdates?.data?.data?.map(
                          (
                            {
                              id,
                              title,
                              text,
                              created_at,
                              image,
                              video_url,
                              created_by,
                            },
                            i
                          ) => (
                            <div
                              key={id}
                              className={classNames("pt-4", {
                                "border-b border-gray-200 pb-4":
                                  i + 1 !== dataWGUpdates?.data?.data?.length,
                              })}
                            >
                              <div className="font-medium">
                                {title}

                                {(workingGroup?.data?.data?.is_owner ||
                                  created_by?.id ===
                                    userResponse?.data?.id) && (
                                  <NavLink
                                    to={withParams(routes.groupUpdatesEdit, {
                                      group_id: workingGroup?.data?.data?.id,
                                      slug: workingGroup?.data?.data?.slug,
                                      id,
                                    })}
                                  >
                                    <Button
                                      type="button"
                                      variant="white"
                                      size="exSmall"
                                      className="ml-4"
                                    >
                                      Edit
                                    </Button>
                                  </NavLink>
                                )}
                              </div>

                              <div className="mt-1 text-sm text-gray-700">
                                {text && (
                                  <div
                                    className="wysiwyg-editor"
                                    dangerouslySetInnerHTML={{
                                      __html: text,
                                    }}
                                  />
                                )}
                              </div>

                              {image && (
                                <div className="mt-1">
                                  <img
                                    src={image}
                                    alt={title}
                                    className="w-full max-w-xl"
                                  />
                                </div>
                              )}

                              {video_url && (
                                <div className="mt-1">
                                  {" "}
                                  <ReactPlayer
                                    url={video_url}
                                    className="w-full max-w-xl"
                                    width="100%"
                                    height="300px"
                                  />
                                </div>
                              )}

                              <NavLink
                                to={withParams(routes.researcherProfile, {
                                  id: created_by?.id,
                                })}
                                className="flex items-center mt-2"
                              >
                                <div
                                  className="flex items-center justify-center w-6 h-6 font-medium text-white bg-gray-300 bg-center bg-cover rounded-full"
                                  style={{
                                    backgroundImage: `url(${created_by?.avatar})`,
                                  }}
                                >
                                  {!created_by?.avatar
                                    ? get(created_by, "name[0]")
                                    : ""}
                                </div>
                                <div className="ml-2 text-sm font-medium text-violet-600 hover:text-violet-700">
                                  <div className="text-xs text-gray-500 font-normal mt-2">
                                    {format(
                                      new Date(created_at!),
                                      "MMM dd YYY, HH:mm"
                                    )}
                                  </div>

                                  {created_by?.name}
                                </div>
                              </NavLink>
                            </div>
                          )
                        )}
                      </div>

                      {(!dataWGUpdates?.data?.data ||
                        dataWGUpdates?.data?.data?.length === 0) && (
                        <div className="w-full text-center text-gray-600">
                          No updates
                        </div>
                      )}

                      {(workingGroup?.data?.data?.is_owner ||
                        workingGroup?.data?.data?.is_moderator) && (
                        <div className="mt-4 border-t border-gray-200 pt-4">
                          <NavLink
                            to={withParams(routes.groupUpdatesAdd, {
                              group_id: workingGroup?.data?.data?.id,
                              slug: workingGroup?.data?.data?.slug,
                            })}
                          >
                            <Button
                              type="button"
                              variant="primary"
                              size="exSmall"
                            >
                              Add updates
                            </Button>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full pt-8" ref={ref}>
            {get(workingGroup, "data.data.id") && (
              <div className="pt-8 mt-8 border-t border-gray-200">
                <div className="lg:w-2/3">
                  <div className="flex items-center mb-3 text-xl mb-3 font-bold tracking-tight text-2xl lg:text-3xl text-violet-550">
                    Discussion
                    <Button
                      type="button"
                      variant="white"
                      size="exSmall"
                      className="ml-4"
                      onClick={() => {
                        setShowDiscussions(false);
                        queryClient.invalidateQueries(LIST_QUERY);
                        setTimeout(() => {
                          setShowDiscussions(true);
                        }, 0);
                      }}
                    >
                      <RefreshIcon className="w-3 mr-1" />
                      Refresh
                    </Button>
                  </div>

                  <div className="text-lg font-medium pb-2 text-gray-600">
                    Thoughts, ideas, comments? Ask the researchers directly by
                    posting a comment below!
                  </div>

                  {showDiscussions && (
                    <TopicsDiscussions
                      id={get(workingGroup, "data.data.id")}
                      // is_member={!!workingGroup?.data?.data?.is_member}
                      is_member={true}
                      can_create_discussion={Boolean(authToken)}
                      can_set_private={!!workingGroup?.data?.data?.is_member}
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          <button
            onClick={handleClickTop}
            className="flex items-center justify-center flex-col text-xl lg:text-2xl text-gray-650 font-semibold mt-8 w-full"
          >
            <div className="top-arrow mb-3" />
            Return To Top
          </button>
        </div>
      )}

      <Modal visible={modalVisible} onClose={closeModal}>
        <InviteMemberForm
          slug={id}
          onSubmit={() => {
            closeModal();
            queryClient.invalidateQueries(GET_INVITED_MEMBERS_WORKING_GROUP);
          }}
        />
      </Modal>

      <Modal
        visible={unauthorizedJoinModal.modalVisible}
        onClose={unauthorizedJoinModal.closeModal}
      >
        <RegisterLite redirect_to={`/working-groups/${id}?joinGroup=true`} />
      </Modal>
    </div>
  );
};
