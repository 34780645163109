import React from "react";
import classNames from "classnames";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FlagIcon } from "@heroicons/react/outline";
import { FlagIcon as FlagIconSolid } from "@heroicons/react/solid";
import { Gamification } from "./Gamification";
import { useQuery } from "react-query";
import { getUserRequest } from "../../../api/user/user";
import get from "lodash/get";

export const GamificationWrap = ({
  theme = "light",
}: {
  theme?: "dark" | "light";
}) => {
  const { data, isLoading } = useQuery("user", () => getUserRequest(), {
    suspense: false,
  });

  const profile: { [key: string]: boolean } = {
    name: !!get(data, "data.name"),
    affiliation: !!get(data, "data.affiliation"),
    avatar: !!get(data, "data.avatar"),
    social: !!get(data, "data.social") || !!get(data, "data.website"),
    location: !!get(data, "data.location"),
    bio: !!get(data, "data.bio"),
    area_of_interest: get(data, "data.area_of_interest", []).length > 0,
    tags: get(data, "data.tags", []).length > 0,
    looking_for: get(data, "data.looking_for", []).length > 0,
  };

  const profileCompletionNum = Object.keys(profile).reduce(
    (acc, item) => (profile[item] ? 1 : 0) + acc,
    0
  );

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`p-2 rounded ${
              open
                ? "text-gray-900 bg-gray-100"
                : classNames("text-opacity-90", {
                    "text-gray-700": theme === "light",
                    "text-white": theme === "dark",
                  })
            }`}
          >
            {profileCompletionNum !== 9 ? (
              <div className="relative">
                <FlagIcon className="w-4 text-yellow-500" />
                <div className="absolute w-1.5 h-1.5 bg-yellow-500 rounded-full -top-1 -right-1.5" />
              </div>
            ) : (
              <FlagIconSolid className="w-4 text-green-500" />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 w-80 max-w-sm px-4 mt-3 transform -translate-x-2/3 left-1/2 sm:px-0 lg:max-w-3xl">
              <Gamification />

            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
