import { Context } from "../types";
import { withParams } from "../utils/url";

type Menu = {
  title: string;
  page_slug: string;
  page_link: string;
  link_url: string;
  image: string;
  sub_menus: Menu[];
};

export type Article = {
  title: string;
  description: string;
  hits: number;
  iframe: string;
  image: string;
  slug: string;
};

export const getStaticPage = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<{
    title: string;
    title_align: string;
    before_title: string;
    body: string;
  }>({
    url: `/pages/${slug}`,
    method: "GET",
  });

export const getStaticSectionPage = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<{
    data: {
      title: string;
      title_align: string;
      before_title: string;
      articles_in_line?: number;
      first_alone?: boolean;
      body: {
        type: string;
        value: string;
        slides?: {
          button_label: string;
          button_link: string;
          description: string;
          iframe?: string;
          image?: string;
        }[];
        articles?: Article[];
      }[];
    };
  }>({
    url: `/pages/${slug}`,
    method: "GET",
  });

export const getStaticMenu = ({ apiClient }: Context) => () =>
  apiClient<Menu[]>({
    url: `/menus`,
    method: "GET",
  });
