import classNames from "classnames";
import { Transition } from "@headlessui/react";
import { AppContext } from "context";
import React, { Fragment, Suspense, useContext, useState } from "react";
import ReactGA from "react-ga4";

import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { routes } from "routes";
import map from "lodash/map";
import { SearchIcon } from "@heroicons/react/outline";

import { AccountDropdownMenu } from "components/AccountDropdownMenu";
import { Notifications } from "components/notifications/Notifications";
import { GamificationWrap } from "domains/my-profile/gamification/GamificationWrap";
import { TextField } from "components/text-field/TextField";
import {
  getTextButtonClassNames,
  getTextButtonBlockClassNames,
} from "components/TextButton";

import { ReactComponent as IconCross } from "assets/icons/cross.svg";
import { ReactComponent as IconLogo } from "assets/icons/logo.svg";
import { ReactComponent as IconLogoDark } from "assets/icons/logo-dark.svg";
import { ReactComponent as IconMenu } from "assets/icons/menu.svg";
import { withParams } from "../../utils/url";
import { Button } from "../button/Button";
import { useContextService } from "../../hooks/use-context-service";
import { getLGPMenu, LGP_MENU } from "../../api/dashboard";
import { useQuery } from "react-query";

const Menu = ({
  theme = "light",
  lgpMenuData = [],
}: {
  theme?: "dark" | "light";
  lgpMenuData?: LGP_MENU[];
}) => {
  const { t } = useTranslation();
  const { authToken } = useContext(AppContext);

  const [searchValue, setSearch] = useState("");
  return (
    <>
      {lgpMenuData.map((menu, i) => {
        return menu.sub_menus.length > 0 ? (
          <AccordionMenu title={menu.title}>
            {map(menu.sub_menus, (item, i) => (
              <NavLink
                to={`${item.page_slug || item.page_link}`}
                className={getTextButtonBlockClassNames({
                  variant: "primary",
                })}
                activeClassName="text-black"
                key={i}
              >
                {item.title}
              </NavLink>
            ))}
          </AccordionMenu>
        ) : (
          <NavLink
            to={`${menu.page_slug || menu.page_link}`}
            className={getTextButtonClassNames({
              variant: theme === "light" ? "primary" : "dark",
            })}
            activeClassName="text-black"
          >
            {menu.title}
          </NavLink>
        );
      })}

      {/*{window.location.pathname !== routes.projects && (
        <div className="w-40 mt-10 xl:mt-0">
          <form
            className="relative"
            onSubmit={(e) => {
              e.preventDefault();
              window.location.replace(
                withParams(routes.projects, {
                  searchQuery: searchValue,
                  ordering: "-created_at",
                })
              );
            }}
          >
            <TextField
              name="search"
              placeholder="Search"
              value={searchValue}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />

            <button type="submit" className="absolute top-2 right-2">
              <SearchIcon className="w-5 text-gray-500" />
            </button>
          </form>
        </div>
      )}*/}
    </>
  );
};

const DropdownLink: React.FC<{ title: string; theme: string }> = ({
  title,
  theme,
  children,
}) => {
  return (
    <div className="group inline-block relative">
      <button
        className={getTextButtonClassNames({
          variant: theme === "light" ? "primary" : "dark",
        })}
      >
        <span className="mr-1">{title}</span>
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      <div className="absolute z-50 w-64 hidden text-gray-700 pt-1 group-hover:block">
        <div className="origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-2 py-2">
          {children}
        </div>
      </div>
    </div>
  );
};

const AccordionMenu: React.FC<{ title: string }> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="group inline-block relative text-center mt-2">
      <button
        className={getTextButtonClassNames({ variant: "primary" })}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <span className="mr-1">{title}</span>
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </button>
      {isOpen && (
        <div className="text-gray-700 pt-1">
          <div className="">{children}</div>
        </div>
      )}
    </div>
  );
};

const DesktopMenu = ({
  theme = "light",
  lgpMenuData = [],
}: {
  theme?: "dark" | "light";
  lgpMenuData?: LGP_MENU[];
}) => {
  const { t } = useTranslation();
  const { authToken } = useContext(AppContext);

  const [searchValue, setSearch] = useState("");
  return (
    <>
      {lgpMenuData.map((menu, i) => {
        return menu.sub_menus.length > 0 ? (
          <DropdownLink title={menu.title} theme={theme}>
            {map(menu.sub_menus, (item, i) => (
              <NavLink
                to={`${item.page_slug || item.page_link}`}
                className={getTextButtonBlockClassNames({
                  variant: "primary",
                })}
                activeClassName="text-black"
                key={i}
              >
                {item.title}
              </NavLink>
            ))}
          </DropdownLink>
        ) : (
          <NavLink
            to={menu.page_slug || menu.page_link}
            className={getTextButtonClassNames({
              variant: theme === "light" ? "primary" : "dark",
            })}
            activeClassName="text-black"
          >
            {menu.title}
          </NavLink>
        );
      })}
    </>
  );
};

export const TopBar = ({ theme = "light" }: { theme?: "dark" | "light" }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const { authToken } = useContext(AppContext);
  const getLGPMenuService = useContextService(getLGPMenu);

  const { data: lgpMenuData, isFetched: lgpMenuIsFetched } = useQuery(
    "LGP_MENU_GET",
    getLGPMenuService,
    {
      suspense: false,
    }
  );

  return (
    <div
      className={classNames("w-full", {
        "bg-white shadow-sm": theme === "light",
      })}
      style={
        theme === "dark"
          ? {
              background:
                "linear-gradient(90deg, rgba(28,27,27,1) 0%, rgba(66,48,110,1) 100%)",
            }
          : {}
      }
    >
      <div className="flex items-center w-full max-w-screen-xl px-4 py-5 mx-auto md:px-8 h-20">
        <div className="flex justify-between w-full text-sm">
          <div className="flex items-center">
            <NavLink to="/" className="mr-2 md:mr-4 xl:mr-6">
              {theme === "dark" ? (
                <IconLogoDark className="w-28 sm:w-36 mb-0.5" />
              ) : (
                <IconLogo className="w-28 sm:w-36 mb-0.5" />
              )}
            </NavLink>

            <div className="items-center hidden space-x-2 xl:flex">
              <DesktopMenu theme={theme} lgpMenuData={lgpMenuData?.data} />
            </div>
          </div>

          <div className="flex items-center">
            {authToken ? (
              <Suspense fallback={<div>loading</div>}>
                <div className="relative mr-0.5">
                  <GamificationWrap theme={theme} />
                </div>
                <div className="relative mr-2">
                  <Notifications theme={theme} />
                </div>
                <AccountDropdownMenu theme={theme} />
              </Suspense>
            ) : (
              <>
                <NavLink
                  to={routes.register}
                  className={`ml-2 ${getTextButtonClassNames({
                    variant: theme === "light" ? "primary" : "dark",
                  })}`}
                  onClick={() => {
                    // ReactGA.event({
                    //   category: "User",
                    //   action: "Clicked the sign up button",
                    // });
                  }}
                >
                  <Button
                    variant={theme === "light" ? "primary" : "white"}
                    size="small"
                    rounded="rounded-full"
                  >
                    <span className="text-xs sm:text-sm sm:font-semibold">
                      SIGN UP NOW
                    </span>
                  </Button>
                </NavLink>

                <span
                  className={`ml-1 ${getTextButtonClassNames({
                    variant: theme === "light" ? "primary" : "dark",
                  })}`}
                >
                  |
                </span>

                <NavLink
                  to={routes.login}
                  className={`ml-1 ${getTextButtonClassNames({
                    variant: theme === "light" ? "primary" : "dark",
                  })}`}
                >
                  Sign in
                </NavLink>
              </>
            )}

            <button
              type="button"
              className={classNames("block ml-4 xl:hidden md:ml-8", {
                "text-gray-700": theme === "light",
                "text-white": theme === "dark",
              })}
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <IconMenu />
            </button>
          </div>
        </div>
      </div>

      <Transition
        show={menuOpen}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={classNames(
            "fixed top-0 w-full h-full z-50 bg-black bg-opacity-30"
            /*{
              "-inset-x-full": !menuOpen,
              "left-0": menuOpen,
            }*/
          )}
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <div
            className={classNames(
              "absolute w-5/6 h-full z-50 bg-gray-100 transition-all",
              {
                /*"-translate-x-full": !menuOpen,
              "translate-x-0": menuOpen,*/
              }
            )}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              type="button"
              className="absolute text-gray-900 top-6 right-6"
            >
              <IconCross
                onClick={() => {
                  setMenuOpen(false);
                }}
              />
            </button>

            <div className="flex flex-col items-center justify-center w-full h-full">
              <Menu lgpMenuData={lgpMenuData?.data} />
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
};
