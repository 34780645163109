import React, { useEffect } from "react";
// import { routes } from "../../routes";
// import { NavLink } from "react-router-dom";
import { useContextService } from "../../hooks/use-context-service";
import { getStaticPage } from "../../api/static-pages";
import { useQuery } from "react-query";
import get from "lodash/get";
import {ReactComponent as IconLoading} from "../../assets/icons/loading.svg";
import {Helmet} from "react-helmet";

const WG_FAQ = 'WG_FAQ';

export const WgFaq = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [WG_FAQ],
    () => getStaticPageService({ slug: "working-group-faq" }),
    {
      suspense: false,
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pb-12 mx-auto">
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.title}</title>
          </Helmet>

          <h2 className="my-2 text-3xl font-bold">{data?.data?.title}</h2>

          {data?.data && (
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{ __html: data?.data?.body }}
            />
          )}
        </div>
      )}

      {/*<h2 className="mt-2 mb-6 text-3xl font-bold">Working group FAQ</h2>

      <div className="text-gray-700">
        <div className="pb-4 text-xl font-bold pt-4">
          What are Working Groups?
        </div>

        <div className="pb-4">
          Working Groups are a designated space for scientific collaboration and
          conversation. The aim of the Working Group is to generate more
          inclusive discussion, facilitate deeper understanding of a research
          topic, and to catalyze innovative ideas; simply put, it’s a space to
          ask and answer all your medical research-related questions.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          How can I use Working Groups?
        </div>
        <div className="pb-4">
          Working Groups are all about community interaction! As a Working Group
          creator, you can post about a topic/idea you’re interested in, define
          your goals, and moderate membership. Discussion is a huge part of it.
          Engage your community to take part as much as possible. Often, the
          Working Group is just the first step to taking an interesting idea and
          turning it into a testable hypothesis. Collaboration early on not only
          helps your chances of successfully funding a resulting project
          proposal, but it can also lead to higher-quality research with broader
          impact.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          Can anyone start a Working Group?
        </div>
        <div className="pb-4">
          Yes — all you need is a Let’s Get Proof user account!
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          Who can post/comment in a Working Group?
        </div>
        <div className="pb-4">All members of the working group.</div>

        <div className="pb-4 text-xl font-bold pt-4">
          Who can do what in a Working Group?
        </div>
        <div className="p-5 rounded bg-gray-100 text-sm mb-8 text-gray-700">
          <b>Member:</b>
          <ul className="ml-4 mb-4 list-disc">
            <li>Can add topic</li>
            <li>Can add comments</li>
          </ul>
          <b>Moderator:</b>
          <ul className="ml-4 mb-4 list-disc">
            <li>Can add topic</li>
            <li>Can add comments</li>
            <li>Can invite/see members</li>
            <li>Can add materials</li>
          </ul>
          <b>Admin:</b>
          <ul className="ml-4 mb-4 list-disc">
            <li>Can add topic</li>
            <li>Can add comments</li>
            <li>Can invite/see members</li>
            <li>Can see/accept/decline join requests</li>
            <li>Can add materials</li>
          </ul>

          <p>Note: Only owner can delete group</p>
          <p>
            Note: Only superuser and creator of the comment can delete comment
          </p>
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          How can I join a working group?
        </div>
        <div className="pb-4">
          You can browse Working Groups according to your areas of interest. If
          you see one you’d like to participate in, simply click on the “Request
          to join” button at the top right of the page. (Feel free to add a
          message to the moderator.) If the request is approved, you will be
          notified on the site as well as with an email. Check out the available
          Working Groups here:
          <br />
          <NavLink to={routes.workingGroups} className="text-violet-700">
            https://www.letsgetproof.com/working-groups
          </NavLink>
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          How is the discussion structured?
        </div>
        <div className="pb-4">
          The discussion is broken up into Topics and Replies. Topics are
          broader categories meant to capture the main themes of the Working
          Group. Each Working Group starts off with a “General” category, with
          members being able to add as many subsequent categories as they see
          fit. Replies are nested within the topics and are meant to get into
          the “nitty gritty” of the Topics.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          What is the voting function for?
        </div>
        <div className="pb-4">
          This is an easy way to indicate which Topics or Replies are currently
          the most helpful/most interesting. The idea is to let the community
          guide which content is positively contributing to the Working Group’s
          goals, or even the site as a whole. The eventual idea is that the
          posts with the most upvotes will become the most visible, directly
          guiding new users to the platform to “hot topics”. If you like
          something, let the community know!
        </div>

        <div className="pb-4 text-xl font-bold pt-4">How does voting work?</div>
        <div className="pb-4">
          Each post (Topic or Reply) has an up or down arrow, with a number in
          the center. The number represents the overall sum of the up and down
          votes. You cannot vote on your own posts, but we encourage you to let
          others know what you think - ideally with a vote <i>and</i> a comment!
        </div>
        <div className="pb-4">
          <u>Side note:</u> because the voting system is meant to let others
          know what is positively contributing and what isn’t, please be
          cautious of <i>why</i> you may be downvoting something - don’t do it
          because you personally disagree/don’t like it. Instead, post a
          comment! Often, respectful disagreements lead to the most interesting
          and productive conversations.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          Who can see the discussion of the Working Group?
        </div>
        <div className="pb-4">
          Members of the Working Group can decide whether a post is to be made
          public (for anyone who visits the site to see) or kept visible only to
          their fellow members.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          Can I add images and attachments to my posts?
        </div>
        <div className="pb-4">
          Currently no, but we’re working on it! We know the importance of being
          able to communicate through images - stay tuned, we hope to add this
          functionality soon.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">Can I edit my posts?</div>
        <div className="pb-4">
          Yes, you can edit or delete your own Topics or Replies at any time. If
          a post has been edited, the date of editing will appear to let others
          know it’s been changed.
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          How can I get my Working Group noticed?
        </div>
        <div className="pb-4">
          Be creative with your title, keep your description short but catchy,
          and use images/videos! While optional, an image (or video) is often
          the most eye-catching piece of a Working Group. (It also helps to have
          interesting imagery for posting on social media, etc...)
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          Can I create multiple Working Groups?
        </div>
        <div className="pb-4">
          Yes, but it’s important to be able to build momentum around your
          interests to get the maximal benefit of the Working Group module. We
          encourage users to start with one, and to host a maximum of two at a
          time. You can always come back again at a later date for more!
        </div>

        <div className="pb-4 text-xl font-bold pt-4">
          What if I can’t find the topic I’m looking for?
        </div>
        <div className="pb-4">
          If there is a category you’re interested in that we haven’t covered,
          please reach out to our team at{" "}
          <a href="mailto:contact@letsgetproof.com" className="text-violet-700">
            contact@letsgetproof.com
          </a>
          , or use the contact form on the site. We’ll do our best to
          accommodate your ideas! Otherwise, feel free to create your own
          Working Group and see where it goes!
        </div>
      </div>*/}
    </div>
  );
};
