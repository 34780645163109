import { Context } from "../types";
import { withParams } from "../utils/url";
import { UserInfo } from "./types";
import { apiUrl, axiosInstance, getHeaders } from "./index";

type createdBy = {
  id: number;
  email: string;
  name: string;
  role: string;
  title?: string;
  avatar?: string;
};

export interface WorkingGroupData {
  id?: string;
  slug?: string;
  title?: string;
  tags?: any;
  summary?: string;
  goals_of_working_group?: any;
  other_goals_of_wg?: string;
  industry_partner_likely?: "yes" | "no";
  role_of_industry_partner?: any;
  other_role_of_industry_partner?: string;
  member_obligations?: string;
  image?: string;
  has_join_request?: boolean;
  is_owner?: boolean;
  is_member?: boolean;
  is_moderator?: boolean;
  embedded_link?: string;
  additional_info?: string;
  created_by?: UserInfo;
  active_status?: string;
  approved_status?: string;
  discussions?: string;
  comments?: string;
  members?: string;
  supplementary_materials?: {
    item_id?: string;
    name?: string;
    label?: string;
    url?: string;
    file_type?: string;
    type?: string;
    video_url?: string;
    file_url?: string;
  }[];
}

export interface WorkingGroupMember {
  id: string;
  email: string;
  role: string;
  name: string;
  title: string;
  blurb?: string;
  avatar: string;
  avatar_thumbnail: string;
  message: string;
  user_id: string;
  affiliation: string;
  can_resend_invitation?: boolean;
  occupation: string[];
}

export type WGUpdates = {
  id?: string;
  title?: string;
  text?: string;
  created_at?: string;
  image?: string;
  video_url?: string;
  file_url?: string;
  approved_status?: string;
  created_by?: createdBy;
};

export const getWorkingGroupsList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 1,
  searchQuery,
  ordering,
  tags,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
  ordering?: string;
  tags?: string[];
}) =>
  apiClient<{
    data: WorkingGroupData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/groups`, {
      page,
      perPage,
      search: searchQuery,
      ordering,
      tags,
    }).replace(/(%5B1%5D|%5B0%5D)/g, ""),
    method: "GET",
  });

export const getMyWorkingGroupsList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 1,
  searchQuery,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
}) =>
  apiClient<{
    data: WorkingGroupData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/me/groups`, {
      page,
      perPage,
      search: searchQuery,
      ordering: "-created_at",
    }).replace(/(%5B1%5D|%5B0%5D)/g, ""),
    method: "GET",
  });

export const getMyWorkingGroupsJoinedList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 100,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
}) =>
  apiClient<{
    data: WorkingGroupData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/me/groups/joined`, {
      page,
      perPage,
    }),
    method: "GET",
  });

export const getMyWorkingGroupsAppliedList = ({ apiClient }: Context) => ({
  page = 1,
  perPage = 100,
}: {
  page?: number;
  perPage?: number;
  searchQuery?: string;
}) =>
  apiClient<{
    data: WorkingGroupData[];
    pagination: { currentPage: number; total: number; perPage: number };
  }>({
    url: withParams(`/me/groups/applied`, {
      page,
      perPage,
    }),
    method: "GET",
  });

export const getWorkingGroupGoals = ({ apiClient }: Context) => () =>
  apiClient<{ id: number; title: string; slug: string }[]>({
    url: withParams(`/groups/goals-of-working-group`),
    method: "GET",
  });

export const getWorkingGroupRolesOfPartner = ({ apiClient }: Context) => () =>
  apiClient<{ id: number; title: string; slug: string }[]>({
    url: withParams(`/groups/role-of-industry-partner`),
    method: "GET",
  });

export const getWorkingGroup = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient<{ data: WorkingGroupData }>({
    url: withParams(`/me/groups/${id}`),
    method: "GET",
  });

export const getWorkingGroupPublic = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<{ data: WorkingGroupData }>({
    url: withParams(`/groups/${slug}`),
    method: "GET",
  });

export const getWorkingGroupMembers = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/groups/${slug}/members`),
    method: "GET",
  });

export const getJoinRequestsWorkingGroup = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/groups/${slug}/join-requests`),
    method: "GET",
  });

export const getInvitedMembersWorkingGroup = ({ apiClient }: Context) => ({
  slug,
}: {
  slug: string;
}) =>
  apiClient<WorkingGroupMember[]>({
    url: withParams(`/groups/${slug}/invited-members`),
    method: "GET",
  });

export const createWorkingGroup = ({ apiClient }: Context) => (
  data: WorkingGroupData
) =>
  apiClient<{ data: WorkingGroupData }>({
    url: withParams("/me/groups"),
    method: "POST",
    data,
  });

export const patchWorkingGroup = ({ apiClient }: Context) => (
  id: string,
  data: WorkingGroupData
) =>
  apiClient<{ data: WorkingGroupData }>({
    url: withParams(`/me/groups/${id}`),
    method: "PATCH",
    data,
  });

export const inviteMemberWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  data: { email: string; message: string }
) =>
  apiClient<{ data: { email: string; message: string } }>({
    url: withParams(`/groups/:slug/invite-member`, { slug }),
    method: "POST",
    data,
  });

export const confirmMemberWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  invite_hash: string
) =>
  apiClient<{ data: { email: string; message: string } }>({
    url: withParams(`/groups/:slug/invited-members/:invite_hash`, {
      slug,
      invite_hash,
    }),
    method: "POST",
    data: {},
  });

export const leaveMemberWorkingGroup = ({ apiClient }: Context) => (
  slug: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/members/leave`, { slug }),
    method: "POST",
    data: {},
  });

export const joinRequestWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  message: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/join-requests`, { slug }),
    method: "POST",
    data: { message },
  });

export const joinRequestCancelWorkingGroup = ({ apiClient }: Context) => (
  slug: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/join-requests`, { slug }),
    method: "DELETE",
  });

export const joinRequestApproveWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/join-requests/:member_id/accept`, {
      slug,
      member_id,
    }),
    method: "POST",
    data: {},
  });

export const removeMemberWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/members/:member_id`, {
      slug,
      member_id,
    }),
    method: "DELETE",
  });

export const changeMemberRoleWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  member_id: string,
  role: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/members/:member_id`, {
      slug,
      member_id,
    }),
    method: "PUT",
    data: {
      role,
    },
  });

export const joinRequestDeclineWorkingGroup = ({ apiClient }: Context) => (
  slug: string,
  member_id: string
) =>
  apiClient({
    url: withParams(`/groups/:slug/join-requests/:member_id/decline`, {
      slug,
      member_id,
    }),
    method: "POST",
    data: {},
  });

export const uploadImageWorkingGroup = ({ apiClient }: Context) => (
  id: string,
  data: any
) =>
  apiClient({
    url: withParams(`/me/groups/${id}/set-image`),
    method: "POST",
    data,
    headers: {
      "content-type": "multipart/form-data",
    },
  });

export const deleteWorkingGroup = ({ apiClient }: Context) => ({
  id,
}: {
  id: string;
}) =>
  apiClient({
    url: withParams("/me/groups/:id", { id }),
    method: "DELETE",
  });

export const deleteInvitedMemberWorkingGroup = ({ apiClient }: Context) => ({
  slug,
  id,
}: {
  slug: string;
  id: string;
}) =>
  apiClient({
    url: withParams("/groups/:slug/invited-members/:id", { slug, id }),
    method: "DELETE",
  });


export const retryInvitedMemberWorkingGroup = ({ apiClient }: Context) => ({
  slug,
  id,
}: {
  slug: string;
  id: string;
}) =>
  apiClient({
    url: withParams("/groups/:slug/resend-invitation/:id", { slug, id }),
    method: "PUT",
    data:{},
  });

export const getWGUpdatesPublicList = ({ apiClient }: Context) => ({
  group_id,
}: {
  group_id: string;
}) =>
  apiClient<{
    data: WGUpdates[];
  }>({
    url: withParams("/groups/:group_id/post", {
      group_id,
      perPage: 99,
    }),
    method: "GET",
  });

export const getWGUpdatesList = ({ apiClient }: Context) => ({
  group_id,
}: {
  group_id: string;
}) =>
  apiClient<{
    data: WGUpdates[];
  }>({
    url: withParams("/me/groups/:group_id/post", {
      group_id,
      perPage: 99,
    }),
    method: "GET",
  });

export const getWGUpdatesSingle = ({ apiClient }: Context) => ({
  group_id,
  id,
}: {
  group_id: string;
  id: string;
}) =>
  apiClient<{ data: WGUpdates }>({
    url: withParams("/me/groups/:group_id/post/:id", { group_id, id }),
    method: "GET",
  });

export const postWGUpdate = ({ apiClient }: Context) => ({
  group_id,
  data,
}: {
  group_id: string;
  data: WGUpdates;
}) =>
  apiClient({
    url: withParams("/me/groups/:group_id/post", { group_id }),
    method: "POST",
    data,
  });

export const patchWGUpdate = ({ apiClient }: Context) => ({
  group_id,
  id,
  data,
}: {
  group_id: string;
  id: string;
  data: WGUpdates;
}) =>
  apiClient({
    url: withParams("/me/groups/:group_id/post/:id", { group_id, id }),
    method: "PATCH",
    data,
  });

export const WGSetImageRequest = ({
  id,
  group_id,
  data,
}: {
  id: string;
  group_id: string;
  data: any;
}) =>
  axiosInstance.post(
    `${apiUrl()}${withParams("/me/groups/:group_id/post/:id/set-image", {
      id,
      group_id,
    })}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
    }
  );

export const supplementaryWGItemsGet = ({ apiClient }: Context) => ({
  group_id,
}: {
  group_id: string | number;
}) =>
  apiClient<{
    data: { id?: string; name: string; created_at: string; type: string; url: string; file_url: string; label: string; created_by: UserInfo }[];
  }>({
    url: withParams("/groups/:group_id/supplementary-materials", {
      group_id,
      perPage: 100,
    }),
    method: "GET",
  });

export const wgSetSupplementaryFileRequest = ({
  group_id,
  data,
  setLoaded,
}: {
  group_id: string;
  data: any;
  setLoaded: (l: number) => void;
}): Promise<{
  data: {
    data: { id?: string; name: string; type: string; url: string };
  };
}> =>
  axiosInstance.post(
    `${apiUrl()}${withParams("/me/groups/:group_id/supplementary-materials", {
      group_id,
    })}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setLoaded((progressEvent.loaded / progressEvent.total) * 100);
      },
    }
  );

export const wgUpdateSupplementaryFileRequest = ({
  id,
  group_id,
  data,
  setLoaded,
}: {
  id: string;
  group_id: string;
  data: any;
  setLoaded: (l: number) => void;
}): Promise<{
  data: {
    data: { id?: string; name: string; type: string; url: string };
  };
}> =>
  axiosInstance.put(
    `${apiUrl()}${withParams("/me/groups/:group_id/supplementary-materials/:id", {
      id,
      group_id,
    })}`,
    data,
    {
      headers: {
        ...getHeaders().headers,
        "content-type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        setLoaded((progressEvent.loaded / progressEvent.total) * 100);
      },
    }
  );

export const wgSupplementaryDeleteRequest = ({
  group_id,
  id,
}: {
  group_id: string;
  id: string;
}) => {
  return axiosInstance.delete(
    `${apiUrl()}${withParams(
      "/me/groups/:group_id/supplementary-materials/:id",
      {
        group_id,
        id,
      }
    )}`,
    getHeaders()
  );
};

export const supplementaryItemsGet = ({ apiClient }: Context) => ({
  group_id,
}: {
  group_id: string;
}) =>
  apiClient<{ id?: string; name: string; type: string; url: string }[]>({
    url: withParams("/me/groups/:group_id/supplementary-materials", {
      group_id,
      perPage: 100,
    }),
    method: "GET",
  });
