import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import get from "lodash/get";
import map from "lodash/map";

import { TextField } from "components/text-field/TextField";
import { TextareaField } from "components/textarea-field/TextareaField";
import { Button } from "components/button/Button";
import { ImageUploadField } from "components/image-upload-field/ImageUploadField";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";
import { RichEditorField } from "components/rich-editor-field/RichEditorField";

import { changeMeRequest } from "api/auth/auth";
import { meSetAvatarRequest, meSetBackgroundRequest } from "api/user/user";
import { User } from "api/types";
import { setFormError } from "utils/setError";
import { uploadImage } from "utils/uploadImage";
import { USER_QUERY_KEY } from "queries/use-user-query";
import { getProjectsTagsRequest } from "api/project/project";
import { pipe } from "fp-ts/function";
import { useStringRequired } from "hooks/use-validation-input";
import { SelectField } from "../../../components/select-field/SelectField";
import { CheckField } from "../../../components/check-field/CheckField";

interface MeForm {
  current_password?: string;
  title?: string;
  name?: string;
  affiliation?: string;
  location?: string;
  website?: string;
  social?: string;
  bio?: string;
  address?: string;
  profile_background?: string;
  avatar?: string;
  area_of_interest?: any;
  tags?: any;
  looking_for?: any;
  gender?: any;
  race?: any;
  ethnicity?: string;
  career_stage?: string;
  age_group?: string;
  // blurb?: string;
  profile_is_private?: boolean;
}

const invalidType = { invalid_type_error: "This field is required" };

const useFormSchema = () =>
  z.object({
    name: pipe(z.string(invalidType), useStringRequired()),
    current_password: pipe(z.string(invalidType), useStringRequired()),
    profile_background: z.string(invalidType).nullable().optional(),
    avatar: z.string(invalidType).nullable().optional(),
    title: z.string().nullable().optional(),
    profile_is_private: z.boolean().nullable().optional(),
    location: z.string().nullable().optional(),
    social: z.string(invalidType).nullable().optional(),
    affiliation: z.string().nullable().optional(),
    website: z.string().nullable().optional(),
    bio: z.string().nullable().optional(),
    address: z.string().nullable().optional(),
    area_of_interest: z.number().array(),
    tags: z.string().array(),
    looking_for: z.string().array(),
    race: z.string().array(),
    gender: z.string(),
    age_group: z.string(),
    // blurb: z.string(),
    ethnicity: z.string(),
    career_stage: z.string(),
  });

export const EditProfileForm = ({ user }: { user: User }) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
    control,
    setValue,
  } = useForm<MeForm>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),

    // @ts-ignore
    defaultValues: {
      ...user,
      area_of_interest: map(
        get(user, "area_of_interest", []),
        (item) => item.id
      ),
      tags: map(get(user, "tags", []), (item) => item),
      looking_for: get(user, "looking_for", []),
      race: get(user, "race").length
        ? get(user, "race", [])
        : ["prefer_not_to_disclose"],
      age_group: get(user, "age_group") || "prefer_not_to_disclose",
      gender: get(user, "gender") || "prefer_not_to_disclose",
      ethnicity: get(user, "ethnicity") || "prefer_not_to_disclose",
      career_stage: get(user, "career_stage") || "prefer_not_to_disclose",
    },
  });

  const queryClient = useQueryClient();

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const { data: projectTags } = useQuery(
    `getProjectTags`,
    getProjectsTagsRequest,
    {
      suspense: false,
    }
  );

  const changeMeQuery = useMutation(changeMeRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      setFormSuccessMessage("Profile has been successfully updated.");
    },
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const uploadAvatarQuery = useMutation(meSetAvatarRequest);
  const uploadBackgroundQuery = useMutation(meSetBackgroundRequest);

  const onSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    try {
      await uploadImage(data.avatar, uploadAvatarQuery);
    } catch (e) {
      console.log(get(e, "response"));
      setError("avatar", {
        type: "error",
        message: get(e, "response.data.messages.file[0]", "Update error"),
      });
    }

    try {
      await uploadImage(data.profile_background, uploadBackgroundQuery);
    } catch (e) {
      setError("profile_background", {
        type: "error",
        message: get(e, "response.data.messages.file[0]", "Update error"),
      });
    }

    changeMeQuery.mutate({
      ...data,
      profile_background: undefined,
      avatar: undefined,
      email: user?.email!,
    });
  };

  return (
    <div className="">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <h2 className="text-lg font-medium text-black mb-2">Edit Profile</h2>

          <div className="mt-5">
            <ImageUploadField
              view="small-rounded"
              label="Avatar"
              name="avatar"
              error={formState.errors?.avatar}
              control={control}
              setValue={setValue}
              aspect={1}
            />
          </div>

          <div className="mt-5">
            <ImageUploadField
              label="Profile background"
              name="profile_background"
              error={formState.errors?.profile_background}
              control={control}
              setValue={setValue}
            />
          </div>

          <div className="mt-5">
            <div className="font-medium pb-1">Privacy</div>
            <CheckField
              label="Only show my name, affiliation & avatar"
              description="Uncheck this box to also show your biography, websites, and projects you've supported"
              error={formState.errors.profile_is_private}
              {...register(`profile_is_private`)}
            />
          </div>

          <div className="w-full mt-5 mb-5">
            <TextField
              label="Name"
              placeholder="Name"
              error={formState.errors?.name}
              required
              {...register("name", { required: true })}
            />
          </div>
          <div className="w-full mb-5">
            <TextField
              label="Title"
              placeholder="Title"
              error={formState.errors?.title}
              {...register("title")}
            />
          </div>
          <div className="w-full mb-5">
            <TextField
              label="Affiliation(s)"
              placeholder="Affiliation(s)"
              error={formState.errors?.affiliation}
              {...register("affiliation")}
            />
          </div>
          <div className="w-full mb-5">
            <TextField
              label="Location"
              placeholder="Location"
              error={formState.errors?.location}
              {...register("location")}
            />
          </div>
          <div className="w-full mb-5">
            <TextField
              label="Website"
              placeholder="http://website.com"
              autoComplete="off"
              error={formState.errors?.website}
              {...register("website")}
            />
          </div>
          <div className="w-full mb-5">
            <TextareaField
              label="Social"
              placeholder="Social"
              error={formState.errors?.social}
              {...register("social")}
            />
          </div>
          <div className="w-full mb-5">
            <RichEditorField
              label="Bio"
              placeholder="Tell us a bit about yourself! This can include your research interests, but also your side projects, hobbies, favorite vacation spot - anything you feel like sharing to help connect to the Let’s Get Proof community!"
              name="bio"
              error={formState.errors?.bio}
              control={control}
            />
          </div>

          <div className="w-full mb-5">
            <TextareaField
              label="Address"
              placeholder="Address"
              error={formState.errors?.address}
              {...register("address")}
            />
          </div>

          {/*<div className="w-full mb-5">
            <TextareaField
              label="Blurb"
              placeholder="Blurb"
              error={formState.errors?.blurb}
              {...register("blurb")}
            />
          </div>*/}

          <div className="w-full mb-5">
            <MultiSelectField
              label="Area(s) of interest"
              error={formState.errors?.area_of_interest}
              name="area_of_interest"
              control={control}
              options={map(get(projectTags, "data"), ({ id, title }) => ({
                value: id,
                label: title,
              }))}
            />
          </div>

          <div className="w-full mt-5">
            <MultiSelectField
              label="What are you looking for with Let’s Get Proof?"
              error={get(formState, "errors.looking_for[0]")}
              name="looking_for"
              control={control}
              options={[
                {
                  value: "want_to_fund_research",
                  label: "I am looking to fund research",
                },
                {
                  value: "looking_for_funding",
                  label: "I am looking for funding",
                },
                {
                  value: "interested_in_collaboration",
                  label:
                    "I am interested in advancing the understanding of a medical problem/issue through collaboration",
                },
                {
                  value: "looking_for_help",
                  label: "I am looking for help/guidance/mentorship",
                },
                {
                  value: "want_to_help",
                  label: "I would like to help researchers with study designs",
                },
                {
                  value: "want_participate",
                  label:
                    "I would like to participate in contributing patients with rare diseases to collaborative studies",
                },
              ]}
            />
          </div>

          <div className="w-full mt-5">
            <MultiSelectField
              label="Profile tags"
              error={get(formState, "errors.tags[0]")}
              name="tags"
              placeholder="Select your top two"
              control={control}
              options={[
                {
                  value: "MEDICAL_DOCTOR",
                  label: "Medical doctor (MD)",
                },
                {
                  value: "CLINICAL_RESEARCHER",
                  label: "Clinical researcher (MD/PhD)",
                },
                {
                  value: "ALLIED_HEALTHCARE_PROFESSIONAL",
                  label: "Allied healthcare professional (AHP)",
                },
                {
                  value: "CAREGIVER",
                  label: "Caregiver",
                },
                {
                  value: "SURVIVOR",
                  label: "Survivor",
                },
                {
                  value: "OTHER",
                  label: "Other",
                },
              ]}
              max={2}
            />
          </div>

          {get(user, "is_project_creator", false) && (
            <div className="mt-5">
              <div className="w-full mt-8 px-4 py-2 text-yellow-800 bg-yellow-100 border border-yellow-400 rounded">
                <h3 className="font-semibold text-lg text-gray-900">
                  Why are we asking this?
                </h3>
                <div className="text-sm">
                  A major goal of Let’s Get Proof is to promote equity, diversity,
                  and inclusion. Collecting this information will give a sense
                  of how we are doing and guide us on how we can improve. For
                  more information, click here
                </div>
              </div>

              <div className="w-full mt-5">
                <SelectField
                  label="Gender"
                  placeholder="Select"
                  options={[
                    {
                      value: "male",
                      label: "Male",
                    },
                    {
                      value: "female",
                      label: "Female",
                    },
                    {
                      value: "non-binary",
                      label: "Non-binary",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.gender}
                  {...register("gender", { required: true })}
                />
              </div>

              {/*<div className="w-full mt-5">
              <MultiSelectField
                label="Race"
                name="race"
                control={control}
                defaultFirst
                options={[
                  {
                    value: "american_indian_or_alaska_native",
                    label: "American Indian or Alaska Native",
                  },
                  {
                    value: "asian",
                    label: "Asian",
                  },
                  {
                    value: "black_or_african_american",
                    label: "Black or African American",
                  },
                  {
                    value: "native_hawalan_or_other_pacific_islander",
                    label: "Native Hawaiian or Other Pacific Islander",
                  },
                  {
                    value: "white",
                    label: "White",
                  },
                  {
                    value: "prefer_not_to_disclose",
                    label: "Prefer not to disclose",
                  },
                ]}
                onChange={(val) => {
                  if (
                    val.length &&
                    val[val.length - 1].value === "prefer_not_to_disclose"
                  ) {
                    setValue("race", ["prefer_not_to_disclose"]);
                  } else if (
                    val.length &&
                    val.some((item) => item.value === "prefer_not_to_disclose")
                  ) {
                    setValue(
                      "race",
                      val
                        .filter(
                          (item) => item.value !== "prefer_not_to_disclose"
                        )
                        .map((item) => item.value)
                    );
                  }
                }}
              />
            </div>*/}

              {/*<div className="w-full mt-5">
              <SelectField
                label="Ethnicity"
                placeholder="Select"
                options={[
                  {
                    value: "",
                    label: "Select",
                  },
                  {
                    value: "hispanic_or_latino",
                    label: "Hispanic or Latino",
                  },
                  {
                    value: "not_hispanic_or_latino",
                    label: "Not Hispanic or Latino",
                  },
                  {
                    value: "prefer_not_to_disclose",
                    label: "Prefer not to disclose",
                  },
                ]}
                error={formState.errors?.ethnicity}
                {...register("ethnicity", { required: true })}
              />
            </div>*/}

              <div className="w-full mt-5">
                <SelectField
                  label="Age group"
                  placeholder="Select"
                  options={[
                    {
                      value: "18_to_20",
                      label: "18 to 20",
                    },
                    {
                      value: "21_to_29",
                      label: "21 to 29",
                    },
                    {
                      value: "30_to_39",
                      label: "30 to 39",
                    },
                    {
                      value: "40_to_49",
                      label: "40 to 49",
                    },
                    {
                      value: "50_to_59",
                      label: "50 to 59",
                    },
                    {
                      value: "60_to_older",
                      label: "60 or older",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.age_group}
                  {...register("age_group", { required: true })}
                />
              </div>

              <div className="w-full mt-5">
                <SelectField
                  label="Career stage"
                  placeholder="Select"
                  options={[
                    {
                      value: "early_career_researcher",
                      label: "Early career researcher",
                    },
                    {
                      value: "junior_staff",
                      label: "Junior staff",
                    },
                    {
                      value: "senior_staff",
                      label: "Senior staff",
                    },
                    {
                      value: "prefer_not_to_disclose",
                      label: "Prefer not to disclose",
                    },
                  ]}
                  error={formState.errors?.career_stage}
                  {...register("career_stage", { required: true })}
                />
              </div>
            </div>
          )}

          <div className="w-full my-5">
            <TextField
              label="Password"
              placeholder="Password"
              type="password"
              error={formState.errors?.current_password}
              {...register("current_password", { required: true })}
            />
          </div>
          {formErrorMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="button"
            className="w-full"
            onClick={handleSubmit(onSubmit)}
            loading={
              changeMeQuery.isLoading ||
              uploadAvatarQuery.isLoading ||
              uploadBackgroundQuery.isLoading
            }
            spinner
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
