import React, { useContext } from "react";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { googleLogin, facebookLogin } from "./services";
import { AppContext } from "../../../context";

export const SSO = () => {
  const { dispatch } = useContext(AppContext);

  return (
    <div className='flex flex-col items-center'>
      <div className="mb-4">
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            const res = await googleLogin(credentialResponse.credential!);
            dispatch({ type: "SET_AUTH_TOKEN", token: res.data.token });
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>

      <div>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID!}
          autoLoad={false}
          fields="name,email,picture"
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              style={{
                backgroundColor: "#5268B4",
                width: "210px",
                height: "40px",
              }}
              className="text-white text-sm rounded font-medium"
            >
              Login with Facebook
            </button>
          )}
          callback={async (credentialResponse) => {
            if ("accessToken" in credentialResponse) {
              const res = await facebookLogin(credentialResponse.accessToken!);
              dispatch({ type: "SET_AUTH_TOKEN", token: res.data.token });
            }
          }}
        />
      </div>
    </div>
  );
};
