import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { get } from "lodash";

import { TextField } from "components/text-field/TextField";
import { Button } from "components/button/Button";

import { affiliationSetupRequest } from "api/auth/auth";
import { User } from "api/types";
import { setFormError } from "utils/setError";
import { USER_QUERY_KEY } from "queries/use-user-query";
import { pipe } from "fp-ts/function";
import { useStringRequired, useValidEmail } from "hooks/use-validation-input";

export const VALIDATE_PROJECT_PERMISSION = "VALIDATE_PROJECT_PERMISSION";

interface AffiliationsFormSchema {
  affiliation: string;
  university_profile_page_url: string;
  university_affiliate_email: string;
}

const invalidType = { invalid_type_error: "This field is required" };

const useFormSchema = () =>
  z.object({
    affiliation: z.string().optional().nullable(),
    university_profile_page_url: z.string().optional().nullable(),
    university_affiliate_email: pipe(
      z.string(invalidType),
      useStringRequired(),
      useValidEmail()
    ),
  });

export const AffiliationsForm = ({ user }: { user: User }) => {
  const {
    register,
    handleSubmit,
    formState,
    setError,
  } = useForm<AffiliationsFormSchema>({
    mode: "onChange",
    resolver: zodResolver(useFormSchema()),

    // @ts-ignore
    defaultValues: {
      ...user,
    },
  });

  const queryClient = useQueryClient();

  const [formErrorMessage, setFormErrorMessage] = useState<
    string | undefined
  >();
  const [formSuccessMessage, setFormSuccessMessage] = useState<
    string | undefined
  >();

  const changeMeQuery = useMutation(affiliationSetupRequest, {
    onSuccess: () => {
      queryClient.invalidateQueries(USER_QUERY_KEY);
      queryClient.invalidateQueries(VALIDATE_PROJECT_PERMISSION);
      setFormSuccessMessage("Affiliations has been successfully updated. Please check your email (including your spam folder).");
    },
    onError: (e) => {
      setFormError(get(e, "response.data"), setFormErrorMessage, setError);
    },
  });

  const onSubmit = async (data: any) => {
    setFormSuccessMessage(undefined);
    setFormErrorMessage(undefined);

    changeMeQuery.mutate({
      ...data,
    });
  };

  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <h2 className="text-lg font-medium text-black mb-2">Please, fill your affiliations</h2>

          <div className="w-full mb-5">
            <TextField
              label="University affiliate email"
              placeholder="University affiliate email"
              error={formState.errors?.university_affiliate_email}
              required
              {...register("university_affiliate_email", { required: true })}
            />
          </div>

          <div className="w-full mb-5">
            <TextField
              label="Affiliation"
              placeholder="Affiliation"
              error={formState.errors?.affiliation}
              {...register("affiliation", { required: true })}
            />
          </div>

          <div className="w-full mb-5">
            <TextField
              label="University profile page url"
              placeholder="University profile page url"
              error={formState.errors?.university_profile_page_url}
              {...register("university_profile_page_url", { required: true })}
            />
          </div>

          {formErrorMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
              {formErrorMessage}
            </div>
          )}

          {formSuccessMessage && (
            <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
              {formSuccessMessage}
            </div>
          )}

          <Button
            type="submit"
            disabled={changeMeQuery.isLoading}
            className="w-full"
            loading={changeMeQuery.isLoading}
            spinner
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
};
