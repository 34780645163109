import React from "react";
// import { PlayIcon } from "@heroicons/react/solid";
// import ImageMayankGoyal from "assets/images/MayankGoyal.jpg";
// import ImageRosalieMcDonough from "assets/images/RosalieMcDonough.jpg";
// import ImageJohannaOspel from "assets/images/JohannaOspel.jpg";
// import ImageAravindGanesh from "assets/images/AravindGanesh.jpg";
// import ImageArnuvMayank from "assets/images/ArnuvMayank.jpg";
// import ImageJeffSaver from "assets/images/JeffSaver.jpg";
// import ImageMichaelHill from "assets/images/MichaelHill.jpg";
// import ImageAlexLemnaru from "assets/images/AlexLemnaru.jpg";
import {useContextService} from "../../hooks/use-context-service";
import {getStaticPage} from "../../api/static-pages";
import {useQuery} from "react-query";
import get from "lodash/get";
import {ReactComponent as IconLoading} from "../../assets/icons/loading.svg";
import classNames from "classnames";
import {Helmet} from "react-helmet";

const ABOUT_REQ = 'ABOUT_REQ';

export const About = () => {
  const getStaticPageService = useContextService(getStaticPage);
  const { data, isFetching, error } = useQuery(
    [ABOUT_REQ],
    () => getStaticPageService({ slug: "about-us" }),
    {
      suspense: false,
    }
  );

  // const [videoPlaying, setVideoPlaying] = useState(false);

  // const playVideo = () => {
  //   setVideoPlaying(true);
  //
  //   videoRef.current.play();
  // };

  // const videoRef = useRef<any>();

  return (
    <div className="pb-12 mx-auto">
      {error ? (
        <div className="w-full px-4 py-2 mb-5 text-sm text-red-800 bg-red-100 rounded">
          {get(error, "response.data.messages.detail", "Something went wrong")}
        </div>
      ) : isFetching ? (
        <div className="flex justify-center mt-24">
          <IconLoading />
        </div>
      ) : (
        <div>
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.title}</title>
          </Helmet>

          {data?.data?.before_title && (
            <h3
              className={classNames("my-2 text-xl font-bold", {
                "text-center": data?.data?.title_align === "CENTER",
                "text-right": data?.data?.title_align === "RIGHT",
              })}
            >
              {data?.data?.before_title}
            </h3>
          )}
          <h2
            className={classNames("my-2 text-3xl font-bold", {
              "text-center": data?.data?.title_align === "CENTER",
              "text-right": data?.data?.title_align === "RIGHT",
            })}
          >
            {data?.data?.title}
          </h2>

          {data?.data && (
            <div
              className="wysiwyg-editor"
              dangerouslySetInnerHTML={{ __html: data?.data?.body }}
            />
          )}
        </div>
      )}

      {/*<h2 className="mt-2 mb-6 text-3xl font-bold">About Us</h2>

      <div className="text-gray-700">
        <div className="pb-4 font-semibold">
          Let’s Get Proof brings together Dynamic Review™ and PrecisionFunding™ to
          make medical research more efficient, inclusive, and impactful
        </div>
        <div className="pb-4">
          Medical research has made incredible strides over the last decade
          alone. But we could do better. There are many great ideas out there,
          but way too numerous hurdles to executing them. Despite the potential
          impact, medical research is severely underfunded. It lacks
          transparency. It lacks agency. It lacks connection.
        </div>
        <div className="pb-4">
          We at Let’s Get Proof believe in the wisdom of the many. We want to
          leverage the power of PrecisionFunding™ and Dynamic Review™ to
          catalyze innovation and incentivize radical ideas from people all over
          the world - to accelerate discovery in the field of medicine
          and cultivate a better and healthier world for us all.
        </div>

        <div className="py-4 text-xl font-bold">How it works:</div>
        <div className="relative max-w-screen-lg mx-auto mb-8">
          <video
            ref={videoRef}
            src="/assets/demo.mp4"
            preload="auto"
            controls
            className={`w-full mx-auto rounded-md shadow-xl ${
              videoPlaying ? "opacity-100" : "opacity-0"
            }`}
          />
          {!videoPlaying && (
            <>
              <img
                alt=""
                src="/assets/video-thumbnail.png"
                className="absolute top-0 w-full h-full rounded-md"
              />
              <button
                onClick={playVideo}
                className="absolute top-0 flex items-center justify-center w-full h-full overflow-hidden transition-all duration-200 bg-purple-100 bg-opacity-50 border-0 rounded-md shadow-xl outline-none hover:bg-opacity-60 hover:bg-violet-200 group hover:shadow-2xl"
              >
                <PlayIcon className="w-24 h-24 text-[#5d39af] transition-all bg-violet-200 rounded-full opacity-90 group-hover:w-28 group-hover:h-28" />
              </button>
            </>
          )}
        </div>

        <div className="py-4 text-xl font-bold">Objectives:</div>
        <ul className="pb-4 ml-4 list-disc">
          <li>
            To provide a global platform to facilitate discovery in the field of
            medicine by and for doctors/healthcare personnel, patients,
            citizen donors, and other interested stakeholders
          </li>
          <li>
            To broaden the scientific process to include a stage of Dynamic
            Review™ where members of the platform can discuss, collaborate,
            and/or financially contribute to medical research
          </li>
          <li>
            To promote education and continuing professional development in the
            field of medical research
          </li>
          <li>
            To promote high quality intellectual, ethical, and professional
            standards
          </li>
          <li>
            To ensure transparency (of how funds are being used, of the design
            process)
          </li>
          <li>
            To host large-scale competitions to tackle particularly relevant medical challenges/topics
          </li>
        </ul>

        <div className="py-4 text-xl font-bold">Team</div>
        <div className="pb-4">
          While we are a registered Canadian company, we think of ourselves as
          citizens of the world. Our team is diverse in location, ethnicity, and
          gender, but held together by the shared passion of engaging other
          motivated people for more focused and impactful research. We are
          researchers, doctors, entrepreneurs, inventors – all bringing our
          collective ideas and energy into pursuing the Let’s Get Proof mission,
          from startup to its fullest realization.
        </div>

        <div className="grid lg:grid-cols-2 gap-8 mt-8">
          <div className="flex  lg:mr-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageMayankGoyal}
                alt="Mayank Goyal"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Mayank Goyal
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                President and Founder
              </div>
              <div className="text-sm pb-1">
                Although officially launched in 2022, Let’s Get Proof represents a
                long-standing dream of Mayank’s: a collaborative, global
                platform to increase the efficiency and funding of high-quality,
                high-impact research. A clinician-scientist with a successful
                academic career in innovation and impact, Mayank is intimately
                acquainted with the drawbacks of the current systems. In his
                role as a mentor, he has seen the struggles early-career
                researchers face; as a trialist, he has had to cut through
                countless layers of red tape to bring meaningful research to the
                forefront; as an innovator, he has felt the resistance to “out
                of the box ideas”; as a citizen of the world, he has seen the
                huge potential for global collaboration. Let’s Get Proof represents
                a comprehensive solution – and is Mayank’s hopeful way of the
                future.
              </div>
            </div>
          </div>
          <div className="flex lg:ml-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageRosalieMcDonough}
                alt="Rosalie McDonough"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Rosalie (Rose) McDonough
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Chief Executive Officer
              </div>
              <div className="text-sm pb-1">
                Rose has always been fascinated by the incredible advances
                humans have made through scientific discovery, sometimes through
                hard work, sometimes through serendipity, often due to a
                combination of both. From an early age, she wanted to be a part
                of the movement and to make her own small contribution to the
                progress. This desire substantially shaped her life, leading her
                to pursue a graduate degree in biology. The shift to medicine
                came after she became aware of the realities of the funding
                landscape, with lots of excellent, innovative ideas remaining
                unfulfilled. As it is with scientific discovery, a combination
                of hard work and a series of serendipitous events during her
                radiology residency led her to Let’s Get Proof, where she, together
                with the Let’s Get Proof community, sees an exciting chance to help
                more great ideas come to light.
              </div>
            </div>
          </div>
          <div className="flex lg:ml-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageAlexLemnaru}
                alt="Alex Lemnaru"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Alexandru (Alex) Lemnaru
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Chief Technology Officer
              </div>
              <div className="text-sm pb-1">
                Have you met an IT person that only understands technology, but
                not business? Or a business person that doesn’t understand
                technology? Or a researcher that doesn’t understand the other
                two? Alex aims to bridge the gap between technology, business
                and research by being fluent in all three domains. In his
                career, Alex has worn a number of hats: programmer, security
                analyst, manager, researcher, research director, company founder
                and many more. His projects span clinical trial management
                solutions, secure research data platforms and may others. Alex
                is the founder of Diamind Solutions Inc., the key technology
                partner for Let’s Get Proof. Being familiar with both the
                technological challenges, as well as the funding gap for new
                research projects, he has decided to join Let’s Get Proof as our
                Chief Technology Officer and help bring to light our vision for
                this platform.
              </div>
            </div>
          </div>

          <div className="flex  lg:mr-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageJohannaOspel}
                alt="Johanna Ospel"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Johanna Ospel
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Chief Scientific Officer
              </div>
              <div className="text-sm pb-1">
                Johanna is a radiology resident at the University of Basel,
                Switzerland and research fellow at the University of Calgary,
                Canada. She did her PhD at the University of Amsterdam, the
                Netherlands. Her main research interest is imaging and treatment
                of acute ischemic stroke. She has co-authored over 140
                peer-reviewed manuscripts, is a co-investigator of the
                ESCAPE-MeVO trial, EVOLVE-Cog study and PI of the ESCAPE-OPTIMUS
                study, which are focused on different aspects of ischemic
                stroke. Making the funding process in neurovascular research
                less time-consuming, more effective and accessible through
                Let’s Get Proof would be a dream come true for Johanna, since she
                herself has spent hundreds of hours with grant-writing, and
                re-formatting, and re-writing, and re-reformatting… you can see
                where the story goes. In her free time, she enjoys cycling
                (currently she cycles to work every day, crossing the
                Swiss-German country border each time), and indulges in her
                coffee addiction, as you would expect from a proper young stroke
                researcher.
              </div>
            </div>
          </div>
          <div className="flex lg:ml-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageAravindGanesh}
                alt="Aravind Ganesh"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Aravind Ganesh
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Chief Knowledge Officer
              </div>
              <div className="text-sm pb-1">
                Aravind is a Vascular and Cognitive Neurologist. He has
                extensive training in clinical epidemiology, imaging, trials,
                and mixed-methods research, having earned an MD from the
                University of Calgary, DPhil in Clinical Neurosciences from the
                University of Oxford as a Rhodes scholar, and a post-doctoral
                fellowship in stroke and cognitive neurology. He is actively
                involved in the development of best-practice guidelines for
                stroke and dementia care. He is passionate about medical
                education and peer review – serving on the editorial boards of
                Neurology, Neurology: Clinical Practice, and Stroke – but is
                also a stand-up comedy connoisseur.
              </div>
            </div>
          </div>

          <div className="flex  lg:mr-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageArnuvMayank}
                alt="Arnuv Mayank"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Arnuv Mayank
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Chief Strategy Officer
              </div>
              <div className="text-sm pb-1">
                Arnuv is a 4th-year undergraduate student at the University of
                Calgary, pursuing a BComm in Finance and a BSc in Mathematics.
                Growing up in a household of physicians, however, Arnuv also
                inevitably grew an attachment to medicine and the health care
                industry as a whole. When he saw Let’s Get Proof operating in the
                intersection of all of these areas, Arnuv wanted to jump in as
                soon as possible. He saw the opportunity to apply his classroom
                knowledge to solve a problem that he really cares about, all
                while experiencing the exciting world of entrepreneurship. Arnuv
                has thoroughly enjoyed working with and learning from such a
                close-knit and talented team.
              </div>
            </div>
          </div>
          <div className="flex lg:ml-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageJeffSaver}
                alt="Jeff Saver"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Jeffrey Saver
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Key Partner KOL
              </div>
              <div className="text-sm pb-1">
                Jeff is a stroke neurologist whose research aims to improve
                stroke treatment, brain and blood vessel imaging, and clinical
                trial design. He was drawn to this work by the desire to help
                others, fascination with the brain, and the appeal of a highly
                collaborative field. In the course of his career he has
                published research with over 2000 collaborators. Over and over
                again he has seen helpful insights from individuals with
                expertise in both allied and distant fields dramatically improve
                a study approach. Like all researchers, he also is keenly aware
                that timely funding can make the difference between achieving an
                important advance versus missing a key opportunity. He is
                excited by the potential for the Let’s Get Proof platform to become
                a unique joint workspace for accelerating scientific discovery.
              </div>
            </div>
          </div>

          <div className="flex  lg:mr-8">
            <div className="w-28 sm:w-36">
              <img
                src={ImageMichaelHill}
                alt="Michael Hill"
                className="w-full rounded-lg"
              />
            </div>

            <div className="flex-1 text-gray-700 ml-4 lg:ml-6">
              <div className="font-semibold text-gray-900 pb-2">
                Michael Hill
              </div>
              <div className="font-semibold text-sm text-gray-900 pb-2">
                Key Partner KOL
              </div>
              <div className="text-sm pb-1">
                Michael is a stroke doctor, based in Calgary who works on
                clinical trials in stroke therapeutics and on the epidemiology
                of stroke. He is interested in and part of efforts in extending
                high quality stroke care throughout the health system.
                Implementation science is one of many underfunded areas of
                investigation. The evolution of health research funding from
                funding almost exclusively bench science to funding more and
                more clinical health research continues to stretch available
                resources. Peer review is the best of a bunch of difficult
                systems for evaluating research proposals. Let’s Get Proof is a
                novel, mixed crowd-sourcing and crowd-reviewing concept for both
                improving clinical research and expanding funding beyond
                traditional sources. The potential of Let’s Get Proof moves well
                beyond stroke care to all types and topics of clinical
                investigation.
              </div>
            </div>
          </div>
        </div>
      </div>*/}
    </div>
  );
};
