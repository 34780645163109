import { AppContext } from "context";
import { NotFound as NotFoundException } from "errors/NotFound";
import { Unauthenticated } from "errors/Unauthenticated";
import { Unauthorized } from "errors/Unauthorized";
import { Unconfirmed } from "errors/Unconfirmed";
import { ReactNode, useContext, useEffect } from "react";
import { FallbackProps } from "react-error-boundary";
import { useTranslation } from "react-i18next";
import { routes } from "routes";
import { Button } from "./button/Button";
import { Card } from "./card/Card";
import { NotFound } from "./NotFound";

const Wrapper = ({ children }: { children: ReactNode }) => (
  <div className="fixed inset-0 flex flex-col items-center justify-center w-full h-full bg-gray-100">
    <div className="max-w-md mx-auto text-center">
      <Card>{children}</Card>
    </div>
  </div>
);

export const ErrorBoundary = ({ resetErrorBoundary, error }: FallbackProps) => {
  const { t } = useTranslation();

  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    if (error instanceof Unauthenticated) {
      dispatch({ type: "SET_AUTH_TOKEN", token: null });

      window.location.replace(routes.login);
    }
  }, [error, dispatch]);

  if (error instanceof Unauthenticated) {
    return null;
  }

  if (error instanceof NotFoundException) {
    return (
      <Wrapper>
        <NotFound resetErrorBoundary={resetErrorBoundary} />
      </Wrapper>
    );
  }

  const getTitle = (error: Error) => {
    if (error instanceof Unauthorized) {
      return t("You are not allowed to perform this action.");
    }

    if (error instanceof Unconfirmed) {
      return t(
        "Please confirm your account using the link you've received via email."
      );
    }

    return t("Something went wrong.");
  };

  return (
    <Wrapper>
      <h3 className="mb-2 text-lg font-medium text-gray-900">
        {getTitle(error)}
      </h3>
      <Button variant="white" onClick={resetErrorBoundary}>
        {t("Try again")}
      </Button>
    </Wrapper>
  );
};
