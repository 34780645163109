import classNames from "classnames";
import { forwardRef, PropsWithoutRef, ReactNode } from "react";
import { FieldProps } from "types/field";
import { getFieldIds } from "utils/field";
import { FieldError } from "../FieldError";
import { FieldLabel } from "../FieldLabel";

export type CheckFieldProps = Omit<
  PropsWithoutRef<JSX.IntrinsicElements["input"]>,
  "className" | "children" | "type"
> &
  FieldProps & {
    description?: string | ReactNode;
    label: string;
    labelClassName?: string;
  };

export const CheckField = forwardRef<HTMLInputElement, CheckFieldProps>(
  ({ label, id, name, description, labelClassName, error, ...props }, ref) => {
    const { computedId, errorId } = getFieldIds({ id, name });

    return (
      <div>
        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              ref={ref}
              {...props}
              type="checkbox"
              id={computedId}
              name={name}
              className={classNames(
                "w-4 h-4 rounded",
                error
                  ? "border-red-300 text-red-800 focus:ring-red-500"
                  : "border-gray-300 text-violet-600 focus:ring-violet-500"
              )}
            />
          </div>
          <div className="ml-3 text-sm">
            {label && <FieldLabel htmlFor={computedId}><span className={labelClassName}>{label}</span></FieldLabel>}
            {description && <p className="text-gray-500">{description}</p>}
          </div>
        </div>
        {error && <FieldError id={errorId}>{error.message}</FieldError>}
      </div>
    );
  }
);
