import React, { FC, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { routes } from "routes";
import { ReactComponent as IconLogo } from "../../assets/icons/logo.svg";
import { confirmUser } from "api/account";
import { Badge } from "components/badge/Badge";
import { LoadingSpinner } from "components/loading-spinner/LoadingSpinner";
import { AppContext } from "context";
import { useContextService } from "hooks/use-context-service";
import {withParams} from "../../utils/url";

const Wrapper: FC = ({ children }) => (
  <div className="flex flex-col items-center justify-center w-full min-h-screen">
    <IconLogo className="w-40 mb-4" />
    {children}
  </div>
);

export const ConfirmUser = () => {
  const history = useHistory();

  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location?.search), [
    location,
  ]);

  const { dispatch } = useContext(AppContext);

  const confirmUserMutation = useMutation(useContextService(confirmUser), {
    onSuccess: (response) => {
      dispatch({ type: "SET_AUTH_TOKEN", token: response.data?.access! });

      setTimeout(() => {
        if (params.get("redirect_to")) {
          history.push(params.get("redirect_to")!);
        } else {
          history.push(withParams(routes.dashboard, { openPersonalization: 'true' }));
        }
      }, 2000);
    },
  });

  const confirmUserMutationRef = useRef(confirmUserMutation);

  useEffect(() => {
    confirmUserMutationRef.current.mutate({ token: params.get("token")! });
  }, [params]);

  const { t } = useTranslation();

  if (confirmUserMutation.isLoading) {
    return (
      <Wrapper>
        <LoadingSpinner className="w-6 text-purple-600" />
      </Wrapper>
    );
  }

  if (confirmUserMutation.isSuccess) {
    return (
      <Wrapper>
        <Badge variant="success">
          {t("Your email has been successfully verified.")}
        </Badge>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Badge variant="danger">
        {t("Invalid or expired confirmation code.")}
      </Badge>
    </Wrapper>
  );
};
