import React, {useState} from "react";

import { XIcon } from "@heroicons/react/solid";

import { Contact } from "./Contact";
// import { FAQ } from "./FAQ";
import { FeaturesNew } from "./FeaturesNew";
import { FooterHome } from "components/footer/FooterHome";
import { HeroNew } from "./HeroNew";
import { Info } from "./Info";
import { OurSystem } from "./OurSystem";
import { TopBar } from "components/topbar/TopBar";

import { ReactComponent as IconLogoOldWhite } from "../../assets/icons/logo-old-white.svg";
import { ReactComponent as IconLogoWhite } from "../../assets/icons/logo-white.svg";
import Cookies from "js-cookie";

const BANNER_NAME = "top_banner";

export const Landing = () => {
  const [banner, setBanner] = useState(!!Cookies.get(BANNER_NAME));

  const giveBanner = () => {
    setBanner(true);

    Cookies.set(BANNER_NAME, "true", { expires: 365 });
  };


  return (
    <div>
      <div className="w-full relative z-50">
        {/*{!banner && <div
          className="topbar relative justify-center text-white overflow-hidden"
        >
          <div className="max-w-screen-2xl flex flex-wrap justify-between items-center  px-4 mx-auto md:px-8">
            <div className='flex flex-wrap items-center'>
              <div
                className="topbar-arrow flex items-center justify-between px-8 text-sm font-medium mr-4"
              >
                <IconLogoOldWhite className="w-40" />
                &nbsp; &nbsp;
                <span>has become</span>
              </div>
              <IconLogoWhite className="w-48" />
            </div>

            <div className="font-medium pr-8">
              new name. renewed vision. better medicine together.
            </div>
          </div>

          <div className="topbar-search-icon" />

          <button type="button" className='absolute right-8 top-6 mt-0.5' onClick={giveBanner}><XIcon className='w-5 text-white' /></button>
        </div>}*/}
        <TopBar />
      </div>
      <div className="relative z-10">
        <HeroNew />
        <div id="about">
          <FeaturesNew />
        </div>
        {/*<OurSystem />*/}
        {/*<Info />*/}
        {/*<FAQ />*/}

        <div id="contact">
          <Contact />
        </div>
        <FooterHome />
      </div>
    </div>
  );
};
