import React, { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import classNames from "classnames";
import { get } from "lodash";

import { CheckField } from "components/check-field/CheckField";
import { Button } from "components/button/Button";

import { setFormError } from "utils/setError";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { NotificationType, putNotifications } from "api/notifications";
import { MultiSelectField } from "components/multi-select-field/MultiSelectField";
import { useContextService } from "hooks/use-context-service";

const useFormSchema = () =>
  z.object({
    notifications: z
      .object({
        is_active: z.boolean(),
        // enabled_aggregation_rules: z.string().array().min(1, "Required"),
        enabled_delivery_methods: z.string().array().min(1, "Required"),
      })
      .array(),
  });

type FormSchema = z.infer<ReturnType<typeof useFormSchema>>;

export const NotificationsForm = ({
  notificationData,
}: {
  notificationData: NotificationType[];
}) => {
  const { register, control, handleSubmit, formState, setError } =
    useForm<FormSchema>({
      mode: "onChange",
      resolver: zodResolver(useFormSchema()),
      defaultValues: {
        notifications: notificationData.map((item) => ({
          is_active: item.is_active,
          // enabled_aggregation_rules: item.enabled_aggregation_rules,
          enabled_delivery_methods: item.enabled_delivery_methods,
        })),
      },
    });

  const { fields } = useFieldArray({
    control,
    name: "notifications",
  });

  const [formErrorMessage, setFormErrorMessage] =
    useState<string | undefined>();
  const [formSuccessMessage, setFormSuccessMessage] =
    useState<string | undefined>();

  const putNotificationsQuery = useMutation(
    useContextService(putNotifications),
    {
      onSuccess: () => {
        setFormSuccessMessage(
          "Notification settings have been successfully updated."
        );
      },
      onError: (e) => {
        setFormError(get(e, "response.data"), setFormErrorMessage, setError);
      },
    }
  );

  const onSubmit = (data: FormSchema) => {
    const result = data.notifications.map((item, index) => ({
      ...notificationData[index],
      ...item,
    }));

    putNotificationsQuery.mutate(result);
  };

  return (
    <div className="max-w-lg">
      <form onSubmit={handleSubmit(onSubmit)} className="">
        <h2 className="text-lg font-medium text-black mb-4">Notifications</h2>

        <ul>
          {fields.map((item, index) => (
            <li key={item.id} className="border-b border-gray-200 p-4 odd:bg-gray-50">
              <div className="w-full">
                <CheckField
                  label={notificationData[index].name}
                  description={notificationData[index].description}
                  error={get(
                    formState.errors,
                    `notifications[${index}].is_active`
                  )}
                  {...register(`notifications.${index}.is_active`)}
                />

                <div
                  className={classNames("mt-4", {
                    hidden:
                      notificationData[index].delivery_methods.length <=
                      1,
                  })}
                >
                  <MultiSelectField
                    label="Enabled delivery methods"
                    placeholder="Enabled delivery methods"
                    error={get(
                      formState.errors,
                      `notifications[${index}].enabled_delivery_methods`
                    )}
                    name={`notifications.${index}.enabled_delivery_methods`}
                    control={control}
                    defaultFirst
                    options={notificationData[index].delivery_methods.map(
                      (rule) => ({
                        label:
                          rule[0].toUpperCase() +
                          rule.slice(1).replace(/_/g, " "),
                        value: rule,
                      })
                    )}
                  />
                </div>

                {notificationData[index].delivery_methods.length === 1 && (
                  <div className="flex items-center mt-4">
                    <div className="text-sm font-medium text-gray-800">
                      Enabled delivery methods
                    </div>
                    {notificationData[index].enabled_delivery_methods.map(
                      (item, i) => (
                        <span
                          className="inline-flex items-center px-2 py-1 rounded text-sm bg-blue-100 text-blue-800 mx-1"
                          key={i}
                        >
                          {item.replace(/_/g, " ")}
                        </span>
                      )
                    )}
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>

        {formErrorMessage && (
          <div className="w-full py-2 px-4 rounded mb-5 bg-red-100 text-sm text-red-800">
            {formErrorMessage}
          </div>
        )}

        {formSuccessMessage && (
          <div className="w-full py-2 px-4 rounded mb-5 bg-green-100 text-sm text-green-800">
            {formSuccessMessage}
          </div>
        )}

        <Button
          type="submit"
          className="w-full"
          disabled={putNotificationsQuery.isLoading}
          loading={putNotificationsQuery.isLoading}
        >
          Save
        </Button>
      </form>
    </div>
  );
};
