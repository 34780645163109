import React from "react";
import { useQuery } from "react-query";

import { useContextService } from "hooks/use-context-service";
import {
  getLectureSeriesSingle,
  getLectureSeriesLatest,
} from "api/lecture-series";
import { ReactComponent as IconLoading } from "assets/icons/loading.svg";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import ReactGA from "react-ga4";

interface ParamTypes {
  id: string;
}

export const LectureSeriesSingle = ({ isLatest }: { isLatest?: boolean }) => {
  const { id } = useParams<ParamTypes>();
  const getLectureSeriesSingleQuery = useContextService(getLectureSeriesSingle);
  const getLectureSeriesLatestQuery = useContextService(getLectureSeriesLatest);

  const { data, isLoading, error } = useQuery(
    `GET_LECTURE_SERIES_SINGLE`,
    () =>
      isLatest
        ? getLectureSeriesLatestQuery()
        : getLectureSeriesSingleQuery(id),
    {
      suspense: false,
    }
  );

  return isLoading && !data ? (
    <div className="flex items-center justify-between min-h-full">
      <IconLoading />
    </div>
  ) : (
    <div>
      {!error ? (
        <div>
          <Helmet>
            <title>Let’s Get Proof | {data?.data?.data?.title}</title>
          </Helmet>

          <h2 className="text-lg font-bold mb-2">{data?.data?.data?.title}</h2>
          <div className="flex justify-between mb-4">
            <div>
              <span className="text-gray-700 text-sm">Name of Speaker</span>
              <br />
              <span className="font-medium">{data?.data?.data?.author}</span>
            </div>
          </div>

          <div className="mb-3 text-gray-700">
            <span className="text-gray-700 text-sm">Summary</span>
            <br />
            <div className="">{data?.data?.data?.summary}</div>
          </div>

          <div className="mb-4">
            {data?.data?.data?.tags?.map(({ title }, i) => (
              <span
                key={i}
                className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800 mr-1 mb-1"
              >
                {title}
              </span>
            ))}
          </div>

          <div className="mb-2">
            <video controls className="w-full" poster={data?.data?.data?.image} onClick={() => {
              // ReactGA.event({
              //   category: 'User',
              //   action: 'Lecture Series Clicks on video'
              // });
            }}>
              <source src={data?.data?.data?.video_file_url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ) : (
        <div className="w-full px-4 py-2 font-medium border mb-4 rounded bg-red-100 text-red-600">
          Error
        </div>
      )}
    </div>
  );
};
